import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebaseConfig';
import './Login.css';
import {
  Description as DescriptionIcon,
  Image as ImageIcon,
  Calculate as CalculateIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import TermsAndPrivacy from './TermsAndPrivacy';



function Login({ setIsAuthenticated, setIsDemoMode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const accessToken = tokenResponse.access_token;
      setIsLoading(true);

      try {
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        const userInfo = userInfoResponse.data;

        const credential = GoogleAuthProvider.credential(null, accessToken);
        await signInWithCredential(auth, credential);

        localStorage.setItem('googleToken', accessToken);
        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        setIsAuthenticated(true);
        navigate('/');
      } catch (error) {
        console.error("Authentication error:", error);
        setError("Failed to sign in with Google. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    scope: 'https://www.googleapis.com/auth/spreadsheets',
  });

  const handleDemoMode = () => {
    setIsDemoMode(true);
    navigate('/');
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <div className="login-card">
          <div className="icon-container">
            <span className="lock-icon">🔒</span>
          </div>
          <h1>新規登録・ログイン</h1>
          <p>
            Specifics-INへようこそ。<br />
            Google認証を使用してログインしてください。
          </p>
          <button className="google-button" onClick={() => googleLogin()} disabled={isLoading}>
            {isLoading ? (
              <span className="loading-spinner"></span>
            ) : (
              <>
                <span className="google-icon">G</span>
                Googleでサインイン
              </>
            )}
          </button>
          <button className="demo-button" onClick={handleDemoMode}>
            DEMOモードを開始
          </button>
          {error && <p className="error-message">{error}</p>}
          <div className="terms" style={{ position: 'relative', paddingBottom: '50px' }}>
            <p>
              サインインすることで、利用規約
              と
              プライバシーポリシー
              に同意したことになります。
            </p>
              <TermsAndPrivacy />
          </div>
        </div>
      </div>
      <div className="feature-section">
        <h2>Specifics-IN</h2>
        <div className="feature-grid">
          <FeatureCard
            icon={<DescriptionIcon fontSize="large" />}
            title="テンプレート管理"
            description="複数のスプレッドシートに対して一貫した変更を効率的に適用します。"
          />
          <FeatureCard
            icon={<ImageIcon fontSize="large" />}
            title="AIタイトル生成"
            description="AIを使用して最適なタイトルを自動生成します。"
          />
          <FeatureCard
            icon={<CalculateIcon fontSize="large" />}
            title="AI商品説明挿入"
            description="AIを使用して最適な商品説明を自動生成します。"
          />
          <FeatureCard
            icon={<CloudUploadIcon fontSize="large" />}
            title="Specificsを挿入可能"
            description="スプレッドシートからeBayへのアップロードをスムーズに行い、出品プロセスを効率化します。"
          />
        </div>
        <h2 className="feature-footer">
          効率的な出品で、eBayビジネスを次のレベルへ
        </h2>
      </div>
    </div>
  );
}

function FeatureCard({ icon, title, description }) {
  return (
    <div className="feature-card">
      <div className="feature-icon">{icon}</div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default Login;