import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { initializeApp } from 'firebase/app';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  Paper,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  TextField,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Snackbar,
  Alert,
  Switch,
  FormControlLabel
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import LinkIcon from '@mui/icons-material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import HelpIcon from '@mui/icons-material/Help';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SettingsIcon from '@mui/icons-material/Settings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LaunchIcon from '@mui/icons-material/Launch';
import EditableSpreadsheet from './EditableSpreadsheet';
import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
import InteractiveManual from './InteractiveManual';
import { generateTitles, createUpdatedSpreadsheetData, validateAndProcessTitles, overwriteExistingTitles, truncateTitles, preparePreviewData, updateTitlesFromPreview } from './titleGenerationService';
import ColumnDeletion from './ColumnDeletion';
import APIKeyInput from './APIKeyInput';
import PreviewDialog from './PreviewDialog';
import TermsAndPrivacy from './TermsAndPrivacy';
import ImageDownloader from './ImageDownloader';
import ImageUrlReplacer from './ImageUrlReplacer';
import CustomColumnSettings from './CustomColumnSettings';
import PriceCalculationSettings from './PriceCalculationSettings';
import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// import ItemSpecificsManager from './ItemSpecificsManager';
import ItemSpecificsSettings from './ItemSpecificsSettings';
import ItemSpecificsApplier from './ItemSpecificsApplier';
import DemoMode from './DemoMode';
import Login from './Login';
import './App.css';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

function AppContent({ setIsAuthenticated, isDemoMode, setIsDemoMode }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [currentSpreadsheet, setCurrentSpreadsheet] = useState({ id: '', name: '', sheetName: '' });
  const [savedSpreadsheets, setSavedSpreadsheets] = useState([]);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sheetNames, setSheetNames] = useState([]);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [analysisResults, setAnalysisResults] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCustomSettingsOpen, setIsCustomSettingsOpen] = useState(false);
  const [customSettings, setCustomSettings] = useState(null);
  const [spreadsheetData, setSpreadsheetData] = useState([]);
  const [isPriceCalculationOpen, setIsPriceCalculationOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isItemSpecificsSettingsOpen, setIsItemSpecificsSettingsOpen] = useState(false);
  const [itemSpecificsSettings, setItemSpecificsSettings] = useState([]);
  const [generatedTitles, setGeneratedTitles] = useState([]);
  const [previewData, setPreviewData] = useState([]);

  const navigate = useNavigate();

  // handleSignOut関数をuseCallbackでメモ化
  const handleSignOut = useCallback(async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('googleToken');
      localStorage.removeItem('userInfo');
      setIsAuthenticated(false);
      setIsDemoMode(false);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out", error);
    }
  }, [navigate, setIsAuthenticated, setIsDemoMode]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser && !isDemoMode) {
        const fetchUserSettings = async () => {
          await loadUserSettings(currentUser.uid);
        };
        fetchUserSettings();
      } else if (!currentUser && !isDemoMode) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate, isDemoMode]);

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.clear();
      console.log('Local storage cleared');
    };
    
    // URLパラメータでクリアを制御
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('clearStorage') === 'true') {
      clearLocalStorage();
    }
  }, []);

  const loadUserSettings = async (userId) => {
    try {
      const docRef = doc(db, "userSettings", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setCustomSettings(userSettings);
        // 他の必要な設定の更新
      }
    } catch (error) {
      console.error('Failed to load user settings:', error);
      setSnackbar({ open: true, message: 'ユーザー設定の読み込みに失敗しました', severity: 'error' });

    }
  };

  useEffect(() => {
    const savedToken = localStorage.getItem('googleToken');
    if (savedToken) {
      setToken(savedToken);
    }
    const saved = JSON.parse(localStorage.getItem('savedSpreadsheets')) || [];
    setSavedSpreadsheets(saved);
  }, []);

  const checkAndRefreshToken = useCallback(async () => {
    const currentToken = localStorage.getItem('googleToken');
    if (!currentToken && !isDemoMode) {
      setError('No access token found. Please sign in again.');
      return false;
    }
    setToken(currentToken);
    return true;
  }, [isDemoMode]);

  // fetchSheetNames関数をuseCallbackでメモ化し、handleSignOutを依存に追加
  const fetchSheetNames = useCallback(async (spreadsheetId) => {
    if (!await checkAndRefreshToken()) return;
    if (!spreadsheetId) {
      setError('Please provide a Spreadsheet ID');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      if (isDemoMode) {
        // DEMOモードの場合、ダミーデータを返す
        const dummySheets = ['Sheet1', 'Sheet2', 'Sheet3'];
        setSheetNames(dummySheets);
        if (dummySheets.length > 0) {
          setCurrentSpreadsheet(prev => ({ ...prev, sheetName: dummySheets[0] }));
        }
        setLoading(false);
        return dummySheets;
      }

      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const sheets = response.data.sheets.map(sheet => sheet.properties.title);
      setSheetNames(sheets);
      if (sheets.length > 0) {
        setCurrentSpreadsheet(prev => ({ ...prev, sheetName: sheets[0] }));
      }
      setLoading(false);
      return sheets;
    } catch (err) {
      // 認証エラーの検出
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        setError('認証エラーが発生しました。再度ログインしてください。');
        console.error('Authentication error:', err);
        await handleSignOut();
      } else {
        setError('Failed to fetch sheet names. Please check the Spreadsheet ID.');
        console.error('Error:', err);
        setLoading(false);
      }
      return null;
    }
  }, [token, checkAndRefreshToken, isDemoMode, handleSignOut]);

  // fetchData関数をuseCallbackでメモ化し、handleSignOutを依存に追加
  const fetchData = useCallback(async (spreadsheetId, sheetName) => {
    if (!await checkAndRefreshToken()) return;
    if (!spreadsheetId || !sheetName) {
      setError('Please enter a Spreadsheet ID and select a sheet');
      return;
    }
    setError(null);
    setLoading(true);
    try {
      if (isDemoMode) {
        // DEMOモードの場合、ダミーデータを返す
        const dummyData = [
          ['Title', 'Description', 'Price', 'PicURL'],
          ['Demo Product 1', 'This is a demo product', '19.99', 'https://example.com/image1.jpg'],
          ['Demo Product 2', 'Another demo item', '24.99', 'https://example.com/image2.jpg'],
          ['Demo Product 3', 'Yet another demo', '14.99', 'https://example.com/image3.jpg'],
        ];
        setData(dummyData);
        setSpreadsheetData(dummyData);
        setLoading(false);
        return;
      }

      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1:BZ1000`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setData(response.data.values);
      setSpreadsheetData(response.data.values);
      setLoading(false);
    } catch (err) {
      // 認証エラーの検出
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        setError('認証エラーが発生しました。再度ログインしてください。');
        console.error('Authentication error:', err);
        await handleSignOut();
      } else {
        setError('Failed to fetch spreadsheet data. Please check the ID and sheet name.');
        console.error('Error:', err);
        setLoading(false);
      }
    }
  }, [token, checkAndRefreshToken, isDemoMode, handleSignOut]);

  const saveSpreadsheet = () => {
    if (!currentSpreadsheet.id || !currentSpreadsheet.name) {
      setError('Please enter both Spreadsheet ID and Name');
      return;
    }
    const updatedSavedSpreadsheets = [
      ...savedSpreadsheets.filter(s => s.id !== currentSpreadsheet.id),
      currentSpreadsheet
    ];
    setSavedSpreadsheets(updatedSavedSpreadsheets);
    localStorage.setItem('savedSpreadsheets', JSON.stringify(updatedSavedSpreadsheets));
    setError(null);
  };

  const loadSavedSpreadsheet = useCallback(async (spreadsheet) => {
    setCurrentSpreadsheet(spreadsheet);
    const sheets = await fetchSheetNames(spreadsheet.id);
    if (sheets && sheets.length > 0) {
      const sheetName = spreadsheet.sheetName || sheets[0];
      setCurrentSpreadsheet(prev => ({ ...prev, sheetName }));
      await fetchData(spreadsheet.id, sheetName);
    }
  }, [fetchSheetNames, fetchData]);

  const deleteSavedSpreadsheet = (idToDelete) => {
    const updatedSavedSpreadsheets = savedSpreadsheets.filter(s => s.id !== idToDelete);
    setSavedSpreadsheets(updatedSavedSpreadsheets);
    localStorage.setItem('savedSpreadsheets', JSON.stringify(updatedSavedSpreadsheets));
  };

  const updateSheetData = useCallback(() => {
    if (currentSpreadsheet.id && currentSpreadsheet.sheetName) {
      fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
    }
  }, [currentSpreadsheet.id, currentSpreadsheet.sheetName, fetchData]);

  const columnToLetter = useCallback((column) => {
    let temp, letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
  }, []);

  useEffect(() => {
    const storedApiKey = localStorage.getItem('openai_api_key');
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  const handleApiKeySet = (key) => {
    setApiKey(key);
    localStorage.setItem('openai_api_key', key);
  };

  const handleTitleGeneration = useCallback(async () => {
    if (!apiKey && !isDemoMode) {
      setError('OpenAI APIキーが設定されていません。');
      return;
    }
    setLoading(true);
    setError(null);
  
    try {
      const jpDescIndex = data[0].findIndex(header => header.toLowerCase() === 'jp_desc');
      if (jpDescIndex === -1) {
        throw new Error('日本語説明（jp_desc）カラムが見つかりません。');
      }
  
      const titleIndex = data[0].findIndex(header => header.toLowerCase() === 'title');
      if (titleIndex === -1) {
        throw new Error('Title カラムが見つかりません。');
      }
  
      const { titles, errors } = await generateTitles(data, jpDescIndex, titleIndex, apiKey);
      const processedTitles = validateAndProcessTitles(titles);
      const preview = preparePreviewData(data, processedTitles);
      setGeneratedTitles(processedTitles);
      setPreviewData(preview);
      setIsPreviewOpen(true);
  
      if (errors.length > 0) {
        console.warn('Some titles could not be generated:', errors);
        setMessage('一部のタイトル生成に失敗しました。詳細はコンソールを確認してください。');
      }
    } catch (error) {
      console.error('Error in title generation:', error);
      setError(`タイトル生成中にエラーが発生しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [apiKey, isDemoMode, data]);

  const handleTitlesConfirm = async (confirmedPreviewData) => {
    setIsPreviewOpen(false);
    setLoading(true);
    try {
      const confirmedTitles = updateTitlesFromPreview(confirmedPreviewData);
      const updatedData = createUpdatedSpreadsheetData(data, confirmedTitles);
      
      if (isDemoMode) {
        setData(updatedData);
        setMessage('新しいタイトルがデモデータに反映されました。');
      } else {
        const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedData[0].length)}${updatedData.length}`;
        await axios.put(
          `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
          {
            range: updateRange,
            values: updatedData,
            majorDimension: "ROWS"
          },
          {
            params: { valueInputOption: 'USER_ENTERED' },
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
        setMessage('新しいタイトルがスプレッドシートに反映されました。');
      }
    } catch (error) {
      console.error('Error updating spreadsheet:', error);
      setError(`スプレッドシートの更新中にエラーが発生しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleTitleOverwrite = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const updatedData = overwriteExistingTitles(data, generatedTitles);
      
      if (isDemoMode) {
        setData(updatedData);
        setMessage('新タイトルが既存のTitleカラムに上書きされました（デモモード）。');
      } else {
        const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedData[0].length)}${updatedData.length}`;
        await axios.put(
          `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
          {
            range: updateRange,
            values: updatedData,
            majorDimension: "ROWS"
          },
          {
            params: { valueInputOption: 'USER_ENTERED' },
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
        setMessage('新タイトルが既存のTitleカラムに上書きされました。');
      }
    } catch (error) {
      console.error('Error overwriting titles:', error);
      setError(`タイトルの上書き中にエラーが発生しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentSpreadsheet, token, data, fetchData, columnToLetter, isDemoMode, generatedTitles]);

  const handleTitleTruncation = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const updatedData = truncateTitles(data);
      
      if (isDemoMode) {
        setData(updatedData);
        setMessage('タイトルを80文字以内に編集し、ダブルクォーテーションを削除しました（デモモード）。');
      } else {
        const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedData[0].length)}${updatedData.length}`;
        await axios.put(
          `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
          {
            range: updateRange,
            values: updatedData,
            majorDimension: "ROWS"
          },
          {
            params: { valueInputOption: 'USER_ENTERED' },
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
        setMessage('タイトルを80文字以内に編集し、ダブルクォーテーションを削除しました。');
      }
    } catch (error) {
      console.error('Error truncating titles:', error);
      setError(`タイトル編集中にエラーが発生しました: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentSpreadsheet, token, data, fetchData, columnToLetter, isDemoMode]);

  const handlePreviewOpen = () => {
    setIsPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleOpenCustomSettings = useCallback(() => {
    setIsCustomSettingsOpen(true);
  }, []);

  const handleCloseCustomSettings = useCallback(() => {
    setIsCustomSettingsOpen(false);
  }, []);

  const handleSaveCustomSettings = useCallback((settings) => {
    setCustomSettings(settings);
    setIsCustomSettingsOpen(false);
    localStorage.setItem('customPriceSettings', JSON.stringify(settings));
    setSnackbar({ open: true, message: '設定が保存されました', severity: 'success' });
  }, []);

  const handleClosePriceCalculation = useCallback(() => {
    setIsPriceCalculationOpen(false);
  }, []);

  const handleUpdatePrices = useCallback(async (updatedSpreadsheetData) => {
    setLoading(true);
    setError(null);
    try {
      if (isDemoMode) {
        // DEMOモードの場合、ローカルのデータのみを更新
        setData(updatedSpreadsheetData);
        setSnackbar({ open: true, message: '価格が更新されました（デモモード）', severity: 'success' });
      } else {
        const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedSpreadsheetData[0].length)}${updatedSpreadsheetData.length}`;
        await axios.put(
          `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
          {
            range: updateRange,
            values: updatedSpreadsheetData,
            majorDimension: "ROWS"
          },
          {
            params: { valueInputOption: 'USER_ENTERED' },
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
        setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
      }
    } catch (error) {
      console.error('Error updating prices:', error);
      setError(`価格の更新中にエラーが発生しました: ${error.message}`);
      setSnackbar({ open: true, message: '価格の更新に失敗しました', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [currentSpreadsheet, token, fetchData, columnToLetter, isDemoMode]);

  const handleDemoModeToggle = () => {
    setIsDemoMode(prevMode => !prevMode);
    if (!isDemoMode) {
      // DEMOモードを開始する時の処理
      setSnackbar({ open: true, message: 'DEMOモードを開始しました。実際のデータは変更されません。', severity: 'info' });
    } else {
      // DEMOモードを終了する時の処理
      setSnackbar({ open: true, message: 'DEMOモードを終了しました。', severity: 'info' });
    }
  };

  const handleItemSpecificsSettingsOpen = () => {
    setIsItemSpecificsSettingsOpen(true);
    setIsDrawerOpen(false);
  };

  const handleItemSpecificsSettingsClose = () => {
    setIsItemSpecificsSettingsOpen(false);
  };

  const handleItemSpecificsSettingsSave = (settings) => {
    setItemSpecificsSettings(settings);
    handleItemSpecificsSettingsClose();
  };

  const menuItems = [
    { 
      text: '価格計算設定', 
      icon: <SettingsIcon />, 
      action: handleOpenCustomSettings
    },
    {
      text: 'Item Specifics設定',
      icon: <SettingsIcon />,
      action: handleItemSpecificsSettingsOpen
    },
    {
      text: 'マニュアル',
      icon: <AttachMoneyIcon />,
      action: () => {
        window.open('https://ebay-marketing-tool.com/ebay-spread-manual-price/', '_blank', 'noopener,noreferrer');
      },
      renderItem: (item) => (
        <>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          <Box component="span" sx={{ ml: 1 }}>
            <LaunchIcon fontSize="small" />
          </Box>
        </>
      )
    },
    // { 
    //   text: 'OpenAI APIキー設定', 
    //   action: () => setIsApiKeyDialogOpen(true)
    // },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Specifics-IN ver 1.1.0
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isDemoMode}
                onChange={handleDemoModeToggle}
                color="secondary"
              />
            }
            label={isDemoMode ? "DEMOモード ON" : "DEMOモード OFF"}
            sx={{ mr: 2 }}
          />
          <Button 
            color="inherit" 
            href="https://www.ebay.com/sh/reports/uploads" 
            target="_blank" 
            rel="noopener noreferrer"
            startIcon={<CloudUploadIcon />}
          >
            eBayアップロード
          </Button>
          <Button 
            color="inherit" 
            href="https://app.photoroom.com/batch" 
            target="_blank" 
            rel="noopener noreferrer"
            startIcon={<PhotoCameraIcon />}
          >
            Photoroom
          </Button>
          <Button 
            color="inherit" 
            href="https://postimages.org/#google_vignette" 
            target="_blank" 
            rel="noopener noreferrer"
            startIcon={<LinkIcon />}
          >
            画像URL変換
          </Button>
          <Button color="inherit" onClick={handleSignOut}>
            Sign Out
          </Button>
          <IconButton 
            color="inherit" 
            onClick={() => setIsHelpOpen(!isHelpOpen)}
            aria-label="ヘルプを表示/非表示"
          >
            <HelpIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '200px',
            boxSizing: 'border-box',
          },
        }}
      >
        <List sx={{ width: '100%' }}>
          {menuItems.map((item, index) => (
            <ListItem 
              button 
              key={index} 
              onClick={item.action}
              sx={{ 
                py: 2,
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              {item.renderItem ? item.renderItem(item) : (
                <>
                  <ListItemIcon sx={{ minWidth: '56px' }}>{item.icon}</ListItemIcon>
                  <ListItemText 
                    primary={item.text} 
                    primaryTypographyProps={{ 
                      fontSize: '1.1rem',
                      fontWeight: 'medium'
                    }} 
                  />
                </>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Grid container spacing={1} className="input-container">
              <Grid item className="input-field">
                <TextField
                  fullWidth
                  label="Spreadsheet ID"
                  value={currentSpreadsheet.id}
                  onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, id: e.target.value})}
                  size="small"
                />
              </Grid>
              <Grid item className="input-field">
                <TextField
                  fullWidth
                  label="Spreadsheet Name"
                  value={currentSpreadsheet.name}
                  onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, name: e.target.value})}
                  size="small"
                />
              </Grid>
              <Grid item className="button-group">
                <Button 
                  variant="contained" 
                  onClick={() => fetchSheetNames(currentSpreadsheet.id)}
                  size="small"
                >
                  読み込み
                </Button>
              </Grid>
              <Grid item className="button-group">
                <Button 
                  variant="contained" 
                  onClick={saveSpreadsheet}
                  size="small"
                >
                  保存
                </Button>
              </Grid>
              <Grid item className="input-field">
                {sheetNames.length > 0 ? (
                  <Select
                    fullWidth
                    value={currentSpreadsheet.sheetName}
                    onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, sheetName: e.target.value})}
                    size="small"
                  >
                    {sheetNames.map((name, index) => (
                      <MenuItem key={index} value={name}>{name}</MenuItem>
                    ))}
                  </Select>
                ) : (
                  <TextField
                    fullWidth
                    label="Sheet Name"
                    value={currentSpreadsheet.sheetName}
                    onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, sheetName: e.target.value})}
                    size="small"
                  />
                )}
              </Grid>
              <Grid item className="button-group">
                <Button
                  variant="contained"
                  onClick={() => fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName)}
                  disabled={loading || !currentSpreadsheet.sheetName}
                  size="small"
                >
                  {loading ? 'Loading...' : 'データ取得'}
                </Button>
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, mb: 2 }}>
              <Typography variant="h6" gutterBottom>
                保存したIDsheets
              </Typography>
              <Paper elevation={2} sx={{ p: 2, overflowX: 'auto' }}>
                <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
                  {savedSpreadsheets.map((spreadsheet, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="subtitle2" noWrap title={spreadsheet.name}>
                            {spreadsheet.name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary" display="block" noWrap title={spreadsheet.id}>
                            ID: {spreadsheet.id}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={() => loadSavedSpreadsheet(spreadsheet)}
                          >
                            読み込み
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() => deleteSavedSpreadsheet(spreadsheet.id)}
                          >
                            消去
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Box>

            {error && <p className="error-message">{error}</p>}
            {message && <p className="success-message">{message}</p>}

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <ColumnNameBasedTemplateManager
                  spreadsheetId={currentSpreadsheet.id}
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  fetchData={updateSheetData}
                />
              </Grid>

              <Grid item xs={4} md={4} lg={4}>
                <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    タイトル生成と編集
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleTitleGeneration}
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading || (!apiKey && !isDemoMode)}
                      >
                        1. タイトルを生成
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handlePreviewOpen}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        disabled={loading || generatedTitles.length === 0}
                      >
                        2. プレビュー
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleTitleOverwrite}
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={loading || generatedTitles.length === 0}
                      >
                        3. 新タイトルを上書き
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        onClick={handleTitleTruncation}
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        disabled={loading || !data || data.length === 0}
                      >
                        4. タイトル80文字制限
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <PreviewDialog
                  open={isPreviewOpen}
                  handleClose={() => setIsPreviewOpen(false)}
                  previewData={previewData}
                  onConfirm={handleTitlesConfirm}
                />
              </Grid>

              <Grid item xs={4}>
                <Paper elevation={3} sx={{}}>
                  <ColumnDeletion
                    currentSpreadsheet={currentSpreadsheet}
                    token={token}
                    data={data}
                    fetchData={fetchData}
                    columnToLetter={columnToLetter}
                    setError={setError}
                    setMessage={setMessage}
                    setLoading={setLoading}
                  />
                </Paper>
              </Grid>

              <Grid item xs={4}>
                <ItemSpecificsApplier
                  spreadsheetId={currentSpreadsheet.id}
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                />
              </Grid>

              {/* <Grid item xs={4}>
              {!apiKey && !isDemoMode ? (
                <Paper elevation={3} sx={{ p: 2, my: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    OpenAI APIキー設定
                  </Typography>
                  <APIKeyInput onApiKeySet={handleApiKeySet} />
                </Paper>
              ) : (
                <Paper elevation={3} sx={{ p: 2, my: 2 }}>
                  <Typography variant="body1">
                    {isDemoMode ? 'DEMOモード: APIキー不要' : 'OpenAI APIキーが設定されています。'}
                  </Typography>
                  {!isDemoMode && (
                    <Button onClick={() => {
                      setApiKey('');
                      localStorage.removeItem('openai_api_key');
                    }}>
                      APIキーをリセット
                    </Button>
                  )}
                </Paper>
              )}
              </Grid> */}

              {/* {error && (
                <Typography 
                  color="error" 
                  sx={{ 
                    mt: 1, 
                    ml: 4,
                    p: 1,                    
                    borderRadius: 1
                  }}
                >
                  {error}
                </Typography>
              )}
              {message && (
                <Typography 
                  color="success" 
                  sx={{ 
                    mt: 1, 
                    ml: 4,
                    p: 1,
                    borderRadius: 1
                  }}
                >
                  {message}
                </Typography>
              )} */}

              {/* <Dialog
                open={isConfirmDialogOpen}
                onClose={() => setIsConfirmDialogOpen(false)}
              >
                <DialogTitle>確認</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    新しいタイトルを生成してスプレッドシートに反映しますか？この操作は元に戻せません。
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
                  <Button onClick={confirmTitleGeneration} autoFocus>
                    生成する
                  </Button>
                </DialogActions>
              </Dialog> */}

              <Grid container spacing={3} sx={{ p: 2 , ml: 0}}>
                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <ImageDownloader
                      spreadsheetId={currentSpreadsheet.id}
                      sheetName={currentSpreadsheet.sheetName}
                      token={token}
                      data={data}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                    <ImageUrlReplacer
                      spreadsheetId={currentSpreadsheet.id}
                      sheetName={currentSpreadsheet.sheetName}
                      token={token}
                      data={data}
                      fetchData={updateSheetData}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Paper elevation={3} sx={{ p: 3, mb: 3}}>
                    <PriceCalculationSettings
                      customSettings={customSettings}
                      spreadsheetData={spreadsheetData}
                      onUpdatePrices={handleUpdatePrices}
                      fetchSpreadsheetData={fetchData}
                    />
                  </Paper>
                </Grid>
              </Grid>


            <Grid container spacing={4}>
              <Grid item xs={6}>
              {!apiKey && !isDemoMode ? (
                <Paper elevation={3} sx={{ p: 1, my: 1 , ml: 4}}>
                  <Typography variant="h6" gutterBottom>
                    OpenAI APIキー設定
                  </Typography>
                  <APIKeyInput onApiKeySet={handleApiKeySet} />
                </Paper>
              ) : (
                <Paper elevation={3} sx={{ p: 1, my: 1, ml: 4 }}>
                  <Typography variant="body1">
                    {isDemoMode ? 'DEMOモード: APIキー不要' : 'OpenAI APIキーが設定されています。'}
                  </Typography>
                  {!isDemoMode && (
                    <Button onClick={() => {
                      setApiKey('');
                      localStorage.removeItem('openai_api_key');
                    }}>
                      APIキーをリセット
                    </Button>
                  )}
                </Paper>
              )}
              </Grid>

              <Grid item xs={6}>
                <OpenAIDescriptionGenerator
                  spreadsheetId={currentSpreadsheet.id}
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  fetchData={updateSheetData}
                  apiKey={apiKey}
                />
             </Grid>
            </Grid>

              <Grid item xs={12}>
                <EditableSpreadsheet 
                  spreadsheetId={currentSpreadsheet.id} 
                  sheetName={currentSpreadsheet.sheetName}
                  token={token}
                  data={data || []}
                  fetchData={updateSheetData}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
      >
        <Box sx={{ width: 300, p: 2 }}>
          <InteractiveManual />
        </Box>
      </Drawer>

      <Dialog
        open={isCustomSettingsOpen}
        onClose={handleCloseCustomSettings}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>価格計算設定</DialogTitle>
        <DialogContent>
          <CustomColumnSettings 
            onSave={handleSaveCustomSettings}
            initialSettings={customSettings}
            userId={user ? user.uid : null}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isPriceCalculationOpen}
        onClose={handleClosePriceCalculation}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>価格計算</DialogTitle>
        <DialogContent>
          <PriceCalculationSettings
            customSettings={customSettings}
            spreadsheetData={spreadsheetData}
            onUpdatePrices={handleUpdatePrices}
            fetchSpreadsheetData={fetchData}
          />
        </DialogContent>
      </Dialog>

      {/* <Dialog
        open={isItemSpecificsOpen}
        onClose={handleItemSpecificsClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Item Specifics Manager</DialogTitle>
        <DialogContent>
          <ItemSpecificsManager
            spreadsheetId={currentSpreadsheet.id}
            sheetName={currentSpreadsheet.sheetName}
            token={token}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleItemSpecificsClose}>Close</Button>
        </DialogActions>
      </Dialog> */}

      <Dialog
        open={isItemSpecificsSettingsOpen}
        onClose={handleItemSpecificsSettingsClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Item Specificsの管理画面</DialogTitle>
        <DialogContent>
          <ItemSpecificsSettings onSave={handleItemSpecificsSettingsSave} onClose={handleItemSpecificsSettingsClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleItemSpecificsSettingsClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      <TermsAndPrivacy />

      <DemoMode isActive={isDemoMode} onComplete={() => setIsDemoMode(false)} />
    </Box>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDemoMode, setIsDemoMode] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route 
            path="/login" 
            element={
              isAuthenticated ? 
                <Navigate to="/" /> : 
                <Login setIsAuthenticated={setIsAuthenticated} setIsDemoMode={setIsDemoMode} />
            } 
          />
          <Route 
            path="/" 
            element={
              isAuthenticated || isDemoMode ? 
                <AppContent setIsAuthenticated={setIsAuthenticated} isDemoMode={isDemoMode} setIsDemoMode={setIsDemoMode} /> : 
                <Navigate to="/login" />
            } 
          />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;







//--------------------------------------------------------------------------------------------------------
// import React, { useState, useEffect, useCallback } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
// import { GoogleOAuthProvider } from '@react-oauth/google';
// import { initializeApp } from 'firebase/app';
// import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
// import { getFirestore, doc, getDoc } from 'firebase/firestore';
// import axios from 'axios';
// import { 
//   Box, 
//   Button, 
//   Typography, 
//   Grid, 
//   Paper,
//   AppBar,
//   Toolbar,
//   IconButton,
//   Drawer,
//   TextField,
//   Select,
//   MenuItem,
//   Card,
//   CardContent,
//   CardActions,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Switch,
//   FormControlLabel
// } from '@mui/material';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import LinkIcon from '@mui/icons-material/Link';
// import MenuIcon from '@mui/icons-material/Menu';
// import HelpIcon from '@mui/icons-material/Help';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import SettingsIcon from '@mui/icons-material/Settings';
// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
// import LaunchIcon from '@mui/icons-material/Launch';
// import EditableSpreadsheet from './EditableSpreadsheet';
// import ColumnNameBasedTemplateManager from './ColumnNameBasedTemplateManager';
// import InteractiveManual from './InteractiveManual';
// import { generateTitles, createUpdatedSpreadsheetData, validateAndProcessTitles, overwriteExistingTitles, truncateTitles, preparePreviewData, updateTitlesFromPreview } from './titleGenerationService';
// import ColumnDeletion from './ColumnDeletion';
// import APIKeyInput from './APIKeyInput';
// import PreviewDialog from './PreviewDialog';
// import TermsAndPrivacy from './TermsAndPrivacy';
// import ImageDownloader from './ImageDownloader';
// import ImageUrlReplacer from './ImageUrlReplacer';
// import CustomColumnSettings from './CustomColumnSettings';
// import PriceCalculationSettings from './PriceCalculationSettings';
// import OpenAIDescriptionGenerator from './OpenAIDescriptionGenerator';
// // import ItemSpecificsManager from './ItemSpecificsManager';
// import ItemSpecificsSettings from './ItemSpecificsSettings';
// import ItemSpecificsApplier from './ItemSpecificsApplier';
// import DemoMode from './DemoMode';
// import Login from './Login';
// import './App.css';

// // Firebase configuration
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

// // Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const auth = getAuth(firebaseApp);
// const db = getFirestore(firebaseApp);


// function AppContent({ setIsAuthenticated, isDemoMode, setIsDemoMode }) {
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(null);
//   const [currentSpreadsheet, setCurrentSpreadsheet] = useState({ id: '', name: '', sheetName: '' });
//   const [savedSpreadsheets, setSavedSpreadsheets] = useState([]);
//   const [data, setData] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [sheetNames, setSheetNames] = useState([]);
//   const [isHelpOpen, setIsHelpOpen] = useState(false);
//   const [message, setMessage] = useState('');
//   const [analysisResults, setAnalysisResults] = useState([]);
//   const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
//   const [apiKey, setApiKey] = useState('');
//   const [isPreviewOpen, setIsPreviewOpen] = useState(false);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isCustomSettingsOpen, setIsCustomSettingsOpen] = useState(false);
//   const [customSettings, setCustomSettings] = useState(null);
//   const [spreadsheetData, setSpreadsheetData] = useState([]);
//   const [isPriceCalculationOpen, setIsPriceCalculationOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [isItemSpecificsSettingsOpen, setIsItemSpecificsSettingsOpen] = useState(false);
//   const [itemSpecificsSettings, setItemSpecificsSettings] = useState([]);
//   const [generatedTitles, setGeneratedTitles] = useState([]);
//   const [previewData, setPreviewData] = useState([]);



//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
//       setUser(currentUser);
//       if (currentUser && !isDemoMode) {
//         const fetchUserSettings = async () => {
//           await loadUserSettings(currentUser.uid);
//         };
//         fetchUserSettings();
//       } else if (!currentUser && !isDemoMode) {
//         navigate('/login');
//       }
//     });
//     return () => unsubscribe();
//   }, [navigate, isDemoMode]);

//   useEffect(() => {
//     const clearLocalStorage = () => {
//       localStorage.clear();
//       console.log('Local storage cleared');
//     };
    
//     // URLパラメータでクリアを制御
//     const urlParams = new URLSearchParams(window.location.search);
//     if (urlParams.get('clearStorage') === 'true') {
//       clearLocalStorage();
//     }
//   }, []);

//   const loadUserSettings = async (userId) => {
//     try {
//       const docRef = doc(db, "userSettings", userId);
//       const docSnap = await getDoc(docRef);

//       if (docSnap.exists()) {
//         const userSettings = docSnap.data();
//         setCustomSettings(userSettings);
//         // 他の必要な設定の更新
//       }
//     } catch (error) {
//       console.error('Failed to load user settings:', error);
//       setSnackbar({ open: true, message: 'ユーザー設定の読み込みに失敗しました', severity: 'error' });

//     }
//   };

//   useEffect(() => {
//     const savedToken = localStorage.getItem('googleToken');
//     if (savedToken) {
//       setToken(savedToken);
//     }
//     const saved = JSON.parse(localStorage.getItem('savedSpreadsheets')) || [];
//     setSavedSpreadsheets(saved);
//   }, []);

//   const handleSignOut = async () => {
//     try {
//       await signOut(auth);
//       localStorage.removeItem('googleToken');
//       localStorage.removeItem('userInfo');
//       setIsAuthenticated(false);
//       setIsDemoMode(false);
//       navigate('/login');
//     } catch (error) {
//       console.error("Error signing out", error);
//     }
//   };

//   const checkAndRefreshToken = useCallback(async () => {
//     const currentToken = localStorage.getItem('googleToken');
//     if (!currentToken && !isDemoMode) {
//       setError('No access token found. Please sign in again.');
//       return false;
//     }
//     setToken(currentToken);
//     return true;
//   }, [isDemoMode]);

//   const fetchSheetNames = useCallback(async (spreadsheetId) => {
//     if (!await checkAndRefreshToken()) return;
//     if (!spreadsheetId) {
//       setError('Please provide a Spreadsheet ID');
//       return;
//     }
//     setError(null);
//     setLoading(true);
//     try {
//       if (isDemoMode) {
//         // DEMOモードの場合、ダミーデータを返す
//         const dummySheets = ['Sheet1', 'Sheet2', 'Sheet3'];
//         setSheetNames(dummySheets);
//         if (dummySheets.length > 0) {
//           setCurrentSpreadsheet(prev => ({ ...prev, sheetName: dummySheets[0] }));
//         }
//         setLoading(false);
//         return dummySheets;
//       }

//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       const sheets = response.data.sheets.map(sheet => sheet.properties.title);
//       setSheetNames(sheets);
//       if (sheets.length > 0) {
//         setCurrentSpreadsheet(prev => ({ ...prev, sheetName: sheets[0] }));
//       }
//       setLoading(false);
//       return sheets;
//     } catch (err) {
//       setError('Failed to fetch sheet names. Please check the Spreadsheet ID.');
//       console.error('Error:', err);
//       setLoading(false);
//       return null;
//     }
//   }, [token, checkAndRefreshToken, isDemoMode]);

//   const fetchData = useCallback(async (spreadsheetId, sheetName) => {
//     if (!await checkAndRefreshToken()) return;
//     if (!spreadsheetId || !sheetName) {
//       setError('Please enter a Spreadsheet ID and select a sheet');
//       return;
//     }
//     setError(null);
//     setLoading(true);
//     try {
//       if (isDemoMode) {
//         // DEMOモードの場合、ダミーデータを返す
//         const dummyData = [
//           ['Title', 'Description', 'Price', 'PicURL'],
//           ['Demo Product 1', 'This is a demo product', '19.99', 'https://example.com/image1.jpg'],
//           ['Demo Product 2', 'Another demo item', '24.99', 'https://example.com/image2.jpg'],
//           ['Demo Product 3', 'Yet another demo', '14.99', 'https://example.com/image3.jpg'],
//         ];
//         setData(dummyData);
//         setSpreadsheetData(dummyData);
//         setLoading(false);
//         return;
//       }

//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1:BZ1000`,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       setData(response.data.values);
//       setSpreadsheetData(response.data.values);
//       setLoading(false);
//     } catch (err) {
//       setError('Failed to fetch spreadsheet data. Please check the ID and sheet name.');
//       console.error('Error:', err);
//       setLoading(false);
//     }
//   }, [token, checkAndRefreshToken, isDemoMode]);

//   const saveSpreadsheet = () => {
//     if (!currentSpreadsheet.id || !currentSpreadsheet.name) {
//       setError('Please enter both Spreadsheet ID and Name');
//       return;
//     }
//     const updatedSavedSpreadsheets = [
//       ...savedSpreadsheets.filter(s => s.id !== currentSpreadsheet.id),
//       currentSpreadsheet
//     ];
//     setSavedSpreadsheets(updatedSavedSpreadsheets);
//     localStorage.setItem('savedSpreadsheets', JSON.stringify(updatedSavedSpreadsheets));
//     setError(null);
//   };

//   const loadSavedSpreadsheet = useCallback(async (spreadsheet) => {
//     setCurrentSpreadsheet(spreadsheet);
//     const sheets = await fetchSheetNames(spreadsheet.id);
//     if (sheets && sheets.length > 0) {
//       const sheetName = spreadsheet.sheetName || sheets[0];
//       setCurrentSpreadsheet(prev => ({ ...prev, sheetName }));
//       await fetchData(spreadsheet.id, sheetName);
//     }
//   }, [fetchSheetNames, fetchData]);

//   const deleteSavedSpreadsheet = (idToDelete) => {
//     const updatedSavedSpreadsheets = savedSpreadsheets.filter(s => s.id !== idToDelete);
//     setSavedSpreadsheets(updatedSavedSpreadsheets);
//     localStorage.setItem('savedSpreadsheets', JSON.stringify(updatedSavedSpreadsheets));
//   };

//   const updateSheetData = useCallback(() => {
//     if (currentSpreadsheet.id && currentSpreadsheet.sheetName) {
//       fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//     }
//   }, [currentSpreadsheet.id, currentSpreadsheet.sheetName, fetchData]);

//   const columnToLetter = useCallback((column) => {
//     let temp, letter = '';
//     while (column > 0) {
//       temp = (column - 1) % 26;
//       letter = String.fromCharCode(temp + 65) + letter;
//       column = (column - temp - 1) / 26;
//     }
//     return letter;
//   }, []);

//   useEffect(() => {
//     const storedApiKey = localStorage.getItem('openai_api_key');
//     if (storedApiKey) {
//       setApiKey(storedApiKey);
//     }
//   }, []);

//   const handleApiKeySet = (key) => {
//     setApiKey(key);
//     localStorage.setItem('openai_api_key', key);
//   };

//   // const handleImageAnalysis = useCallback(async () => {
//   //   if (!apiKey && !isDemoMode) {
//   //     setError('OpenAI APIキーが設定されていません。');
//   //     return;
//   //   }
//   //   if (!currentSpreadsheet.id || !currentSpreadsheet.sheetName) {
//   //     setError('スプレッドシートIDとシート名を入力してください。');
//   //     return;
//   //   }

//   //   setLoading(true);
//   //   setError(null);
//   //   setMessage('');

//   //   try {
//   //     if (isDemoMode) {
//   //       // DEMOモードの場合、ダミーの解析結果を返す
//   //       const dummyAnalysisResults = [
//   //         'Type: T-shirt, Characteristics: Casual wear, Character: None, Colors: Blue and white, Materials: Cotton, Brand impression: Casual and comfortable, Quality: Good, Uses: Everyday wear, Suitable occasions: Casual outings',
//   //         'Type: Mug, Characteristics: Ceramic, Character: None, Colors: White with red design, Materials: Ceramic, Brand impression: Fun and quirky, Quality: Standard, Uses: Drinking hot beverages, Suitable occasions: Home use, office',
//   //         'Type: Poster, Characteristics: Wall art, Character: Movie character, Colors: Multicolor, Materials: Paper, Brand impression: Pop culture, Quality: High-quality print, Uses: Wall decoration, Suitable occasions: Home, dorm rooms',
//   //       ];
//   //       setAnalysisResults(dummyAnalysisResults);
//   //       setMessage('画像解析が完了しました（デモ用ダミーデータ）。タイトル生成ボタンでタイトルを更新できます。');
//   //       setLoading(false);
//   //       return;
//   //     }

//   //     const sheetInfoResponse = await axios.get(
//   //       `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}`,
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${token}`
//   //         }
//   //       }
//   //     );

//   //     const sheet = sheetInfoResponse.data.sheets.find(s => s.properties.title === currentSpreadsheet.sheetName);
//   //     if (!sheet) {
//   //       throw new Error('指定されたシートが見つかりません。');
//   //     }

//   //     const columnCount = sheet.properties.gridProperties.columnCount;
//   //     console.log('Total column count:', columnCount);

//   //     const lastColumn = columnToLetter(columnCount);
//   //     const dataRange = `${currentSpreadsheet.sheetName}!A1:${lastColumn}`;
//   //     const response = await axios.get(
//   //       `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(dataRange)}`,
//   //       {
//   //         headers: {
//   //           Authorization: `Bearer ${token}`
//   //         }
//   //       }
//   //     );

//   //     const sheetData = response.data.values;
//   //     const headers = sheetData[0];

//   //     const picUrlIndex = headers.findIndex(header => header.toLowerCase() === 'picurl');
//   //     const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');

//   //     if (picUrlIndex === -1 || titleIndex === -1) {
//   //       throw new Error('PicURL列またはTitle列が見つかりません。');
//   //     }

//       // const updatedValues = await Promise.all(sheetData.map(async (row, index) => {
//       //   if (index === 0) return [...headers, '解析結果 (英語)'];

//       //   const imageUrls = row[picUrlIndex].split('|');

//       //   if (imageUrls.length > 0 && imageUrls[0]) {
//       //     try {
//       //       const result = await analyzeImage(imageUrls[0], apiKey);
//       //       return [...row, result];
//       //     } catch (error) {
//       //       console.error(`Error analyzing image in row ${index + 1}:`, error);
//       //       return [...row, 'Error: ' + error.message];
//       //     }
//       //   } else {
//       //     return [...row, ''];
//       //   }
//       // }));

//       // const newColumnCount = columnCount + 1;
//       // const newLastColumn = columnToLetter(newColumnCount);
//       // const updateRange = `${currentSpreadsheet.sheetName}!A1:${newLastColumn}${updatedValues.length}`;
//       // const updateUrl = `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`;

//       // console.log('Update URL:', updateUrl);

//   //     const updateResponse = await axios.put(
//   //       updateUrl,
//   //       {
//   //         range: updateRange,
//   //         values: updatedValues,
//   //         majorDimension: "ROWS"
//   //       },
//   //       {
//   //         params: {
//   //           valueInputOption: 'USER_ENTERED'
//   //         },
//   //         headers: {
//   //           'Authorization': `Bearer ${token}`,
//   //           'Content-Type': 'application/json'
//   //         }
//   //       }
//   //     );

//   //     console.log('Sheets API Update Response:', updateResponse.data);
//   //     await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);

//   //     setAnalysisResults(updatedValues.slice(1).map(row => row[row.length - 1]));
//   //     setMessage('画像解析が完了しました。タイトル生成ボタンでタイトルを更新できます。');

//   //   } catch (error) {
//   //     console.error('Error in image analysis:', error);
//   //     setError(`画像解析中にエラーが発生しました: ${error.message}`);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // }, [currentSpreadsheet.id, currentSpreadsheet.sheetName, token, columnToLetter, apiKey, fetchData, isDemoMode]);

//   // const handleTitleGeneration = useCallback(() => {
//   //   if (!apiKey && !isDemoMode) {
//   //     setError('OpenAI APIキーが設定されていません。');
//   //     return;
//   //   }
//   //   if (analysisResults.length === 0) {
//   //     setError('先に画像解析を行ってください。');
//   //     return;
//   //   }
//   //   setIsConfirmDialogOpen(true);
//   // }, [analysisResults.length, apiKey, isDemoMode]);



//   const handleTitleGeneration = useCallback(async () => {
//     if (!apiKey && !isDemoMode) {
//       setError('OpenAI APIキーが設定されていません。');
//       return;
//     }
//     setLoading(true);
//     setError(null);
  
//     try {
//       const jpDescIndex = data[0].findIndex(header => header.toLowerCase() === 'jp_desc');
//       if (jpDescIndex === -1) {
//         throw new Error('日本語説明（jp_desc）カラムが見つかりません。');
//       }
  
//       const titleIndex = data[0].findIndex(header => header.toLowerCase() === 'title');
//       if (titleIndex === -1) {
//         throw new Error('Title カラムが見つかりません。');
//       }
  
//       const { titles, errors } = await generateTitles(data, jpDescIndex, titleIndex, apiKey);
//       const processedTitles = validateAndProcessTitles(titles);
//       const preview = preparePreviewData(data, processedTitles);
//       setGeneratedTitles(processedTitles);
//       setPreviewData(preview);
//       setIsPreviewOpen(true);
  
//       if (errors.length > 0) {
//         console.warn('Some titles could not be generated:', errors);
//         setMessage('一部のタイトル生成に失敗しました。詳細はコンソールを確認してください。');
//       }
//     } catch (error) {
//       console.error('Error in title generation:', error);
//       setError(`タイトル生成中にエラーが発生しました: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   }, [apiKey, isDemoMode, data]);

//   // const handleTitleGeneration = useCallback(async () => {
//   //   if (!apiKey && !isDemoMode) {
//   //     setError('OpenAI APIキーが設定されていません。');
//   //     return;
//   //   }
//   //   setLoading(true);
//   //   setError(null);

//   //   try {
//   //     const jpDescIndex = data[0].findIndex(header => header.toLowerCase() === 'jp_desc');
//   //     if (jpDescIndex === -1) {
//   //       throw new Error('日本語説明（jp_desc）カラムが見つかりません。');
//   //     }

//   //     const { titles, errors } = await generateTitles(data, jpDescIndex, apiKey);
//   //     const processedTitles = validateAndProcessTitles(titles);
//   //     const preview = preparePreviewData(data, processedTitles);
//   //     setGeneratedTitles(processedTitles);
//   //     setPreviewData(preview);
//   //     setIsPreviewOpen(true);

//   //     if (errors.length > 0) {
//   //       console.warn('Some titles could not be generated:', errors);
//   //       setMessage('一部のタイトル生成に失敗しました。詳細はコンソールを確認してください。');
//   //     }
//   //   } catch (error) {
//   //     console.error('Error in title generation:', error);
//   //     setError(`タイトル生成中にエラーが発生しました: ${error.message}`);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // }, [apiKey, isDemoMode, data]);

//   const handleTitlesConfirm = async (confirmedPreviewData) => {
//     setIsPreviewOpen(false);
//     setLoading(true);
//     try {
//       const confirmedTitles = updateTitlesFromPreview(confirmedPreviewData);
//       const updatedData = createUpdatedSpreadsheetData(data, confirmedTitles);
      
//       if (isDemoMode) {
//         setData(updatedData);
//         setMessage('新しいタイトルがデモデータに反映されました。');
//       } else {
//         const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedData[0].length)}${updatedData.length}`;
//         await axios.put(
//           `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//           {
//             range: updateRange,
//             values: updatedData,
//             majorDimension: "ROWS"
//           },
//           {
//             params: { valueInputOption: 'USER_ENTERED' },
//             headers: { 'Authorization': `Bearer ${token}` }
//           }
//         );

//         await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//         setMessage('新しいタイトルがスプレッドシートに反映されました。');
//       }
//     } catch (error) {
//       console.error('Error updating spreadsheet:', error);
//       setError(`スプレッドシートの更新中にエラーが発生しました: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleTitleOverwrite = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const updatedData = overwriteExistingTitles(data, generatedTitles);
      
//       if (isDemoMode) {
//         setData(updatedData);
//         setMessage('新タイトルが既存のTitleカラムに上書きされました（デモモード）。');
//       } else {
//         const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedData[0].length)}${updatedData.length}`;
//         await axios.put(
//           `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//           {
//             range: updateRange,
//             values: updatedData,
//             majorDimension: "ROWS"
//           },
//           {
//             params: { valueInputOption: 'USER_ENTERED' },
//             headers: { 'Authorization': `Bearer ${token}` }
//           }
//         );

//         await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//         setMessage('新タイトルが既存のTitleカラムに上書きされました。');
//       }
//     } catch (error) {
//       console.error('Error overwriting titles:', error);
//       setError(`タイトルの上書き中にエラーが発生しました: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   }, [currentSpreadsheet, token, data, fetchData, columnToLetter, isDemoMode, generatedTitles]);

//   const handleTitleTruncation = useCallback(async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const updatedData = truncateTitles(data);
      
//       if (isDemoMode) {
//         setData(updatedData);
//         setMessage('タイトルを80文字以内に編集し、ダブルクォーテーションを削除しました（デモモード）。');
//       } else {
//         const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedData[0].length)}${updatedData.length}`;
//         await axios.put(
//           `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//           {
//             range: updateRange,
//             values: updatedData,
//             majorDimension: "ROWS"
//           },
//           {
//             params: { valueInputOption: 'USER_ENTERED' },
//             headers: { 'Authorization': `Bearer ${token}` }
//           }
//         );

//         await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//         setMessage('タイトルを80文字以内に編集し、ダブルクォーテーションを削除しました。');
//       }
//     } catch (error) {
//       console.error('Error truncating titles:', error);
//       setError(`タイトル編集中にエラーが発生しました: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   }, [currentSpreadsheet, token, data, fetchData, columnToLetter, isDemoMode]);


//   // const confirmTitleGeneration = useCallback(async () => {
//   //   setIsConfirmDialogOpen(false);
//   //   setLoading(true);
//   //   setError(null);
//   //   setMessage('');

//   //   try {
//   //     if (isDemoMode) {
//   //       // DEMOモードの場合、ダミーの新タイトルを生成
//   //       const dummyNewTitles = [
//   //         'Stylish Blue and White Cotton T-shirt for Casual Everyday Wear',
//   //         'Fun and Quirky White Ceramic Mug with Red Design for Home and Office',
//   //         'High-Quality Multicolor Movie Character Poster for Home and Dorm Decoration',
//   //       ];
//   //       const updatedData = data.map((row, index) => {
//   //         if (index === 0) {
//   //           return [...row, '新タイトル'];
//   //         }
//   //         return [...row, dummyNewTitles[index - 1] || ''];
//   //       });
//   //       setData(updatedData);
//   //       setMessage('新しいタイトルがスプレッドシートに反映されました（デモ用ダミーデータ）。');
//   //       setLoading(false);
//   //       return;
//   //     }

//   //     const response = await axios.get(
//   //       `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${currentSpreadsheet.sheetName}`,
//   //       {headers: { Authorization: `Bearer ${token}` }}
//   //     );

//   //     const sheetData = response.data.values;
//   //     const headers = sheetData[0];
//   //     const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');

//   //     if (titleIndex === -1) {
//   //       throw new Error('Title列が見つかりません。');
//   //     }

//   //     const updatedValues = await Promise.all(sheetData.map(async (row, index) => {
//   //       if (index === 0) {
//   //         return [...row, '新タイトル'];
//   //       }
//   //       const existingTitle = row[titleIndex] || '';
//   //       const analysisResult = analysisResults[index - 1];
//   //       if (analysisResult && typeof analysisResult === 'string' && !analysisResult.startsWith('Error:')) {
//   //         const newTitle = await generateTitle(existingTitle, analysisResult, apiKey);
//   //         return [...row, newTitle];
//   //       } else {
//   //         return [...row, existingTitle];
//   //       }
//   //     }));

//   //     const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedValues[0].length)}${updatedValues.length}`;
//   //     await axios.put(
//   //       `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//   //       {
//   //         range: updateRange,
//   //         values: updatedValues,
//   //         majorDimension: "ROWS"
//   //       },
//   //       {
//   //         params: { valueInputOption: 'USER_ENTERED' },
//   //         headers: { 'Authorization': `Bearer ${token}` }
//   //       }
//   //     );

//   //     await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);

//   //     setMessage('新しいタイトルがスプレッドシートに反映されました。');
//   //   } catch (error) {
//   //     console.error('Error in title generation:', error);
//   //     setError(`タイトル生成中にエラーが発生しました: ${error.message}`);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // }, [currentSpreadsheet.id, currentSpreadsheet.sheetName, token, analysisResults, columnToLetter, fetchData, isDemoMode, data, apiKey]);

//   // const handleTitleOverwrite = useCallback(async () => {
//   //   if (!data || data.length === 0) {
//   //     setError('データが存在しません。');
//   //     return;
//   //   }

//   //   setLoading(true);
//   //   setError(null);
//   //   setMessage('');

//   //   try {
//   //     const headers = data[0];
//   //     const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
//   //     const newTitleIndex = headers.findIndex(header => header.toLowerCase() === '新タイトル');

//   //     if (titleIndex === -1) {
//   //       throw new Error('"Title"カラムが見つかりません。');
//   //     }
//   //     if (newTitleIndex === -1) {
//   //       throw new Error('"新タイトル"カラムが見つかりません。');
//   //     }

//   //     const updatedValues = data.map((row, index) => {
//   //       if (index === 0) return row; // ヘッダー行はそのまま
//   //       const newRow = [...row];
//   //       newRow[titleIndex] = row[newTitleIndex] || row[titleIndex]; // 新タイトルが空の場合は既存のタイトルを維持
//   //       return newRow;
//   //     });

//   //     if (isDemoMode) {
//   //       // DEMOモードの場合、ローカルのデータのみを更新
//   //       setData(updatedValues);
//   //       setMessage('新タイトルが既存のTitleカラムに上書きされました（デモモード）。');
//   //     } else {
//   //       // スプレッドシートを更新
//   //       const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedValues[0].length)}${updatedValues.length}`;
//   //       await axios.put(
//   //         `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//   //         {
//   //           range: updateRange,
//   //           values: updatedValues,
//   //           majorDimension: "ROWS"
//   //         },
//   //         {
//   //           params: { valueInputOption: 'USER_ENTERED' },
//   //           headers: { 'Authorization': `Bearer ${token}` }
//   //         }
//   //       );

//   //       // データを再取得
//   //       await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//   //     }

//   //     setMessage('新タイトルが既存のTitleカラムに上書きされました。');
//   //   } catch (error) {
//   //     console.error('Error in title overwrite:', error);
//   //     setError(`タイトル上書き中にエラーが発生しました: ${error.message}`);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // }, [currentSpreadsheet, token, data, fetchData, columnToLetter, isDemoMode]);

//   // const handleTitleTruncation = useCallback(async () => {
//   //   if (!data || data.length === 0) {
//   //     setError('データが存在しません。');
//   //     return;
//   //   }

//   //   setLoading(true);
//   //   setError(null);
//   //   setMessage('');

//   //   try {
//   //     const headers = data[0];
//   //     const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');

//   //     if (titleIndex === -1) {
//   //       throw new Error('"Title"カラムが見つかりません。');
//   //     }

//   //     const updatedValues = data.map((row, index) => {
//   //       if (index === 0) return row; // ヘッダー行はそのまま
//   //       const newRow = [...row];
//   //       if (newRow[titleIndex]) {
//   //         // ダブルクォーテーションを削除し、80文字に切り詰める
//   //         newRow[titleIndex] = newRow[titleIndex].replace(/"/g, '').slice(0, 80);
//   //       }
//   //       return newRow;
//   //     });

//   //     if (isDemoMode) {
//   //       // DEMOモードの場合、ローカルのデータのみを更新
//   //       setData(updatedValues);
//   //       setMessage('タイトルを80文字以内に編集し、ダブルクォーテーションを削除しました（デモモード）。');
//   //     } else {
//   //       // スプレッドシートを更新
//   //       const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedValues[0].length)}${updatedValues.length}`;
//   //       await axios.put(`https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//   //         {
//   //           range: updateRange,
//   //           values: updatedValues,
//   //           majorDimension: "ROWS"
//   //         },
//   //         {
//   //           params: { valueInputOption: 'USER_ENTERED' },
//   //           headers: { 'Authorization': `Bearer ${token}` }
//   //         }
//   //       );

//   //       // データを再取得
//   //       await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//   //     }

//   //     setMessage('タイトルを80文字以内に編集し、ダブルクォーテーションを削除しました。');
//   //   } catch (error) {
//   //     console.error('Error in title truncation:', error);
//   //     setError(`タイトル編集中にエラーが発生しました: ${error.message}`);
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // }, [currentSpreadsheet, token, data, fetchData, columnToLetter, isDemoMode]);

//   const handlePreviewOpen = () => {
//     setIsPreviewOpen(true);
//   };

//   const handlePreviewClose = () => {
//     setIsPreviewOpen(false);
//   };

//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setIsDrawerOpen(open);
//   };

//   const handleOpenCustomSettings = useCallback(() => {
//     setIsCustomSettingsOpen(true);
//   }, []);

//   const handleCloseCustomSettings = useCallback(() => {
//     setIsCustomSettingsOpen(false);
//   }, []);

//   const handleSaveCustomSettings = useCallback((settings) => {
//     setCustomSettings(settings);
//     setIsCustomSettingsOpen(false);
//     localStorage.setItem('customPriceSettings', JSON.stringify(settings));
//     setSnackbar({ open: true, message: '設定が保存されました', severity: 'success' });
//   }, []);

//   const handleClosePriceCalculation = useCallback(() => {
//     setIsPriceCalculationOpen(false);
//   }, []);

//   const handleUpdatePrices = useCallback(async (updatedSpreadsheetData) => {
//     setLoading(true);
//     setError(null);
//     try {
//       if (isDemoMode) {
//         // DEMOモードの場合、ローカルのデータのみを更新
//         setData(updatedSpreadsheetData);
//         setSnackbar({ open: true, message: '価格が更新されました（デモモード）', severity: 'success' });
//       } else {
//         const updateRange = `${currentSpreadsheet.sheetName}!A1:${columnToLetter(updatedSpreadsheetData[0].length)}${updatedSpreadsheetData.length}`;
//         await axios.put(
//           `https://sheets.googleapis.com/v4/spreadsheets/${currentSpreadsheet.id}/values/${encodeURIComponent(updateRange)}`,
//           {
//             range: updateRange,
//             values: updatedSpreadsheetData,
//             majorDimension: "ROWS"
//           },
//           {
//             params: { valueInputOption: 'USER_ENTERED' },
//             headers: { 'Authorization': `Bearer ${token}` }
//           }
//         );

//         await fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName);
//         setSnackbar({ open: true, message: '価格が更新されました', severity: 'success' });
//       }
//     } catch (error) {
//       console.error('Error updating prices:', error);
//       setError(`価格の更新中にエラーが発生しました: ${error.message}`);
//       setSnackbar({ open: true, message: '価格の更新に失敗しました', severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   }, [currentSpreadsheet, token, fetchData, columnToLetter, isDemoMode]);

//   const handleDemoModeToggle = () => {
//     setIsDemoMode(prevMode => !prevMode);
//     if (!isDemoMode) {
//       // DEMOモードを開始する時の処理
//       setSnackbar({ open: true, message: 'DEMOモードを開始しました。実際のデータは変更されません。', severity: 'info' });
//     } else {
//       // DEMOモードを終了する時の処理
//       setSnackbar({ open: true, message: 'DEMOモードを終了しました。', severity: 'info' });
//     }
//   };

//   const handleItemSpecificsSettingsOpen = () => {
//     setIsItemSpecificsSettingsOpen(true);
//     setIsDrawerOpen(false);
//   };

//   const handleItemSpecificsSettingsClose = () => {
//     setIsItemSpecificsSettingsOpen(false);
//   };

//   const handleItemSpecificsSettingsSave = (settings) => {
//     setItemSpecificsSettings(settings);
//     handleItemSpecificsSettingsClose();
//   };

//   const menuItems = [
//     { 
//       text: '価格計算設定', 
//       icon: <SettingsIcon />, 
//       action: handleOpenCustomSettings
//     },
//     {
//       text: 'Item Specifics設定',
//       icon: <SettingsIcon />,
//       action: handleItemSpecificsSettingsOpen
//     },
//     {
//       text: 'マニュアル',
//       icon: <AttachMoneyIcon />,
//       action: () => {
//         window.open('https://ebay-marketing-tool.com/ebay-spread-manual-price/', '_blank', 'noopener,noreferrer');
//       },
//       renderItem: (item) => (
//         <>
//           <ListItemIcon>{item.icon}</ListItemIcon>
//           <ListItemText primary={item.text} />
//           <Box component="span" sx={{ ml: 1 }}>
//             <LaunchIcon fontSize="small" />
//           </Box>
//         </>
//       )
//     },
//     // { 
//     //   text: 'OpenAI APIキー設定', 
//     //   action: () => setIsApiKeyDialogOpen(true)
//     // },
//   ];

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <AppBar position="static">
//         <Toolbar>
//           <IconButton
//             size="large"
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             sx={{ mr: 2 }}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//             Specifics-IN ver 1.0.6
//           </Typography>
//           <FormControlLabel
//             control={
//               <Switch
//                 checked={isDemoMode}
//                 onChange={handleDemoModeToggle}
//                 color="secondary"
//               />
//             }
//             label={isDemoMode ? "DEMOモード ON" : "DEMOモード OFF"}
//             sx={{ mr: 2 }}
//           />
//           <Button 
//             color="inherit" 
//             href="https://www.ebay.com/sh/reports/uploads" 
//             target="_blank" 
//             rel="noopener noreferrer"
//             startIcon={<CloudUploadIcon />}
//           >
//             eBayアップロード
//           </Button>
//           <Button 
//             color="inherit" 
//             href="https://app.photoroom.com/batch" 
//             target="_blank" 
//             rel="noopener noreferrer"
//             startIcon={<PhotoCameraIcon />}
//           >
//             Photoroom
//           </Button>
//           <Button 
//             color="inherit" 
//             href="https://postimages.org/#google_vignette" 
//             target="_blank" 
//             rel="noopener noreferrer"
//             startIcon={<LinkIcon />}
//           >
//             画像URL変換
//           </Button>
//           <Button color="inherit" onClick={handleSignOut}>
//             Sign Out
//           </Button>
//           <IconButton 
//             color="inherit" 
//             onClick={() => setIsHelpOpen(!isHelpOpen)}
//             aria-label="ヘルプを表示/非表示"
//           >
//             <HelpIcon />
//           </IconButton>
//         </Toolbar>
//       </AppBar>

//       <Drawer
//         anchor="left"
//         open={isDrawerOpen}
//         onClose={toggleDrawer(false)}
//         sx={{
//           '& .MuiDrawer-paper': {
//             width: '200px',
//             boxSizing: 'border-box',
//           },
//         }}
//       >
//         <List sx={{ width: '100%' }}>
//           {menuItems.map((item, index) => (
//             <ListItem 
//               button 
//               key={index} 
//               onClick={item.action}
//               sx={{ 
//                 py: 2,
//                 '&:hover': {
//                   backgroundColor: 'rgba(0, 0, 0, 0.04)',
//                 },
//               }}
//             >
//               {item.renderItem ? item.renderItem(item) : (
//                 <>
//                   <ListItemIcon sx={{ minWidth: '56px' }}>{item.icon}</ListItemIcon>
//                   <ListItemText 
//                     primary={item.text} 
//                     primaryTypographyProps={{ 
//                       fontSize: '1.1rem',
//                       fontWeight: 'medium'
//                     }} 
//                   />
//                 </>
//               )}
//             </ListItem>
//           ))}
//         </List>
//       </Drawer>

//       <Grid container spacing={2}>
//         <Grid item xs={12}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Grid container spacing={1} className="input-container">
//               <Grid item className="input-field">
//                 <TextField
//                   fullWidth
//                   label="Spreadsheet ID"
//                   value={currentSpreadsheet.id}
//                   onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, id: e.target.value})}
//                   size="small"
//                 />
//               </Grid>
//               <Grid item className="input-field">
//                 <TextField
//                   fullWidth
//                   label="Spreadsheet Name"
//                   value={currentSpreadsheet.name}
//                   onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, name: e.target.value})}
//                   size="small"
//                 />
//               </Grid>
//               <Grid item className="button-group">
//                 <Button 
//                   variant="contained" 
//                   onClick={() => fetchSheetNames(currentSpreadsheet.id)}
//                   size="small"
//                 >
//                   読み込み
//                 </Button>
//               </Grid>
//               <Grid item className="button-group">
//                 <Button 
//                   variant="contained" 
//                   onClick={saveSpreadsheet}
//                   size="small"
//                 >
//                   保存
//                 </Button>
//               </Grid>
//               <Grid item className="input-field">
//                 {sheetNames.length > 0 ? (
//                   <Select
//                     fullWidth
//                     value={currentSpreadsheet.sheetName}
//                     onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, sheetName: e.target.value})}
//                     size="small"
//                   >
//                     {sheetNames.map((name, index) => (
//                       <MenuItem key={index} value={name}>{name}</MenuItem>
//                     ))}
//                   </Select>
//                 ) : (
//                   <TextField
//                     fullWidth
//                     label="Sheet Name"
//                     value={currentSpreadsheet.sheetName}
//                     onChange={(e) => setCurrentSpreadsheet({...currentSpreadsheet, sheetName: e.target.value})}
//                     size="small"
//                   />
//                 )}
//               </Grid>
//               <Grid item className="button-group">
//                 <Button
//                   variant="contained"
//                   onClick={() => fetchData(currentSpreadsheet.id, currentSpreadsheet.sheetName)}
//                   disabled={loading || !currentSpreadsheet.sheetName}
//                   size="small"
//                 >
//                   {loading ? 'Loading...' : 'データ取得'}
//                 </Button>
//               </Grid>
//             </Grid>

//             <Box sx={{ mt: 4, mb: 2 }}>
//               <Typography variant="h6" gutterBottom>
//                 保存したIDsheets
//               </Typography>
//               <Paper elevation={2} sx={{ p: 2, overflowX: 'auto' }}>
//                 <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
//                   {savedSpreadsheets.map((spreadsheet, index) => (
//                     <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
//                       <Card variant="outlined">
//                         <CardContent>
//                           <Typography variant="subtitle2" noWrap title={spreadsheet.name}>
//                             {spreadsheet.name}
//                           </Typography>
//                           <Typography variant="caption" color="text.secondary" display="block" noWrap title={spreadsheet.id}>
//                             ID: {spreadsheet.id}
//                           </Typography>
//                         </CardContent>
//                         <CardActions>
//                           <Button
//                             size="small"
//                             variant="contained"
//                             onClick={() => loadSavedSpreadsheet(spreadsheet)}
//                           >
//                             読み込み
//                           </Button>
//                           <Button
//                             size="small"
//                             variant="outlined"
//                             color="error"
//                             onClick={() => deleteSavedSpreadsheet(spreadsheet.id)}
//                           >
//                             消去
//                           </Button>
//                         </CardActions>
//                       </Card>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </Paper>
//             </Box>

//             {error && <p className="error-message">{error}</p>}
//             {message && <p className="success-message">{message}</p>}

//             <Grid container spacing={4}>
//               <Grid item xs={12}>
//                 <ColumnNameBasedTemplateManager
//                   spreadsheetId={currentSpreadsheet.id}
//                   sheetName={currentSpreadsheet.sheetName}
//                   token={token}
//                   fetchData={updateSheetData}
//                 />
//               </Grid>

//               <Grid item xs={4} md={4} lg={4}>
//                 <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
//                   <Typography variant="h6" gutterBottom>
//                     タイトル生成と編集
//                   </Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={6}>
//                       <Button
//                         onClick={handleTitleGeneration}
//                         variant="contained"
//                         color="primary"
//                         fullWidth
//                         disabled={loading || (!apiKey && !isDemoMode)}
//                       >
//                         1. タイトルを生成
//                       </Button>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Button
//                         onClick={handlePreviewOpen}
//                         variant="outlined"
//                         color="primary"
//                         fullWidth
//                         disabled={loading || generatedTitles.length === 0}
//                       >
//                         2. プレビュー
//                       </Button>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Button
//                         onClick={handleTitleOverwrite}
//                         variant="contained"
//                         color="secondary"
//                         fullWidth
//                         disabled={loading || generatedTitles.length === 0}
//                       >
//                         3. 新タイトルを上書き
//                       </Button>
//                     </Grid>
//                     <Grid item xs={6}>
//                       <Button
//                         onClick={handleTitleTruncation}
//                         variant="outlined"
//                         color="secondary"
//                         fullWidth
//                         disabled={loading || !data || data.length === 0}
//                       >
//                         4. タイトル80文字制限
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </Paper>
//                 <PreviewDialog
//                   open={isPreviewOpen}
//                   handleClose={() => setIsPreviewOpen(false)}
//                   previewData={previewData}
//                   onConfirm={handleTitlesConfirm}
//                 />
//               </Grid>

//               <Grid item xs={4}>
//                 <Paper elevation={3} sx={{}}>
//                   <ColumnDeletion
//                     currentSpreadsheet={currentSpreadsheet}
//                     token={token}
//                     data={data}
//                     fetchData={fetchData}
//                     columnToLetter={columnToLetter}
//                     setError={setError}
//                     setMessage={setMessage}
//                     setLoading={setLoading}
//                   />
//                 </Paper>
//               </Grid>

//               <Grid item xs={4}>
//               <ItemSpecificsApplier
//                 spreadsheetId={currentSpreadsheet.id}
//                 sheetName={currentSpreadsheet.sheetName}
//                 token={token}
//               />
//               </Grid>

//               {/* <Grid item xs={4}>
//               {!apiKey && !isDemoMode ? (
//                 <Paper elevation={3} sx={{ p: 2, my: 2 }}>
//                   <Typography variant="h6" gutterBottom>
//                     OpenAI APIキー設定
//                   </Typography>
//                   <APIKeyInput onApiKeySet={handleApiKeySet} />
//                 </Paper>
//               ) : (
//                 <Paper elevation={3} sx={{ p: 2, my: 2 }}>
//                   <Typography variant="body1">
//                     {isDemoMode ? 'DEMOモード: APIキー不要' : 'OpenAI APIキーが設定されています。'}
//                   </Typography>
//                   {!isDemoMode && (
//                     <Button onClick={() => {
//                       setApiKey('');
//                       localStorage.removeItem('openai_api_key');
//                     }}>
//                       APIキーをリセット
//                     </Button>
//                   )}
//                 </Paper>
//               )}
//               </Grid> */}

//               {/* {error && (
//                 <Typography 
//                   color="error" 
//                   sx={{ 
//                     mt: 1, 
//                     ml: 4,
//                     p: 1,                    
//                     borderRadius: 1
//                   }}
//                 >
//                   {error}
//                 </Typography>
//               )}
//               {message && (
//                 <Typography 
//                   color="success" 
//                   sx={{ 
//                     mt: 1, 
//                     ml: 4,
//                     p: 1,
//                     borderRadius: 1
//                   }}
//                 >
//                   {message}
//                 </Typography>
//               )} */}

//               {/* <Dialog
//                 open={isConfirmDialogOpen}
//                 onClose={() => setIsConfirmDialogOpen(false)}
//               >
//                 <DialogTitle>確認</DialogTitle>
//                 <DialogContent>
//                   <DialogContentText>
//                     新しいタイトルを生成してスプレッドシートに反映しますか？この操作は元に戻せません。
//                   </DialogContentText>
//                 </DialogContent>
//                 <DialogActions>
//                   <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
//                   <Button onClick={confirmTitleGeneration} autoFocus>
//                     生成する
//                   </Button>
//                 </DialogActions>
//               </Dialog> */}

//               <Grid container spacing={3} sx={{ p: 2 , ml: 0}}>
//                 <Grid item xs={12} md={4}>
//                   <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//                     <ImageDownloader
//                       spreadsheetId={currentSpreadsheet.id}
//                       sheetName={currentSpreadsheet.sheetName}
//                       token={token}
//                       data={data}
//                     />
//                   </Paper>
//                 </Grid>

//                 <Grid item xs={12} md={4}>
//                   <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
//                     <ImageUrlReplacer
//                       spreadsheetId={currentSpreadsheet.id}
//                       sheetName={currentSpreadsheet.sheetName}
//                       token={token}
//                       data={data}
//                       fetchData={updateSheetData}
//                     />
//                   </Paper>
//                 </Grid>

//                 <Grid item xs={12} md={4}>
//                   <Paper elevation={3} sx={{ p: 3, mb: 3}}>
//                     <PriceCalculationSettings
//                       customSettings={customSettings}
//                       spreadsheetData={spreadsheetData}
//                       // customSettings={customSettings || {}}
//                       // spreadsheetData={spreadsheetData || []}
//                       onUpdatePrices={handleUpdatePrices}
//                       fetchSpreadsheetData={fetchData}
//                     />
//                   </Paper>
//                 </Grid>
//               </Grid>


//             <Grid container spacing={4}>
//               <Grid item xs={6}>
//               {!apiKey && !isDemoMode ? (
//                 <Paper elevation={3} sx={{ p: 1, my: 1 , ml: 4}}>
//                   <Typography variant="h6" gutterBottom>
//                     OpenAI APIキー設定
//                   </Typography>
//                   <APIKeyInput onApiKeySet={handleApiKeySet} />
//                 </Paper>
//               ) : (
//                 <Paper elevation={3} sx={{ p: 1, my: 1, ml: 4 }}>
//                   <Typography variant="body1">
//                     {isDemoMode ? 'DEMOモード: APIキー不要' : 'OpenAI APIキーが設定されています。'}
//                   </Typography>
//                   {!isDemoMode && (
//                     <Button onClick={() => {
//                       setApiKey('');
//                       localStorage.removeItem('openai_api_key');
//                     }}>
//                       APIキーをリセット
//                     </Button>
//                   )}
//                 </Paper>
//               )}
//               </Grid>

//               <Grid item xs={6}>
//                 <OpenAIDescriptionGenerator
//                   spreadsheetId={currentSpreadsheet.id}
//                   sheetName={currentSpreadsheet.sheetName}
//                   token={token}
//                   fetchData={updateSheetData}
//                   apiKey={apiKey}
//                 />
//              </Grid>
//             </Grid>

//               <Grid item xs={12}>
//                 <EditableSpreadsheet 
//                   spreadsheetId={currentSpreadsheet.id} 
//                   sheetName={currentSpreadsheet.sheetName}
//                   token={token}
//                   data={data || []}
//                   fetchData={updateSheetData}
//                 />
//               </Grid>
//             </Grid>
//           </Paper>
//         </Grid>
//       </Grid>

//       <Drawer
//         anchor="right"
//         open={isHelpOpen}
//         onClose={() => setIsHelpOpen(false)}
//       >
//         <Box sx={{ width: 300, p: 2 }}>
//           <InteractiveManual />
//         </Box>
//       </Drawer>

//       <Dialog
//         open={isCustomSettingsOpen}
//         onClose={handleCloseCustomSettings}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>価格計算設定</DialogTitle>
//         <DialogContent>
//           <CustomColumnSettings 
//             onSave={handleSaveCustomSettings}
//             initialSettings={customSettings}
//             userId={user ? user.uid : null}
//           />
//         </DialogContent>
//       </Dialog>

//       <Dialog
//         open={isPriceCalculationOpen}
//         onClose={handleClosePriceCalculation}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>価格計算</DialogTitle>
//         <DialogContent>
//           <PriceCalculationSettings
//             customSettings={customSettings}
//             spreadsheetData={spreadsheetData}
//             onUpdatePrices={handleUpdatePrices}
//             fetchSpreadsheetData={fetchData}
//           />
//         </DialogContent>
//       </Dialog>

//       {/* <Dialog
//         open={isItemSpecificsOpen}
//         onClose={handleItemSpecificsClose}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>Item Specifics Manager</DialogTitle>
//         <DialogContent>
//           <ItemSpecificsManager
//             spreadsheetId={currentSpreadsheet.id}
//             sheetName={currentSpreadsheet.sheetName}
//             token={token}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleItemSpecificsClose}>Close</Button>
//         </DialogActions>
//       </Dialog> */}

//       <Dialog
//         open={isItemSpecificsSettingsOpen}
//         onClose={handleItemSpecificsSettingsClose}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>Item Specificsの管理画面</DialogTitle>
//         <DialogContent>
//           <ItemSpecificsSettings onSave={handleItemSpecificsSettingsSave} onClose={handleItemSpecificsSettingsClose} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleItemSpecificsSettingsClose}>Close</Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       <TermsAndPrivacy />

//       <DemoMode isActive={isDemoMode} onComplete={() => setIsDemoMode(false)} />
//     </Box>
//   );
// }

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isDemoMode, setIsDemoMode] = useState(false);

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setIsAuthenticated(!!user);
//       setIsLoading(false);
//     });
//     return () => unsubscribe();
//   }, []);

//   if (isLoading) {
//     return <CircularProgress />;
//   }

//   return (
//     <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
//       <Router>
//         <Routes>
//           <Route 
//             path="/login" 
//             element={
//               isAuthenticated ? 
//                 <Navigate to="/" /> : 
//                 <Login setIsAuthenticated={setIsAuthenticated} setIsDemoMode={setIsDemoMode} />
//             } 
//           />
//           <Route 
//             path="/" 
//             element={
//               isAuthenticated || isDemoMode ? 
//                 <AppContent setIsAuthenticated={setIsAuthenticated} isDemoMode={isDemoMode} setIsDemoMode={setIsDemoMode} /> : 
//                 <Navigate to="/login" />
//             } 
//           />
//         </Routes>
//       </Router>
//     </GoogleOAuthProvider>
//   );
// }

// export default App;