import React, { useState } from 'react';
import {
  Box,
  Typography,
  Link,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';

function CustomDialogTitle({ children }) {
  return (
    <Box sx={{ m: 0, p: 2 }}>
      <Typography variant="h6" component="div">
        {children}
      </Typography>
    </Box>
  );
}

function TermsAndPrivacy() {
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);
  const handleOpenPrivacy = () => setOpenPrivacy(true);
  const handleClosePrivacy = () => setOpenPrivacy(false);

  return (
    <Box sx={{ mt: 4, position: 'absolute', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>
      <Typography variant="body2">
        <Link component="button" onClick={handleOpenTerms}>
          利用規約
        </Link>
        {' | '}
        <Link component="button" onClick={handleOpenPrivacy}>
          プライバシーポリシー
        </Link>
      </Typography>

      <Dialog open={openTerms} onClose={handleCloseTerms} scroll="paper" maxWidth="md" fullWidth>
        <CustomDialogTitle>
          利用規約
        </CustomDialogTitle>
        <Typography variant="subtitle1" sx={{ px: 3, pt: 0, pb: 2 }}>
          eBayスプレッドシート管理アプリケーション
        </Typography>
        <DialogContent dividers>
          <DialogContentText component="div">
            <Typography variant="h6" gutterBottom>1. サービスの概要</Typography>
            <Typography paragraph>本アプリケーションは、eBay出品者向けのスプレッドシート管理ツールです。AI画像解析、タイトル生成、およびテンプレート管理機能を含みます。</Typography>

            <Typography variant="h6" gutterBottom>2. 利用条件</Typography>
            <Typography paragraph>本サービスを利用するには、18歳以上であり、有効なeBayアカウントを所持している必要があります。また、OpenAI APIの利用規約に同意する必要があります。</Typography>

            <Typography variant="h6" gutterBottom>3. ユーザーの責任</Typography>
            <Typography component="div">
              <ul>
                <li>正確な情報の提供</li>
                <li>アカウントとパスワードの管理</li>
                <li>適切なデータ管理</li>
                <li>OpenAI APIキーの適切な管理と使用</li>
                <li>AI生成コンテンツの適切な使用と確認</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>4. 禁止事項</Typography>
            <Typography component="div">
              <ul>
                <li>不正アクセス</li>
                <li>サービスの不正利用</li>
                <li>他のユーザーへの妨害</li>
                <li>AI生成コンテンツの不適切な使用</li>
                <li>OpenAI APIの過剰な使用や不正利用</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>5. 知的財産権</Typography>
            <Typography paragraph>本サービスに関連するすべての知的財産権は当社に帰属します。ユーザーが作成したテンプレートや生成されたタイトルの権利はユーザーに帰属しますが、当社はサービス改善のためにこれらを匿名で使用する権利を有します。</Typography>

            <Typography variant="h6" gutterBottom>6. AI生成コンテンツ</Typography>
            <Typography paragraph>本サービスのAI機能により生成されたコンテンツの正確性、適切性、完全性について、当社は保証しません。ユーザーは生成されたコンテンツを確認し、適切に編集する責任があります。</Typography>

            <Typography variant="h6" gutterBottom>7. 免責事項</Typography>
            <Typography paragraph>本サービスは「現状有姿」で提供され、特定の目的への適合性を保証するものではありません。AI機能の精度や可用性、OpenAI APIの継続的な利用可能性についても保証しません。</Typography>

            <Typography variant="h6" gutterBottom>8. サービスの変更・終了</Typography>
            <Typography paragraph>当社は、事前の通知なくサービスの変更または終了を行う権利を有します。これにはAI機能やテンプレート管理機能の変更も含まれます。</Typography>

            <Typography variant="h6" gutterBottom>9. 準拠法と管轄裁判所</Typography>
            <Typography paragraph>本規約の解釈および適用は日本法に準拠し、関連する紛争は東京地方裁判所を第一審の専属的合意管轄裁判所とします。</Typography>

            <Typography variant="h6" gutterBottom>10. 規約の変更</Typography>
            <Typography paragraph>当社は、本規約を随時変更する権利を有します。変更後の利用継続をもって、新規約に同意したものとみなします。</Typography>

            <Typography variant="h6" gutterBottom>11. 外部リンクポリシー</Typography>
            <Typography paragraph>
              当サービスは、ユーザーの利便性向上のため、外部ウェブサイトへのリンクを提供する場合があります。これらのリンクは情報提供のみを目的としており、リンク先のウェブサイトやその内容を当社が推奨、保証、または管理していることを意味するものではありません。
            </Typography>
            <Typography paragraph>
              当社は、リンク先の外部ウェブサイトの内容、正確性、適法性、または安全性について一切の責任を負いません。ユーザーは、自己の責任において外部リンクを利用するものとし、リンク先のウェブサイトの利用により生じた損害や不利益について、当社は一切の責任を負いません。
            </Typography>
            <Typography paragraph>
              ユーザーは、外部リンクを利用する前に、リンク先ウェブサイトの利用規約、プライバシーポリシー、その他の方針を確認し、それらに同意する必要があります。外部ウェブサイトでの個人情報の取り扱いについては、当該ウェブサイトのプライバシーポリシーが適用されます。
            </Typography>
            <Typography paragraph>
              当社は、不適切または違法なコンテンツを含む外部ウェブサイトへのリンクを発見した場合、予告なくそのリンクを削除する権利を有します。ユーザーは、当サービス内で不適切または違法なコンテンツへのリンクを発見した場合、速やかに当社に報告する義務があります。
            </Typography>

            <Typography variant="h6" gutterBottom>12. 画像の取り扱いについて</Typography>
            <Typography paragraph>
             本サービスは、ユーザーが合法的に所有または使用権を持つ画像の加工と管理を目的としています。ユーザーは、著作権法を遵守し、適切な権利を有する画像のみをダウンロード・アップロードし処理することに同意するものとします。
            </Typography>
            <Typography paragraph>
             ユーザーは、アップロード・ダウンロードする画像に関するすべての権利と許可を有していることを保証するものとします。本サービスは、ユーザーが不適切にダウンロード・アップロードした画像に関する一切の責任を負いません。
            </Typography>
            <Typography paragraph>
             ユーザーは、本サービスを利用する際に適用されるすべての法律、規制、および第三者の権利を遵守することに同意するものとします。
            </Typography>
  
          

            <Typography variant="body2" align="right" sx={{ mt: 2 }}>
              最終更新日：2024年8月24日
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTerms}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPrivacy} onClose={handleClosePrivacy} scroll="paper" maxWidth="md" fullWidth>
        <CustomDialogTitle>
          プライバシーポリシー
        </CustomDialogTitle>
        <Typography variant="subtitle1" sx={{ px: 3, pt: 0, pb: 2 }}>
          eBayスプレッドシート管理アプリケーション
        </Typography>
        <DialogContent dividers>
          <DialogContentText component="div">
            <Typography variant="h6" gutterBottom>1. 収集する情報と現状</Typography>
            <Typography component="div">
              <ul>
                <li>アカウント情報（メールアドレス）</li>
                <li>現状では、上記以外の情報をサーバーサイドなどで管理・保存していませんので上記以外の情報は活用しません。</li>
                {/* <li>eBayアカウント連携情報</li>
                <li>スプレッドシートデータ</li>
                <li>利用ログ</li>
                <li>AI解析用の画像URL</li>
                <li>生成されたタイトルデータ</li>
                <li>ユーザー作成テンプレート</li> */}
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>2. 情報の使用目的</Typography>
            <Typography component="div">
              <ul>
                <li>サービスの提供と改善</li>
                <li>ユーザーサポート</li>
                <li>テンプレート推奨システムの開発</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>3. 情報の共有</Typography>
            <Typography paragraph>法的要請がある場合を除き、ユーザーの同意なく第三者と情報を共有することはありません。</Typography>

            <Typography variant="h6" gutterBottom>4. データセキュリティ</Typography>
            <Typography paragraph>適切な技術的・組織的措置を講じて、ユーザー情報を保護します。OpenAI APIキーは暗号化して保存され、サーバー側では保持していません。</Typography>

            <Typography variant="h6" gutterBottom>5. ユーザーの権利</Typography>
            <Typography component="div">
              <ul>
                <li>本ツールの使用の自由</li>
              </ul>
            </Typography>

            <Typography variant="h6" gutterBottom>6. クッキーの使用</Typography>
            <Typography paragraph>本サービスでは、ユーザーエクスペリエンス向上のためにクッキーを使用しています。これには、テンプレート管理機能の設定保存なども含まれます。</Typography>

            <Typography variant="h6" gutterBottom>7. 第三者サービス</Typography>
            <Typography paragraph>Google認証、eBay API、OpenAI APIなどの第三者サービスを利用しています。これらのサービスの利用には、それぞれのプライバシーポリシーが適用されます。</Typography>

            <Typography variant="h6" gutterBottom>8. AI生成データの取り扱い</Typography>
            <Typography paragraph>AI機能により生成されたデータ（解析結果、生成タイトル等）は、サービス改善のために匿名化して使用する場合があります。個人を特定できる情報は含まれません。</Typography>

            <Typography variant="h6" gutterBottom>9. プライバシーポリシーの変更</Typography>
            <Typography paragraph>本ポリシーは随時更新される可能性があります。重要な変更がある場合は、サービス内で通知します。</Typography>

            <Typography variant="h6" gutterBottom>10. 画像データの取り扱い</Typography>
            <Typography paragraph>ユーザーがダウンロードした画像は当社は保存していません。本サービスの画像ダウンロード機能を使用する場合、ユーザーのスプレッドシートに含まれる画像URLから画像をダウンロードし、ZIP形式でユーザーに提供しますが、保持していません。当社はユーザーの画像データを保持せず、第三者と共有することもありません。</Typography>

            <Typography variant="h6" gutterBottom>11.画像の使用と責任 </Typography>
            <Typography paragraph>ユーザーは、本サービスを通じてダウンロードまたはアップロードする画像について、適切な権利を有していることを確認する責任があります。当社は、ユーザーが不適切な方法で取得した画像や、著作権を侵害する画像の使用について一切の責任を負いません。</Typography>

            <Typography variant="h6" gutterBottom>12. お問い合わせ</Typography>
            <Typography paragraph>プライバシーに関するお問い合わせは、info@awiiin.comまでご連絡ください。</Typography>

            <Typography variant="body2" align="right" sx={{ mt: 2 }}>
              最終更新日：2024年8月24日
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrivacy}>閉じる</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TermsAndPrivacy;