const designTemplates = {
    professional: {
      name: 'Professional',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
          <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">${title}</h1>
          
          <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
            <div style="flex: 1; min-width: 300px; margin-right: 20px;">
              <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
              <ul style="list-style-type: none; padding-left: 0;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
                  <span style="position: absolute; left: 0; color: #4299e1;">✓</span> ${feature}
                </li>`).join('')}
              </ul>
            </div>
            
            <div style="flex: 1; min-width: 300px;">
              <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value]) => `
                    <tr>
                      <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </div>
          </div>
          
          <div style="margin-top: 30px;">
            <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
            <p style="line-height: 1.6;">${description}</p>
          </div>
          
          <div style="margin-top: 30px; background-color: #f7fafc; border: 1px solid #e2e8f0; border-radius: 4px; padding: 15px;">
            <h3 style="color: #2c5282; font-size: 18px; margin-top: 0;">Why Choose Us?</h3>
            <ul style="list-style-type: none; padding-left: 0;">
              <li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
                <span style="position: absolute; left: 0; color: #4299e1;">•</span> Quality Assurance
              </li>
              <li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
                <span style="position: absolute; left: 0; color: #4299e1;">•</span> Fast Shipping
              </li>
              <li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
                <span style="position: absolute; left: 0; color: #4299e1;">•</span> Excellent Customer Service
              </li>
            </ul>
          </div>
        </div>
      `,
    },
    modern: {
      name: 'Modern',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
          <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">${title}</h1>
          
          <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-bottom: 30px;">
            <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
              <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
              <ul style="list-style-type: none; padding-left: 0;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 12px; padding-left: 25px; position: relative;">
                  <span style="position: absolute; left: 0; color: #4299e1; font-weight: bold;">➤</span> ${feature}
                </li>`).join('')}
              </ul>
            </div>
            
            <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
              <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
              <table style="width: 100%; border-collapse: separate; border-spacing: 0 8px;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value]) => `
                    <tr>
                      <td style="padding: 8px; background-color: #edf2f7; font-weight: 600; border-radius: 4px 0 0 4px;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 8px; background-color: #edf2f7; border-radius: 0 4px 4px 0;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </div>
          </div>
          
          <div style="background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px; margin-bottom: 30px;">
            <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
            <p style="line-height: 1.7;">${description}</p>
          </div>
          
          <div style="background-color: #ebf8ff; border-radius: 8px; padding: 20px;">
            <h3 style="color: #2b6cb0; font-size: 20px; margin-top: 0;">Why Choose Us?</h3>
            <div style="display: flex; flex-wrap: wrap; gap: 15px;">
              <div style="flex: 1; min-width: 200px; background-color: white; border-radius: 8px; padding: 15px; text-align: center;">
                <span style="font-size: 24px; color: #4299e1;">🏆</span>
                <p style="margin: 10px 0 0; font-weight: 600;">Quality Assurance</p>
              </div>
              <div style="flex: 1; min-width: 200px; background-color: white; border-radius: 8px; padding: 15px; text-align: center;">
                <span style="font-size: 24px; color: #4299e1;">🚚</span>
                <p style="margin: 10px 0 0; font-weight: 600;">Fast Shipping</p>
              </div>
              <div style="flex: 1; min-width: 200px; background-color: white; border-radius: 8px; padding: 15px; text-align: center;">
                <span style="font-size: 24px; color: #4299e1;">📞</span>
                <p style="margin: 10px 0 0; font-weight: 600;">Excellent Support</p>
              </div>
            </div>
          </div>
        </div>
      `,
    },
    elegant: {
      name: 'Elegant',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
          <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">${title}</h1>
          
          <div style="display: flex; flex-wrap: wrap; gap: 30px; margin-bottom: 40px;">
            <div style="flex: 1; min-width: 300px;">
              <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
              <ul style="list-style-type: none; padding-left: 0;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 15px; padding-left: 30px; position: relative;">
                  <span style="position: absolute; left: 0; color: #d69e2e; font-size: 18px;">✦</span> ${feature}
                </li>`).join('')}
              </ul>
            </div>
            
            <div style="flex: 1; min-width: 300px;">
              <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value], index) => `
                    <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
                      <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 12px; border: 1px solid #ecc94b;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </div>
          </div>
          
          <div style="margin-bottom: 40px;">
            <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
            <p style="line-height: 1.8; text-align: justify;">${description}</p>
          </div>
          
          <div style="background-color: #fefcbf; border: 2px solid #d69e2e; border-radius: 8px; padding: 20px;">
            <h3 style="color: #975a16; font-size: 22px; margin-top: 0; text-align: center; font-weight: normal;">Why Choose Us?</h3>
            <div style="display: flex; flex-wrap: wrap; justify-content: space-around; text-align: center;">
              <div style="flex: 1; min-width: 200px; margin: 10px;">
                <span style="font-size: 36px; color: #d69e2e;">♛</span>
                <p style="margin: 10px 0 0; font-weight: bold;">Premium Quality</p>
              </div>
              <div style="flex: 1; min-width: 200px; margin: 10px;">
                <span style="font-size: 36px; color: #d69e2e;">⚡</span>
                <p style="margin: 10px 0 0; font-weight: bold;">Swift Delivery</p>
              </div>
              <div style="flex: 1; min-width: 200px; margin: 10px;">
                <span style="font-size: 36px; color: #d69e2e;">♥</span>
                <p style="margin: 10px 0 0; font-weight: bold;">Dedicated Service</p>
              </div>
            </div>
          </div>
        </div>
      `,
    },
    minimalist: {
      name: 'Minimalist',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: 'Helvetica', sans-serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #333;">
          <h1 style="font-size: 28px; text-align: center; margin-bottom: 40px;">${title}</h1>
  
          <div style="display: grid; grid-template-columns: 1fr; gap: 40px;">
            <section>
              <h2 style="font-size: 22px; margin-bottom: 20px;">Features</h2>
              <ul style="list-style: none; padding: 0;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">• ${feature}</li>`).join('')}
              </ul>
            </section>
  
            <section>
              <h2 style="font-size: 22px; margin-bottom: 20px;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value]) => `
                    <tr>
                      <td style="padding: 8px; border-bottom: 1px solid #ccc;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 8px; border-bottom: 1px solid #ccc;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </section>
  
            <section>
              <h2 style="font-size: 22px; margin-bottom: 20px;">Description</h2>
              <p style="line-height: 1.6;">${description}</p>
            </section>
          </div>
        </div>
      `,
    },
  
    vintage: {
      name: 'Vintage',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: 'Times New Roman', serif; max-width: 800px; margin: 0 auto; padding: 20px; color: #3e2723; background-color: #fbe9e7;">
          <h1 style="font-size: 32px; text-align: center; margin-bottom: 40px; border-bottom: 2px solid #bf360c;">${title}</h1>
  
          <div style="display: flex; flex-direction: column; gap: 30px;">
            <section>
              <h2 style="font-size: 24px; margin-bottom: 20px;">Features</h2>
              <ul style="list-style-type: square; padding-left: 20px;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 10px;">${feature}</li>`).join('')}
              </ul>
            </section>
  
            <section>
              <h2 style="font-size: 24px; margin-bottom: 20px;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value]) => `
                    <tr>
                      <td style="padding: 8px; border: 1px solid #bf360c;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 8px; border: 1px solid #bf360c;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </section>
  
            <section>
              <h2 style="font-size: 24px; margin-bottom: 20px;">Description</h2>
              <p style="line-height: 1.8;">${description}</p>
            </section>
          </div>
        </div>
      `,
    },
  
    creative: {
      name: 'Creative',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: 'Comic Sans MS', cursive; max-width: 800px; margin: 0 auto; padding: 20px; color: #000; background-color: #fff;">
          <h1 style="font-size: 30px; text-align: center; margin-bottom: 30px;">${title}</h1>
  
          <div style="display: grid; grid-template-columns: 1fr; gap: 20px;">
            <section>
              <h2 style="font-size: 24px; margin-bottom: 15px;">Features</h2>
              <ul style="list-style-type: circle; padding-left: 20px;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 8px;">${feature}</li>`).join('')}
              </ul>
            </section>
  
            <section>
              <h2 style="font-size: 24px; margin-bottom: 15px;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value]) => `
                    <tr>
                      <td style="padding: 6px; border-bottom: 1px dashed #ccc;">${key.replace(/^C:/, '')}</td>
                      <td style="padding: 6px; border-bottom: 1px dashed #ccc;">${value}</td>
                    </tr>
                  `).join('')}
              </table>
            </section>
  
            <section>
              <h2 style="font-size: 24px; margin-bottom: 15px;">Description</h2>
              <p style="line-height: 1.5;">${description}</p>
            </section>
          </div>
        </div>
      `,
    },
    feminineElegant: {
      name: 'Feminine Elegant',
      generateHTML: (title, features, description, specifications) => `
        <div style="font-family: 'Playfair Display', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #4a4a4a; background-color: #fff5f5;">
          <h1 style="font-size: 36px; color: #d64161; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #ffb3ba; padding-bottom: 10px;">${title}</h1>
          
          <div style="display: flex; flex-wrap: wrap; gap: 30px; margin-bottom: 40px;">
            <div style="flex: 1; min-width: 300px;">
              <h2 style="color: #d64161; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
              <ul style="list-style-type: none; padding-left: 0;">
                ${features.split('\n').map(feature => `<li style="margin-bottom: 15px; padding-left: 30px; position: relative;">
                  <span style="position: absolute; left: 0; color: #ff8ba7; font-size: 18px;">▫️</span> ${feature}
                </li>`).join('')}
              </ul>
            </div>
            
            <div style="flex: 1; min-width: 300px;">
              <h2 style="color: #d64161; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
              <table style="width: 100%; border-collapse: collapse;">
                ${Object.entries(specifications)
                  .filter(([key, value]) => value !== "NA" && value !== "")
                  .map(([key, value], index) => `
                    <tr style="background-color: ${index % 2 === 0 ? '#fff0f5' : '#ffe4e1'};">
                      <td style="padding: 12px; border: 1px solid #ffb3ba; font-weight: bold;">${key.replace(/^C:/, '')}</td>
                    <td style="padding: 12px; border: 1px solid #ffb3ba;">${value}</td>
                  </tr>
                `).join('')}
            </table>
          </div>
        </div>
        
        <div style="margin-bottom: 40px;">
          <h2 style="color: #d64161; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
          <p style="line-height: 1.8; text-align: justify;">${description}</p>
        </div>
        
        <div style="background-color: #ffdab9; border: 2px solid #ffb3ba; border-radius: 8px; padding: 20px;">
          <h3 style="color: #d64161; font-size: 22px; margin-top: 0; text-align: center; font-weight: normal;">Why Choose Us?</h3>
          <div style="display: flex; flex-wrap: wrap; justify-content: space-around; text-align: center;">
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 36px; color: #ff8ba7;">🌸</span>
              <p style="margin: 10px 0 0; font-weight: bold;">Exquisite Quality</p>
            </div>
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 36px; color: #ff8ba7;">🎀</span>
              <p style="margin: 10px 0 0; font-weight: bold;">Thoughtful Service</p>
            </div>
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 36px; color: #ff8ba7;">💖</span>
              <p style="margin: 10px 0 0; font-weight: bold;">Customer Satisfaction</p>
            </div>
          </div>
        </div>
      </div>
    `,
  },
  feminineCasual: {
    name: 'Feminine Casual',
    generateHTML: (title, features, description, specifications) => `
      <div style="font-family: 'Quicksand', sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #5a5a5a; background-color: #f0f8ff;">
        <h1 style="font-size: 32px; color: #6495ed; text-align: center; margin-bottom: 30px; font-weight: 500; border-bottom: 2px dashed #87cefa; padding-bottom: 10px;">${title}</h1>
        
        <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-bottom: 30px;">
          <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
            <h2 style="color: #6495ed; font-size: 22px; margin-bottom: 15px;">What You'll Love</h2>
            <ul style="list-style-type: none; padding-left: 0;">
              ${features.split('\n').map(feature => `<li style="margin-bottom: 12px; padding-left: 25px; position: relative;">
                <span style="position: absolute; left: 0; color: #87cefa; font-weight: bold;">✿</span> ${feature}
              </li>`).join('')}
            </ul>
          </div>
          
          <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
            <h2 style="color: #6495ed; font-size: 22px; margin-bottom: 15px;">The Details</h2>
            <table style="width: 100%; border-collapse: separate; border-spacing: 0 8px;">
              ${Object.entries(specifications)
                .filter(([key, value]) => value !== "NA" && value !== "")
                .map(([key, value]) => `
                  <tr>
                    <td style="padding: 8px; background-color: #e6f2ff; font-weight: 600; border-radius: 4px 0 0 4px;">${key.replace(/^C:/, '')}</td>
                    <td style="padding: 8px; background-color: #f0f8ff; border-radius: 0 4px 4px 0;">${value}</td>
                  </tr>
                `).join('')}
            </table>
          </div>
        </div>
        
        <div style="background-color: white; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px; margin-bottom: 30px;">
          <h2 style="color: #6495ed; font-size: 22px; margin-bottom: 15px;">About This Item</h2>
          <p style="line-height: 1.7;">${description}</p>
        </div>
        
        <div style="background-color: #e6f2ff; border-radius: 15px; padding: 20px; text-align: center;">
          <h3 style="color: #6495ed; font-size: 20px; margin-top: 0;">Why Shop With Us?</h3>
          <div style="display: flex; justify-content: space-around; flex-wrap: wrap;">
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 48px; color: #87cefa;">🌟</span>
              <p style="margin-top: 10px; font-weight: 500;">Top-Notch Quality</p>
            </div>
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 48px; color: #87cefa;">🚚</span>
              <p style="margin-top: 10px; font-weight: 500;">Swift Delivery</p>
            </div>
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 48px; color: #87cefa;">💁‍♀️</span>
              <p style="margin-top: 10px; font-weight: 500;">Friendly Support</p>
            </div>
          </div>
        </div>
      </div>
    `,
  },
  feminineModern: {
    name: 'Feminine Modern',
    generateHTML: (title, features, description, specifications) => `
      <div style="font-family: 'Montserrat', sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333; background-color: #fff;">
        <h1 style="font-size: 36px; color: #ff69b4; text-align: center; margin-bottom: 30px; font-weight: 600; letter-spacing: 1px;">${title}</h1>
        
        <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); gap: 20px; margin-bottom: 30px;">
          <div style="background-color: #fff0f5; border-radius: 10px; padding: 20px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
            <h2 style="color: #ff69b4; font-size: 24px; margin-bottom: 20px; font-weight: 500;">Features</h2>
            <ul style="list-style-type: none; padding-left: 0;">
              ${features.split('\n').map(feature => `<li style="margin-bottom: 15px; padding-left: 25px; position: relative;">
                <span style="position: absolute; left: 0; color: #ff69b4; font-weight: bold;">•</span> ${feature}
              </li>`).join('')}
            </ul>
          </div>
          
          <div style="background-color: #fff0f5; border-radius: 10px; padding: 20px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
            <h2 style="color: #ff69b4; font-size: 24px; margin-bottom: 20px; font-weight: 500;">Specifications</h2>
            <table style="width: 100%; border-collapse: separate; border-spacing: 0 10px;">
              ${Object.entries(specifications)
                .filter(([key, value]) => value !== "NA" && value !== "")
                .map(([key, value]) => `
                  <tr>
                    <td style="padding: 10px; background-color: #ffe4e1; font-weight: 500; border-radius: 5px 0 0 5px;">${key.replace(/^C:/, '')}</td>
                    <td style="padding: 10px; background-color: #fff; border: 1px solid #ffe4e1; border-radius: 0 5px 5px 0;">${value}</td>
                  </tr>
                `).join('')}
            </table>
          </div>
        </div>
        
        <div style="background-color: #fff; border-radius: 10px; padding: 20px; margin-bottom: 30px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
          <h2 style="color: #ff69b4; font-size: 24px; margin-bottom: 20px; font-weight: 500;">Description</h2>
          <p style="line-height: 1.8; text-align: justify;">${description}</p>
        </div>
        
        <div style="background-color: #ff69b4; color: white; border-radius: 10px; padding: 20px; text-align: center;">
          <h3 style="font-size: 24px; margin-bottom: 20px; font-weight: 500;">Why Choose Us</h3>
          <div style="display: flex; justify-content: space-around; flex-wrap: wrap;">
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 48px;">💎</span>
              <p style="margin-top: 10px; font-weight: 500;">Premium Quality</p>
            </div>
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 48px;">🎁</span>
              <p style="margin-top: 10px; font-weight: 500;">Gift-Ready Packaging</p>
            </div>
            <div style="flex: 1; min-width: 200px; margin: 10px;">
              <span style="font-size: 48px;">💯</span>
              <p style="margin-top: 10px; font-weight: 500;">100% Satisfaction</p>
            </div>
          </div>
        </div>
      </div>
    `,
  },
};

export default designTemplates;


//   const designTemplates = {
//     professional: {
//       name: 'Professional',
//       generateHTML: (title, features, description, specifications) => `
//         <div style="font-family: Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333;">
//           <h1 style="color: #1e3a8a; border-bottom: 2px solid #1e3a8a; padding-bottom: 10px; font-size: 24px;">${title}</h1>
          
//           <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
//             <div style="flex: 1; min-width: 300px; margin-right: 20px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Key Features</h2>
//               <ul style="list-style-type: none; padding-left: 0;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
//                   <span style="position: absolute; left: 0; color: #4299e1;">✓</span> ${feature}
//                 </li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="flex: 1; min-width: 300px;">
//               <h2 style="color: #2c5282; font-size: 20px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications).map(([key, value]) => `
//                   <tr>
//                     <td style="padding: 8px; border-bottom: 1px solid #e2e8f0; font-weight: bold;">${key}</td>
//                     <td style="padding: 8px; border-bottom: 1px solid #e2e8f0;">${value}</td>
//                   </tr>
//                 `).join('')}
//               </table>
//             </div>
//           </div>
          
//           <div style="margin-top: 30px;">
//             <h2 style="color: #2c5282; font-size: 20px;">Product Description</h2>
//             <p style="line-height: 1.6;">${description}</p>
//           </div>
          
//           <div style="margin-top: 30px; background-color: #f7fafc; border: 1px solid #e2e8f0; border-radius: 4px; padding: 15px;">
//             <h3 style="color: #2c5282; font-size: 18px; margin-top: 0;">Why Choose Us?</h3>
//             <ul style="list-style-type: none; padding-left: 0;">
//               <li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
//                 <span style="position: absolute; left: 0; color: #4299e1;">•</span> Quality Assurance
//               </li>
//               <li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
//                 <span style="position: absolute; left: 0; color: #4299e1;">•</span> Fast Shipping
//               </li>
//               <li style="margin-bottom: 10px; padding-left: 20px; position: relative;">
//                 <span style="position: absolute; left: 0; color: #4299e1;">•</span> Excellent Customer Service
//               </li>
//             </ul>
//           </div>
//         </div>
//       `,
//     },
//     modern: {
//       name: 'Modern',
//       generateHTML: (title, features, description, specifications) => `
//         <div style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #f7fafc;">
//           <h1 style="color: #2b6cb0; font-size: 28px; font-weight: 600; text-align: center; margin-bottom: 30px;">${title}</h1>
          
//           <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-bottom: 30px;">
//             <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Key Features</h2>
//               <ul style="list-style-type: none; padding-left: 0;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 12px; padding-left: 25px; position: relative;">
//                   <span style="position: absolute; left: 0; color: #4299e1; font-weight: bold;">➤</span> ${feature}
//                 </li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
//               <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Specifications</h2>
//               <table style="width: 100%; border-collapse: separate; border-spacing: 0 8px;">
//                 ${Object.entries(specifications).map(([key, value]) => `
//                   <tr>
//                     <td style="padding: 8px; background-color: #edf2f7; font-weight: 600; border-radius: 4px 0 0 4px;">${key}</td>
//                     <td style="padding: 8px; background-color: #edf2f7; border-radius: 0 4px 4px 0;">${value}</td>
//                   </tr>
//                 `).join('')}
//               </table>
//             </div>
//           </div>
          
//           <div style="background-color: white; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px; margin-bottom: 30px;">
//             <h2 style="color: #3182ce; font-size: 22px; margin-bottom: 15px;">Product Description</h2>
//             <p style="line-height: 1.7;">${description}</p>
//           </div>
          
//           <div style="background-color: #ebf8ff; border-radius: 8px; padding: 20px;">
//             <h3 style="color: #2b6cb0; font-size: 20px; margin-top: 0;">Why Choose Us?</h3>
//             <div style="display: flex; flex-wrap: wrap; gap: 15px;">
//               <div style="flex: 1; min-width: 200px; background-color: white; border-radius: 8px; padding: 15px; text-align: center;">
//                 <span style="font-size: 24px; color: #4299e1;">🏆</span>
//                 <p style="margin: 10px 0 0; font-weight: 600;">Quality Assurance</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; background-color: white; border-radius: 8px; padding: 15px; text-align: center;">
//                 <span style="font-size: 24px; color: #4299e1;">🚚</span>
//                 <p style="margin: 10px 0 0; font-weight: 600;">Fast Shipping</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; background-color: white; border-radius: 8px; padding: 15px; text-align: center;">
//                 <span style="font-size: 24px; color: #4299e1;">📞</span>
//                 <p style="margin: 10px 0 0; font-weight: 600;">Excellent Support</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       `,
//     },
//     elegant: {
//       name: 'Elegant',
//       generateHTML: (title, features, description, specifications) => `
//         <div style="font-family: 'Georgia', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #2d3748; background-color: #fffaf0;">
//           <h1 style="color: #744210; font-size: 32px; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #d69e2e; padding-bottom: 10px;">${title}</h1>
          
//           <div style="display: flex; flex-wrap: wrap; gap: 30px; margin-bottom: 40px;">
//             <div style="flex: 1; min-width: 300px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
//               <ul style="list-style-type: none; padding-left: 0;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 15px; padding-left: 30px; position: relative;">
//                   <span style="position: absolute; left: 0; color: #d69e2e; font-size: 18px;">✦</span> ${feature}
//                 </li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="flex: 1; min-width: 300px;">
//               <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications).map(([key, value], index) => `
//                   <tr style="background-color: ${index % 2 === 0 ? '#faf089' : '#fefcbf'};">
//                     <td style="padding: 12px; border: 1px solid #ecc94b; font-weight: bold;">${key}</td>
//                     <td style="padding: 12px; border: 1px solid #ecc94b;">${value}</td>
//                   </tr>
//                 `).join('')}
//               </table>
//             </div>
//           </div>
          
//           <div style="margin-bottom: 40px;">
//             <h2 style="color: #975a16; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
//             <p style="line-height: 1.8; text-align: justify;">${description}</p>
//           </div>
          
//           <div style="background-color: #fefcbf; border: 2px solid #d69e2e; border-radius: 8px; padding: 20px;">
//             <h3 style="color: #975a16; font-size: 22px; margin-top: 0; text-align: center; font-weight: normal;">Why Choose Us?</h3>
//             <div style="display: flex; flex-wrap: wrap; justify-content: space-around; text-align: center;">
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 36px; color: #d69e2e;">♛</span>
//                 <p style="margin: 10px 0 0; font-weight: bold;">Premium Quality</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 36px; color: #d69e2e;">⚡</span>
//                 <p style="margin: 10px 0 0; font-weight: bold;">Swift Delivery</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 36px; color: #d69e2e;">♥</span>
//                 <p style="margin: 10px 0 0; font-weight: bold;">Dedicated Service</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       `,
//     },
//     feminineElegant: {
//       name: 'Feminine Elegant',
//       generateHTML: (title, features, description, specifications) => `
//         <div style="font-family: 'Playfair Display', serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #4a4a4a; background-color: #fff5f5;">
//           <h1 style="font-size: 36px; color: #d64161; text-align: center; margin-bottom: 30px; font-weight: normal; border-bottom: 2px solid #ffb3ba; padding-bottom: 10px;">${title}</h1>
          
//           <div style="display: flex; flex-wrap: wrap; gap: 30px; margin-bottom: 40px;">
//             <div style="flex: 1; min-width: 300px;">
//               <h2 style="color: #d64161; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Key Features</h2>
//               <ul style="list-style-type: none; padding-left: 0;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 15px; padding-left: 30px; position: relative;">
//                   <span style="position: absolute; left: 0; color: #ff8ba7; font-size: 18px;">▫️</span> ${feature}
//                 </li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="flex: 1; min-width: 300px;">
//               <h2 style="color: #d64161; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Specifications</h2>
//               <table style="width: 100%; border-collapse: collapse;">
//                 ${Object.entries(specifications).map(([key, value], index) => `
//                   <tr style="background-color: ${index % 2 === 0 ? '#fff0f5' : '#ffe4e1'};">
//                     <td style="padding: 12px; border: 1px solid #ffb3ba; font-weight: bold;">${key}</td>
//                     <td style="padding: 12px; border: 1px solid #ffb3ba;">${value}</td>
//                   </tr>
//                 `).join('')}
//               </table>
//             </div>
//           </div>
          
//           <div style="margin-bottom: 40px;">
//             <h2 style="color: #d64161; font-size: 24px; margin-bottom: 20px; font-weight: normal;">Product Description</h2>
//             <p style="line-height: 1.8; text-align: justify;">${description}</p>
//           </div>
          
//           <div style="background-color: #ffdab9; border: 2px solid #ffb3ba; border-radius: 8px; padding: 20px;">
//             <h3 style="color: #d64161; font-size: 22px; margin-top: 0; text-align: center; font-weight: normal;">Why Choose Us?</h3>
//             <div style="display: flex; flex-wrap: wrap; justify-content: space-around; text-align: center;">
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 36px; color: #ff8ba7;">🌸</span>
//                 <p style="margin: 10px 0 0; font-weight: bold;">Exquisite Quality</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 36px; color: #ff8ba7;">🎀</span>
//                 <p style="margin: 10px 0 0; font-weight: bold;">Thoughtful Service</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 36px; color: #ff8ba7;">💖</span>
//                 <p style="margin: 10px 0 0; font-weight: bold;">Customer Satisfaction</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       `,
//     },
//     feminineCasual: {
//       name: 'Feminine Casual',
//       generateHTML: (title, features, description, specifications) => `
//         <div style="font-family: 'Quicksand', sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #5a5a5a; background-color: #f0f8ff;">
//           <h1 style="font-size: 32px; color: #6495ed; text-align: center; margin-bottom: 30px; font-weight: 500; border-bottom: 2px dashed #87cefa; padding-bottom: 10px;">${title}</h1>
          
//           <div style="display: flex; flex-wrap: wrap; gap: 20px; margin-bottom: 30px;">
//             <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
//               <h2 style="color: #6495ed; font-size: 22px; margin-bottom: 15px;">What You'll Love</h2>
//               <ul style="list-style-type: none; padding-left: 0;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 12px; padding-left: 25px; position: relative;">
//                   <span style="position: absolute; left: 0; color: #87cefa; font-weight: bold;">✿</span> ${feature}
//                 </li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="flex: 1; min-width: 300px; background-color: white; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px;">
//               <h2 style="color: #6495ed; font-size: 22px; margin-bottom: 15px;">The Details</h2>
//               <table style="width: 100%; border-collapse: separate; border-spacing: 0 8px;">
//                 ${Object.entries(specifications).map(([key, value]) => `
//                   <tr>
//                     <td style="padding: 8px; background-color: #e6f2ff; font-weight: 600; border-radius: 4px 0 0 4px;">${key}</td>
//                     <td style="padding: 8px; background-color: #f0f8ff; border-radius: 0 4px 4px 0;">${value}</td>
//                   </tr>
//                 `).join('')}
//               </table>
//             </div>
//           </div>
          
//           <div style="background-color: white; border-radius: 15px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); padding: 20px; margin-bottom: 30px;">
//             <h2 style="color: #6495ed; font-size: 22px; margin-bottom: 15px;">About This Item</h2>
//             <p style="line-height: 1.7;">${description}</p>
//           </div>
          
//           <div style="background-color: #e6f2ff; border-radius: 15px; padding: 20px; text-align: center;">
//             <h3 style="color: #6495ed; font-size: 20px; margin-top: 0;">Why Shop With Us?</h3>
//             <div style="display: flex; justify-content: space-around; flex-wrap: wrap;">
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 48px; color: #87cefa;">🌟</span>
//                 <p style="margin-top: 10px; font-weight: 500;">Top-Notch Quality</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 48px; color: #87cefa;">🚚</span>
//                 <p style="margin-top: 10px; font-weight: 500;">Swift Delivery</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 48px; color: #87cefa;">💁‍♀️</span>
//                 <p style="margin-top: 10px; font-weight: 500;">Friendly Support</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       `,
//     },
//     feminineModern: {
//       name: 'Feminine Modern',
//       generateHTML: (title, features, description, specifications) => `
//         <div style="font-family: 'Montserrat', sans-serif; max-width: 1000px; margin: 0 auto; padding: 20px; color: #333; background-color: #fff;">
//           <h1 style="font-size: 36px; color: #ff69b4; text-align: center; margin-bottom: 30px; font-weight: 600; letter-spacing: 1px;">${title}</h1>
          
//           <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); gap: 20px; margin-bottom: 30px;">
//             <div style="background-color: #fff0f5; border-radius: 10px; padding: 20px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
//               <h2 style="color: #ff69b4; font-size: 24px; margin-bottom: 20px; font-weight: 500;">Features</h2>
//               <ul style="list-style-type: none; padding-left: 0;">
//                 ${features.split('\n').map(feature => `<li style="margin-bottom: 15px; padding-left: 25px; position: relative;">
//                   <span style="position: absolute; left: 0; color: #ff69b4; font-weight: bold;">•</span> ${feature}
//                 </li>`).join('')}
//               </ul>
//             </div>
            
//             <div style="background-color: #fff0f5; border-radius: 10px; padding: 20px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
//               <h2 style="color: #ff69b4; font-size: 24px; margin-bottom: 20px; font-weight: 500;">Specifications</h2>
//               <table style="width: 100%; border-collapse: separate; border-spacing: 0 10px;">
//                 ${Object.entries(specifications).map(([key, value]) => `
//                   <tr>
//                     <td style="padding: 10px; background-color: #ffe4e1; font-weight: 500; border-radius: 5px 0 0 5px;">${key}</td>
//                     <td style="padding: 10px; background-color: #fff; border: 1px solid #ffe4e1; border-radius: 0 5px 5px 0;">${value}</td>
//                   </tr>
//                 `).join('')}
//               </table>
//             </div>
//           </div>
          
//           <div style="background-color: #fff; border-radius: 10px; padding: 20px; margin-bottom: 30px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
//             <h2 style="color: #ff69b4; font-size: 24px; margin-bottom: 20px; font-weight: 500;">Description</h2>
//             <p style="line-height: 1.8; text-align: justify;">${description}</p>
//           </div>
          
//           <div style="background-color: #ff69b4; color: white; border-radius: 10px; padding: 20px; text-align: center;">
//             <h3 style="font-size: 24px; margin-bottom: 20px; font-weight: 500;">Why Choose Us</h3>
//             <div style="display: flex; justify-content: space-around; flex-wrap: wrap;">
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 48px;">💎</span>
//                 <p style="margin-top: 10px; font-weight: 500;">Premium Quality</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 48px;">🎁</span>
//                 <p style="margin-top: 10px; font-weight: 500;">Gift-Ready Packaging</p>
//               </div>
//               <div style="flex: 1; min-width: 200px; margin: 10px;">
//                 <span style="font-size: 48px;">💯</span>
//                 <p style="margin-top: 10px; font-weight: 500;">100% Satisfaction</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       `,
//     },
//   };

//   export default designTemplates;
