// OpenAIDescriptionGenerator.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField, // TextFieldのインポートを追加
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Tooltip,
  Box,
  LinearProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import designTemplates from './designTemplates';
import PQueue from 'p-queue';

// 定数の設定
const BATCH_SIZE = 5; // バッチサイズを5に縮小
const INITIAL_RETRY_DELAY = 1000; // 初期リトライ遅延: 1秒
const MAX_RETRIES = 5; // 最大リトライ回数を5に増加
const CONCURRENCY = 2; // 同時実行数
const INTERVAL = 1000; // 1秒あたりのリクエスト数
const INTERVAL_CAP = 5; // 1秒あたり最大5リクエスト

const OpenAIDescriptionGenerator = ({ spreadsheetId, sheetName, token, fetchData, apiKey }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [expandedProduct, setExpandedProduct] = useState(null);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [previewProduct, setPreviewProduct] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('professional');
  const [progress, setProgress] = useState(0);
  const [cache, setCache] = useState({});

  // p-queueの設定
  const queue = new PQueue({ 
    concurrency: CONCURRENCY, 
    interval: INTERVAL, 
    intervalCap: INTERVAL_CAP 
  });

  useEffect(() => {
    if (open) {
      fetchSheetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedProducts([]);
    setExpandedProduct(null);
  };

  // シートデータの取得
  const fetchSheetData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
      const jpDescIndex = headers.findIndex(header => header.toLowerCase() === 'jp_desc');
      const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

      if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
        throw new Error('必要な列（Title, JP_Desc, Description）が見つかりません');
      }

      const productData = data.slice(1).map((row, index) => ({
        id: index,
        title: row[titleIndex],
        jpDesc: row[jpDescIndex],
        description: row[descriptionIndex] || '',
        generatedFeatures: '',
        editableGeneratedFeatures: '',
        updatedDescription: '',
        selected: true,
        editableFields: headers.reduce((acc, header, idx) => {
          if (header.startsWith('C:')) {
            acc[header] = row[idx] || '';
          }
          return acc;
        }, {}),
      }));

      setProducts(productData);
      setSelectedProducts(productData.map(product => product.id));
      setSnackbarMessage('シートデータを正常に取得しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('シートデータの取得エラー:', err);
      setError(`シートデータの取得に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // 商品特徴の生成
  const generateProductFeatures = async () => {
    if (!apiKey) {
      setError('OpenAI APIキーが設定されていません');
      setSnackbarMessage('OpenAI APIキーが必要です');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (selectedProducts.length === 0) {
      setError('少なくとも一つの商品を選択してください');
      setSnackbarMessage('商品が選択されていません');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');
    setProgress(0);

    const selectedProductsData = products.filter(product => selectedProducts.includes(product.id));
    const totalBatches = Math.ceil(selectedProductsData.length / BATCH_SIZE);
    let processedCount = 0;

    for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
      const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
      try {
        const updatedBatch = await processBatch(batch);
        setProducts(prevProducts => {
          const newProducts = [...prevProducts];
          updatedBatch.forEach(updatedProduct => {
            const index = newProducts.findIndex(p => p.id === updatedProduct.id);
            if (index !== -1) {
              newProducts[index] = updatedProduct;
            }
          });
          return newProducts;
        });
        processedCount += batch.length;
        setProgress((processedCount / selectedProductsData.length) * 100);
      } catch (err) {
        console.error('バッチ処理エラー:', err);
        setError(`バッチ処理に失敗しました: ${err.message}`);
        setSnackbarMessage(`エラー: ${err.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        break;
      }
    }

    setLoading(false);
    setMessage('商品特徴の生成が完了しました');
    setSnackbarMessage('商品特徴の生成が完了しました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    setShowPreviewDialog(true);
  };

  // バッチ処理の実行
  const processBatch = async (batch) => {
    return await Promise.all(batch.map(async (product) => {
      let generatedFeatures;
      if (cache[product.jpDesc]) {
        generatedFeatures = cache[product.jpDesc];
      } else {
        generatedFeatures = await generateFeaturesWithRetry(product.jpDesc);
        setCache(prevCache => ({ ...prevCache, [product.jpDesc]: generatedFeatures }));
      }
      const updatedDescription = generateFormattedDescription(
        product.title,
        generatedFeatures,
        product.description,
        product.editableFields
      );
      return { 
        ...product, 
        generatedFeatures, 
        editableGeneratedFeatures: generatedFeatures,
        updatedDescription 
      };
    }));
  };

  // リトライロジックの強化
  const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
    try {
      return await queue.add(() => generateFeatures(jpDesc));
    } catch (error) {
      if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
        const retryAfter = error.response.headers['retry-after'];
        const delay = retryAfter ? parseInt(retryAfter) * 1000 : INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
        const jitter = Math.random() * 1000; // 0〜1000msのランダム遅延
        await new Promise(resolve => setTimeout(resolve, delay + jitter));
        return generateFeaturesWithRetry(jpDesc, retryCount + 1);
      }
      throw error;
    }
  };

  // OpenAI APIを使用して特徴を生成
    const generateFeatures = async (jpDesc, maxTokens = 200) => {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: "gpt-4o-2024-08-06",
        messages: [
          {
            role: "system",
            content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

1. product description (if applicable)
   - Brand name (if applicable)
   - Product type
   - Model or product name
2. detailed features (if applicable)
   - Color, material, size 3.
3. condition of the item (if applicable)
   - Details of usage, scratches, stains, etc. (if used)

Notes:
- No content other than the above will be output.
- Additional information is not required.
- Do not output information related to shipping or options.
- Use clear and concise English.
- Use bullet points to ensure readability.
- If information is unclear or ambiguous, omit the item.
- Use only reliable information and do not include speculative or uncertain information

Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`
          },
          {
            role: "user",
            content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。ただし、単語が羅列されている商品説明は参考にしない。また、日本語の商品説明がない場合は、タイトルから単語を抽出して書くだけで良いです。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`
          }
        ],
        max_tokens: maxTokens,
        temperature: 0.1
      },
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data.choices[0].message.content.trim();
  };

  // デザインテンプレートに基づく説明文の生成
  const generateFormattedDescription = (title, features, description, specifications) => {
    const template = designTemplates[selectedTemplate];
    return template.generateHTML(title, features, description, specifications);
  };

  // 全選択/解除のハンドリング
  const handleSelectAll = (event) => {
    const updatedProducts = products.map(product => ({
      ...product,
      selected: event.target.checked
    }));
    setProducts(updatedProducts);
    setSelectedProducts(event.target.checked ? updatedProducts.map(p => p.id) : []);
  };

  // 個別選択のハンドリング
  const handleSelectProduct = (productId) => {
    const updatedProducts = products.map(product =>
      product.id === productId ? { ...product, selected: !product.selected } : product
    );
    setProducts(updatedProducts);
    setSelectedProducts(updatedProducts.filter(p => p.selected).map(p => p.id));
  };

  // 商品の展開/折りたたみのハンドリング
  const handleExpandProduct = (productId) => {
    setExpandedProduct(expandedProduct === productId ? null : productId);
  };

  // 先頭の余白を削除
  const removeLeadingWhitespace = async (productId) => {
    setLoading(true);
    setError('');
    
    try {
      const product = products.find(p => p.id === productId);
      if (!product) throw new Error('商品が見つかりません');

      // `<div style=...>` の前の空白と改行を削除
      const trimmedDescription = product.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1');
      
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');
      const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');

      if (descriptionIndex === -1 || titleIndex === -1) {
        throw new Error('必要な列が見つかりません');
      }

      const rowIndex = data.findIndex(row => row[titleIndex] === product.title);
      if (rowIndex === -1) throw new Error('商品行が見つかりません');

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!${String.fromCharCode(65 + descriptionIndex)}${rowIndex + 1}`,
        {
          values: [[trimmedDescription]]
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setProducts(prevProducts =>
        prevProducts.map(p =>
          p.id === productId ? { ...p, description: trimmedDescription } : p
        )
      );

      setSnackbarMessage('先頭の余白を削除しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('先頭の余白削除エラー:', err);
      setError(`先頭の余白削除に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // 全商品の先頭余白を削除
  const removeAllLeadingWhitespace = async () => {
    setLoading(true);
    setError('');
    
    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data.values;
      const headers = data[0];
      const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

      if (descriptionIndex === -1) {
        throw new Error('Description列が見つかりません');
      }

      const updatedData = data.map((row, index) => {
        if (index === 0) return row;
        const newRow = [...row];
        newRow[descriptionIndex] = newRow[descriptionIndex].replace(/^\s*(<div style[\s\S]*?>)/, '$1');
        return newRow;
      });

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
        { 
          values: updatedData
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setProducts(prevProducts =>
        prevProducts.map(p => ({ ...p, description: p.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1') }))
      );

      setSnackbarMessage('全商品の先頭余白を削除しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData();
    } catch (err) {
      console.error('全商品の先頭余白削除エラー:', err);
      setError(`全商品の先頭余白削除に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // 更新された説明文をスプレッドシートに適用
  const applyUpdatedDescriptions = async () => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const currentData = response.data.values;

      const headers = currentData[0];
      const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

      if (descriptionIndex === -1) {
        throw new Error('Description列が見つかりません');
      }

      const updatedData = currentData.map((row, index) => {
        if (index === 0) return row;

        const product = products.find(p => p.title === row[headers.indexOf('Title')]);
        if (product && product.selected && product.updatedDescription) {
          const newRow = [...row];
          newRow[descriptionIndex] = product.updatedDescription;
          return newRow;
        }
        return row;
      });

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
        { 
          values: updatedData
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setMessage('説明文を正常に更新しました');
      setSnackbarMessage('説明文を正常に更新しました');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData();
    } catch (err) {
      console.error('説明文更新エラー:', err);
      setError(`説明文の更新に失敗しました: ${err.message}`);
      setSnackbarMessage(`エラー: ${err.message}`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // プレビューダイアログのオープン
  const handlePreviewOpen = (product) => {
    setPreviewProduct(product);
    setShowPreviewDialog(true);
  };

  // プレビューダイアログのクローズ
  const handlePreviewClose = () => {
    setPreviewProduct(null);
    setShowPreviewDialog(false);
  };

  // eBayプレビューのレンダリング
  const renderEbayPreview = (product) => {
    return (
      <div dangerouslySetInnerHTML={{ __html: product.updatedDescription || product.description }} />
    );
  };

  // 生成された特徴の編集ハンドリング
  const handleEditFeatures = (productId, newFeatures) => {
    setProducts(prevProducts =>
      prevProducts.map(product =>
        product.id === productId
          ? {
              ...product,
              editableGeneratedFeatures: newFeatures,
              updatedDescription: generateFormattedDescription(
                product.title,
                newFeatures,
                product.description,
                product.editableFields
              )
            }
          : product
      )
    );
  };

  // キャッシュのクリア
  const clearCache = () => {
    setCache({});
    setSnackbarMessage('キャッシュを正常にクリアしました');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 4,
        mt: 1, 
        mb: 1, 
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
        {/* ヘッダーセクション */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>
              AIを活用した商品説明生成
            </Typography>
            <Typography variant="body1" paragraph>
              このツールは、AIを使用して、日本語の商品説明から英語の商品特徴を自動生成し、
              eBay向けの魅力的な商品説明を作成します。
              余白削除はebayに出品するために使います。
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>主な機能：</Typography>
            <Grid container spacing={1}>
              {[
                '日本語の商品説明から英語の商品特徴を抽出',
                'デザインテンプレートからデザインを選択',
                '複数の商品を一括で処理',
                '生成された説明のプレビューと編集',
                'バッチ処理とリトライメカニズムによる安定性向上',
                'キャッシュ機能による効率化'
              ].map((feature, index) => (
                <Grid item xs={6} key={index}>
                  <Typography variant="body2">• {feature}</Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* アクションボタンセクション */}
        <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleOpen}
            startIcon={<AddIcon />}
          >
            商品説明をAIで生成する
          </Button>
          <Button 
            variant="contained" 
            color="secondary" 
            onClick={removeAllLeadingWhitespace}
            startIcon={<FormatColorResetIcon />}
          >
            Descriptionの先頭の余白を削除する
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={clearCache}
            startIcon={<CachedIcon />}
          >
            キャッシュをクリア
          </Button>
        </Box>

        {/* ダイアログセクション */}
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>AIで商品説明を生成する</DialogTitle>
          <DialogContent>
            {/* 選択オプション */}
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedProducts.length === products.length}
                      onChange={handleSelectAll}
                      indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                    />
                  }
                  label="全て選択"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  value={selectedTemplate}
                  onChange={(e) => setSelectedTemplate(e.target.value)}
                  fullWidth
                >
                  {Object.entries(designTemplates).map(([key, template]) => (
                    <MenuItem key={key} value={key}>{template.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            {/* アクションボタン */}
            <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateProductFeatures}
                  disabled={loading || !apiKey || selectedProducts.length === 0}
                >
                  AIで商品説明を生成する
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={applyUpdatedDescriptions}
                  disabled={loading || selectedProducts.length === 0}
                >
                  商品説明をスプレッドシートに更新する
                </Button>
              </Grid>
            </Grid>

            {/* 進捗状況表示 */}
            {loading && (
              <Box sx={{ width: '100%', mb: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" color="text.secondary" align="center">
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            )}

            {/* 商品リスト */}
            <List>
              {products.map((product) => (
                <Paper key={product.id} elevation={3} style={{ marginBottom: '10px' }}>
                  <ListItem>
                    <ListItemText 
                      primary={
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={product.selected}
                              onChange={() => handleSelectProduct(product.id)}
                            />
                          }
                          label={product.title}
                        />
                      }
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title="先頭の余白を削除">
                        <IconButton edge="end" onClick={() => removeLeadingWhitespace(product.id)} disabled={loading}>
                          <FormatColorResetIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton edge="end" onClick={() => handleExpandProduct(product.id)}>
                        {expandedProduct === product.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse in={expandedProduct === product.id} timeout="auto" unmountOnExit>
                    <Grid container spacing={2} style={{ padding: '10px' }}>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Japanese Description</Typography>
                        <TextField
                          multiline
                          rows={4}
                          fullWidth
                          variant="outlined"
                          value={product.jpDesc}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Current Description</Typography>
                        <TextField
                          multiline
                          rows={4}
                          fullWidth
                          variant="outlined"
                          value={product.description}
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>
                      {product.generatedFeatures && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Generated Features (Editable)</Typography>
                          <TextField
                            multiline
                            rows={4}
                            fullWidth
                            variant="outlined"
                            value={product.editableGeneratedFeatures}
                            onChange={(e) => handleEditFeatures(product.id, e.target.value)}
                          />
                        </Grid>
                      )}
                      {product.updatedDescription && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Updated Description</Typography>
                          <div dangerouslySetInnerHTML={{ __html: product.updatedDescription }} />
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>
                </Paper>
              ))}
            </List>
            
            {/* エラーメッセージ */}
            {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
            {/* 成功メッセージ */}
            {message && <Typography color="primary" style={{ marginTop: '20px' }}>{message}</Typography>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* プレビューダイアログ */}
        <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
          <DialogTitle>eBay Preview: {previewProduct?.title}</DialogTitle>
          <DialogContent>
            {previewProduct && renderEbayPreview(previewProduct)}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePreviewClose}>閉じる</Button>
          </DialogActions>
        </Dialog>

        {/* スナックバー通知 */}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Paper>
  );
};

export default OpenAIDescriptionGenerator;




// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   CircularProgress,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Collapse,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Select,
//   MenuItem,
//   Tooltip,
//   Box,
//   LinearProgress,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import designTemplates from './designTemplates';

// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;

// const OpenAIDescriptionGenerator = ({ spreadsheetId, sheetName, token, fetchData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [expandedProduct, setExpandedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [cache, setCache] = useState({});

//   useEffect(() => {
//     if (open) {
//       fetchSheetData();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedProducts([]);
//     setExpandedProduct(null);
//   };

//   const fetchSheetData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex(header => header.toLowerCase() === 'jp_desc');
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

//       if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//         throw new Error('Required columns (Title, JP_Desc, Description) not found');
//       }

//       const productData = data.slice(1).map((row, index) => ({
//         id: index,
//         title: row[titleIndex],
//         jpDesc: row[jpDescIndex],
//         description: row[descriptionIndex] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: true,
//         editableFields: headers.reduce((acc, header, idx) => {
//           if (header.startsWith('C:')) {
//             acc[header] = row[idx] || '';
//           }
//           return acc;
//         }, {}),
//       }));

//       setProducts(productData);
//       setSelectedProducts(productData.map(product => product.id));
//       setSnackbarMessage('Sheet data fetched successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('Error fetching sheet data:', err);
//       setError(`Failed to fetch sheet data: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI API key is not set');
//       setSnackbarMessage('OpenAI API key is required');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('Please select at least one product');
//       setSnackbarMessage('No products selected');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(product => selectedProducts.includes(product.id));
//     const totalBatches = Math.ceil(selectedProductsData.length / BATCH_SIZE);

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts(prevProducts => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach(updatedProduct => {
//             const index = newProducts.findIndex(p => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         setProgress((i + batch.length) / selectedProductsData.length * 100);
//       } catch (err) {
//         console.error('Error processing batch:', err);
//         setError(`Failed to process batch: ${err.message}`);
//         setSnackbarMessage(`Error: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('Product features generated successfully');
//     setSnackbarMessage('Product features generated successfully');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(batch.map(async (product) => {
//       let generatedFeatures;
//       if (cache[product.jpDesc]) {
//         generatedFeatures = cache[product.jpDesc];
//       } else {
//         generatedFeatures = await generateFeaturesWithRetry(product.jpDesc);
//         setCache(prevCache => ({ ...prevCache, [product.jpDesc]: generatedFeatures }));
//       }
//       const updatedDescription = generateFormattedDescription(
//         product.title,
//         generatedFeatures,
//         product.description,
//         product.editableFields
//       );
//       return { 
//         ...product, 
//         generatedFeatures, 
//         editableGeneratedFeatures: generatedFeatures,
//         updatedDescription 
//       };
//     }));
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await generateFeatures(jpDesc);
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         await new Promise(resolve => setTimeout(resolve, delay));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: "gpt-4o-2024-08-06",
//         messages: [
//           {
//             role: "system",
//             content: `You are eBay's AI assistant specializing in product description optimization. Create effective English product descriptions based on the given Japanese product information. Please provide accurate information to buyers, including the following elements

// 1. product description (if applicable)
//    - Brand name (if applicable)
//    - Product type
//    - Model or product name
// 2. detailed features (if applicable)
//    - Color, material, size 3.
// 3. condition of the item (if applicable)
//    - Details of usage, scratches, stains, etc. (if used)

// Notes:
// - No content other than the above will be output.
// - Additional information is not required.
// - Do not output information related to shipping or options.
// - Use clear and concise English.
// - Use bullet points to ensure readability.
// - If information is unclear or ambiguous, omit the item.
// - Use only reliable information and do not include speculative or uncertain information

// Analyze the given product information and follow the guidelines above to generate the best possible product description in English. Try to provide a detailed and engaging description that will help buyers make a purchase decision. Stay within the specified token count limit.`
//           },
//           {
//             role: "user",
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。ただし、単語が羅列されている商品説明は参考にしない。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`
//           }
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1
//       },
//       {
//         headers: {
//           'Authorization': `Bearer ${apiKey}`,
//           'Content-Type': 'application/json'
//         }
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     return template.generateHTML(title, features, description, specifications);
//   };

//   const handleSelectAll = (event) => {
//     const updatedProducts = products.map(product => ({
//       ...product,
//       selected: event.target.checked
//     }));
//     setProducts(updatedProducts);
//     setSelectedProducts(event.target.checked ? updatedProducts.map(p => p.id) : []);
//   };

//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map(product =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//     setSelectedProducts(updatedProducts.filter(p => p.selected).map(p => p.id));
//   };

//   const handleExpandProduct = (productId) => {
//     setExpandedProduct(expandedProduct === productId ? null : productId);
//   };

//   const removeLeadingWhitespace = async (productId) => {
//     setLoading(true);
//     setError('');
    
//     try {
//       const product = products.find(p => p.id === productId);
//       if (!product) throw new Error('Product not found');

//       // `<div style=...>` の前の空白と改行を削除
//       const trimmedDescription = product.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1');
      
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');
//       const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');

//       if (descriptionIndex === -1 || titleIndex === -1) {
//         throw new Error('Required columns not found');
//       }

//       const rowIndex = data.findIndex(row => row[titleIndex] === product.title);
//       if (rowIndex === -1) throw new Error('Product row not found');

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!${String.fromCharCode(65 + descriptionIndex)}${rowIndex + 1}`,
//         {
//           values: [[trimmedDescription]]
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setProducts(prevProducts =>
//         prevProducts.map(p =>
//           p.id === productId ? { ...p, description: trimmedDescription } : p
//         )
//       );

//       setSnackbarMessage('Leading whitespace removed successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('Error removing leading whitespace:', err);
//       setError(`Failed to remove leading whitespace: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const removeAllLeadingWhitespace = async () => {
//     setLoading(true);
//     setError('');
    
//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

//       if (descriptionIndex === -1) {
//         throw new Error('Description column not found');
//       }

//       const updatedData = data.map((row, index) => {
//         if (index === 0) return row;
//         const newRow = [...row];
//         newRow[descriptionIndex] = newRow[descriptionIndex].replace(/^\s*(<div style[\s\S]*?>)/, '$1');
//         return newRow;
//       });

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
//         { 
//           values: updatedData
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setProducts(prevProducts =>
//         prevProducts.map(p => ({ ...p, description: p.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1') }))
//       );

//       setSnackbarMessage('Leading whitespace removed from all descriptions');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchData();
//     } catch (err) {
//       console.error('Error removing all leading whitespace:', err);
//       setError(`Failed to remove all leading whitespace: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const currentData = response.data.values;

//       const headers = currentData[0];
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

//       if (descriptionIndex === -1) {
//         throw new Error('Description column not found');
//       }

//       const updatedData = currentData.map((row, index) => {
//         if (index === 0) return row;

//         const product = products.find(p => p.title === row[headers.indexOf('Title')]);
//         if (product && product.selected && product.updatedDescription) {
//           const newRow = [...row];
//           newRow[descriptionIndex] = product.updatedDescription;
//           return newRow;
//         }
//         return row;
//       });

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
//         { 
//           values: updatedData
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setMessage('Descriptions updated successfully');
//       setSnackbarMessage('Descriptions updated successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchData();
//     } catch (err) {
//       console.error('Error updating descriptions:', err);
//       setError(`Failed to update descriptions: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const renderEbayPreview = (product) => {
//     return (
//       <div dangerouslySetInnerHTML={{ __html: product.updatedDescription || product.description }} />
//     );
//   };

//   const handleEditFeatures = (productId, newFeatures) => {
//     setProducts(prevProducts =>
//       prevProducts.map(product =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 product.description,
//                 product.editableFields
//               )
//             }
//           : product
//       )
//     );
//   };

//   const clearCache = () => {
//     setCache({});
//     setSnackbarMessage('Cache cleared successfully');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   return (
//     <Paper 
//       elevation={3} 
//       sx={{ 
//         p: 4,
//         mt: 1, 
//         mb: 1, 
//         maxWidth: '100%',
//         margin: '0 auto',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               このツールは、AIを使用して、日本語の商品説明から英語の商品特徴を自動生成し、
//               eBay向けの魅力的な商品説明を作成します。
//               余白削除はebayに出品するために使います。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>主な機能：</Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '生成された説明のプレビューと編集',
//                 'バッチ処理とリトライメカニズムによる安定性向上',
//                 'キャッシュ機能による効率化'
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>
  
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button 
//             variant="contained" 
//             color="primary" 
//             onClick={handleOpen}
//             startIcon={<AddIcon />}
//           >
//             商品説明をAIで生成する
//           </Button>
//           <Button 
//             variant="contained" 
//             color="secondary" 
//             onClick={removeAllLeadingWhitespace}
//             startIcon={<FormatColorResetIcon />}
//           >
//             Descriptionの先頭の余白を削除する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={clearCache}
//             startIcon={<CachedIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>
  
//         <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
//           <DialogTitle>AIで商品説明を生成する</DialogTitle>
//           <DialogContent>
//             <Grid container spacing={2} alignItems="center">
//               <Grid item xs={12} sm={6}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={selectedProducts.length === products.length}
//                       onChange={handleSelectAll}
//                       indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
//                     />
//                   }
//                   label="Select All"
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Select
//                   value={selectedTemplate}
//                   onChange={(e) => setSelectedTemplate(e.target.value)}
//                   fullWidth
//                 >
//                   {Object.entries(designTemplates).map(([key, template]) => (
//                     <MenuItem key={key} value={key}>{template.name}</MenuItem>
//                   ))}
//                 </Select>
//               </Grid>
//             </Grid>
  
//             <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
//               <Grid item>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={generateProductFeatures}
//                   disabled={loading || !apiKey || selectedProducts.length === 0}
//                 >
//                   AIで商品説明を生成する
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={applyUpdatedDescriptions}
//                   disabled={loading || selectedProducts.length === 0}
//                 >
//                   商品説明をスプレッドシートに更新する
//                 </Button>
//               </Grid>
//             </Grid>
  
//             {loading && (
//               <Box sx={{ width: '100%', mb: 2 }}>
//                 <LinearProgress variant="determinate" value={progress} />
//                 <Typography variant="body2" color="text.secondary" align="center">
//                   {`${Math.round(progress)}%`}
//                 </Typography>
//               </Box>
//             )}
  
//             <List>
//               {products.map((product) => (
//                 <Paper key={product.id} elevation={3} style={{ marginBottom: '10px' }}>
//                   <ListItem>
//                     <ListItemText 
//                       primary={
//                         <FormControlLabel
//                           control={
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                             />
//                           }
//                           label={product.title}
//                         />
//                       }
//                     />
//                     <ListItemSecondaryAction>
//                       <Tooltip title="Remove leading whitespace">
//                         <IconButton edge="end" onClick={() => removeLeadingWhitespace(product.id)} disabled={loading}>
//                           <FormatColorResetIcon />
//                         </IconButton>
//                       </Tooltip>
//                       <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
//                         <VisibilityIcon />
//                       </IconButton>
//                       <IconButton edge="end" onClick={() => handleExpandProduct(product.id)}>
//                         {expandedProduct === product.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                       </IconButton>
//                     </ListItemSecondaryAction>
//                   </ListItem>
//                   <Collapse in={expandedProduct === product.id} timeout="auto" unmountOnExit>
//                     <Grid container spacing={2} style={{ padding: '10px' }}>
//                       <Grid item xs={12}>
//                         <Typography variant="subtitle1">Japanese Description</Typography>
//                         <TextField
//                           multiline
//                           rows={4}
//                           fullWidth
//                           variant="outlined"
//                           value={product.jpDesc}
//                           InputProps={{ readOnly: true }}
//                         />
//                       </Grid>
//                       <Grid item xs={12}>
//                         <Typography variant="subtitle1">Current Description</Typography>
//                         <TextField
//                           multiline
//                           rows={4}
//                           fullWidth
//                           variant="outlined"
//                           value={product.description}
//                           InputProps={{ readOnly: true }}
//                         />
//                       </Grid>
//                       {product.generatedFeatures && (
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Generated Features (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={4}
//                             fullWidth
//                             variant="outlined"
//                             value={product.editableGeneratedFeatures}
//                             onChange={(e) => handleEditFeatures(product.id, e.target.value)}
//                           />
//                         </Grid>
//                       )}
//                       {product.updatedDescription && (
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Updated Description</Typography>
//                           <div dangerouslySetInnerHTML={{ __html: product.updatedDescription }} />
//                         </Grid>
//                       )}
//                     </Grid>
//                   </Collapse>
//                 </Paper>
//               ))}
//             </List>
            
//             {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
//             {message && <Typography color="primary" style={{ marginTop: '20px' }}>{message}</Typography>}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>Close</Button>
//           </DialogActions>
//         </Dialog>
  
//         <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
//           <DialogTitle>eBay Preview: {previewProduct?.title}</DialogTitle>
//           <DialogContent>
//             {previewProduct && renderEbayPreview(previewProduct)}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handlePreviewClose}>Close</Button>
//           </DialogActions>
//         </Dialog>
  
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;



// import React, { useState, useEffect, useCallback } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Typography,
//   CircularProgress,
//   TextField,
//   Checkbox,
//   FormControlLabel,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemSecondaryAction,
//   IconButton,
//   Collapse,
//   Paper,
//   Grid,
//   Snackbar,
//   Alert,
//   Select,
//   MenuItem,
//   Tooltip,
//   Box,
//   LinearProgress,
// } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
// import AddIcon from '@mui/icons-material/Add';
// import CachedIcon from '@mui/icons-material/Cached';
// import designTemplates from './designTemplates';

// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;

// const OpenAIDescriptionGenerator = ({ spreadsheetId, sheetName, token, fetchData, apiKey }) => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [message, setMessage] = useState('');
//   const [products, setProducts] = useState([]);
//   const [selectedProducts, setSelectedProducts] = useState([]);
//   const [expandedProduct, setExpandedProduct] = useState(null);
//   const [showPreviewDialog, setShowPreviewDialog] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');
//   const [snackbarSeverity, setSnackbarSeverity] = useState('info');
//   const [previewProduct, setPreviewProduct] = useState(null);
//   const [selectedTemplate, setSelectedTemplate] = useState('professional');
//   const [progress, setProgress] = useState(0);
//   const [cache, setCache] = useState({});

//   useEffect(() => {
//     if (open) {
//       fetchSheetData();
//     }
//   }, [open]);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedProducts([]);
//     setExpandedProduct(null);
//   };

//   const fetchSheetData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
//       const jpDescIndex = headers.findIndex(header => header.toLowerCase() === 'jp_desc');
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

//       if (titleIndex === -1 || jpDescIndex === -1 || descriptionIndex === -1) {
//         throw new Error('Required columns (Title, JP_Desc, Description) not found');
//       }

//       const productData = data.slice(1).map((row, index) => ({
//         id: index,
//         title: row[titleIndex],
//         jpDesc: row[jpDescIndex],
//         description: row[descriptionIndex] || '',
//         generatedFeatures: '',
//         editableGeneratedFeatures: '',
//         updatedDescription: '',
//         selected: true,
//         editableFields: headers.reduce((acc, header, idx) => {
//           if (header.startsWith('C:')) {
//             acc[header] = row[idx] || '';
//           }
//           return acc;
//         }, {}),
//       }));

//       setProducts(productData);
//       setSelectedProducts(productData.map(product => product.id));
//       setSnackbarMessage('Sheet data fetched successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('Error fetching sheet data:', err);
//       setError(`Failed to fetch sheet data: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const generateProductFeatures = async () => {
//     if (!apiKey) {
//       setError('OpenAI API key is not set');
//       setSnackbarMessage('OpenAI API key is required');
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//       return;
//     }

//     if (selectedProducts.length === 0) {
//       setError('Please select at least one product');
//       setSnackbarMessage('No products selected');
//       setSnackbarSeverity('warning');
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);
//     setError('');
//     setMessage('');
//     setProgress(0);

//     const selectedProductsData = products.filter(product => selectedProducts.includes(product.id));
//     const totalBatches = Math.ceil(selectedProductsData.length / BATCH_SIZE);

//     for (let i = 0; i < selectedProductsData.length; i += BATCH_SIZE) {
//       const batch = selectedProductsData.slice(i, i + BATCH_SIZE);
//       try {
//         const updatedBatch = await processBatch(batch);
//         setProducts(prevProducts => {
//           const newProducts = [...prevProducts];
//           updatedBatch.forEach(updatedProduct => {
//             const index = newProducts.findIndex(p => p.id === updatedProduct.id);
//             if (index !== -1) {
//               newProducts[index] = updatedProduct;
//             }
//           });
//           return newProducts;
//         });
//         setProgress((i + batch.length) / selectedProductsData.length * 100);
//       } catch (err) {
//         console.error('Error processing batch:', err);
//         setError(`Failed to process batch: ${err.message}`);
//         setSnackbarMessage(`Error: ${err.message}`);
//         setSnackbarSeverity('error');
//         setSnackbarOpen(true);
//         break;
//       }
//     }

//     setLoading(false);
//     setMessage('Product features generated successfully');
//     setSnackbarMessage('Product features generated successfully');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//     setShowPreviewDialog(true);
//   };

//   const processBatch = async (batch) => {
//     return await Promise.all(batch.map(async (product) => {
//       let generatedFeatures;
//       if (cache[product.jpDesc]) {
//         generatedFeatures = cache[product.jpDesc];
//       } else {
//         generatedFeatures = await generateFeaturesWithRetry(product.jpDesc);
//         setCache(prevCache => ({ ...prevCache, [product.jpDesc]: generatedFeatures }));
//       }
//       const updatedDescription = generateFormattedDescription(
//         product.title,
//         generatedFeatures,
//         product.description,
//         product.editableFields
//       );
//       return { 
//         ...product, 
//         generatedFeatures, 
//         editableGeneratedFeatures: generatedFeatures,
//         updatedDescription 
//       };
//     }));
//   };

//   const generateFeaturesWithRetry = async (jpDesc, retryCount = 0) => {
//     try {
//       return await generateFeatures(jpDesc);
//     } catch (error) {
//       if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//         const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//         await new Promise(resolve => setTimeout(resolve, delay));
//         return generateFeaturesWithRetry(jpDesc, retryCount + 1);
//       }
//       throw error;
//     }
//   };

//   const generateFeatures = async (jpDesc, maxTokens = 200) => {
//     const response = await axios.post(
//       'https://api.openai.com/v1/chat/completions',
//       {
//         model: "gpt-4o-2024-08-06",
//         messages: [
//           {
//             role: "system",
//             content: `あなたはeBayの商品説明最適化専門のAIアシスタントです。与えられた日本語の商品情報を基に、効果的な英語の商品説明を作成してください。以下の要素を含め、バイヤーに正確な情報を提供してください：

// 1. 商品の概要（該当する場合）
//    - ブランド名（該当する場合）
//    - 商品タイプ
//    - モデル名や商品名
// 2. 詳細な特徴（該当する場合）
//    - 色、素材、サイズ
// 3. 商品の状態（該当する場合）
//    - 使用状況や傷、汚れなどの詳細（中古の場合）

// 注意点：
// - 上記以外の内容は出力しない。
// - 追記情報は不要。
// - 発送に関することやオプションなどは出力しない。
// - 明確で簡潔な英語を使用する。
// - 箇条書きを使用して読みやすさを確保する
// - 情報が不明確または曖昧な場合は、その項目を省略する
// - 確実な情報のみを使用し、推測や不確かな情報は含めない

// 与えられた商品情報を分析し、上記のガイドラインに従って最適な商品説明を英語で生成してください。バイヤーが購入を決断するのに役立つ、詳細かつ魅力的な説明を心がけてください。指定されたトークン数制限内に収めてください。`
//           },
//           {
//             role: "user",
//             content: `以下の日本語の商品説明を分析し、eBayの商品説明として最適化された英語の説明を生成してください。ただし、単語が羅列されている商品説明は参考にしない。出力は${maxTokens}トークン以内に収めてください：「${jpDesc}」`
//           }
//         ],
//         max_tokens: maxTokens,
//         temperature: 0.1
//       },
//       {
//         headers: {
//           'Authorization': `Bearer ${apiKey}`,
//           'Content-Type': 'application/json'
//         }
//       }
//     );

//     return response.data.choices[0].message.content.trim();
//   };

//   const generateFormattedDescription = (title, features, description, specifications) => {
//     const template = designTemplates[selectedTemplate];
//     return template.generateHTML(title, features, description, specifications);
//   };

//   const handleSelectAll = (event) => {
//     const updatedProducts = products.map(product => ({
//       ...product,
//       selected: event.target.checked
//     }));
//     setProducts(updatedProducts);
//     setSelectedProducts(event.target.checked ? updatedProducts.map(p => p.id) : []);
//   };

//   const handleSelectProduct = (productId) => {
//     const updatedProducts = products.map(product =>
//       product.id === productId ? { ...product, selected: !product.selected } : product
//     );
//     setProducts(updatedProducts);
//     setSelectedProducts(updatedProducts.filter(p => p.selected).map(p => p.id));
//   };

//   const handleExpandProduct = (productId) => {
//     setExpandedProduct(expandedProduct === productId ? null : productId);
//   };

//   const removeLeadingWhitespace = async (productId) => {
//     setLoading(true);
//     setError('');
    
//     try {
//       const product = products.find(p => p.id === productId);
//       if (!product) throw new Error('Product not found');

//       // `<div style=...>` の前の空白と改行を削除
//       const trimmedDescription = product.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1');
      
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');
//       const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');

//       if (descriptionIndex === -1 || titleIndex === -1) {
//         throw new Error('Required columns not found');
//       }

//       const rowIndex = data.findIndex(row => row[titleIndex] === product.title);
//       if (rowIndex === -1) throw new Error('Product row not found');

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!${String.fromCharCode(65 + descriptionIndex)}${rowIndex + 1}`,
//         {
//           values: [[trimmedDescription]]
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setProducts(prevProducts =>
//         prevProducts.map(p =>
//           p.id === productId ? { ...p, description: trimmedDescription } : p
//         )
//       );

//       setSnackbarMessage('Leading whitespace removed successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//     } catch (err) {
//       console.error('Error removing leading whitespace:', err);
//       setError(`Failed to remove leading whitespace: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const removeAllLeadingWhitespace = async () => {
//     setLoading(true);
//     setError('');
    
//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const data = response.data.values;
//       const headers = data[0];
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

//       if (descriptionIndex === -1) {
//         throw new Error('Description column not found');
//       }

//       const updatedData = data.map((row, index) => {
//         if (index === 0) return row;
//         const newRow = [...row];
//         newRow[descriptionIndex] = newRow[descriptionIndex].replace(/^\s*(<div style[\s\S]*?>)/, '$1');
//         return newRow;
//       });

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
//         { 
//           values: updatedData
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setProducts(prevProducts =>
//         prevProducts.map(p => ({ ...p, description: p.description.replace(/^\s*(<div style[\s\S]*?>)/, '$1') }))
//       );

//       setSnackbarMessage('Leading whitespace removed from all descriptions');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchData();
//     } catch (err) {
//       console.error('Error removing all leading whitespace:', err);
//       setError(`Failed to remove all leading whitespace: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const applyUpdatedDescriptions = async () => {
//     setLoading(true);
//     setError('');
//     setMessage('');

//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       const currentData = response.data.values;

//       const headers = currentData[0];
//       const descriptionIndex = headers.findIndex(header => header.toLowerCase() === 'description');

//       if (descriptionIndex === -1) {
//         throw new Error('Description column not found');
//       }

//       const updatedData = currentData.map((row, index) => {
//         if (index === 0) return row;

//         const product = products.find(p => p.title === row[headers.indexOf('Title')]);
//         if (product && product.selected && product.updatedDescription) {
//           const newRow = [...row];
//           newRow[descriptionIndex] = product.updatedDescription;
//           return newRow;
//         }
//         return row;
//       });

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
//         { 
//           values: updatedData
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setMessage('Descriptions updated successfully');
//       setSnackbarMessage('Descriptions updated successfully');
//       setSnackbarSeverity('success');
//       setSnackbarOpen(true);
//       fetchData();
//     } catch (err) {
//       console.error('Error updating descriptions:', err);
//       setError(`Failed to update descriptions: ${err.message}`);
//       setSnackbarMessage(`Error: ${err.message}`);
//       setSnackbarSeverity('error');
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePreviewOpen = (product) => {
//     setPreviewProduct(product);
//     setShowPreviewDialog(true);
//   };

//   const handlePreviewClose = () => {
//     setPreviewProduct(null);
//     setShowPreviewDialog(false);
//   };

//   const renderEbayPreview = (product) => {
//     return (
//       <div dangerouslySetInnerHTML={{ __html: product.updatedDescription || product.description }} />
//     );
//   };

//   const handleEditFeatures = (productId, newFeatures) => {
//     setProducts(prevProducts =>
//       prevProducts.map(product =>
//         product.id === productId
//           ? {
//               ...product,
//               editableGeneratedFeatures: newFeatures,
//               updatedDescription: generateFormattedDescription(
//                 product.title,
//                 newFeatures,
//                 product.description,
//                 product.editableFields
//               )
//             }
//           : product
//       )
//     );
//   };

//   const clearCache = () => {
//     setCache({});
//     setSnackbarMessage('Cache cleared successfully');
//     setSnackbarSeverity('success');
//     setSnackbarOpen(true);
//   };

//   return (
//     <Paper 
//       elevation={3} 
//       sx={{ 
//         p: 4,
//         mt: 1, 
//         mb: 1, 
//         maxWidth: '100%',
//         margin: '0 auto',
//       }}
//     >
//       <Box sx={{ maxWidth: '1600px', margin: '0 auto' }}>
//         <Grid container spacing={3} alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Typography variant="h5" gutterBottom>
//               AIを活用した商品説明生成
//             </Typography>
//             <Typography variant="body1" paragraph>
//               このツールは、AIを使用して、日本語の商品説明から英語の商品特徴を自動生成し、
//               eBay向けの魅力的な商品説明を作成します。
//               余白削除はebayに出品するために使います。
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Typography variant="subtitle1" gutterBottom>主な機能：</Typography>
//             <Grid container spacing={1}>
//               {[
//                 '日本語の商品説明から英語の商品特徴を抽出',
//                 'デザインテンプレートからデザインを選択',
//                 '複数の商品を一括で処理',
//                 '生成された説明のプレビューと編集',
//                 'バッチ処理とリトライメカニズムによる安定性向上',
//                 'キャッシュ機能による効率化'
//               ].map((feature, index) => (
//                 <Grid item xs={6} key={index}>
//                   <Typography variant="body2">• {feature}</Typography>
//                 </Grid>
//               ))}
//             </Grid>
//           </Grid>
//         </Grid>
  
//         <Box sx={{ mt: 3, display: 'flex', gap: '10px', justifyContent: 'center' }}>
//           <Button 
//             variant="contained" 
//             color="primary" 
//             onClick={handleOpen}
//             startIcon={<AddIcon />}
//           >
//             商品説明をAIで生成する
//           </Button>
//           <Button 
//             variant="contained" 
//             color="secondary" 
//             onClick={removeAllLeadingWhitespace}
//             startIcon={<FormatColorResetIcon />}
//           >
//             Descriptionの先頭の余白を削除する
//           </Button>
//           <Button
//             variant="outlined"
//             color="primary"
//             onClick={clearCache}
//             startIcon={<CachedIcon />}
//           >
//             キャッシュをクリア
//           </Button>
//         </Box>
  
//         <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
//           <DialogTitle>AIで商品説明を生成する</DialogTitle>
//           <DialogContent>
//             <Grid container spacing={2} alignItems="center">
//               <Grid item xs={12} sm={6}>
//                 <FormControlLabel
//                   control={
//                     <Checkbox
//                       checked={selectedProducts.length === products.length}
//                       onChange={handleSelectAll}
//                       indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
//                     />
//                   }
//                   label="Select All"
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <Select
//                   value={selectedTemplate}
//                   onChange={(e) => setSelectedTemplate(e.target.value)}
//                   fullWidth
//                 >
//                   {Object.entries(designTemplates).map(([key, template]) => (
//                     <MenuItem key={key} value={key}>{template.name}</MenuItem>
//                   ))}
//                 </Select>
//               </Grid>
//             </Grid>
  
//             <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
//               <Grid item>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={generateProductFeatures}
//                   disabled={loading || !apiKey || selectedProducts.length === 0}
//                 >
//                   AIで商品説明を生成する
//                 </Button>
//               </Grid>
//               <Grid item>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={applyUpdatedDescriptions}
//                   disabled={loading || selectedProducts.length === 0}
//                 >
//                   商品説明をスプレッドシートに更新する
//                 </Button>
//               </Grid>
//             </Grid>
  
//             {loading && (
//               <Box sx={{ width: '100%', mb: 2 }}>
//                 <LinearProgress variant="determinate" value={progress} />
//                 <Typography variant="body2" color="text.secondary" align="center">
//                   {`${Math.round(progress)}%`}
//                 </Typography>
//               </Box>
//             )}
  
//             <List>
//               {products.map((product) => (
//                 <Paper key={product.id} elevation={3} style={{ marginBottom: '10px' }}>
//                   <ListItem>
//                     <ListItemText 
//                       primary={
//                         <FormControlLabel
//                           control={
//                             <Checkbox
//                               checked={product.selected}
//                               onChange={() => handleSelectProduct(product.id)}
//                             />
//                           }
//                           label={product.title}
//                         />
//                       }
//                     />
//                     <ListItemSecondaryAction>
//                       <Tooltip title="Remove leading whitespace">
//                         <IconButton edge="end" onClick={() => removeLeadingWhitespace(product.id)} disabled={loading}>
//                           <FormatColorResetIcon />
//                         </IconButton>
//                       </Tooltip>
//                       <IconButton edge="end" onClick={() => handlePreviewOpen(product)}>
//                         <VisibilityIcon />
//                       </IconButton>
//                       <IconButton edge="end" onClick={() => handleExpandProduct(product.id)}>
//                         {expandedProduct === product.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                       </IconButton>
//                     </ListItemSecondaryAction>
//                   </ListItem>
//                   <Collapse in={expandedProduct === product.id} timeout="auto" unmountOnExit>
//                     <Grid container spacing={2} style={{ padding: '10px' }}>
//                       <Grid item xs={12}>
//                         <Typography variant="subtitle1">Japanese Description</Typography>
//                         <TextField
//                           multiline
//                           rows={4}
//                           fullWidth
//                           variant="outlined"
//                           value={product.jpDesc}
//                           InputProps={{ readOnly: true }}
//                         />
//                       </Grid>
//                       <Grid item xs={12}>
//                         <Typography variant="subtitle1">Current Description</Typography>
//                         <TextField
//                           multiline
//                           rows={4}
//                           fullWidth
//                           variant="outlined"
//                           value={product.description}
//                           InputProps={{ readOnly: true }}
//                         />
//                       </Grid>
//                       {product.generatedFeatures && (
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Generated Features (Editable)</Typography>
//                           <TextField
//                             multiline
//                             rows={4}
//                             fullWidth
//                             variant="outlined"
//                             value={product.editableGeneratedFeatures}
//                             onChange={(e) => handleEditFeatures(product.id, e.target.value)}
//                           />
//                         </Grid>
//                       )}
//                       {product.updatedDescription && (
//                         <Grid item xs={12}>
//                           <Typography variant="subtitle1">Updated Description</Typography>
//                           <div dangerouslySetInnerHTML={{ __html: product.updatedDescription }} />
//                         </Grid>
//                       )}
//                     </Grid>
//                   </Collapse>
//                 </Paper>
//               ))}
//             </List>
            
//             {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
//             {message && <Typography color="primary" style={{ marginTop: '20px' }}>{message}</Typography>}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleClose}>Close</Button>
//           </DialogActions>
//         </Dialog>
  
//         <Dialog open={showPreviewDialog} onClose={handlePreviewClose} maxWidth="md" fullWidth>
//           <DialogTitle>eBay Preview: {previewProduct?.title}</DialogTitle>
//           <DialogContent>
//             {previewProduct && renderEbayPreview(previewProduct)}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handlePreviewClose}>Close</Button>
//           </DialogActions>
//         </Dialog>
  
//         <Snackbar
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={() => setSnackbarOpen(false)}
//         >
//           <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Box>
//     </Paper>
//   );
// };

// export default OpenAIDescriptionGenerator;



