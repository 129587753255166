import React, { useState, useEffect ,useCallback } from 'react';
import {
  Box,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardMedia,
  CardContent,
  Paper,
  Divider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const HighlightOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  border: `2px solid ${theme.palette.secondary.main}`,
  borderRadius: '4px',
  boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
  pointerEvents: 'none',
  transition: 'all 0.3s ease-in-out',
}));

const StyledInstruction = styled(Typography)(({ theme }) => ({
    position: 'fixed',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    zIndex: 1001,
    maxWidth: '300px',
    maxHeight: '200px', // 高さの最大値を設定
    overflow: 'auto', // コンテンツが多い場合はスクロール可能に
  }));

  const DemoPanel = styled(Paper)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(4), // 画面下部から少し上に配置
    right: theme.spacing(4), // 右側に配置
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
    zIndex: 1200,
    maxWidth: '400px',
    maxHeight: '80vh',
    overflow: 'auto',
  }));

const themes = [
  {
    name: "基本設定",
    steps: [
      {
        label: 'スプレッドシートIDの入力',
        instruction: 'スプレッドシートIDを入力してください。',
        content: 'スプレッドシートIDを入力してください。dとeditに挟まれている部分がIDです。https://docs.google.com/spreadsheets/d/[ここがID]/edit',
        image: '/demo_id.png',
        target: '.input-field:nth-child(1) input',
        position: { top: '100%', left: 0 },
      },
      {
        label: 'シート名の入力',
        instruction: '任意のシート名を入力してください。',
        content: 'スプレッドシート内の特定のシートの名前を入力します。正確に入力してください。',
        image: '/sheet_name_input.png',
        target: '.input-field:nth-child(2) input',
        position: { top: '100%', left: 0 },
      },
      {
        label: 'データの読み込み',
        instruction: '読み込みボタンをクリックしてください。',
        content: 'ボタンをクリックすると、入力したIDとシート名に基づいてデータが読み込まれます。',
        image: '/load_data.png',
        target: '.button-group:nth-child(3) button',
        position: { top: '100%', left: 0 },
      },
      {
        label: 'スプレッドシートの保存',
        instruction: '保存ボタンをクリックしてください。',
        content: '現在のスプレッドシート情報を保存します。これにより、後で簡単に同じスプレッドシートにアクセスできます。',
        image: '/save_spreadsheet.png',
        target: '.button-group:nth-child(4) button',
        position: { top: '100%', left: 0 },
      },
      {
        label: 'データの確認',
        instruction: '画面下部にスプレッドシートのデータが表示されていることを確認してください。',
        content: '読み込みが成功すると、ここにスプレッドシートのデータが表示されます。データが正しく表示されていることを確認してください。',
        image: '/data_display.png',
        target: '.editable-spreadsheet',
        position: { top: '-100%', left: 0 },
      },
    ]
  },
  {
    name: "テンプレート管理",
    steps: [
      {
        label: 'テンプレート管理を開く',
        instruction: 'テンプレート管理ボタンをクリックしてください。',
        content: 'テンプレート管理画面を開きます。ここでテンプレートの作成、編集、適用を行います。',
        image: '/open_template_management.png',
        target: 'button[aria-label="テンプレートを開く"]',
        position: { top: '100%', left: 0 },
      },
      {
        label: '新しいテンプレートの作成',
        instruction: '新しいテンプレートボタンをクリックしてください。',
        content: '新しいテンプレートを作成します。テンプレート名を入力し、必要な操作を追加していきます。',
        image: '/create_new_template.png',
        target: 'button[aria-label="新しいテンプレート"]',
        position: { top: '100%', left: 0 },
      },
      {
        label: 'テンプレートの編集',
        instruction: 'テンプレートに操作を追加してください。',
        content: 'カラム名、アクション、値を入力し、操作を追加します。複数の操作を追加できます。',
        image: '/edit_template.png',
        target: 'button[aria-label="操作を追加"]',
        position: { top: '100%', left: 0 },
      },
      {
        label: 'テンプレートの適用',
        instruction: 'テンプレートを適用ボタンをクリックしてください。',
        content: '作成したテンプレートをスプレッドシートに適用します。確認ダイアログが表示されます。',
        image: '/apply_template.png',
        target: 'button[aria-label="テンプレートを適用"]',
        position: { top: '100%', left: 0 },
      },
      {
        label: '適用の確認',
        instruction: '確認ダイアログで「適用」をクリックしてください。',
        content: 'テンプレートの適用を確認します。この操作は元に戻せないので注意してください。',
        image: '/confirm_apply_template.png',
        target: 'button[aria-label="適用"]',
        position: { top: '100%', left: 0 },
      },
    ]
  }
];

function DemoMode({ isActive, onComplete }) {
    const [activeTheme, setActiveTheme] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [highlightPosition, setHighlightPosition] = useState(null);
  
    const resetDemo = useCallback(() => {
      setActiveTheme(0);
      setActiveStep(0);
    }, []);
  
    const findTargetElement = useCallback((selector) => {
      const elements = document.querySelectorAll(selector);
      if (elements.length === 0) {
        console.warn(`Target element not found for selector: ${selector}`);
        return null;
      }
      return elements[0];
    }, []);
  
    useEffect(() => {
      if (isActive && activeTheme < themes.length && activeStep < themes[activeTheme].steps.length) {
        const currentStep = themes[activeTheme].steps[activeStep];
        const targetElement = findTargetElement(currentStep.target);
        if (targetElement) {
          const rect = targetElement.getBoundingClientRect();
          setHighlightPosition({
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
            height: rect.height,
          });
        } else {
          setHighlightPosition(null);
        }
      }
    }, [isActive, activeTheme, activeStep, findTargetElement]);
  
    const handleNext = useCallback(() => {
      const currentTheme = themes[activeTheme];
      if (activeStep === currentTheme.steps.length - 1) {
        if (activeTheme === themes.length - 1) {
          onComplete();
          resetDemo(); // DEMOが完了したときのみリセット
        } else {
          setActiveTheme(prevTheme => prevTheme + 1);
          setActiveStep(0);
        }
      } else {
        setActiveStep(prevStep => prevStep + 1);
      }
    }, [activeTheme, activeStep, onComplete, resetDemo]);
  
    const handleBack = useCallback(() => {
      if (activeStep === 0) {
        if (activeTheme > 0) {
          setActiveTheme(prevTheme => prevTheme - 1);
          setActiveStep(themes[activeTheme - 1].steps.length - 1);
        }
      } else {
        setActiveStep(prevStep => prevStep - 1);
      }
    }, [activeTheme, activeStep]);
  
    const handleClose = useCallback(() => {
      onComplete();
      // 閉じる時はリセットしない
    }, [onComplete]);
  
    if (!isActive) return null;

  const currentTheme = themes[activeTheme];
  const currentStep = currentTheme.steps[activeStep];

  return (
    <>
      <DemoPanel>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="h6">
            DEMOモード: {currentTheme.name}
          </Typography>
          <IconButton onClick={handleClose} aria-label="DEMOモードを閉じる">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Typography variant="subtitle1" gutterBottom>
          ステップ {activeStep + 1}: {currentStep.label}
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {currentTheme.steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2 }}>
          <Card>
            {currentStep.image && (
              <CardMedia
                component="img"
                height="140"
                image={currentStep.image}
                alt={currentStep.label}
              />
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {currentStep.content}
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            disabled={activeTheme === 0 && activeStep === 0} 
            onClick={handleBack}
          >
            戻る
          </Button>
          <Button 
            variant="contained" 
            onClick={handleNext}
          >
            {activeTheme === themes.length - 1 && activeStep === currentTheme.steps.length - 1 ? '完了' : '次へ'}
          </Button>
        </Box>
      </DemoPanel>
      {highlightPosition && (
        <>
          <HighlightOverlay style={highlightPosition} />
          <StyledInstruction style={{
            top: Math.min(
              highlightPosition.top + highlightPosition.height + 50,
              window.innerHeight - 220 // window.innerHeightを直接使用
            ),
            left: highlightPosition.left,
            transform: 'translateY(0)',
          }}>
            <Typography variant="subtitle1" gutterBottom>
              {currentStep.label}
            </Typography>
            <Typography variant="body2">
              {currentStep.instruction}
            </Typography>
          </StyledInstruction>
        </>
      )}
    </>
  );
}

export default DemoMode;