import React, { useState } from 'react';
import { 
  Button, 
  TextField, 
  Typography, 
  Box, 
  CircularProgress, 
  Alert, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Paper,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';

function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(150);
  const [newUrls, setNewUrls] = useState('');
  const [isReplacing, setIsReplacing] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [openPreview, setOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [enlargedImages, setEnlargedImages] = useState(null);
  const [skippedRows, setSkippedRows] = useState(new Set());

  const generatePreviewData = () => {
    setError('');
    const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
    if (picUrlIndex === -1) {
      setError('PicURL column not found');
      return;
    }

    const actualStartRow = startRow;
    const actualEndRow = Math.min(endRow, data.length - 1);
    let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

    // Create a map of image numbers to URLs
    const urlMap = new Map();
    newUrlList.forEach(url => {
      const match = url.match(/image-(\d+)/);
      if (match) {
        urlMap.set(parseInt(match[1]), url);
      }
    });

    const previewItems = [];
    for (let i = actualStartRow; i <= actualEndRow; i++) {
      const oldUrl = data[i][picUrlIndex].split('|')[0];
      const newUrl = urlMap.get(i) || '';
      if (newUrl) {
        previewItems.push({ rowIndex: i, oldUrl, newUrl });
      }
    }

    if (previewItems.length === 0) {
      setError('No matching URLs found for the selected rows');
      return;
    }

    setPreviewData(previewItems);
    setOpenPreview(true);
  };

  const handleReplace = async () => {
    setIsReplacing(true);
    setError('');
    setSuccess('');

    try {
      const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
      if (picUrlIndex === -1) {
        throw new Error('PicURL column not found');
      }

      const actualStartRow = startRow;
      const actualEndRow = Math.min(endRow, data.length - 1);
      let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

      // Create a map of image numbers to URLs
      const urlMap = new Map();
      newUrlList.forEach(url => {
        const match = url.match(/image-(\d+)/);
        if (match) {
          urlMap.set(parseInt(match[1]), url);
        }
      });

      const batchUpdates = [];
      const picUrlColumn = String.fromCharCode(65 + picUrlIndex);

      for (let i = actualStartRow; i <= actualEndRow; i++) {
        if (!skippedRows.has(i)) {
          const newUrl = urlMap.get(i);
          if (newUrl) {
            const currentUrls = data[i][picUrlIndex].split('|');
            currentUrls[0] = newUrl;
            batchUpdates.push({
              range: `${sheetName}!${picUrlColumn}${i + 1}`,
              values: [[currentUrls.join('|')]]
            });
          }
        }
      }

      if (batchUpdates.length === 0) {
        throw new Error('No matching URLs found for the selected rows');
      }

      // Use batchUpdate to update multiple cells in a single API call
      const response = await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchUpdate`,
        {
          valueInputOption: 'RAW',
          data: batchUpdates
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      console.log('API Response:', response.data);

      setSuccess('URLs replaced successfully');
      fetchData();
    } catch (err) {
      console.error('Full error object:', err);
      if (err.response) {
        console.error('Error response:', err.response.data);
        setError(`Replace failed: ${err.response.data.error.message || err.message}`);
      } else {
        setError(`Replace failed: ${err.message}`);
      }
    } finally {
      setIsReplacing(false);
    }
  };

  const handleImageClick = (oldUrl, newUrl) => {
    setEnlargedImages({ oldUrl, newUrl });
  };

  const handleSkipToggle = (rowIndex) => {
    setSkippedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(rowIndex)) {
        newSet.delete(rowIndex);
      } else {
        newSet.add(rowIndex);
      }
      return newSet;
    });
  };

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h6">Image URL Replacer</Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        画像URLリンクをそのまま貼り付けてください。URLの "image-X" の数字が行番号と一致する画像が変更されます。
      </Typography>
      <TextField
        label="Start Row"
        type="number"
        value={startRow}
        onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
        sx={{ mr: 2 }}
      />
      <TextField
        label="End Row"
        type="number"
        value={endRow}
        onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
        sx={{ mr: 2 }}
      />
      <TextField
        label="New URLs (one per line)"
        multiline
        rows={4}
        value={newUrls}
        onChange={(e) => setNewUrls(e.target.value)}
        fullWidth
        sx={{ my: 2 }}
      />
      <Box sx={{ mt: 3 }}>
        <Button
          variant="outlined"
          onClick={generatePreviewData}
          sx={{ mr: 2 }}
        >
          プレビュー
        </Button>
        <Button
          variant="contained"
          onClick={handleReplace}
          disabled={isReplacing || !data || data.length <= 1 || previewData.length === 0}
        >
          {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
        </Button>
      </Box>
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}

      <Dialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>画像プレビュー</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            画像をクリックすると、eBayでの表示イメージを確認できます。チェックボックスを外すと、その行の画像は変更されません。
          </Typography>
          <Grid container spacing={2}>
            {previewData.map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">Row {item.rowIndex}</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!skippedRows.has(item.rowIndex)}
                          onChange={() => handleSkipToggle(item.rowIndex)}
                        />
                      }
                      label="この画像を変更する"
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">変更前</Typography>
                        <CardMedia
                          component="img"
                          height="140"
                          image={item.oldUrl}
                          alt={`Old image ${index + 1}`}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleImageClick(item.oldUrl, item.newUrl)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">変更後</Typography>
                        <CardMedia
                          component="img"
                          height="140"
                          image={item.newUrl}
                          alt={`New image ${index + 1}`}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleImageClick(item.oldUrl, item.newUrl)}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!enlargedImages}
        onClose={() => setEnlargedImages(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          eBayでの表示イメージ
          <IconButton
            aria-label="close"
            onClick={() => setEnlargedImages(null)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            これらの画像がeBayの商品ページに表示されます。左が現在の画像、右が変更後の画像です。
          </Typography>
          <Paper elevation={3} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <img
                  src={enlargedImages?.oldUrl}
                  alt="Current eBay image"
                  style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
                />
                <Typography variant="subtitle1" align="center">現在の画像</Typography>
              </Grid>
              <Grid item xs={6}>
                <img
                  src={enlargedImages?.newUrl}
                  alt="New eBay image"
                  style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
                />
                <Typography variant="subtitle1" align="center">変更後の画像</Typography>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ImageUrlReplacer;

// import React, { useState } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent,
//   IconButton,
//   Paper
// } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);
//   const [enlargedImages, setEnlargedImages] = useState(null);

//   const generatePreviewData = () => {
//     setError('');
//     const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//     if (picUrlIndex === -1) {
//       setError('PicURL column not found');
//       return;
//     }

//     const actualStartRow = startRow;
//     const actualEndRow = Math.min(endRow, data.length - 1);
//     let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//     // Create a map of image numbers to URLs
//     const urlMap = new Map();
//     newUrlList.forEach(url => {
//       const match = url.match(/image-(\d+)/);
//       if (match) {
//         urlMap.set(parseInt(match[1]), url);
//       }
//     });

//     const previewItems = [];
//     for (let i = actualStartRow; i <= actualEndRow; i++) {
//       const oldUrl = data[i][picUrlIndex].split('|')[0];
//       const newUrl = urlMap.get(i) || '';
//       if (newUrl) {
//         previewItems.push({ rowIndex: i, oldUrl, newUrl });
//       }
//     }

//     if (previewItems.length === 0) {
//       setError('No matching URLs found for the selected rows');
//       return;
//     }

//     setPreviewData(previewItems);
//     setOpenPreview(true);
//   };

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = startRow;
//       const actualEndRow = Math.min(endRow, data.length - 1);
//       let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//       // Create a map of image numbers to URLs
//       const urlMap = new Map();
//       newUrlList.forEach(url => {
//         const match = url.match(/image-(\d+)/);
//         if (match) {
//           urlMap.set(parseInt(match[1]), url);
//         }
//       });

//       const updatedUrls = [];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const currentUrls = data[i][picUrlIndex].split('|');
//         const newUrl = urlMap.get(i);
//         if (newUrl) {
//           currentUrls[0] = newUrl;
//           updatedUrls.push([currentUrls.join('|')]);
//         }
//       }

//       if (updatedUrls.length === 0) {
//         throw new Error('No matching URLs found for the selected rows');
//       }

//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);
//       const range = `${sheetName}!${picUrlColumn}${actualStartRow + 1}:${picUrlColumn}${actualEndRow + 1}`;
      
//       console.log('Data to update:', updatedUrls);
//       console.log('Range:', range);

//       const response = await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
//         {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       console.log('API Response:', response.data);

//       setSuccess('URLs replaced successfully');
//       fetchData();
//     } catch (err) {
//       console.error('Full error object:', err);
//       if (err.response) {
//         console.error('Error response:', err.response.data);
//         setError(`Replace failed: ${err.response.data.error.message || err.message}`);
//       } else {
//         setError(`Replace failed: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   const handleImageClick = (oldUrl, newUrl) => {
//     setEnlargedImages({ oldUrl, newUrl });
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6">Image URL Replacer</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。URLの "image-X" の数字が行番号と一致する画像が変更されます。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="New URLs (one per line)"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <Box sx={{ mt: 2 }}>
//         <Button
//           variant="outlined"
//           onClick={generatePreviewData}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={isReplacing || !data || data.length <= 1 || previewData.length === 0}
//         >
//           {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
//         </Button>
//       </Box>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}

//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>画像プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             画像をクリックすると、eBayでの表示イメージを確認できます。
//           </Typography>
//           <Grid container spacing={2}>
//             {previewData.map((item, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">Row {item.rowIndex}</Typography>
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更前</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.oldUrl}
//                           alt={`Old image ${index + 1}`}
//                           sx={{ cursor: 'pointer' }}
//                           onClick={() => handleImageClick(item.oldUrl, item.newUrl)}
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更後</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.newUrl}
//                           alt={`New image ${index + 1}`}
//                           sx={{ cursor: 'pointer' }}
//                           onClick={() => handleImageClick(item.oldUrl, item.newUrl)}
//                         />
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={!!enlargedImages}
//         onClose={() => setEnlargedImages(null)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>
//           eBayでの表示イメージ
//           <IconButton
//             aria-label="close"
//             onClick={() => setEnlargedImages(null)}
//             sx={{
//               position: 'absolute',
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" sx={{ mb: 2 }}>
//             これらの画像がeBayの商品ページに表示されます。左が現在の画像、右が変更後の画像です。
//           </Typography>
//           <Paper elevation={3} sx={{ p: 2, bgcolor: '#f5f5f5' }}>
//             <Grid container spacing={2} justifyContent="center">
//               <Grid item xs={6}>
//                 <img
//                   src={enlargedImages?.oldUrl}
//                   alt="Current eBay image"
//                   style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
//                 />
//                 <Typography variant="subtitle1" align="center">現在の画像</Typography>
//               </Grid>
//               <Grid item xs={6}>
//                 <img
//                   src={enlargedImages?.newUrl}
//                   alt="New eBay image"
//                   style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
//                 />
//                 <Typography variant="subtitle1" align="center">変更後の画像</Typography>
//               </Grid>
//             </Grid>
//           </Paper>
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;

// import React, { useState } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Checkbox, 
//   FormControlLabel,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent
// } from '@mui/material';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [sortUrls, setSortUrls] = useState(false);
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);

//   const generatePreviewData = () => {
//     setError('');
//     const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//     if (picUrlIndex === -1) {
//       setError('PicURL column not found');
//       return;
//     }

//     const actualStartRow = startRow;
//     const actualEndRow = Math.min(endRow, data.length - 1);
//     let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//     // Create a map of image numbers to URLs
//     const urlMap = new Map();
//     newUrlList.forEach(url => {
//       const match = url.match(/image-(\d+)/);
//       if (match) {
//         urlMap.set(parseInt(match[1]), url);
//       }
//     });

//     const previewItems = [];
//     for (let i = actualStartRow; i <= actualEndRow; i++) {
//       const oldUrl = data[i][picUrlIndex].split('|')[0];
//       const newUrl = urlMap.get(i) || '';
//       if (newUrl) {
//         previewItems.push({ rowIndex: i, oldUrl, newUrl });
//       }
//     }

//     if (previewItems.length === 0) {
//       setError('No matching URLs found for the selected rows');
//       return;
//     }

//     setPreviewData(previewItems);
//     setOpenPreview(true);
//   };

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = startRow;
//       const actualEndRow = Math.min(endRow, data.length - 1);
//       let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//       // Create a map of image numbers to URLs
//       const urlMap = new Map();
//       newUrlList.forEach(url => {
//         const match = url.match(/image-(\d+)/);
//         if (match) {
//           urlMap.set(parseInt(match[1]), url);
//         }
//       });

//       const updatedUrls = [];
//       for (let i = actualStartRow; i <= actualEndRow; i++) {
//         const currentUrls = data[i][picUrlIndex].split('|');
//         const newUrl = urlMap.get(i);
//         if (newUrl) {
//           currentUrls[0] = newUrl;
//           updatedUrls.push([currentUrls.join('|')]);
//         }
//       }

//       if (updatedUrls.length === 0) {
//         throw new Error('No matching URLs found for the selected rows');
//       }

//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);
//       const range = `${sheetName}!${picUrlColumn}${actualStartRow + 1}:${picUrlColumn}${actualEndRow + 1}`;
      
//       console.log('Data to update:', updatedUrls);
//       console.log('Range:', range);

//       const response = await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
//         {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       console.log('API Response:', response.data);

//       setSuccess('URLs replaced successfully');
//       fetchData();
//     } catch (err) {
//       console.error('Full error object:', err);
//       if (err.response) {
//         console.error('Error response:', err.response.data);
//         setError(`Replace failed: ${err.response.data.error.message || err.message}`);
//       } else {
//         setError(`Replace failed: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6">Image URL Replacer</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。URLの "image-X" の数字が行番号と一致する画像が変更されます。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="New URLs (one per line)"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <Box sx={{ mt: 2 }}>
//         <Button
//           variant="outlined"
//           onClick={generatePreviewData}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={isReplacing || !data || data.length <= 1 || previewData.length === 0}
//         >
//           {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
//         </Button>
//       </Box>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}

//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>画像プレビュー</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             {previewData.map((item, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">Row {item.rowIndex}</Typography>
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更前</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.oldUrl}
//                           alt={`Old image ${index + 1}`}
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更後</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.newUrl}
//                           alt={`New image ${index + 1}`}
//                         />
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;

// import React, { useState } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Checkbox, 
//   FormControlLabel,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent
// } from '@mui/material';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [sortUrls, setSortUrls] = useState(false);
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);

//   const generatePreviewData = () => {
//     setError('');
//     const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//     if (picUrlIndex === -1) {
//       setError('PicURL column not found');
//       return;
//     }

//     const actualStartRow = startRow;
//     const actualEndRow = Math.min(endRow, data.length - 1);
//     let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//     if (sortUrls) {
//       newUrlList = newUrlList.sort((a, b) => {
//         const numA = parseInt(a.match(/image-(\d+)/)?.[1] || '0');
//         const numB = parseInt(b.match(/image-(\d+)/)?.[1] || '0');
//         return numA - numB;
//       });
//     }

//     if (actualEndRow - actualStartRow + 1 > newUrlList.length) {
//       setError('Not enough new URLs provided');
//       return;
//     }

//     const previewItems = data.slice(actualStartRow, actualEndRow + 1).map((row, index) => {
//       const oldUrl = row[picUrlIndex].split('|')[0];
//       const newUrl = newUrlList[index] || '';
//       return { oldUrl, newUrl };
//     });

//     setPreviewData(previewItems);
//     setOpenPreview(true);
//   };

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = startRow;
//       const actualEndRow = Math.min(endRow, data.length - 1);
//       let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//       if (sortUrls) {
//         newUrlList = newUrlList.sort((a, b) => {
//           const numA = parseInt(a.match(/image-(\d+)/)?.[1] || '0');
//           const numB = parseInt(b.match(/image-(\d+)/)?.[1] || '0');
//           return numA - numB;
//         });
//       }

//       if (actualEndRow - actualStartRow + 1 > newUrlList.length) {
//         throw new Error('Not enough new URLs provided');
//       }

//       const updatedUrls = data.slice(actualStartRow, actualEndRow + 1).map((row, index) => {
//         const currentUrls = row[picUrlIndex].split('|');
//         currentUrls[0] = newUrlList[index];
//         return [currentUrls.join('|')];
//       });

//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);
//       const range = `${sheetName}!${picUrlColumn}${actualStartRow + 1}:${picUrlColumn}${actualEndRow + 1}`;
      
//       console.log('Data to update:', updatedUrls);
//       console.log('Range:', range);
//       console.log('API Request:', {
//         spreadsheetId,
//         range,
//         valueInputOption: 'RAW',
//         resource: {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         }
//       });

//       const response = await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
//         {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       console.log('API Response:', response.data);

//       setSuccess('URLs replaced successfully');
//       fetchData();
//     } catch (err) {
//       console.error('Full error object:', err);
//       if (err.response) {
//         console.error('Error response:', err.response.data);
//         setError(`Replace failed: ${err.response.data.error.message || err.message}`);
//       } else {
//         setError(`Replace failed: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6">Image URL Replacer</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。※上から順になります。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="New URLs (one per line)"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={sortUrls}
//             onChange={(e) => setSortUrls(e.target.checked)}
//             name="sortUrls"
//           />
//         }
//         label="URLの数字で並び替える"
//       />
//       <Box sx={{ mt: 2 }}>
//         <Button
//           variant="outlined"
//           onClick={generatePreviewData}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={isReplacing || !data || data.length <= 1 || previewData.length === 0}
//         >
//           {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
//         </Button>
//       </Box>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}

//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>画像プレビュー</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             {previewData.map((item, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">Row {startRow + index}</Typography>
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更前</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.oldUrl}
//                           alt={`Old image ${index + 1}`}
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更後</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.newUrl}
//                           alt={`New image ${index + 1}`}
//                         />
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;

// import React, { useState, useEffect } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Checkbox, 
//   FormControlLabel,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent
// } from '@mui/material';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [sortUrls, setSortUrls] = useState(false);
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);

//   const generatePreviewData = () => {
//     const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//     if (picUrlIndex === -1) {
//       setError('PicURL column not found');
//       return;
//     }

//     const actualStartRow = startRow;
//     const actualEndRow = Math.min(endRow, data.length - 1);
//     let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//     if (sortUrls) {
//       newUrlList = newUrlList.sort((a, b) => {
//         const numA = parseInt(a.match(/image-(\d+)/)?.[1] || '0');
//         const numB = parseInt(b.match(/image-(\d+)/)?.[1] || '0');
//         return numA - numB;
//       });
//     }

//     if (actualEndRow - actualStartRow + 1 > newUrlList.length) {
//       setError('Not enough new URLs provided');
//       return;
//     }

//     const previewItems = data.slice(actualStartRow, actualEndRow + 1).map((row, index) => {
//       const oldUrl = row[picUrlIndex].split('|')[0];
//       const newUrl = newUrlList[index] || '';
//       return { oldUrl, newUrl };
//     });

//     setPreviewData(previewItems);
//     setOpenPreview(true);
//   };

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = startRow;
//       const actualEndRow = Math.min(endRow, data.length - 1);
//       let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//       if (sortUrls) {
//         newUrlList = newUrlList.sort((a, b) => {
//           const numA = parseInt(a.match(/image-(\d+)/)?.[1] || '0');
//           const numB = parseInt(b.match(/image-(\d+)/)?.[1] || '0');
//           return numA - numB;
//         });
//       }

//       if (actualEndRow - actualStartRow + 1 > newUrlList.length) {
//         throw new Error('Not enough new URLs provided');
//       }

//       const updatedUrls = data.slice(actualStartRow, actualEndRow + 1).map((row, index) => {
//         const currentUrls = row[picUrlIndex].split('|');
//         currentUrls[0] = newUrlList[index];
//         return [currentUrls.join('|')];
//       });

//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);
//       const range = `${sheetName}!${picUrlColumn}${actualStartRow + 1}:${picUrlColumn}${actualEndRow + 1}`;
      
//       console.log('Data to update:', updatedUrls);
//       console.log('Range:', range);

//       const response = await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
//         {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       console.log('API Response:', response.data);

//       setSuccess('URLs replaced successfully');
//       fetchData();
//     } catch (err) {
//       console.error('Full error object:', err);
//       if (err.response) {
//         console.error('Error response:', err.response.data);
//         setError(`Replace failed: ${err.response.data.error.message || err.message}`);
//       } else {
//         setError(`Replace failed: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6">Image URL Replacer</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。※上から順になります。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="New URLs (one per line)"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={sortUrls}
//             onChange={(e) => setSortUrls(e.target.checked)}
//             name="sortUrls"
//           />
//         }
//         label="URLの数字で並び替える"
//       />
//       <Box sx={{ mt: 2 }}>
//         <Button
//           variant="outlined"
//           onClick={generatePreviewData}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={isReplacing || !data || data.length <= 1 || previewData.length === 0}
//         >
//           {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
//         </Button>
//       </Box>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}

//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>画像プレビュー</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             {previewData.map((item, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">Row {startRow + index}</Typography>
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更前</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.oldUrl}
//                           alt={`Old image ${index + 1}`}
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更後</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.newUrl}
//                           alt={`New image ${index + 1}`}
//                         />
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;

// import React, { useState, useEffect } from 'react';
// import { 
//   Button, 
//   TextField, 
//   Typography, 
//   Box, 
//   CircularProgress, 
//   Alert, 
//   Checkbox, 
//   FormControlLabel,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Grid,
//   Card,
//   CardMedia,
//   CardContent
// } from '@mui/material';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [sortUrls, setSortUrls] = useState(false);
//   const [openPreview, setOpenPreview] = useState(false);
//   const [previewData, setPreviewData] = useState([]);

//   useEffect(() => {
//     if (openPreview) {
//       generatePreviewData();
//     }
//   }, [openPreview, newUrls, data, startRow, endRow, sortUrls]);

//   const generatePreviewData = () => {
//     const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//     if (picUrlIndex === -1) return;

//     const actualStartRow = startRow + 1;
//     const actualEndRow = Math.min(endRow + 1, data.length);
//     let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//     if (sortUrls) {
//       newUrlList = newUrlList.sort((a, b) => {
//         const numA = parseInt(a.match(/image-(\d+)/)?.[1] || '0');
//         const numB = parseInt(b.match(/image-(\d+)/)?.[1] || '0');
//         return numA - numB;
//       });
//     }

//     const previewItems = data.slice(actualStartRow - 1, actualEndRow).map((row, index) => {
//       const oldUrl = row[picUrlIndex].split('|')[0];
//       const newUrl = newUrlList[index] || '';
//       return { oldUrl, newUrl };
//     });

//     setPreviewData(previewItems);
//   };

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = startRow + 1;
//       const actualEndRow = Math.min(endRow + 1, data.length);
//       let newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//       if (sortUrls) {
//         newUrlList = newUrlList.sort((a, b) => {
//           const numA = parseInt(a.match(/image-(\d+)/)?.[1] || '0');
//           const numB = parseInt(b.match(/image-(\d+)/)?.[1] || '0');
//           return numA - numB;
//         });
//       }

//       if (actualEndRow - actualStartRow + 1 > newUrlList.length) {
//         throw new Error('Not enough new URLs provided');
//       }

//       const updatedUrls = data.slice(actualStartRow - 1, actualEndRow).map((row, index) => {
//         const currentUrls = row[picUrlIndex].split('|');
//         currentUrls[0] = newUrlList[index];
//         return [currentUrls.join('|')];
//       });

//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);
//       const range = `${sheetName}!${picUrlColumn}${actualStartRow}:${picUrlColumn}${actualEndRow}`;
      
//       console.log('Data to update:', updatedUrls);
//       console.log('Range:', range);
//       console.log('API Request:', {
//         spreadsheetId,
//         range,
//         valueInputOption: 'RAW',
//         resource: {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         }
//       });

//       const response = await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
//         {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       console.log('API Response:', response.data);

//       setSuccess('URLs replaced successfully');
//       fetchData();
//     } catch (err) {
//       console.error('Full error object:', err);
//       if (err.response) {
//         console.error('Error response:', err.response.data);
//         setError(`Replace failed: ${err.response.data.error.message || err.message}`);
//       } else {
//         setError(`Replace failed: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6">Image URL Replacer</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。※上から順になります。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="New URLs (one per line)"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={sortUrls}
//             onChange={(e) => setSortUrls(e.target.checked)}
//             name="sortUrls"
//           />
//         }
//         label="URLの数字で並び替える"
//       />
//       <Box sx={{ mt: 2 }}>
//         <Button
//           variant="outlined"
//           onClick={() => setOpenPreview(true)}
//           sx={{ mr: 2 }}
//         >
//           プレビュー
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleReplace}
//           disabled={isReplacing || !data || data.length <= 1}
//         >
//           {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
//         </Button>
//       </Box>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}

//       <Dialog
//         open={openPreview}
//         onClose={() => setOpenPreview(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>画像プレビュー</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             {previewData.map((item, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h6">Row {startRow + index}</Typography>
//                     <Grid container spacing={2}>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更前</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.oldUrl}
//                           alt={`Old image ${index + 1}`}
//                         />
//                       </Grid>
//                       <Grid item xs={6}>
//                         <Typography variant="subtitle1">変更後</Typography>
//                         <CardMedia
//                           component="img"
//                           height="140"
//                           image={item.newUrl}
//                           alt={`New image ${index + 1}`}
//                         />
//                       </Grid>
//                     </Grid>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenPreview(false)}>閉じる</Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default ImageUrlReplacer;

// import React, { useState } from 'react';
// import { Button, TextField, Typography, Box, CircularProgress, Alert } from '@mui/material';
// import axios from 'axios';

// function ImageUrlReplacer({ spreadsheetId, sheetName, token, data, fetchData }) {
//   const [startRow, setStartRow] = useState(1);
//   const [endRow, setEndRow] = useState(150);
//   const [newUrls, setNewUrls] = useState('');
//   const [isReplacing, setIsReplacing] = useState(false);
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');

//   const handleReplace = async () => {
//     setIsReplacing(true);
//     setError('');
//     setSuccess('');

//     try {
//       const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
//       if (picUrlIndex === -1) {
//         throw new Error('PicURL column not found');
//       }

//       const actualStartRow = startRow + 1;
//       const actualEndRow = Math.min(endRow + 1, data.length);
//       const newUrlList = newUrls.split('\n').filter(url => url.trim() !== '');

//       if (actualEndRow - actualStartRow + 1 > newUrlList.length) {
//         throw new Error('Not enough new URLs provided');
//       }

//       const updatedUrls = data.slice(actualStartRow - 1, actualEndRow).map((row, index) => {
//         const currentUrls = row[picUrlIndex].split('|');
//         currentUrls[0] = newUrlList[index];
//         return [currentUrls.join('|')];
//       });

//       const picUrlColumn = String.fromCharCode(65 + picUrlIndex);
//       const range = `${sheetName}!${picUrlColumn}${actualStartRow}:${picUrlColumn}${actualEndRow}`;
      
//       console.log('Data to update:', updatedUrls);
//       console.log('Range:', range);
//       console.log('API Request:', {
//         spreadsheetId,
//         range,
//         valueInputOption: 'RAW',
//         resource: {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         }
//       });

//       const response = await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
//         {
//           range: range,
//           values: updatedUrls,
//           majorDimension: "ROWS"
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       console.log('API Response:', response.data);

//       setSuccess('URLs replaced successfully');
//       fetchData();
//     } catch (err) {
//       console.error('Full error object:', err);
//       if (err.response) {
//         console.error('Error response:', err.response.data);
//         setError(`Replace failed: ${err.response.data.error.message || err.message}`);
//       } else {
//         setError(`Replace failed: ${err.message}`);
//       }
//     } finally {
//       setIsReplacing(false);
//     }
//   };

//   return (
//     <Box sx={{ mt: 2 }}>
//       <Typography variant="h6">Image URL Replacer</Typography>
//       <Typography variant="body2" sx={{ mb: 2 }}>
//         画像URLリンクをそのまま貼り付けてください。※上から順になります。
//       </Typography>
//       <TextField
//         label="Start Row"
//         type="number"
//         value={startRow}
//         onChange={(e) => setStartRow(Math.max(1, parseInt(e.target.value) || 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="End Row"
//         type="number"
//         value={endRow}
//         onChange={(e) => setEndRow(Math.min(Math.max(startRow, parseInt(e.target.value) || startRow), data.length - 1))}
//         sx={{ mr: 2 }}
//       />
//       <TextField
//         label="New URLs (one per line)"
//         multiline
//         rows={4}
//         value={newUrls}
//         onChange={(e) => setNewUrls(e.target.value)}
//         fullWidth
//         sx={{ my: 2 }}
//       />
//       <Button
//         variant="contained"
//         onClick={handleReplace}
//         disabled={isReplacing || !data || data.length <= 1}
//       >
//         {isReplacing ? <CircularProgress size={24} /> : 'Replace URLs'}
//       </Button>
//       {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
//       {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
//     </Box>
//   );
// }

// export default ImageUrlReplacer;