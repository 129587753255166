import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  TextField,
  Typography,
  Box,
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const ItemSpecificsSettings = ({ onSave, onClose }) => {
  const [categories, setCategories] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [newColumn, setNewColumn] = useState('');
  const [newValue, setNewValue] = useState('');
  const [bulkInput, setBulkInput] = useState('');
  const [currentColumn, setCurrentColumn] = useState('');
  const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [expandedAccordions, setExpandedAccordions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editMode, setEditMode] = useState({ active: false, item: null, value: '' });
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [duplicateCategory, setDuplicateCategory] = useState('');

  const itemsPerPage = 50;

  useEffect(() => {
    const savedCategories = localStorage.getItem('itemSpecificsCategories');
    if (savedCategories) {
      try {
        const parsedCategories = JSON.parse(savedCategories);
        setCategories(parsedCategories);
      } catch (error) {
        console.error('Error parsing saved categories:', error);
        setCategories({});
      }
    }
  }, []);

  const handleAddCategory = () => {
    if (newCategory && !categories[newCategory]) {
      setCategories({ ...categories, [newCategory]: {} });
      setSelectedCategory(newCategory);
      setNewCategory('');
      setSnackbar({ open: true, message: `Category "${newCategory}" added successfully`, severity: 'success' });
    } else if (categories[newCategory]) {
      setSnackbar({ open: true, message: `Category "${newCategory}" already exists`, severity: 'warning' });
    } else {
      setSnackbar({ open: true, message: 'Please enter a valid category name', severity: 'error' });
    }
  };

  const handleSelectCategory = (event) => {
    setSelectedCategory(event.target.value);
    setExpandedAccordions([]);
    setPage(1);
  };

  const handleAddValue = (column) => {
    if (newValue) {
      setCategories((prevCategories) => {
        const lowerCaseValues = (prevCategories[selectedCategory][column] || []).map((v) => v.toLowerCase());
        if (!lowerCaseValues.includes(newValue.toLowerCase())) {
          return {
            ...prevCategories,
            [selectedCategory]: {
              ...prevCategories[selectedCategory],
              [column]: [...(prevCategories[selectedCategory][column] || []), newValue],
            },
          };
        } else {
          setSnackbar({ open: true, message: `Value "${newValue}" already exists in ${column}`, severity: 'warning' });
          return prevCategories;
        }
      });
      setSnackbar({ open: true, message: `Value "${newValue}" added to ${column}`, severity: 'success' });
      setNewValue('');
    } else {
      setSnackbar({ open: true, message: 'Please enter a value', severity: 'error' });
    }
  };

  const handleAddColumn = () => {
    const lowerCaseNewColumn = newColumn.toLowerCase();
    if (newColumn && !Object.keys(categories[selectedCategory] || {}).some((col) => col.toLowerCase() === lowerCaseNewColumn)) {
      setCategories({
        ...categories,
        [selectedCategory]: {
          ...categories[selectedCategory],
          [newColumn]: [],
        },
      });
      setNewColumn('');
      setSnackbar({ open: true, message: `Column "${newColumn}" added successfully`, severity: 'success' });
    } else if (Object.keys(categories[selectedCategory] || {}).some((col) => col.toLowerCase() === lowerCaseNewColumn)) {
      setSnackbar({ open: true, message: `Column "${newColumn}" already exists`, severity: 'warning' });
    } else {
      setSnackbar({ open: true, message: 'Please enter a valid column name', severity: 'error' });
    }
  };

  const handleDeleteValue = useCallback((column, index) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [selectedCategory]: {
        ...prevCategories[selectedCategory],
        [column]: prevCategories[selectedCategory][column].filter((_, i) => i !== index),
      },
    }));
    setSnackbar({ open: true, message: `Value deleted from ${column}`, severity: 'success' });
  }, [selectedCategory]);

  // 安全な handleDeleteValue 呼び出し用のラッパー関数
  const handleDeleteValueSafe = useCallback((column, index) => {
    if (!column || index === undefined || index === null) {
      console.error('Invalid column or index in handleDeleteValue');
      setSnackbar({ open: true, message: '削除対象の情報が不正です', severity: 'error' });
      return;
    }

    handleDeleteValue(column, index);
  }, [handleDeleteValue]);

  const handleDeleteColumn = (column) => {
    setCategories((prevCategories) => {
      const { [column]: deletedColumn, ...restColumns } = prevCategories[selectedCategory];
      return {
        ...prevCategories,
        [selectedCategory]: restColumns,
      };
    });
    setSnackbar({ open: true, message: `Column "${column}" deleted`, severity: 'success' });
  };

  const handleDeleteCategory = () => {
    const { [selectedCategory]: deletedCategory, ...restCategories } = categories;
    setCategories(restCategories);
    setSelectedCategory('');
    setSnackbar({ open: true, message: `Category "${selectedCategory}" deleted`, severity: 'success' });
  };

  const handleBulkInput = () => {
    const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
    setCategories((prevCategories) => ({
      ...prevCategories,
      [selectedCategory]: {
        ...prevCategories[selectedCategory],
        [currentColumn]: [...(prevCategories[selectedCategory][currentColumn] || []), ...values],
      },
    }));
    setBulkInput('');
    setIsBulkInputOpen(false);
    setSnackbar({ open: true, message: `${values.length} values added to ${currentColumn}`, severity: 'success' });
  };

  const handleFileUpload = (event) => {
    // Implement file upload functionality per category if needed
  };

  const handleSaveSettings = () => {
    localStorage.setItem('itemSpecificsCategories', JSON.stringify(categories));
    onSave(categories);
    setSnackbar({ open: true, message: 'All settings saved successfully', severity: 'success' });
    onClose();
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordions((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const handleMenuOpen = useCallback((event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  }, []);

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleEdit = () => {
    if (!selectedItem) {
      setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
      handleMenuClose();
      return;
    }

    const { column, index, value } = selectedItem;
    if (!column || index === undefined || index === null) {
      setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
      handleMenuClose();
      return;
    }

    setEditMode({ active: true, item: selectedItem, value: value });
    handleMenuClose();
  };

  const handleEditCancel = () => {
    setEditMode({ active: false, item: null, value: '' });
  };

  // 修正: handleEditSave は editMode.item を使用する
  const handleEditSave = useCallback(() => {
    if (!editMode.item) {
      console.error('editMode.item is null in handleEditSave');
      setSnackbar({ open: true, message: '編集対象が選択されていません', severity: 'error' });
      return;
    }

    const { column, index } = editMode.item;
    if (!column || index === undefined || index === null) {
      console.error('editMode.item does not have valid column or index');
      setSnackbar({ open: true, message: '編集対象の情報が不正です', severity: 'error' });
      return;
    }

    setCategories((prevCategories) => ({
      ...prevCategories,
      [selectedCategory]: {
        ...prevCategories[selectedCategory],
        [column]: prevCategories[selectedCategory][column].map((value, i) =>
          i === index ? editMode.value : value
        ),
      },
    }));
    setEditMode({ active: false, item: null, value: '' });
    setSnackbar({ open: true, message: '値が正常に更新されました', severity: 'success' });
  }, [editMode.item, editMode.value, selectedCategory]);

  // 修正: Duplicate Settingsを新しいカテゴリー名でコピーするように変更
  const handleDuplicateSettings = () => {
    if (!duplicateCategory) {
      setSnackbar({
        open: true,
        message: 'Please enter a valid target category name',
        severity: 'error',
      });
      return;
    }

    if (categories[duplicateCategory]) {
      setSnackbar({
        open: true,
        message: `Category "${duplicateCategory}" already exists`,
        severity: 'warning',
      });
      return;
    }

    // 深いコピーを実施
    const newCategoryData = JSON.parse(JSON.stringify(categories[selectedCategory]));

    setCategories((prevCategories) => ({
      ...prevCategories,
      [duplicateCategory]: newCategoryData,
    }));
    setSnackbar({
      open: true,
      message: `Settings duplicated to "${duplicateCategory}"`,
      severity: 'success',
    });
    setDuplicateDialogOpen(false);
    setDuplicateCategory('');
  };

  const renderColumnItems = useCallback(
    (column, items) => {
      const safeItems = Array.isArray(items) ? items : [];

      const filteredItems = safeItems.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase())
      );
      const start = (page - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      const paginatedItems = filteredItems.slice(start, end);

      return (
        <>
          <List>
            {paginatedItems.map((item, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="more"
                    onClick={(e) =>
                      handleMenuOpen(e, { column, index, value: item })
                    }
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={
                    editMode.active &&
                    editMode.item &&
                    editMode.item.column === column &&
                    editMode.item.index === index ? (
                      <TextField
                        value={editMode.value}
                        onChange={(e) =>
                          setEditMode({ ...editMode, value: e.target.value })
                        }
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleEditSave();
                          }
                        }}
                        autoFocus
                        fullWidth
                      />
                    ) : (
                      item
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={page}
            onChange={(event, value) => setPage(value)}
            sx={{ mt: 2 }}
          />
        </>
      );
    },
    [search, page, itemsPerPage, editMode, handleMenuOpen, handleEditSave]
  );

  return (
    <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h5" gutterBottom>
        Item Specifics Settings
      </Typography>

      {/* Category Selection */}
      <Grid container spacing={2} alignItems="center" mb={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Select Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleSelectCategory}
              label="Select Category"
            >
              {Object.keys(categories).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="New Category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleAddCategory}
            startIcon={<AddIcon />}
          >
            Add Category
          </Button>
        </Grid>
        {selectedCategory && (
          <>
            <Grid item xs={6} sm={3}>
              <Button
                fullWidth
                variant="outlined"
                color="error"
                onClick={handleDeleteCategory}
                startIcon={<DeleteIcon />}
              >
                Delete Category
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setDuplicateDialogOpen(true)}
                startIcon={<FileCopyIcon />}
              >
                Duplicate Settings
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      {selectedCategory && (
        <>
          <Grid container spacing={2} alignItems="center" mb={3}>
            <Grid item xs={9}>
              <TextField
                fullWidth
                label="New Column (e.g., C:Brand)"
                value={newColumn}
                onChange={(e) => setNewColumn(e.target.value)}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleAddColumn}
                startIcon={<AddIcon />}
              >
                Add Column
              </Button>
            </Grid>
          </Grid>

          <TextField
            fullWidth
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
            {Object.entries(categories[selectedCategory] || {}).map(
              ([column, values]) => (
                <Accordion
                  key={column}
                  expanded={expandedAccordions.includes(column)}
                  onChange={handleAccordionChange(column)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{column}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <TextField
                        label="New Value"
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        variant="outlined"
                        size="small"
                      />
                      <Button
                        variant="contained"
                        onClick={() => handleAddValue(column)}
                        startIcon={<AddIcon />}
                      >
                        Add Value
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setCurrentColumn(column);
                          setIsBulkInputOpen(true);
                        }}
                      >
                        Bulk Input
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteColumn(column)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete Column
                      </Button>
                    </Box>
                    {renderColumnItems(column, values)}
                  </AccordionDetails>
                </Accordion>
              )
            )}
          </Box>
        </>
      )}

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveSettings}
          startIcon={<SaveIcon />}
        >
          Save All Settings
        </Button>
        {/* Implement CSV Import per category if needed */}
        {/* <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
        >
          Import CSV
          <input
            type="file"
            hidden
            accept=".csv"
            onChange={handleFileUpload}
          />
        </Button> */}
      </Box>

      {/* Bulk Input Dialog */}
      <Dialog
        open={isBulkInputOpen}
        onClose={() => setIsBulkInputOpen(false)}
        aria-labelledby="bulk-input-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="bulk-input-dialog-title">
          Bulk Input for {currentColumn}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            rows={10}
            fullWidth
            variant="outlined"
            value={bulkInput}
            onChange={(e) => setBulkInput(e.target.value)}
            placeholder="Enter values (one per line)"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleBulkInput} color="primary" variant="contained">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Duplicate Settings Dialog */}
      <Dialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        aria-labelledby="duplicate-dialog-title"
      >
        <DialogTitle id="duplicate-dialog-title">
          Duplicate Settings to Another Category
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Target Category Name"
            value={duplicateCategory}
            onChange={(e) => setDuplicateCategory(e.target.value)}
            variant="outlined"
            size="small"
            sx={{ mt: 2 }}
            placeholder="Enter new category name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDuplicateSettings}
            color="primary"
            variant="contained"
          >
            Duplicate
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Context Menu */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleEdit}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (selectedItem) {
              handleDeleteValueSafe(selectedItem.column, selectedItem.index);
            }
            handleMenuClose();
          }}
        >
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>

      {/* Edit Dialog */}
      <Dialog
        open={editMode.active}
        onClose={handleEditCancel}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle id="edit-dialog-title">Edit Value</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Value"
            type="text"
            fullWidth
            value={editMode.value}
            onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ItemSpecificsSettings;






// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   Grid,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Pagination,
//   InputAdornment,
//   Menu,
//   MenuItem,
//   Select,
//   FormControl,
//   InputLabel,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import SaveIcon from '@mui/icons-material/Save';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SearchIcon from '@mui/icons-material/Search';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';
// import FileCopyIcon from '@mui/icons-material/FileCopy';

// const ItemSpecificsSettings = ({ onSave, onClose }) => {
//   const [categories, setCategories] = useState({});
//   const [newCategory, setNewCategory] = useState('');
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [newColumn, setNewColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [bulkInput, setBulkInput] = useState('');
//   const [currentColumn, setCurrentColumn] = useState('');
//   const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [search, setSearch] = useState('');
//   const [page, setPage] = useState(1);
//   const [expandedAccordions, setExpandedAccordions] = useState([]);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [editMode, setEditMode] = useState({ active: false, item: null, value: '' });
//   const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
//   const [duplicateCategory, setDuplicateCategory] = useState('');

//   const itemsPerPage = 50;

//   useEffect(() => {
//     const savedCategories = localStorage.getItem('itemSpecificsCategories');
//     if (savedCategories) {
//       try {
//         const parsedCategories = JSON.parse(savedCategories);
//         setCategories(parsedCategories);
//       } catch (error) {
//         console.error('Error parsing saved categories:', error);
//         setCategories({});
//       }
//     }
//   }, []);

//   const handleAddCategory = () => {
//     if (newCategory && !categories[newCategory]) {
//       setCategories({ ...categories, [newCategory]: {} });
//       setSelectedCategory(newCategory);
//       setNewCategory('');
//       setSnackbar({ open: true, message: `Category "${newCategory}" added successfully`, severity: 'success' });
//     } else if (categories[newCategory]) {
//       setSnackbar({ open: true, message: `Category "${newCategory}" already exists`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: 'Please enter a valid category name', severity: 'error' });
//     }
//   };

//   const handleSelectCategory = (event) => {
//     setSelectedCategory(event.target.value);
//     setExpandedAccordions([]);
//     setPage(1);
//   };

//   const handleAddValue = (column) => {
//     if (newValue) {
//       setCategories((prevCategories) => {
//         const lowerCaseValues = (prevCategories[selectedCategory][column] || []).map((v) => v.toLowerCase());
//         if (!lowerCaseValues.includes(newValue.toLowerCase())) {
//           return {
//             ...prevCategories,
//             [selectedCategory]: {
//               ...prevCategories[selectedCategory],
//               [column]: [...(prevCategories[selectedCategory][column] || []), newValue],
//             },
//           };
//         } else {
//           setSnackbar({ open: true, message: `Value "${newValue}" already exists in ${column}`, severity: 'warning' });
//           return prevCategories;
//         }
//       });
//       setSnackbar({ open: true, message: `Value "${newValue}" added to ${column}`, severity: 'success' });
//       setNewValue('');
//     } else {
//       setSnackbar({ open: true, message: 'Please enter a value', severity: 'error' });
//     }
//   };

//   const handleAddColumn = () => {
//     const lowerCaseNewColumn = newColumn.toLowerCase();
//     if (newColumn && !Object.keys(categories[selectedCategory] || {}).some((col) => col.toLowerCase() === lowerCaseNewColumn)) {
//       setCategories({
//         ...categories,
//         [selectedCategory]: {
//           ...categories[selectedCategory],
//           [newColumn]: [],
//         },
//       });
//       setNewColumn('');
//       setSnackbar({ open: true, message: `Column "${newColumn}" added successfully`, severity: 'success' });
//     } else if (Object.keys(categories[selectedCategory] || {}).some((col) => col.toLowerCase() === lowerCaseNewColumn)) {
//       setSnackbar({ open: true, message: `Column "${newColumn}" already exists`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: 'Please enter a valid column name', severity: 'error' });
//     }
//   };

//   const handleDeleteValue = useCallback((column, index) => {
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [selectedCategory]: {
//         ...prevCategories[selectedCategory],
//         [column]: prevCategories[selectedCategory][column].filter((_, i) => i !== index),
//       },
//     }));
//     setSnackbar({ open: true, message: `Value deleted from ${column}`, severity: 'success' });
//   }, [selectedCategory]);

//   const handleDeleteColumn = (column) => {
//     setCategories((prevCategories) => {
//       const { [column]: deletedColumn, ...restColumns } = prevCategories[selectedCategory];
//       return {
//         ...prevCategories,
//         [selectedCategory]: restColumns,
//       };
//     });
//     setSnackbar({ open: true, message: `Column "${column}" deleted`, severity: 'success' });
//   };

//   const handleDeleteCategory = () => {
//     const { [selectedCategory]: deletedCategory, ...restCategories } = categories;
//     setCategories(restCategories);
//     setSelectedCategory('');
//     setSnackbar({ open: true, message: `Category "${selectedCategory}" deleted`, severity: 'success' });
//   };

//   const handleBulkInput = () => {
//     const values = bulkInput.split('\n').map((v) => v.trim()).filter((v) => v);
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [selectedCategory]: {
//         ...prevCategories[selectedCategory],
//         [currentColumn]: [...(prevCategories[selectedCategory][currentColumn] || []), ...values],
//       },
//     }));
//     setBulkInput('');
//     setIsBulkInputOpen(false);
//     setSnackbar({ open: true, message: `${values.length} values added to ${currentColumn}`, severity: 'success' });
//   };

//   const handleFileUpload = (event) => {
//     // Implement file upload functionality per category if needed
//   };

//   const handleSaveSettings = () => {
//     localStorage.setItem('itemSpecificsCategories', JSON.stringify(categories));
//     onSave(categories);
//     setSnackbar({ open: true, message: 'All settings saved successfully', severity: 'success' });
//     onClose();
//   };

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpandedAccordions((prev) =>
//       isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
//     );
//   };

//   const handleMenuOpen = useCallback((event, item) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedItem(item);
//   }, []);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedItem(null);
//   };

//   const handleEdit = () => {
//     setEditMode({ active: true, item: selectedItem, value: selectedItem.value });
//     handleMenuClose();
//   };

//   const handleEditCancel = () => {
//     setEditMode({ active: false, item: null, value: '' });
//   };

//   const handleEditSave = useCallback(() => {
//     const { column, index } = selectedItem;
//     setCategories((prevCategories) => ({
//       ...prevCategories,
//       [selectedCategory]: {
//         ...prevCategories[selectedCategory],
//         [column]: prevCategories[selectedCategory][column].map((value, i) =>
//           i === index ? editMode.value : value
//         ),
//       },
//     }));
//     setEditMode({ active: false, item: null, value: '' });
//     setSnackbar({ open: true, message: 'Value updated successfully', severity: 'success' });
//   }, [selectedItem, editMode.value, selectedCategory]);

//   const handleDuplicateSettings = () => {
//     if (duplicateCategory && categories[duplicateCategory]) {
//       setCategories((prevCategories) => ({
//         ...prevCategories,
//         [duplicateCategory]: { ...prevCategories[selectedCategory] },
//       }));
//       setSnackbar({
//         open: true,
//         message: `Settings duplicated to "${duplicateCategory}"`,
//         severity: 'success',
//       });
//       setDuplicateDialogOpen(false);
//       setDuplicateCategory('');
//     } else {
//       setSnackbar({
//         open: true,
//         message: 'Please select a valid target category',
//         severity: 'error',
//       });
//     }
//   };

//   const renderColumnItems = useCallback(
//     (column, items) => {
//       const safeItems = Array.isArray(items) ? items : [];

//       const filteredItems = safeItems.filter((item) =>
//         item.toLowerCase().includes(search.toLowerCase())
//       );
//       const start = (page - 1) * itemsPerPage;
//       const end = start + itemsPerPage;
//       const paginatedItems = filteredItems.slice(start, end);

//       return (
//         <>
//           <List>
//             {paginatedItems.map((item, index) => (
//               <ListItem
//                 key={index}
//                 secondaryAction={
//                   <IconButton
//                     edge="end"
//                     aria-label="more"
//                     onClick={(e) =>
//                       handleMenuOpen(e, { column, index, value: item })
//                     }
//                   >
//                     <MoreVertIcon />
//                   </IconButton>
//                 }
//               >
//                 <ListItemText
//                   primary={
//                     editMode.active &&
//                     editMode.item &&
//                     editMode.item.column === column &&
//                     editMode.item.index === index ? (
//                       <TextField
//                         value={editMode.value}
//                         onChange={(e) =>
//                           setEditMode({ ...editMode, value: e.target.value })
//                         }
//                         onKeyPress={(e) => {
//                           if (e.key === 'Enter') {
//                             handleEditSave();
//                           }
//                         }}
//                         autoFocus
//                         fullWidth
//                       />
//                     ) : (
//                       item
//                     )
//                   }
//                 />
//               </ListItem>
//             ))}
//           </List>
//           <Pagination
//             count={Math.ceil(filteredItems.length / itemsPerPage)}
//             page={page}
//             onChange={(event, value) => setPage(value)}
//             sx={{ mt: 2 }}
//           />
//         </>
//       );
//     },
//     [search, page, itemsPerPage, editMode, handleMenuOpen, handleEditSave]
//   );

//   return (
//     <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
//       <Typography variant="h5" gutterBottom>
//         Item Specifics Settings
//       </Typography>

//       {/* Category Selection */}
//       <Grid container spacing={2} alignItems="center" mb={3}>
//         <Grid item xs={12} sm={6}>
//           <FormControl fullWidth variant="outlined" size="small">
//             <InputLabel>Select Category</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={handleSelectCategory}
//               label="Select Category"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <TextField
//             fullWidth
//             label="New Category"
//             value={newCategory}
//             onChange={(e) => setNewCategory(e.target.value)}
//             variant="outlined"
//             size="small"
//           />
//         </Grid>
//         <Grid item xs={6} sm={3}>
//           <Button
//             fullWidth
//             variant="contained"
//             onClick={handleAddCategory}
//             startIcon={<AddIcon />}
//           >
//             Add Category
//           </Button>
//         </Grid>
//         {selectedCategory && (
//           <>
//             <Grid item xs={6} sm={3}>
//               <Button
//                 fullWidth
//                 variant="outlined"
//                 color="error"
//                 onClick={handleDeleteCategory}
//                 startIcon={<DeleteIcon />}
//               >
//                 Delete Category
//               </Button>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Button
//                 fullWidth
//                 variant="outlined"
//                 onClick={() => setDuplicateDialogOpen(true)}
//                 startIcon={<FileCopyIcon />}
//               >
//                 Duplicate Settings
//               </Button>
//             </Grid>
//           </>
//         )}
//       </Grid>

//       {selectedCategory && (
//         <>
//           <Grid container spacing={2} alignItems="center" mb={3}>
//             <Grid item xs={9}>
//               <TextField
//                 fullWidth
//                 label="New Column (e.g., C:Brand)"
//                 value={newColumn}
//                 onChange={(e) => setNewColumn(e.target.value)}
//                 variant="outlined"
//                 size="small"
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <Button
//                 fullWidth
//                 variant="contained"
//                 onClick={handleAddColumn}
//                 startIcon={<AddIcon />}
//               >
//                 Add Column
//               </Button>
//             </Grid>
//           </Grid>

//           <TextField
//             fullWidth
//             label="Search"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             variant="outlined"
//             size="small"
//             sx={{ mb: 2 }}
//             InputProps={{
//               startAdornment: (
//                 <InputAdornment position="start">
//                   <SearchIcon />
//                 </InputAdornment>
//               ),
//             }}
//           />

//           <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
//             {Object.entries(categories[selectedCategory] || {}).map(
//               ([column, values]) => (
//                 <Accordion
//                   key={column}
//                   expanded={expandedAccordions.includes(column)}
//                   onChange={handleAccordionChange(column)}
//                 >
//                   <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//                     <Typography>{column}</Typography>
//                   </AccordionSummary>
//                   <AccordionDetails>
//                     <Box
//                       display="flex"
//                       justifyContent="space-between"
//                       alignItems="center"
//                       mb={2}
//                     >
//                       <TextField
//                         label="New Value"
//                         value={newValue}
//                         onChange={(e) => setNewValue(e.target.value)}
//                         variant="outlined"
//                         size="small"
//                       />
//                       <Button
//                         variant="contained"
//                         onClick={() => handleAddValue(column)}
//                         startIcon={<AddIcon />}
//                       >
//                         Add Value
//                       </Button>
//                       <Button
//                         variant="outlined"
//                         onClick={() => {
//                           setCurrentColumn(column);
//                           setIsBulkInputOpen(true);
//                         }}
//                       >
//                         Bulk Input
//                       </Button>
//                       <Button
//                         variant="outlined"
//                         color="error"
//                         onClick={() => handleDeleteColumn(column)}
//                         startIcon={<DeleteIcon />}
//                       >
//                         Delete Column
//                       </Button>
//                     </Box>
//                     {renderColumnItems(column, values)}
//                   </AccordionDetails>
//                 </Accordion>
//               )
//             )}
//           </Box>
//         </>
//       )}

//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSaveSettings}
//           startIcon={<SaveIcon />}
//         >
//           Save All Settings
//         </Button>
//         {/* Implement CSV Import per category if needed */}
//         {/* <Button
//           variant="contained"
//           component="label"
//           startIcon={<CloudUploadIcon />}
//         >
//           Import CSV
//           <input
//             type="file"
//             hidden
//             accept=".csv"
//             onChange={handleFileUpload}
//           />
//         </Button> */}
//       </Box>

//       {/* Bulk Input Dialog */}
//       <Dialog
//         open={isBulkInputOpen}
//         onClose={() => setIsBulkInputOpen(false)}
//         aria-labelledby="bulk-input-dialog-title"
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle id="bulk-input-dialog-title">
//           Bulk Input for {currentColumn}
//         </DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             multiline
//             rows={10}
//             fullWidth
//             variant="outlined"
//             value={bulkInput}
//             onChange={(e) => setBulkInput(e.target.value)}
//             placeholder="Enter values (one per line)"
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleBulkInput} color="primary" variant="contained">
//             Add
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Duplicate Settings Dialog */}
//       <Dialog
//         open={duplicateDialogOpen}
//         onClose={() => setDuplicateDialogOpen(false)}
//         aria-labelledby="duplicate-dialog-title"
//       >
//         <DialogTitle id="duplicate-dialog-title">
//           Duplicate Settings to Another Category
//         </DialogTitle>
//         <DialogContent>
//           <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2 }}>
//             <InputLabel>Select Target Category</InputLabel>
//             <Select
//               value={duplicateCategory}
//               onChange={(e) => setDuplicateCategory(e.target.value)}
//               label="Select Target Category"
//             >
//               {Object.keys(categories).map((category) => (
//                 category !== selectedCategory && (
//                   <MenuItem key={category} value={category}>
//                     {category}
//                   </MenuItem>
//                 )
//               ))}
//             </Select>
//           </FormControl>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setDuplicateDialogOpen(false)} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={handleDuplicateSettings}
//             color="primary"
//             variant="contained"
//           >
//             Duplicate
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for messages */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       {/* Context Menu */}
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
//         <MenuItem onClick={handleEdit}>
//           <EditIcon fontSize="small" sx={{ mr: 1 }} />
//           Edit
//         </MenuItem>
//         <MenuItem
//           onClick={() => {
//             if (selectedItem) {
//               handleDeleteValue(selectedItem.column, selectedItem.index);
//             }
//             handleMenuClose();
//           }}
//         >
//           <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
//           Delete
//         </MenuItem>
//       </Menu>

//       {/* Edit Dialog */}
//       <Dialog
//         open={editMode.active}
//         onClose={handleEditCancel}
//         aria-labelledby="edit-dialog-title"
//       >
//         <DialogTitle id="edit-dialog-title">Edit Value</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Value"
//             type="text"
//             fullWidth
//             value={editMode.value}
//             onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleEditCancel} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleEditSave} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default ItemSpecificsSettings;




// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   Grid,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   IconButton,
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Pagination,
//   InputAdornment,
//   Menu,
//   MenuItem,
// } from '@mui/material';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
// import SaveIcon from '@mui/icons-material/Save';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SearchIcon from '@mui/icons-material/Search';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import EditIcon from '@mui/icons-material/Edit';

// const ItemSpecificsSettings = ({ onSave, onClose }) => {
//   const [columns, setColumns] = useState({});
//   const [newColumn, setNewColumn] = useState('');
//   const [newValue, setNewValue] = useState('');
//   const [bulkInput, setBulkInput] = useState('');
//   const [currentColumn, setCurrentColumn] = useState('');
//   const [isBulkInputOpen, setIsBulkInputOpen] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [search, setSearch] = useState('');
//   const [page, setPage] = useState(1);
//   const [expandedAccordions, setExpandedAccordions] = useState([]);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [editMode, setEditMode] = useState({ active: false, item: null, value: '' });

//   const itemsPerPage = 50;

//   useEffect(() => {
//     const savedColumns = localStorage.getItem('itemSpecificsColumns');
//     if (savedColumns) {
//       try {
//         const parsedColumns = JSON.parse(savedColumns);
//         const validatedColumns = Object.entries(parsedColumns).reduce((acc, [key, value]) => {
//           acc[key] = Array.isArray(value) ? value : [];
//           return acc;
//         }, {});
//         setColumns(validatedColumns);
//       } catch (error) {
//         console.error('Error parsing saved columns:', error);
//         setColumns({});
//       }
//     }
//   }, []);

//   const handleAddValue = (column) => {
//     if (newValue) {
//       setColumns(prevColumns => {
//         const lowerCaseValues = prevColumns[column].map(v => v.toLowerCase());
//         if (!lowerCaseValues.includes(newValue.toLowerCase())) {
//           return {
//             ...prevColumns,
//             [column]: [...prevColumns[column], newValue]
//           };
//         } else {
//           setSnackbar({ open: true, message: `Value "${newValue}" already exists in ${column}`, severity: 'warning' });
//           return prevColumns;
//         }
//       });
//       setNewValue('');
//       setSnackbar({ open: true, message: `Value "${newValue}" added to ${column}`, severity: 'success' });
//     } else {
//       setSnackbar({ open: true, message: 'Please enter a value', severity: 'error' });
//     }
//   };
  
//   const handleAddColumn = () => {
//     const lowerCaseNewColumn = newColumn.toLowerCase();
//     if (newColumn && !Object.keys(columns).some(col => col.toLowerCase() === lowerCaseNewColumn)) {
//       setColumns({ ...columns, [newColumn]: [] });
//       setNewColumn('');
//       setSnackbar({ open: true, message: `Column "${newColumn}" added successfully`, severity: 'success' });
//     } else if (Object.keys(columns).some(col => col.toLowerCase() === lowerCaseNewColumn)) {
//       setSnackbar({ open: true, message: `Column "${newColumn}" already exists`, severity: 'warning' });
//     } else {
//       setSnackbar({ open: true, message: 'Please enter a valid column name', severity: 'error' });
//     }
//   };

//   const handleDeleteValue = useCallback((column, index) => {
//     setColumns(prevColumns => ({
//       ...prevColumns,
//       [column]: prevColumns[column].filter((_, i) => i !== index)
//     }));
//     setSnackbar({ open: true, message: `Value deleted from ${column}`, severity: 'success' });
//   }, []);

//   const handleDeleteColumn = (column) => {
//     const { [column]: deletedColumn, ...restColumns } = columns;
//     setColumns(restColumns);
//     setSnackbar({ open: true, message: `Column "${column}" deleted`, severity: 'success' });
//   };

//   const handleBulkInput = () => {
//     const values = bulkInput.split('\n').map(v => v.trim()).filter(v => v);
//     setColumns(prevColumns => ({
//       ...prevColumns,
//       [currentColumn]: [...prevColumns[currentColumn], ...values]
//     }));
//     setBulkInput('');
//     setIsBulkInputOpen(false);
//     setSnackbar({ open: true, message: `${values.length} values added to ${currentColumn}`, severity: 'success' });
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const content = e.target.result;
//       const lines = content.split('\n');
//       const header = lines[0].split(',');
//       const data = lines.slice(1).map(line => line.split(','));
      
//       const newColumns = {};
//       header.forEach((columnName, index) => {
//         newColumns[columnName.trim()] = data.map(row => row[index]?.trim()).filter(Boolean);
//       });

//       setColumns(prevColumns => ({
//         ...prevColumns,
//         ...newColumns
//       }));
//       setSnackbar({ open: true, message: 'CSV imported successfully', severity: 'success' });
//     };
//     reader.readAsText(file);
//   };

//   const handleSaveSettings = () => {
//     localStorage.setItem('itemSpecificsColumns', JSON.stringify(columns));
//     onSave(columns);
//     setSnackbar({ open: true, message: 'All settings saved successfully', severity: 'success' });
//     onClose();
//   };

//   const handleAccordionChange = (panel) => (event, isExpanded) => {
//     setExpandedAccordions(prev => 
//       isExpanded 
//         ? [...prev, panel] 
//         : prev.filter(p => p !== panel)
//     );
//   };

//   const handleMenuOpen = useCallback((event, item) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedItem(item);
//   }, []);

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedItem(null);
//   };

//   const handleEdit = () => {
//     setEditMode({ active: true, item: selectedItem, value: selectedItem.value });
//     handleMenuClose();
//   };

//   const handleEditCancel = () => {
//     setEditMode({ active: false, item: null, value: '' });
//   };

//   const handleEditSave = useCallback(() => {
//     const { column, index } = selectedItem;
//     setColumns(prevColumns => ({
//       ...prevColumns,
//       [column]: prevColumns[column].map((value, i) => i === index ? editMode.value : value)
//     }));
//     setEditMode({ active: false, item: null, value: '' });
//     setSnackbar({ open: true, message: 'Value updated successfully', severity: 'success' });
//   }, [selectedItem, editMode.value]);

//   const renderColumnItems = useCallback((column, items) => {
//     const safeItems = Array.isArray(items) ? items : [];
    
//     const filteredItems = safeItems.filter(item => 
//       item.toLowerCase().includes(search.toLowerCase())
//     );
//     const start = (page - 1) * itemsPerPage;
//     const end = start + itemsPerPage;
//     const paginatedItems = filteredItems.slice(start, end);

//     return (
//       <>
//         <List>
//           {paginatedItems.map((item, index) => (
//             <ListItem
//               key={index}
//               secondaryAction={
//                 <IconButton edge="end" aria-label="more"
//                   onClick={(e) => handleMenuOpen(e, { column, index, value: item })}
//                 >
//                   <MoreVertIcon />
//                 </IconButton>
//               }
//             >
//               <ListItemText 
//                 primary={
//                   editMode.active && editMode.item && 
//                   editMode.item.column === column && 
//                   editMode.item.index === index ? (
//                     <TextField
//                       value={editMode.value}
//                       onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
//                       onKeyPress={(e) => {
//                         if (e.key === 'Enter') {
//                           handleEditSave();
//                         }
//                       }}
//                       autoFocus
//                       fullWidth
//                     />
//                   ) : (
//                     item
//                   )
//                 }
//               />
//             </ListItem>
//           ))}
//         </List>
//         <Pagination
//           count={Math.ceil(filteredItems.length / itemsPerPage)}
//           page={page}
//           onChange={(event, value) => setPage(value)}
//           sx={{ mt: 2 }}
//         />
//       </>
//     );
//   }, [search, page, itemsPerPage, editMode, handleMenuOpen, handleEditSave]);

//   return (
//     <Box sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
//       <Typography variant="h5" gutterBottom>Item Specifics Settings</Typography>
      
//       <Grid container spacing={2} alignItems="center" mb={3}>
//         <Grid item xs={9}>
//           <TextField
//             fullWidth
//             label="New Column (e.g., C:Brand)"
//             value={newColumn}
//             onChange={(e) => setNewColumn(e.target.value)}
//             variant="outlined"
//             size="small"
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <Button
//             fullWidth
//             variant="contained"
//             onClick={handleAddColumn}
//             startIcon={<AddIcon />}
//           >
//             Add
//           </Button>
//         </Grid>
//       </Grid>

//       <TextField
//         fullWidth
//         label="Search"
//         value={search}
//         onChange={(e) => setSearch(e.target.value)}
//         variant="outlined"
//         size="small"
//         sx={{ mb: 2 }}
//         InputProps={{
//           startAdornment: (
//             <InputAdornment position="start">
//               <SearchIcon />
//             </InputAdornment>
//           ),
//         }}
//       />

//       <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
//         {Object.entries(columns).map(([column, values]) => (
//           <Accordion 
//             key={column}
//             expanded={expandedAccordions.includes(column)}
//             onChange={handleAccordionChange(column)}
//           >
//             <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//               <Typography>{column}</Typography>
//             </AccordionSummary>
//             <AccordionDetails>
//               <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                 <TextField
//                   label="New Value"
//                   value={newValue}
//                   onChange={(e) => setNewValue(e.target.value)}
//                   variant="outlined"
//                   size="small"
//                 />
//                 <Button
//                   variant="contained"
//                   onClick={() => handleAddValue(column)}
//                   startIcon={<AddIcon />}
//                 >
//                   Add Value
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   onClick={() => {
//                     setCurrentColumn(column);
//                     setIsBulkInputOpen(true);
//                   }}
//                 >
//                   Bulk Input
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   color="error"
//                   onClick={() => handleDeleteColumn(column)}
//                   startIcon={<DeleteIcon />}
//                 >
//                   Delete Column
//                 </Button>
//               </Box>
//               {renderColumnItems(column, values)}
//             </AccordionDetails>
//           </Accordion>
//         ))}
//       </Box>

//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleSaveSettings}
//           startIcon={<SaveIcon />}
//         >
//           Save All Settings
//         </Button>
//         <Button
//           variant="contained"
//           component="label"
//           startIcon={<CloudUploadIcon />}
//         >
//           Import CSV
//           <input
//             type="file"
//             hidden
//             accept=".csv"
//             onChange={handleFileUpload}
//           />
//         </Button>
//       </Box>

//       <Dialog
//         open={isBulkInputOpen}
//         onClose={() => setIsBulkInputOpen(false)}
//         aria-labelledby="bulk-input-dialog-title"
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle id="bulk-input-dialog-title">Bulk Input for {currentColumn}</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             multiline
//             rows={10}
//             fullWidth
//             variant="outlined"
//             value={bulkInput}
//             onChange={(e) => setBulkInput(e.target.value)}
//             placeholder="Enter values (one per line)"
//             sx={{ mt: 2 }}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setIsBulkInputOpen(false)} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleBulkInput} color="primary" variant="contained">
//             Add
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert
//           onClose={() => setSnackbar({ ...snackbar, open: false })}
//           severity={snackbar.severity}
//           variant="filled"
//           sx={{ width: '100%' }}
//         >
//           {snackbar.message}
//         </Alert>
//       </Snackbar>

//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         <MenuItem onClick={handleEdit}>
//           <EditIcon fontSize="small" sx={{ mr: 1 }} />
//           Edit
//         </MenuItem>
//         <MenuItem onClick={() => {
//           if (selectedItem) {
//             handleDeleteValue(selectedItem.column, selectedItem.index);
//           }
//           handleMenuClose();
//         }}>
//           <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
//           Delete
//         </MenuItem>
//       </Menu>

//       <Dialog
//         open={editMode.active}
//         onClose={handleEditCancel}
//         aria-labelledby="edit-dialog-title"
//       >
//         <DialogTitle id="edit-dialog-title">Edit Value</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Value"
//             type="text"
//             fullWidth
//             value={editMode.value}
//             onChange={(e) => setEditMode({ ...editMode, value: e.target.value })}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleEditCancel} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleEditSave} color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default ItemSpecificsSettings;