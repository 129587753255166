import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField,
  Typography,
  Box
} from '@mui/material';

function PreviewDialog({ open, handleClose, previewData, onConfirm }) {
  const [editedData, setEditedData] = useState([]);

  useEffect(() => {
    if (Array.isArray(previewData)) {
      setEditedData(previewData);
    } else {
      setEditedData([]);
    }
  }, [previewData]);

  const handleTitleChange = (index, newTitle) => {
    const newData = [...editedData];
    newData[index] = { ...newData[index], generatedTitle: newTitle };
    setEditedData(newData);
  };

  const handleConfirm = () => {
    onConfirm(editedData);
    handleClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      maxWidth="xl" 
      fullWidth
      PaperProps={{
        style: {
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle>生成されたタイトルのプレビュー</DialogTitle>
      <DialogContent style={{ flex: 1, overflow: 'auto' }}>
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '20%' }}>元のタイトル</TableCell>
                <TableCell style={{ width: '40%' }}>日本語説明</TableCell>
                <TableCell style={{ width: '40%' }}>生成されたタイトル</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(editedData) && editedData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="body2" style={{ wordBreak: 'break-word' }}>
                      {item.originalTitle}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                      {item.jpDesc}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      value={item.generatedTitle || ''}
                      onChange={(e) => handleTitleChange(index, e.target.value)}
                      variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>キャンセル</Button>
        <Button onClick={handleConfirm} color="primary">
          確認して適用
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewDialog;


// import React, { useState, useEffect } from 'react';
// import { 
//   Dialog, 
//   DialogTitle, 
//   DialogContent, 
//   DialogActions, 
//   Button, 
//   Table, 
//   TableBody, 
//   TableCell, 
//   TableContainer, 
//   TableHead, 
//   TableRow, 
//   Paper, 
//   TextField
// } from '@mui/material';

// function PreviewDialog({ open, handleClose, previewData, onConfirm }) {
//   const [editedData, setEditedData] = useState([]);

//   useEffect(() => {
//     setEditedData(previewData);
//   }, [previewData]);

//   const handleTitleChange = (index, newTitle) => {
//     const newData = [...editedData];
//     newData[index] = { ...newData[index], generatedTitle: newTitle };
//     setEditedData(newData);
//   };

//   const handleConfirm = () => {
//     onConfirm(editedData);
//     handleClose();
//   };

//   return (
//     <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
//       <DialogTitle>生成されたタイトルのプレビュー</DialogTitle>
//       <DialogContent>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>元のタイトル</TableCell>
//                 <TableCell>日本語説明</TableCell>
//                 <TableCell>生成されたタイトル</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {editedData.map((item, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{item.originalTitle}</TableCell>
//                   <TableCell>{item.jpDesc}</TableCell>
//                   <TableCell>
//                     <TextField
//                       fullWidth
//                       value={item.generatedTitle || ''}
//                       onChange={(e) => handleTitleChange(index, e.target.value)}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>キャンセル</Button>
//         <Button onClick={handleConfirm} color="primary">
//           確認して適用
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }

// export default PreviewDialog;