import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Box, FormControlLabel, Checkbox, Typography, Alert, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CryptoJS from 'crypto-js';

function APIKeyInput({ onApiKeySet, clearApiKey }) {
  const [apiKey, setApiKey] = useState('');
  const [rememberKey, setRememberKey] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [autoLogout, setAutoLogout] = useState(true);
  const [isKeyCleared, setIsKeyCleared] = useState(false);
  const [lastUsedKeyHint, setLastUsedKeyHint] = useState('');

  const encryptKey = useCallback((key) => {
    const salt = CryptoJS.lib.WordArray.random(128/8);
    const iv = CryptoJS.lib.WordArray.random(128/8);
    const encrypted = CryptoJS.AES.encrypt(key, salt.toString(), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return salt.toString() + iv.toString() + encrypted.toString();
  }, []);

  const decryptKey = useCallback((encryptedKey) => {
    const salt = CryptoJS.enc.Hex.parse(encryptedKey.substr(0, 32));
    const iv = CryptoJS.enc.Hex.parse(encryptedKey.substr(32, 32));
    const encrypted = encryptedKey.substring(64);
    const key = CryptoJS.PBKDF2(salt.toString(), salt, {
      keySize: 256/32,
      iterations: 1000
    });
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
      iv: iv, 
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }, []);

  const clearStoredKey = useCallback(() => {
    sessionStorage.removeItem('openai_api_key');
    localStorage.removeItem('openai_api_key');
    setApiKey('');
    onApiKeySet('');
    setIsKeyCleared(true);
    setLastUsedKeyHint(maskApiKey(apiKey));
    if (clearApiKey) clearApiKey();
  }, [apiKey, onApiKeySet, clearApiKey]);

  useEffect(() => {
    const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
    if (storedApiKey) {
      try {
        const decryptedKey = decryptKey(storedApiKey);
        setApiKey(decryptedKey);
        setRememberKey(!!localStorage.getItem('openai_api_key'));
        onApiKeySet(decryptedKey);
        setLastUsedKeyHint(maskApiKey(decryptedKey));
      } catch (error) {
        console.error('Failed to decrypt stored API key:', error);
        clearStoredKey();
      }
    }

    if (autoLogout) {
      const logoutTimer = setTimeout(() => {
        clearStoredKey();
      }, 30 * 60 * 1000); // 30分後

      return () => clearTimeout(logoutTimer);
    }
  }, [onApiKeySet, autoLogout, clearStoredKey, decryptKey]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const encryptedKey = encryptKey(apiKey);
    if (rememberKey) {
      localStorage.setItem('openai_api_key', encryptedKey);
      sessionStorage.removeItem('openai_api_key');
    } else {
      sessionStorage.setItem('openai_api_key', encryptedKey);
      localStorage.removeItem('openai_api_key');
    }
    onApiKeySet(apiKey);
    setIsKeyCleared(false);
    setLastUsedKeyHint(maskApiKey(apiKey));
  };

  const maskApiKey = (key) => {
    if (!key) return '';
    if (key.length < 10) return '****';
    return key.slice(0, 4) + '*'.repeat(key.length - 8) + key.slice(-4);
  };

  const handleQuickReset = () => {
    const storedKey = localStorage.getItem('openai_api_key') || sessionStorage.getItem('openai_api_key');
    if (storedKey) {
      try {
        const decryptedKey = decryptKey(storedKey);
        setApiKey(decryptedKey);
        setIsKeyCleared(false);
        onApiKeySet(decryptedKey);
        alert('APIキーが正常に再設定されました。');
      } catch (error) {
        console.error('Failed to decrypt stored API key:', error);
        clearStoredKey();
        alert('APIキーの再設定に失敗しました。新しいキーを入力してください。');
      }
    } else {
      alert('保存されたAPIキーが見つかりません。新しいキーを入力してください。');
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <Alert severity="warning" sx={{ mb: 1 }}>
        セキュリティ警告: APIキーはクライアント側で保存されます。公共のデバイスでは使用しないでください。        
      </Alert>
      {isKeyCleared && (
        <Alert severity="info" sx={{ mb: 2 }}>
          APIキーが自動的に消去されました。再設定するには下のボタンをクリックするか、新しいキーを入力してください。
          <Button onClick={handleQuickReset} variant="outlined" size="small" sx={{ mt: 1 }}>
            前回のキーを再設定
          </Button>
        </Alert>
      )}
      {/* 非表示のユーザー名フィールドを追加 */}
      <input
        type="text"
        autoComplete="username"
        style={{ display: 'none' }}
        aria-hidden="true"
      />
      <TextField
        label="OpenAI API Key"
        value={apiKey ? (showKey ? apiKey : maskApiKey(apiKey)) : ''}
        onChange={(e) => setApiKey(e.target.value)}
        type={showKey ? "text" : "password"}
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setShowKey(!showKey)} edge="end">
              {showKey ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          )
        }}
        inputProps={{
          autoComplete: "current-password"
        }}
      />
      {isKeyCleared && lastUsedKeyHint && (
        <Typography variant="caption" display="block" gutterBottom>
          前回使用したキーのヒント: {lastUsedKeyHint}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberKey}
            onChange={(e) => setRememberKey(e.target.checked)}
          />
        }
        label="APIキーを記憶する"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={autoLogout}
            onChange={(e) => setAutoLogout(e.target.checked)}
          />
        }
        label="30分後に自動的にキーをクリアする"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 0 }}>
        APIキーを設定
      </Button>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
        キーの使用に関連するすべての料金はユーザーの責任となります。
      </Typography>
    </Box>
  );
}

export default APIKeyInput;

// import React, { useState, useEffect, useCallback } from 'react';
// import { TextField, Button, Box, FormControlLabel, Checkbox, Typography, Alert, IconButton } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CryptoJS from 'crypto-js';

// function APIKeyInput({ onApiKeySet, clearApiKey }) {
//   const [apiKey, setApiKey] = useState('');
//   const [rememberKey, setRememberKey] = useState(false);
//   const [showKey, setShowKey] = useState(false);
//   const [autoLogout, setAutoLogout] = useState(true);
//   const [isKeyCleared, setIsKeyCleared] = useState(false);
//   const [lastUsedKeyHint, setLastUsedKeyHint] = useState('');

//   const encryptKey = useCallback((key) => {
//     const salt = CryptoJS.lib.WordArray.random(128/8);
//     const iv = CryptoJS.lib.WordArray.random(128/8);
//     const encrypted = CryptoJS.AES.encrypt(key, salt.toString(), {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7
//     });
//     return salt.toString() + iv.toString() + encrypted.toString();
//   }, []);

//   const decryptKey = useCallback((encryptedKey) => {
//     const salt = CryptoJS.enc.Hex.parse(encryptedKey.substr(0, 32));
//     const iv = CryptoJS.enc.Hex.parse(encryptedKey.substr(32, 32));
//     const encrypted = encryptedKey.substring(64);
//     const key = CryptoJS.PBKDF2(salt.toString(), salt, {
//       keySize: 256/32,
//       iterations: 1000
//     });
//     const decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
//       iv: iv, 
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7
//     });
//     return decrypted.toString(CryptoJS.enc.Utf8);
//   }, []);

//   const clearStoredKey = useCallback(() => {
//     sessionStorage.removeItem('openai_api_key');
//     localStorage.removeItem('openai_api_key');
//     setApiKey('');
//     onApiKeySet('');
//     setIsKeyCleared(true);
//     setLastUsedKeyHint(maskApiKey(apiKey));
//     if (clearApiKey) clearApiKey();
//   }, [apiKey, onApiKeySet, clearApiKey]);

//   useEffect(() => {
//     const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
//     if (storedApiKey) {
//       try {
//         const decryptedKey = decryptKey(storedApiKey);
//         setApiKey(decryptedKey);
//         setRememberKey(!!localStorage.getItem('openai_api_key'));
//         onApiKeySet(decryptedKey);
//         setLastUsedKeyHint(maskApiKey(decryptedKey));
//       } catch (error) {
//         console.error('Failed to decrypt stored API key:', error);
//         clearStoredKey();
//       }
//     }

//     if (autoLogout) {
//       const logoutTimer = setTimeout(() => {
//         clearStoredKey();
//       }, 30 * 60 * 1000); // 30分後

//       return () => clearTimeout(logoutTimer);
//     }
//   }, [onApiKeySet, autoLogout, clearStoredKey, decryptKey]);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const encryptedKey = encryptKey(apiKey);
//     if (rememberKey) {
//       localStorage.setItem('openai_api_key', encryptedKey);
//       sessionStorage.removeItem('openai_api_key');
//     } else {
//       sessionStorage.setItem('openai_api_key', encryptedKey);
//       localStorage.removeItem('openai_api_key');
//     }
//     onApiKeySet(apiKey);
//     setIsKeyCleared(false);
//     setLastUsedKeyHint(maskApiKey(apiKey));
//   };

//   const maskApiKey = (key) => {
//     if (!key) return '';
//     if (key.length < 10) return '****';
//     return key.slice(0, 4) + '*'.repeat(key.length - 8) + key.slice(-4);
//   };

//   const handleQuickReset = () => {
//     const storedKey = localStorage.getItem('openai_api_key') || sessionStorage.getItem('openai_api_key');
//     if (storedKey) {
//       try {
//         const decryptedKey = decryptKey(storedKey);
//         setApiKey(decryptedKey);
//         setIsKeyCleared(false);
//         onApiKeySet(decryptedKey);
//         alert('APIキーが正常に再設定されました。');
//       } catch (error) {
//         console.error('Failed to decrypt stored API key:', error);
//         clearStoredKey();
//         alert('APIキーの再設定に失敗しました。新しいキーを入力してください。');
//       }
//     } else {
//       alert('保存されたAPIキーが見つかりません。新しいキーを入力してください。');
//     }
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
//       <Alert severity="warning" sx={{ mb: 2 }}>
//         セキュリティ警告: APIキーはクライアント側で保存されます。公共のデバイスでは使用しないでください。        
//       </Alert>
//       {isKeyCleared && (
//         <Alert severity="info" sx={{ mb: 2 }}>
//           APIキーが自動的に消去されました。再設定するには下のボタンをクリックするか、新しいキーを入力してください。
//           <Button onClick={handleQuickReset} variant="outlined" size="small" sx={{ mt: 1 }}>
//             前回のキーを再設定
//           </Button>
//         </Alert>
//       )}
//       <TextField
//         label="OpenAI API Key"
//         value={apiKey ? (showKey ? apiKey : maskApiKey(apiKey)) : ''}
//         onChange={(e) => setApiKey(e.target.value)}
//         type={showKey ? "text" : "password"}
//         fullWidth
//         margin="normal"
//         InputProps={{
//           endAdornment: (
//             <IconButton onClick={() => setShowKey(!showKey)} edge="end">
//               {showKey ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           )
//         }}
//         inputProps={{
//           autoComplete: "new-password"  // この行を修正
//         }}
//       />
//       {isKeyCleared && lastUsedKeyHint && (
//         <Typography variant="caption" display="block" gutterBottom>
//           前回使用したキーのヒント: {lastUsedKeyHint}
//         </Typography>
//       )}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={rememberKey}
//             onChange={(e) => setRememberKey(e.target.checked)}
//           />
//         }
//         label="APIキーを記憶する"
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={autoLogout}
//             onChange={(e) => setAutoLogout(e.target.checked)}
//           />
//         }
//         label="30分後に自動的にキーをクリアする"
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
//         APIキーを設定
//       </Button>
//       <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
//         注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
//         キーの使用に関連するすべての料金はユーザーの責任となります。
//       </Typography>
//     </Box>
//   );
// }

// export default APIKeyInput;

// import React, { useState, useEffect } from 'react';
// import { TextField, Button, Box, FormControlLabel, Checkbox, Typography, Alert, IconButton } from '@mui/material';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
// import CryptoJS from 'crypto-js';

// function APIKeyInput({ onApiKeySet, clearApiKey }) {
//   const [apiKey, setApiKey] = useState('');
//   const [rememberKey, setRememberKey] = useState(false);
//   const [showKey, setShowKey] = useState(false);
//   const [autoLogout, setAutoLogout] = useState(true);
//   const [isKeyCleared, setIsKeyCleared] = useState(false);
//   const [lastUsedKeyHint, setLastUsedKeyHint] = useState('');

//   const encryptKey = (key) => {
//     const salt = CryptoJS.lib.WordArray.random(128/8);
//     const iv = CryptoJS.lib.WordArray.random(128/8);
//     const encrypted = CryptoJS.AES.encrypt(key, salt.toString(), {
//       iv: iv,
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7
//     });
//     return salt.toString() + iv.toString() + encrypted.toString();
//   };

//   const decryptKey = (encryptedKey) => {
//     const salt = CryptoJS.enc.Hex.parse(encryptedKey.substr(0, 32));
//     const iv = CryptoJS.enc.Hex.parse(encryptedKey.substr(32, 32));
//     const encrypted = encryptedKey.substring(64);
//     const key = CryptoJS.PBKDF2(salt.toString(), salt, {
//       keySize: 256/32,
//       iterations: 1000
//     });
//     const decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
//       iv: iv, 
//       mode: CryptoJS.mode.CBC,
//       padding: CryptoJS.pad.Pkcs7
//     });
//     return decrypted.toString(CryptoJS.enc.Utf8);
//   };

//   useEffect(() => {
//     const storedApiKey = sessionStorage.getItem('openai_api_key') || localStorage.getItem('openai_api_key');
//     if (storedApiKey) {
//       try {
//         const decryptedKey = decryptKey(storedApiKey);
//         setApiKey(decryptedKey);
//         setRememberKey(!!localStorage.getItem('openai_api_key'));
//         onApiKeySet(decryptedKey);
//         setLastUsedKeyHint(maskApiKey(decryptedKey));
//       } catch (error) {
//         console.error('Failed to decrypt stored API key:', error);
//         clearStoredKey();
//       }
//     }

//     if (autoLogout) {
//       const logoutTimer = setTimeout(() => {
//         clearStoredKey();
//       }, 30 * 60 * 1000); // 30分後

//       return () => clearTimeout(logoutTimer);
//     }
//   }, [onApiKeySet, autoLogout, clearApiKey]);

//   const clearStoredKey = () => {
//     sessionStorage.removeItem('openai_api_key');
//     localStorage.removeItem('openai_api_key');
//     setApiKey('');
//     onApiKeySet('');
//     setIsKeyCleared(true);
//     setLastUsedKeyHint(maskApiKey(apiKey));
//     clearApiKey();
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const encryptedKey = encryptKey(apiKey);
//     if (rememberKey) {
//       localStorage.setItem('openai_api_key', encryptedKey);
//       sessionStorage.removeItem('openai_api_key');
//     } else {
//       sessionStorage.setItem('openai_api_key', encryptedKey);
//       localStorage.removeItem('openai_api_key');
//     }
//     onApiKeySet(apiKey);
//     setIsKeyCleared(false);
//     setLastUsedKeyHint(maskApiKey(apiKey));
//   };

//   const maskApiKey = (key) => {
//     if (!key) return '';
//     if (key.length < 10) return '****';
//     return key.slice(0, 4) + '*'.repeat(key.length - 8) + key.slice(-4);
//   };

//   const handleQuickReset = () => {
//     const storedKey = localStorage.getItem('openai_api_key') || sessionStorage.getItem('openai_api_key');
//     if (storedKey) {
//       try {
//         const decryptedKey = decryptKey(storedKey);
//         setApiKey(decryptedKey);
//         setIsKeyCleared(false);
//         onApiKeySet(decryptedKey);
//         alert('APIキーが正常に再設定されました。');
//       } catch (error) {
//         console.error('Failed to decrypt stored API key:', error);
//         clearStoredKey();
//         alert('APIキーの再設定に失敗しました。新しいキーを入力してください。');
//       }
//     } else {
//       alert('保存されたAPIキーが見つかりません。新しいキーを入力してください。');
//     }
//   };

//   return (
//     <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
//       <Alert severity="warning" sx={{ mb: 2 }}>
//         セキュリティ警告: APIキーはクライアント側で保存されます。公共のデバイスでは使用しないでください。        
//       </Alert>
//       {isKeyCleared && (
//         <Alert severity="info" sx={{ mb: 2 }}>
//           APIキーが自動的に消去されました。再設定するには下のボタンをクリックするか、新しいキーを入力してください。
//           <Button onClick={handleQuickReset} variant="outlined" size="small" sx={{ mt: 1 }}>
//             前回のキーを再設定
//           </Button>
//         </Alert>
//       )}
//       <TextField
//         label="OpenAI API Key"
//         value={apiKey ? (showKey ? apiKey : maskApiKey(apiKey)) : ''}
//         onChange={(e) => setApiKey(e.target.value)}
//         type={showKey ? "text" : "password"}
//         fullWidth
//         margin="normal"
//         InputProps={{
//           endAdornment: (
//             <IconButton onClick={() => setShowKey(!showKey)} edge="end">
//               {showKey ? <VisibilityOff /> : <Visibility />}
//             </IconButton>
//           )
//         }}
//       />
//       {isKeyCleared && lastUsedKeyHint && (
//         <Typography variant="caption" display="block" gutterBottom>
//           前回使用したキーのヒント: {lastUsedKeyHint}
//         </Typography>
//       )}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={rememberKey}
//             onChange={(e) => setRememberKey(e.target.checked)}
//           />
//         }
//         label="APIキーを記憶する"
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={autoLogout}
//             onChange={(e) => setAutoLogout(e.target.checked)}
//           />
//         }
//         label="30分後に自動的にキーをクリアする"
//       />
//       <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
//         APIキーを設定
//       </Button>
//       <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
//         注意: このアプリケーションは、入力されたAPIキーを使用してOpenAI APIにリクエストを送信します。
//         キーの使用に関連するすべての料金はユーザーの責任となります。
//       </Typography>
//     </Box>
//   );
// }

// export default APIKeyInput;