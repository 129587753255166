import axios from 'axios';

// 定数の定義
const BATCH_SIZE = 10;
const INITIAL_RETRY_DELAY = 1000;
const MAX_RETRIES = 3;

// グローバル変数の初期化
let titleCache = {};
let errorLogs = [];
let isDebugMode = false;

// キャッシュをローカルストレージに保存する関数
function saveCacheToLocalStorage() {
  localStorage.setItem('titleGenerationCache', JSON.stringify(titleCache));
}

// キャッシュをローカルストレージから読み込む関数
function loadCacheFromLocalStorage() {
  const savedCache = localStorage.getItem('titleGenerationCache');
  if (savedCache) {
    titleCache = JSON.parse(savedCache);
  }
}

// アプリケーション起動時にキャッシュを読み込む
loadCacheFromLocalStorage();

// エラーログを記録する関数
function logError(error, context) {
  const logEntry = {
    timestamp: new Date().toISOString(),
    error: error.message,
    stack: error.stack,
    context: context
  };
  errorLogs.push(logEntry);
  if (isDebugMode) {
    console.error('Error logged:', logEntry);
  }
}

// デバッグ情報を出力する関数
function debugLog(...args) {
  if (isDebugMode) {
    console.log('[DEBUG]', ...args);
  }
}

// APIリクエストの詳細をログに記録する関数
async function logApiRequest(url, method, requestData, responseData) {
  if (isDebugMode) {
    console.log(`[API ${method}] ${url}`);
    console.log('Request:', requestData);
    console.log('Response:', responseData);
  }
}

// 単一のタイトルを生成する関数
async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
  if (!apiKey) {
    throw new Error('API key is not provided');
  }

  const cacheKey = `${originalTitle}_${jpDesc.slice(0, 150)}`;
  if (titleCache[cacheKey]) {
    debugLog('Cache hit:', cacheKey);
    return titleCache[cacheKey];
  }

  try {
    const url = 'https://api.openai.com/v1/chat/completions';
    const limitedJpDesc = jpDesc.slice(0, 150);
    const requestData = {
      model: "gpt-4o-2024-08-06",
      messages: [
        {
          role: "system",
          content: `あなたはeBayの商品タイトル最適化専門のAIアシスタントです。与えられた元のタイトルと日本語の商品情報を基に、80文字以内の効果的な英語タイトルを作成してください。
タイトルの構成順序：
1. ブランド名（該当する場合）
2. モデル名や商品名・キャラクター名（該当する場合）
3. 商品タイプ（該当する場合）
4. 主要な特徴（色、素材、サイズ）（該当する場合）
5. 特徴的な要素や独自性（該当する場合）
6. 状態（新品/中古）（該当する場合）
7. 重要なキーワード（該当する場合）
注意点：
- 元のタイトルの重要な情報を保持しつつ、新しい情報を追加または最適化する
- 不要な形容詞や「eBay」などの単語は避ける
- 略語や通称は適切に使用する
- 検索者の視点で考え、買い手が使用しそうな検索語を含める
- 情報が不明確または曖昧な場合は、その項目を省略する
- 確実な情報のみを使用し、推測や不確かな情報は含めない
- 単語の羅列になっている日本語の説明からは抽出しない
- タイトルは英語で生成し、80文字以内に収める`
        },
        {
          role: "user",
          content: `元のタイトル：「${originalTitle}」
日本語の商品説明：「${jpDesc}」
この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。また日本語の商品説明が空の場合は元のタイトルから生成してください`
        },
      ],
      max_tokens: 100,
    };

    const response = await axios.post(url, requestData, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });

    await logApiRequest(url, 'POST', requestData, response.data);

    const generatedTitle = response.data.choices[0].message.content.trim();
    titleCache[cacheKey] = generatedTitle;
    saveCacheToLocalStorage();

    return generatedTitle;
  } catch (error) {
    logError(error, { function: 'generateSingleTitle', originalTitle, jpDesc: jpDesc.slice(0, 150) });
    throw new Error(`Failed to generate title: ${error.message}`);
  }
}

// リトライ機能付きの単一タイトル生成関数
async function generateSingleTitleWithRetry(originalTitle, jpDesc, apiKey, retryCount = 0) {
  const start = performance.now();
  try {
    const title = await generateSingleTitle(originalTitle, jpDesc, apiKey);
    
    const end = performance.now();
    titleCache.totalGenerationTime = (titleCache.totalGenerationTime || 0) + (end - start);
    titleCache.apiCalls = (titleCache.apiCalls || 0) + 1;

    return title;
  } catch (error) {
    if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
      const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
      debugLog(`Rate limit exceeded. Retrying in ${delay}ms...`);
      await new Promise(resolve => setTimeout(resolve, delay));
      return generateSingleTitleWithRetry(originalTitle, jpDesc, apiKey, retryCount + 1);
    }
    throw error;
  }
}

// バッチ処理を行う関数
async function processBatch(batch, jpDescIndex, titleIndex, apiKey) {
  const batchTitles = [];
  const batchErrors = [];

  for (const row of batch) {
    const jpDesc = row[jpDescIndex] || '';
    const originalTitle = row[titleIndex] || '';
    if (jpDesc) {
      try {
        const title = await generateSingleTitleWithRetry(originalTitle, jpDesc, apiKey);
        batchTitles.push({ index: batch.indexOf(row) + 1, originalTitle, originalDesc: jpDesc, generatedTitle: title });
      } catch (error) {
        console.error(`Error generating title for row ${batch.indexOf(row) + 1}:`, error);
        batchErrors.push({ index: batch.indexOf(row) + 1, error: error.message });
        batchTitles.push({ index: batch.indexOf(row) + 1, originalTitle, originalDesc: jpDesc, generatedTitle: '' });
      }
    } else {
      batchTitles.push({ index: batch.indexOf(row) + 1, originalTitle, originalDesc: '', generatedTitle: '' });
    }
  }

  return { titles: batchTitles, errors: batchErrors };
}

// メインのタイトル生成関数
async function generateTitles(data, jpDescIndex, titleIndex, apiKey, onProgress) {
  return measurePerformance(async () => {
    const titles = [];
    const errors = [];
    const totalItems = data.length - 1; // ヘッダー行を除く
    let processedItems = 0;

    for (let i = 1; i < data.length; i += BATCH_SIZE) {
      const batch = data.slice(i, i + BATCH_SIZE);
      try {
        const batchResults = await processBatch(batch, jpDescIndex, titleIndex, apiKey);
        titles.push(...batchResults.titles);
        errors.push(...batchResults.errors);
        processedItems += batchResults.titles.length;
        
        if (onProgress) {
          const progress = Math.floor((processedItems / totalItems) * 100);
          const status = `生成中： ${processedItems}/${totalItems} (${progress}%)`;
          onProgress(progress, status);
        }
      } catch (error) {
        logError(error, { function: 'generateTitles', batchIndex: i });
        errors.push({ index: i, error: error.message });
      }
    }

    if (onProgress) {
      onProgress(100, '完了');
    }

    return { titles, errors };
  });
}

// スプレッドシートデータを更新する関数
function createUpdatedSpreadsheetData(originalData, titles) {
  return originalData.map((row, index) => {
    if (index === 0) {
      return [...row, '新タイトル'];
    }
    const titleEntry = titles.find(t => t.index === index);
    return [...row, titleEntry ? titleEntry.generatedTitle : ''];
  });
}

// タイトルを検証し処理する関数
function validateAndProcessTitles(titles) {
  return titles.map(title => ({
    ...title,
    generatedTitle: title.generatedTitle.replace(/"/g, '').slice(0, 80),
    warning: title.generatedTitle.length > 80 ? 'Title truncated to 80 characters' : undefined
  }));
}

// プレビューデータを準備する関数
function preparePreviewData(originalData, generatedTitles) {
  const titleIndex = originalData[0].findIndex(header => header.toLowerCase() === 'title');
  const jpDescIndex = originalData[0].findIndex(header => header.toLowerCase() === 'jp_desc');

  return generatedTitles.map(title => ({
    index: title.index,
    originalTitle: titleIndex !== -1 ? originalData[title.index][titleIndex] : '',
    jpDesc: jpDescIndex !== -1 ? originalData[title.index][jpDescIndex] : '',
    generatedTitle: title.generatedTitle,
    warning: title.warning
  }));
}

// プレビューからタイトルを更新する関数
function updateTitlesFromPreview(previewData) {
  return previewData.map(item => ({
    index: item.index,
    generatedTitle: item.generatedTitle
  }));
}

// 既存のタイトルを上書きする関数
function overwriteExistingTitles(data, newTitles) {
  const titleIndex = data[0].findIndex(header => header.toLowerCase() === 'title');
  if (titleIndex === -1) {
    throw new Error('"Title"カラムが見つかりません。');
  }

  return data.map((row, index) => {
    if (index === 0) return row; // ヘッダー行はそのまま
    const newTitle = newTitles.find(t => t.index === index)?.generatedTitle || '';
    const newRow = [...row];
    newRow[titleIndex] = newTitle || row[titleIndex]; // 新タイトルが空の場合は既存のタイトルを維持
    return newRow;
  });
}

// タイトルを切り詰める関数
function truncateTitles(data, maxLength = 80) {
  const titleIndex = data[0].findIndex(header => header.toLowerCase() === 'title');
  if (titleIndex === -1) {
    throw new Error('"Title"カラムが見つかりません。');
  }

  return data.map((row, index) => {
    if (index === 0) return row; // ヘッダー行はそのまま
    const newRow = [...row];
    if (newRow[titleIndex]) {
      // ダブルクォーテーションを削除し、指定された文字数に切り詰める
      newRow[titleIndex] = newRow[titleIndex].replace(/"/g, '').slice(0, maxLength);
    }
    return newRow;
  });
}

// タイトルキャッシュをクリアする関数
function clearTitleCache() {
  titleCache = {};
  localStorage.removeItem('titleGenerationCache');
  console.log('Title cache cleared');
}

// タイトルキャッシュの状態を取得する関数
function getTitleCacheStatus() {
  return {
    size: Object.keys(titleCache).length,
    items: titleCache,
    storageSize: new Blob([JSON.stringify(titleCache)]).size
  };
}

// エラーログを取得する関数
function getErrorLogs() {
  return errorLogs;
}

// エラーログをクリアする関数
function clearErrorLogs() {
  errorLogs = [];
  console.log('Error logs cleared');
}

// デバッグモードを切り替える関数
function toggleDebugMode(enable) {
  isDebugMode = enable;
  console.log(`Debug mode ${enable ? 'enabled' : 'disabled'}`);
}

// パフォーマンスを測定する関数
async function measurePerformance(func, ...args) {
  const start = performance.now();
  try {
    const result = await func(...args);
    const end = performance.now();
    const duration = end - start;
    debugLog(`Performance: ${func.name} took ${duration.toFixed(2)}ms`);
    return result;
  } catch (error) {
    const end = performance.now();
    const duration = end - start;
    debugLog(`Performance: ${func.name} failed after ${duration.toFixed(2)}ms`);
    throw error;
  }
}

// タイトル生成の統計情報を取得する関数
function getTitleGenerationStats() {
  return {
    totalGenerated: Object.keys(titleCache).length,
    cacheHits: titleCache.cacheHits || 0,
    apiCalls: titleCache.apiCalls || 0,
    averageGenerationTime: titleCache.totalGenerationTime ? (titleCache.totalGenerationTime / titleCache.apiCalls).toFixed(2) : 0,
  };
}

// タイトル生成の統計情報をリセットする関数
function resetTitleGenerationStats() {
  titleCache.cacheHits = 0;
  titleCache.apiCalls = 0;
  titleCache.totalGenerationTime = 0;
}

// キャッシュのパフォーマンスを計算する関数
function calculateCachePerformance() {
  const totalRequests = (titleCache.cacheHits || 0) + (titleCache.apiCalls || 0);
  const cacheHitRate = totalRequests > 0 ? (titleCache.cacheHits || 0) / totalRequests : 0;
  return {
    cacheHitRate: cacheHitRate.toFixed(2),
    estimatedTimeSaved: ((titleCache.cacheHits || 0) * (titleCache.averageGenerationTime || 0)).toFixed(2)
  };
}

// キャッシュの有効期限をチェックし、古いエントリを削除する関数
function cleanupExpiredCache(expirationTime = 7 * 24 * 60 * 60 * 1000) { // デフォルトは1週間
  const now = Date.now();
  Object.keys(titleCache).forEach(key => {
    if (titleCache[key].timestamp && now - titleCache[key].timestamp > expirationTime) {
      delete titleCache[key];
    }
  });
  saveCacheToLocalStorage();
}

// キャッシュのサイズを制限する関数
function limitCacheSize(maxSize = 1000) {
  const keys = Object.keys(titleCache);
  if (keys.length > maxSize) {
    const sortedKeys = keys.sort((a, b) => titleCache[b].timestamp - titleCache[a].timestamp);
    const keysToRemove = sortedKeys.slice(maxSize);
    keysToRemove.forEach(key => delete titleCache[key]);
    saveCacheToLocalStorage();
  }
}

// エラー処理を強化した関数
function safelyExecuteFunction(func, ...args) {
  try {
    return func(...args);
  } catch (error) {
    logError(error, { function: func.name, arguments: args });
    console.error(`Error in ${func.name}:`, error);
    return null;
  }
}

// バッチ処理の進捗を視覚化する関数
function visualizeProgress(processed, total) {
  const progressBarWidth = 50;
  const filledWidth = Math.floor((processed / total) * progressBarWidth);
  const emptyWidth = progressBarWidth - filledWidth;
  const progressBar = '[' + '='.repeat(filledWidth) + ' '.repeat(emptyWidth) + ']';
  const percentage = ((processed / total) * 100).toFixed(2);
  return `${progressBar} ${percentage}% (${processed}/${total})`;
}

// タイトル生成のバッチ処理を最適化する関数
async function optimizedBatchProcessing(data, jpDescIndex, titleIndex, apiKey, onProgress) {
  const batchSize = BATCH_SIZE;
  const totalBatches = Math.ceil(data.length / batchSize);
  let processedItems = 0;

  for (let i = 0; i < totalBatches; i++) {
    const start = i * batchSize;
    const end = Math.min((i + 1) * batchSize, data.length);
    const batch = data.slice(start, end);

    const batchResults = await processBatch(batch, jpDescIndex, titleIndex, apiKey);
    processedItems += batchResults.titles.length;

    if (onProgress) {
      const progress = Math.floor((processedItems / data.length) * 100);
      const status = visualizeProgress(processedItems, data.length);
      onProgress(progress, status);
    }

    // ここでバッチ結果を処理または保存します
    // 例: saveBatchResults(batchResults);
  }
}

// API呼び出しの同時実行数を制限する関数
async function limitConcurrency(tasks, maxConcurrent = 5) {
  const results = [];
  const executing = new Set();
  for (const task of tasks) {
    const p = Promise.resolve().then(() => task());
    results.push(p);
    executing.add(p);
    const clean = () => executing.delete(p);
    p.then(clean).catch(clean);
    if (executing.size >= maxConcurrent) {
      await Promise.race(executing);
    }
  }
  return Promise.all(results);
}

// エラーハンドリングを強化した非同期関数のラッパー
function asyncErrorHandler(fn) {
  return async function(...args) {
    try {
      return await fn(...args);
    } catch (error) {
      logError(error, { function: fn.name, arguments: args });
      console.error(`Error in ${fn.name}:`, error);
      throw error;
    }
  };
}

// タイトル生成プロセスの監視とレポート生成関数
function generateTitleGenerationReport(startTime, endTime, totalItems, successfulItems, failedItems) {
  const duration = (endTime - startTime) / 1000; // 秒単位
  const successRate = (successfulItems / totalItems) * 100;
  const averageTimePerItem = duration / totalItems;

  return `
タイトル生成レポート:
-------------------
総アイテム数: ${totalItems}
成功: ${successfulItems}
失敗: ${failedItems}
成功率: ${successRate.toFixed(2)}%
総処理時間: ${duration.toFixed(2)}秒
アイテムあたりの平均時間: ${averageTimePerItem.toFixed(2)}秒
  `;
}

// タイトル生成のパフォーマンス最適化のためのヒントを提供する関数
function getTitleGenerationOptimizationHints() {
  const stats = getTitleGenerationStats();
  const hints = [];

  if (stats.cacheHitRate < 0.5) {
    hints.push("キャッシュのヒット率が低いです。類似商品のタイトルを再利用することで改善できる可能性があります。");
  }

  if (stats.averageGenerationTime > 2000) {
    hints.push("タイトル生成の平均時間が長いです。ネットワーク接続やAPIの応答性を確認してください。");
  }

  if (stats.totalGenerated < 100) {
    hints.push("生成したタイトルの総数が少ないです。より多くのデータでテストすることで、パフォーマンスの傾向をより正確に把握できます。");
  }

  return hints;
}

// 非同期処理を使用してタイトル生成を最適化する関数
async function optimizedTitleGeneration(data, jpDescIndex, titleIndex, apiKey, onProgress) {
  const chunkSize = 100; // 一度に処理するデータの量
  const chunks = [];

  for (let i = 0; i < data.length; i += chunkSize) {
    chunks.push(data.slice(i, i + chunkSize));
  }

  const results = await Promise.all(chunks.map(async (chunk, index) => {
    const chunkResult = await generateTitles(chunk, jpDescIndex, titleIndex, apiKey, (progress, status) => {
      const overallProgress = (index * chunkSize + progress * chunk.length) / data.length;
      onProgress(Math.floor(overallProgress * 100), `Chunk ${index + 1}/${chunks.length}: ${status}`);
    });
    return chunkResult;
  }));

  return results.reduce((acc, curr) => {
    acc.titles.push(...curr.titles);
    acc.errors.push(...curr.errors);
    return acc;
  }, { titles: [], errors: [] });
}

// エクスポートする関数のリスト
export {
  generateTitles,
  createUpdatedSpreadsheetData,
  validateAndProcessTitles,
  preparePreviewData,
  updateTitlesFromPreview,
  overwriteExistingTitles,
  truncateTitles,
  clearTitleCache,
  getTitleCacheStatus,
  getErrorLogs,
  clearErrorLogs,
  toggleDebugMode,
  measurePerformance,
  getTitleGenerationStats,
  resetTitleGenerationStats,
  calculateCachePerformance,
  cleanupExpiredCache,
  limitCacheSize,
  safelyExecuteFunction,
  visualizeProgress,
  optimizedBatchProcessing,
  limitConcurrency,
  asyncErrorHandler,
  generateTitleGenerationReport,
  getTitleGenerationOptimizationHints,
  optimizedTitleGeneration
};





// import axios from 'axios';

// // 定数の定義
// const BATCH_SIZE = 10;
// const INITIAL_RETRY_DELAY = 1000;
// const MAX_RETRIES = 3;

// // グローバル変数の初期化
// let titleCache = {};
// let errorLogs = [];
// let isDebugMode = false;

// // エラーログを記録する関数
// function logError(error, context) {
//   const logEntry = {
//     timestamp: new Date().toISOString(),
//     error: error.message,
//     stack: error.stack,
//     context: context
//   };
//   errorLogs.push(logEntry);
//   if (isDebugMode) {
//     console.error('Error logged:', logEntry);
//   }
// }

// // デバッグ情報を出力する関数
// function debugLog(...args) {
//   if (isDebugMode) {
//     console.log('[DEBUG]', ...args);
//   }
// }

// // APIリクエストの詳細をログに記録する関数
// async function logApiRequest(url, method, requestData, responseData) {
//   if (isDebugMode) {
//     console.log(`[API ${method}] ${url}`);
//     console.log('Request:', requestData);
//     console.log('Response:', responseData);
//   }
// }


// async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
//   if (!apiKey) {
//     throw new Error('API key is not provided');
//   }

//   try {
//     const url = 'https://api.openai.com/v1/chat/completions';
//     // jpDescを150文字に制限
//     const limitedJpDesc = jpDesc.slice(0, 150);
//     const requestData = {
//       model: "gpt-4o-2024-08-06",
//       messages: [
//         {
//           role: "system",
//           content: `You are an AI assistant specializing in eBay product title optimization. Based on the given original title and Japanese product information, create an effective English title within 80 characters.
// Order of title composition:
// 1. brand name (if applicable)
// 2. model name or product name/character name (if applicable)
// 3. product type (if applicable)
// 4. key features (color, material, size) (if applicable)
// 5. distinctive elements or uniqueness (if applicable)
// 6. condition (new/used) (if applicable)
// 7. important keywords (if applicable)
// NOTES:
// - Add or optimize new information while retaining important information from the original title
// - Avoid unnecessary adjectives and words such as “eBay
// - Use abbreviations and common names appropriately
// - Think from the searcher's perspective and include search terms that buyers are likely to use
// - If information is unclear or ambiguous, omit it.
// - Use only reliable information and do not include guesswork or uncertain information.
// - Do not extract from Japanese descriptions that are a list of words.
// - Titles should be generated in English and should not exceed 80 characters.`
//         },
//         {
//           role: "user",
//           content: `元のタイトル：「${originalTitle}」
// 日本語の商品説明（最初の150文字）：「${limitedJpDesc}」
// Use this information to generate a new, optimized eBay product title.`
//         },
//       ],
//       max_tokens: 100,
//     };

//     const response = await axios.post(url, requestData, {
//       headers: {
//         'Authorization': `Bearer ${apiKey}`,
//         'Content-Type': 'application/json',
//       },
//     });

//     await logApiRequest(url, 'POST', requestData, response.data);

//     return response.data.choices[0].message.content.trim();
//   } catch (error) {
//     logError(error, { function: 'generateSingleTitle', originalTitle, jpDesc: jpDesc.slice(0, 150) });
//     throw new Error(`Failed to generate title: ${error.message}`);
//   }
// }

// // 単一のタイトルを生成する関数
// // async function generateSingleTitle(originalTitle, jpDesc, apiKey) {
// //   if (!apiKey) {
// //     throw new Error('API key is not provided');
// //   }

// //   try {
// //     const url = 'https://api.openai.com/v1/chat/completions';
// //     const requestData = {
// //       model: "gpt-4o-2024-08-06",
// //       messages: [
// //         {
// //           role: "system",
// //           content: `あなたはeBayの商品タイトル最適化専門のAIアシスタントです。与えられた元のタイトルと日本語の商品情報を基に、80文字以内の効果的な英語タイトルを作成してください。
// // タイトルの構成順序：
// // 1. ブランド名（該当する場合）
// // 2. モデル名や商品名・キャラクター名（該当する場合）
// // 3. 商品タイプ（該当する場合）
// // 4. 主要な特徴（色、素材、サイズ）（該当する場合）
// // 5. 特徴的な要素や独自性（該当する場合）
// // 6. 状態（新品/中古）（該当する場合）
// // 7. 重要なキーワード（該当する場合）
// // 注意点：
// // - 元のタイトルの重要な情報を保持しつつ、新しい情報を追加または最適化する
// // - 不要な形容詞や「eBay」などの単語は避ける
// // - 略語や通称は適切に使用する
// // - 検索者の視点で考え、買い手が使用しそうな検索語を含める
// // - 情報が不明確または曖昧な場合は、その項目を省略する
// // - 確実な情報のみを使用し、推測や不確かな情報は含めない
// // - 単語の羅列になっている日本語の説明からは抽出しない
// // - タイトルは英語で生成し、80文字以内に収める`
// //         },
// //         {
// //           role: "user",
// //           content: `元のタイトル：「${originalTitle}」
// // 日本語の商品説明：「${jpDesc}」
// // この情報を基に、最適化された新しいeBayの商品タイトルを生成してください。`
// //         },
// //       ],
// //       max_tokens: 100,
// //     };

// //     const response = await axios.post(url, requestData, {
// //       headers: {
// //         'Authorization': `Bearer ${apiKey}`,
// //         'Content-Type': 'application/json',
// //       },
// //     });

// //     await logApiRequest(url, 'POST', requestData, response.data);

// //     return response.data.choices[0].message.content.trim();
// //   } catch (error) {
// //     logError(error, { function: 'generateSingleTitle', originalTitle, jpDesc });
// //     throw new Error(`Failed to generate title: ${error.message}`);
// //   }
// // }

// // リトライ機能付きの単一タイトル生成関数
// async function generateSingleTitleWithRetry(originalTitle, jpDesc, apiKey, retryCount = 0) {
//   const start = performance.now();
//   try {
//     const cacheKey = `${originalTitle}_${jpDesc}`;
//     if (titleCache[cacheKey]) {
//       titleCache.cacheHits = (titleCache.cacheHits || 0) + 1;
//       return titleCache[cacheKey];
//     }

//     titleCache.apiCalls = (titleCache.apiCalls || 0) + 1;
//     const title = await generateSingleTitle(originalTitle, jpDesc, apiKey);
    
//     titleCache[cacheKey] = title;
    
//     const end = performance.now();
//     titleCache.totalGenerationTime = (titleCache.totalGenerationTime || 0) + (end - start);

//     return title;
//   } catch (error) {
//     if (error.response && error.response.status === 429 && retryCount < MAX_RETRIES) {
//       const delay = INITIAL_RETRY_DELAY * Math.pow(2, retryCount);
//       debugLog(`Rate limit exceeded. Retrying in ${delay}ms...`);
//       await new Promise(resolve => setTimeout(resolve, delay));
//       return generateSingleTitleWithRetry(originalTitle, jpDesc, apiKey, retryCount + 1);
//     }
//     throw error;
//   }
// }

// // バッチ処理を行う関数
// async function processBatch(batch, jpDescIndex, titleIndex, apiKey) {
//   const batchTitles = [];
//   const batchErrors = [];

//   for (const row of batch) {
//     const jpDesc = row[jpDescIndex] || '';
//     const originalTitle = row[titleIndex] || '';
//     if (jpDesc) {
//       try {
//         const title = await generateSingleTitleWithRetry(originalTitle, jpDesc, apiKey);
//         batchTitles.push({ index: batch.indexOf(row) + 1, originalTitle, originalDesc: jpDesc, generatedTitle: title });
//       } catch (error) {
//         console.error(`Error generating title for row ${batch.indexOf(row) + 1}:`, error);
//         batchErrors.push({ index: batch.indexOf(row) + 1, error: error.message });
//         batchTitles.push({ index: batch.indexOf(row) + 1, originalTitle, originalDesc: jpDesc, generatedTitle: '' });
//       }
//     } else {
//       batchTitles.push({ index: batch.indexOf(row) + 1, originalTitle, originalDesc: '', generatedTitle: '' });
//     }
//   }

//   return { titles: batchTitles, errors: batchErrors };
// }

// // メインのタイトル生成関数
// async function generateTitles(data, jpDescIndex, titleIndex, apiKey, onProgress) {
//   return measurePerformance(async () => {
//     const titles = [];
//     const errors = [];
//     const totalItems = data.length - 1; // ヘッダー行を除く
//     let processedItems = 0;

//     for (let i = 1; i < data.length; i += BATCH_SIZE) {
//       const batch = data.slice(i, i + BATCH_SIZE);
//       try {
//         const batchResults = await processBatch(batch, jpDescIndex, titleIndex, apiKey);
//         titles.push(...batchResults.titles);
//         errors.push(...batchResults.errors);
//         processedItems += batchResults.titles.length;
        
//         if (onProgress) {
//           onProgress(Math.floor((processedItems / totalItems) * 100));
//         }
//       } catch (error) {
//         logError(error, { function: 'generateTitles', batchIndex: i });
//         errors.push({ index: i, error: error.message });
//       }
//     }

//     return { titles, errors };
//   });
// }

// // スプレッドシートデータを更新する関数
// function createUpdatedSpreadsheetData(originalData, titles) {
//   return originalData.map((row, index) => {
//     if (index === 0) {
//       return [...row, '新タイトル'];
//     }
//     const titleEntry = titles.find(t => t.index === index);
//     return [...row, titleEntry ? titleEntry.generatedTitle : ''];
//   });
// }

// // タイトルを検証し処理する関数
// function validateAndProcessTitles(titles) {
//   return titles.map(title => ({
//     ...title,
//     generatedTitle: title.generatedTitle.replace(/"/g, '').slice(0, 80),
//     warning: title.generatedTitle.length > 80 ? 'Title truncated to 80 characters' : undefined
//   }));
// }

// // プレビューデータを準備する関数
// function preparePreviewData(originalData, generatedTitles) {
//   const titleIndex = originalData[0].findIndex(header => header.toLowerCase() === 'title');
//   const jpDescIndex = originalData[0].findIndex(header => header.toLowerCase() === 'jp_desc');

//   return generatedTitles.map(title => ({
//     index: title.index,
//     originalTitle: titleIndex !== -1 ? originalData[title.index][titleIndex] : '',
//     jpDesc: jpDescIndex !== -1 ? originalData[title.index][jpDescIndex] : '',
//     generatedTitle: title.generatedTitle,
//     warning: title.warning
//   }));
// }

// // プレビューからタイトルを更新する関数
// function updateTitlesFromPreview(previewData) {
//   return previewData.map(item => ({
//     index: item.index,
//     generatedTitle: item.generatedTitle
//   }));
// }

// // 既存のタイトルを上書きする関数
// function overwriteExistingTitles(data, newTitles) {
//   const titleIndex = data[0].findIndex(header => header.toLowerCase() === 'title');
//   if (titleIndex === -1) {
//     throw new Error('"Title"カラムが見つかりません。');
//   }

//   return data.map((row, index) => {
//     if (index === 0) return row; // ヘッダー行はそのまま
//     const newTitle = newTitles.find(t => t.index === index)?.generatedTitle || '';
//     const newRow = [...row];
//     newRow[titleIndex] = newTitle || row[titleIndex]; // 新タイトルが空の場合は既存のタイトルを維持
//     return newRow;
//   });
// }

// // タイトルを切り詰める関数
// function truncateTitles(data, maxLength = 80) {
//   const titleIndex = data[0].findIndex(header => header.toLowerCase() === 'title');
//   if (titleIndex === -1) {
//     throw new Error('"Title"カラムが見つかりません。');
//   }

//   return data.map((row, index) => {
//     if (index === 0) return row; // ヘッダー行はそのまま
//     const newRow = [...row];
//     if (newRow[titleIndex]) {
//       // ダブルクォーテーションを削除し、指定された文字数に切り詰める
//       newRow[titleIndex] = newRow[titleIndex].replace(/"/g, '').slice(0, maxLength);
//     }
//     return newRow;
//   });
// }

// // タイトルキャッシュをクリアする関数
// function clearTitleCache() {
//   titleCache = {};
//   console.log('Title cache cleared');
// }

// // タイトルキャッシュの状態を取得する関数
// function getTitleCacheStatus() {
//   return {
//     size: Object.keys(titleCache).length,
//     items: titleCache
//   };
// }

// // エラーログを取得する関数
// function getErrorLogs() {
//   return errorLogs;
// }

// // エラーログをクリアする関数
// function clearErrorLogs() {
//   errorLogs = [];
//   console.log('Error logs cleared');
// }

// // デバッグモードを切り替える関数
// function toggleDebugMode(enable) {
//   isDebugMode = enable;
//   console.log(`Debug mode ${enable ? 'enabled' : 'disabled'}`);
// }

// // パフォーマンスを測定する関数
// async function measurePerformance(func, ...args) {
//   const start = performance.now();
//   try {
//     const result = await func(...args);
//     const end = performance.now();
//     const duration = end - start;
//     debugLog(`Performance: ${func.name} took ${duration.toFixed(2)}ms`);
//     return result;
//   } catch (error) {
//     const end = performance.now();
//     const duration = end - start;
//     debugLog(`Performance: ${func.name} failed after ${duration.toFixed(2)}ms`);
//     throw error;
//   }
// }

// // タイトル生成の統計情報を取得する関数
// function getTitleGenerationStats() {
//   return {
//     totalGenerated: Object.keys(titleCache).length,
//     cacheHits: titleCache.cacheHits || 0,
//     apiCalls: titleCache.apiCalls || 0,
//     averageGenerationTime: titleCache.totalGenerationTime ? (titleCache.totalGenerationTime / titleCache.apiCalls).toFixed(2) : 0,
//   };
// }

// // タイトル生成の統計情報をリセットする関数
// function resetTitleGenerationStats() {
//   titleCache.cacheHits = 0;
//   titleCache.apiCalls = 0;
//   titleCache.totalGenerationTime = 0;
// }

// // エクスポートする関数のリスト
// export {
//   generateTitles,
//   createUpdatedSpreadsheetData,
//   validateAndProcessTitles,
//   preparePreviewData,
//   updateTitlesFromPreview,
//   overwriteExistingTitles,
//   truncateTitles,
//   clearTitleCache,
//   getTitleCacheStatus,
//   getErrorLogs,
//   clearErrorLogs,
//   toggleDebugMode,
//   measurePerformance,
//   getTitleGenerationStats,
//   resetTitleGenerationStats
// };