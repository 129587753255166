// EditableSpreadsheet.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BulkColumnEdit from './BulkColumnEdit';
import Select from 'react-select/creatable';
import Modal from 'react-modal';
import './EditableSpreadsheet.css';

// モーダルのアクセシビリティ設定
Modal.setAppElement('#root');

function EditableSpreadsheet({ spreadsheetId, sheetName, token, data: initialData, fetchData }) {
  // State variables
  const [data, setData] = useState(initialData || []);
  const [error, setError] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [sheetId, setSheetId] = useState(null);
  const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [rowsToDelete, setRowsToDelete] = useState([]);
  const [deletionLog, setDeletionLog] = useState([]);
  const [titleIndex, setTitleIndex] = useState(-1);
  const [jpTitleIndex, setJpTitleIndex] = useState(-1);
  const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
  const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

  // Effect hooks
  useEffect(() => {
    setData(initialData || []);
  }, [initialData]);

  useEffect(() => {
    const fetchSheetId = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sheet = response.data.sheets.find(s => s.properties.title === sheetName);
        if (sheet) {
          setSheetId(sheet.properties.sheetId);
        } else {
          setError(`シート名 "${sheetName}" が見つかりません。`);
        }
      } catch (err) {
        console.error('Error fetching sheet ID:', err);
        setError('シートIDの取得に失敗しました');
      }
    };

    if (spreadsheetId && sheetName) {
      fetchSheetId();
    }
  }, [spreadsheetId, sheetName, token]);

  useEffect(() => {
    if (data && data.length > 0) {
      const headers = data[0];
      setTitleIndex(headers.findIndex(header => header.toLowerCase() === 'title'));
      setJpTitleIndex(headers.findIndex(header => header.toLowerCase() === 'jp_title'));
    }
  }, [data]);

  // Functions
  const updateCell = async (rowIndex, colIndex, newValue) => {
    try {
      const range = `${sheetName}!${columnToLetter(colIndex + 1)}${rowIndex + 1}`;
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
        {
          values: [[newValue]]
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const newData = [...data];
      newData[rowIndex][colIndex] = newValue;
      setData(newData);
    } catch (err) {
      console.error('Error updating cell:', err);
      setError('セルの更新に失敗しました');
    }
  };

  const handleColumnClick = (colIndex) => {
    setSelectedColumn(colIndex);
  };

  const columnToLetter = (column) => {
    let temp, letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = Math.floor((column - temp - 1) / 26);
    }
    return letter;
  };

  const renderCell = (cell, rowIndex, colIndex) => {
    const header = data[0][colIndex]?.toLowerCase();
    if (header === 'picurl' && rowIndex > 0) {
      const imageUrls = cell?.split('|') || [];
      return (
        <img 
          src={imageUrls[0]} 
          alt="Product" 
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
      );
    } else if (header === '画像' && rowIndex > 0) {
      const picUrlIndex = data[0].findIndex(h => h.toLowerCase() === 'picurl');
      const imageUrls = data[rowIndex][picUrlIndex]?.split('|') || [];
      return (
        <img 
          src={imageUrls[0]} 
          alt="Product" 
          style={{ maxWidth: '100px', maxHeight: '100px' }}
        />
      );
    } else {
      return (
        <input
          value={cell || ''}
          onChange={(e) => updateCell(rowIndex, colIndex, e.target.value)}
        />
      );
    }
  };

  const handleDeleteRowsByWord = async () => {
    if (deleteWords.length === 0) {
      setError('削除したいワードを入力してください。');
      return;
    }

    if (!sheetId) {
      setError('シートIDが取得されていません。');
      return;
    }

    try {
      if (titleIndex === -1 && jpTitleIndex === -1) {
        setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
        return;
      }

      const words = deleteWords.map(word => word.value).filter(w => w);

      if (words.length === 0) {
        setError('有効な削除ワードがありません。');
        return;
      }

      // 正規表現を使用して完全一致を判定（大文字小文字を無視）
      const regexes = words.map(word => new RegExp(`\\b${escapeRegExp(word)}\\b`, 'i'));

      const rowsToDeleteTemp = [];
      const deletionLogTemp = [];

      for (let i = 1; i < data.length; i++) {
        const titleCell = titleIndex !== -1 ? data[i][titleIndex] : '';
        const jpTitleCell = jpTitleIndex !== -1 ? data[i][jpTitleIndex] : '';

        const titleMatches = regexes.some(regex => regex.test(titleCell));
        const jpTitleMatches = regexes.some(regex => regex.test(jpTitleCell));

        if (titleMatches || jpTitleMatches) {
          rowsToDeleteTemp.push(i);
          deletionLogTemp.push({
            rowIndex: i,
            rowData: data[i],
          });
        }
      }

      if (rowsToDeleteTemp.length === 0) {
        setError(`指定したワードを含む行は見つかりませんでした。`);
        return;
      }

      rowsToDeleteTemp.sort((a, b) => b - a);

      setRowsToDelete(rowsToDeleteTemp);
      setDeletionLog(prevLog => [...prevLog, ...deletionLogTemp]);
      setIsConfirmOpen(true);
      setError(null);
    } catch (err) {
      console.error('Error preparing rows for deletion:', err);
      setError('行の削除準備中にエラーが発生しました');
    }
  };

  // 正規表現で特殊文字をエスケープする関数
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& はマッチした文字列全体
  };

  const confirmDeleteRows = async () => {
    try {
      const requests = rowsToDelete.map(rowIndex => ({
        deleteDimension: {
          range: {
            sheetId: sheetId,
            dimension: "ROWS",
            startIndex: rowIndex,
            endIndex: rowIndex + 1
          }
        }
      }));

      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        { requests },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      let newData = [...data];
      for (let rowIndex of rowsToDelete) {
        newData.splice(rowIndex, 1);
      }
      setData(newData);

      setDeleteWords([]); // 削除後にワードをクリアする場合
      setIsConfirmOpen(false);
      setRowsToDelete([]);
      setError(null);
    } catch (err) {
      console.error('Error deleting rows:', err);
      setError('行の削除に失敗しました');
      setIsConfirmOpen(false);
    }
  };

  const cancelDeleteRows = () => {
    setIsConfirmOpen(false);
    setRowsToDelete([]);
  };

  const handleDeleteRow = async (rowIndex) => {
    if (rowIndex <= 0) {
      setError('ヘッダー行は削除できません。');
      return;
    }

    if (!sheetId) {
      setError('シートIDが取得されていません。');
      return;
    }

    try {
      const requestBody = {
        requests: [
          {
            deleteDimension: {
              range: {
                sheetId: sheetId,
                dimension: "ROWS",
                startIndex: rowIndex,
                endIndex: rowIndex + 1
              }
            }
          }
        ]
      };

      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      setDeletionLog(prevLog => [
        ...prevLog,
        {
          rowIndex: rowIndex,
          rowData: data[rowIndex],
        }
      ]);

      const newData = data.filter((_, index) => index !== rowIndex);
      setData(newData);
      setError(null);
    } catch (err) {
      console.error('Error deleting row:', err);
      setError('行の削除に失敗しました');
    }
  };

  const handleRestoreRow = async (log) => {
    if (!sheetId) {
      setError('シートIDが取得されていません。');
      return;
    }

    try {
      const insertRowIndex = data.length;

      const insertRequest = {
        requests: [
          {
            insertDimension: {
              range: {
                sheetId: sheetId,
                dimension: "ROWS",
                startIndex: insertRowIndex,
                endIndex: insertRowIndex + 1
              },
              inheritFromBefore: false
            }
          }
        ]
      };

      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
        insertRequest,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const range = `${sheetName}!A${insertRowIndex + 1}`;
      const values = [log.rowData];

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?valueInputOption=RAW`,
        { values },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const newData = [...data];
      newData.push(log.rowData);
      setData(newData);

      setDeletionLog(prevLog => prevLog.filter(item => item !== log));

      setError(null);
    } catch (err) {
      console.error('Error restoring row:', err);
      setError('行の復元に失敗しました');
    }
  };

  // 削除ワードの変更ハンドラー
  const handleDeleteWordsChange = (newWords) => {
    // 新しいワードセットをそのまま設定
    setDeleteWords(newWords || []);
  };

  // ワード管理用モーダルのハンドラー
  const openManageModal = () => {
    setIsManageModalOpen(true);
  };

  const closeManageModal = () => {
    setIsManageModalOpen(false);
  };

  // モーダル内でのワード管理（追加・削除）
  const handleManageAddWord = (newWord) => {
    if (newWord && !deleteWords.some(word => word.value === newWord)) {
      setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
    }
  };

  const handleManageDeleteWord = (wordToDelete) => {
    setDeleteWords(deleteWords.filter(word => word.value !== wordToDelete.value));
  };

  // 一括インポート機能のハンドラー
  const handleImportWords = () => {
    if (importText.trim() === '') return;

    // カンマ、全角カンマ、改行で分割
    const importedWords = importText.split(/[,、\n]+/).map(w => w.trim()).filter(w => w);
    const uniqueImportedWords = Array.from(new Set(importedWords));
    const formattedImportedWords = uniqueImportedWords.map(w => ({ value: w, label: w }));

    setDeleteWords(prevWords => {
      const updatedWords = [...prevWords];
      formattedImportedWords.forEach(w => {
        if (!updatedWords.some(word => word.value === w.value)) {
          updatedWords.push(w);
        }
      });
      return updatedWords;
    });

    setImportText(''); // テキストエリアをクリア
  };

  // 削除ログモーダルのスタイル
  const deletionLogModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxHeight: '80%',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  // 確認ダイアログのスタイル
  const confirmDialogStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
      maxHeight: '70%',
      overflow: 'auto',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  return (
    <div className="editable-spreadsheet-container">
      {/* Bulk Column Edit component */}
      <BulkColumnEdit 
        spreadsheetId={spreadsheetId} 
        sheetName={sheetName}
        fetchData={fetchData}
        selectedColumn={selectedColumn !== null ? columnToLetter(selectedColumn + 1) : ''}
        token={token}
      />

      {/* 削除ワード入力と管理ボタン */}
      <div className="delete-rows-container">
        <Select
          isMulti
          isClearable
          onChange={handleDeleteWordsChange}
          options={deleteWords}
          value={deleteWords}
          placeholder="削除したいワードを入力"
          components={{
            DropdownIndicator: null,
          }}
          styles={{
            multiValue: (styles) => ({
              ...styles,
              backgroundColor: '#e0e0e0',
            }),
            multiValueLabel: (styles) => ({
              ...styles,
              color: '#333',
            }),
            multiValueRemove: (styles) => ({
              ...styles,
              color: '#666',
              ':hover': {
                backgroundColor: '#ccc',
                color: '#000',
              },
            }),
          }}
        />
        <button className="manage-words-button" onClick={openManageModal}>
          ワードを管理
        </button>
        <button className="delete-rows-button" onClick={handleDeleteRowsByWord}>
          指定ワードを含む行を削除
        </button>
        <button className="view-deletion-log-button" onClick={() => setIsDeletionLogOpen(true)}>
          削除された商品を見る
        </button>
      </div>

      {/* エラーメッセージ表示 */}
      {error && <div className="error-message">{error}</div>}

      {/* 確認ダイアログ */}
      <Modal
        isOpen={isConfirmOpen}
        onRequestClose={cancelDeleteRows}
        contentLabel="削除確認"
        style={confirmDialogStyles}
      >
        <div className="confirm-dialog-content">
          <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
          <ul>
            {rowsToDelete.map((rowIndex, idx) => (
              <li key={idx}>
                行 {rowIndex}: {titleIndex !== -1 && `Title="${data[rowIndex][titleIndex]}"`} {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][jpTitleIndex]}"`}
              </li>
            ))}
          </ul>
          <button className="confirm-button" onClick={confirmDeleteRows}>削除する</button>
          <button className="cancel-button" onClick={cancelDeleteRows}>キャンセル</button>
        </div>
      </Modal>

      {/* 削除ログモーダル */}
      <Modal
        isOpen={isDeletionLogOpen}
        onRequestClose={() => setIsDeletionLogOpen(false)}
        contentLabel="削除された商品"
        style={deletionLogModalStyles}
      >
        <div className="deletion-log-content">
          <h3>削除された商品</h3>
          <button className="close-modal-button" onClick={() => setIsDeletionLogOpen(false)}>×</button>
          {deletionLog.length > 0 ? (
            <table className="deleted-items-table">
              <thead>
                <tr>
                  <th>行番号</th>
                  {titleIndex !== -1 && <th>Title</th>}
                  {jpTitleIndex !== -1 && <th>jp_title</th>}
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                {deletionLog.map((log, index) => (
                  <tr key={index}>
                    <td>{log.rowIndex}</td>
                    {titleIndex !== -1 && <td>{log.rowData[titleIndex]}</td>}
                    {jpTitleIndex !== -1 && <td>{log.rowData[jpTitleIndex]}</td>}
                    <td>
                      <button className="restore-button" onClick={() => handleRestoreRow(log)}>復元</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>削除された商品はありません。</p>
          )}
          <p>復元された商品はシートの末尾に追加されます。</p>
        </div>
      </Modal>

      {/* ワード管理モーダル */}
      <Modal
        isOpen={isManageModalOpen}
        onRequestClose={closeManageModal}
        contentLabel="削除ワード管理"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            maxHeight: '80%',
            overflow: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <h2>削除ワードを管理</h2>
        <div className="manage-words-input">
          <input
            type="text"
            placeholder="新しいワードを入力"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                const newWord = e.target.value.trim();
                if (newWord) {
                  handleManageAddWord(newWord);
                  e.target.value = '';
                }
              }
            }}
          />
          <button onClick={() => {
            const input = document.querySelector('.manage-words-input input');
            const newWord = input.value.trim();
            if (newWord) {
              handleManageAddWord(newWord);
              input.value = '';
            }
          }}>追加</button>
        </div>
        {/* 一括インポート機能 */}
        <div className="manage-words-import">
          <textarea
            placeholder="一括でワードを入力（カンマ、改行で区切る）"
            rows="4"
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
          />
          <button onClick={handleImportWords}>一括インポート</button>
        </div>
        <ul className="manage-words-list">
          {deleteWords.map((word, index) => (
            <li key={index}>
              {word.label}
              <button onClick={() => handleManageDeleteWord(word)}>削除</button>
            </li>
          ))}
        </ul>
        <button onClick={closeManageModal} className="close-modal-button">閉じる</button>
      </Modal>

      {/* Spreadsheet display */}
      {data.length === 0 ? (
        <div className="no-data-message">
          データがありません。上部の機能を使ってデータをインポートしてください。
        </div>
      ) : (
        <div className="spreadsheet-container">
          <table className="editable-spreadsheet">
            <thead>
              <tr>
                <th className="row-number-header">#</th>
                {data[0].map((header, colIndex) => (
                  <th 
                    key={colIndex} 
                    onClick={() => handleColumnClick(colIndex)}
                    className={selectedColumn === colIndex ? 'selected-column' : ''}
                  >
                    {columnToLetter(colIndex + 1)}
                  </th>
                ))}
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="row-number">{rowIndex === 0 ? '0' : rowIndex}</td>
                  {row.map((cell, colIndex) => (
                    <td 
                      key={colIndex}
                      className={selectedColumn === colIndex ? 'selected-column' : ''}
                      style={{ minWidth: '100px' }}
                    >
                      {renderCell(cell, rowIndex, colIndex)}
                    </td>
                  ))}
                  {rowIndex > 0 && (
                    <td>
                      <button 
                        className="delete-button" 
                        onClick={() => handleDeleteRow(rowIndex)}
                      >
                        削除
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EditableSpreadsheet;




// // EditableSpreadsheet.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import BulkColumnEdit from './BulkColumnEdit';
// import Select from 'react-select/creatable';
// import Modal from 'react-modal';
// import './EditableSpreadsheet.css';

// // モーダルのアクセシビリティ設定
// Modal.setAppElement('#root');

// function EditableSpreadsheet({ spreadsheetId, sheetName, token, data: initialData, fetchData }) {
//   // State variables
//   const [data, setData] = useState(initialData || []);
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   const [sheetId, setSheetId] = useState(null);
//   const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
//   const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート
//   const [importText, setImportText] = useState(''); // 一括インポート用のローカルステート

//   // Effect hooks
//   useEffect(() => {
//     setData(initialData || []);
//   }, [initialData]);

//   useEffect(() => {
//     const fetchSheetId = async () => {
//       try {
//         const response = await axios.get(
//           `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const sheet = response.data.sheets.find(s => s.properties.title === sheetName);
//         if (sheet) {
//           setSheetId(sheet.properties.sheetId);
//         } else {
//           setError(`シート名 "${sheetName}" が見つかりません。`);
//         }
//       } catch (err) {
//         console.error('Error fetching sheet ID:', err);
//         setError('シートIDの取得に失敗しました');
//       }
//     };

//     if (spreadsheetId && sheetName) {
//       fetchSheetId();
//     }
//   }, [spreadsheetId, sheetName, token]);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = data[0];
//       setTitleIndex(headers.findIndex(header => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex(header => header.toLowerCase() === 'jp_title'));
//     }
//   }, [data]);

//   // Functions
//   const updateCell = async (rowIndex, colIndex, newValue) => {
//     try {
//       const range = `${sheetName}!${columnToLetter(colIndex + 1)}${rowIndex + 1}`;
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
//         {
//           values: [[newValue]]
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       const newData = [...data];
//       newData[rowIndex][colIndex] = newValue;
//       setData(newData);
//     } catch (err) {
//       console.error('Error updating cell:', err);
//       setError('セルの更新に失敗しました');
//     }
//   };

//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   const columnToLetter = (column) => {
//     let temp, letter = '';
//     while (column > 0) {
//       temp = (column - 1) % 26;
//       letter = String.fromCharCode(temp + 65) + letter;
//       column = Math.floor((column - temp - 1) / 26);
//     }
//     return letter;
//   };

//   const renderCell = (cell, rowIndex, colIndex) => {
//     const header = data[0][colIndex]?.toLowerCase();
//     if (header === 'picurl' && rowIndex > 0) {
//       const imageUrls = cell?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else if (header === '画像' && rowIndex > 0) {
//       const picUrlIndex = data[0].findIndex(h => h.toLowerCase() === 'picurl');
//       const imageUrls = data[rowIndex][picUrlIndex]?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ''}
//           onChange={(e) => updateCell(rowIndex, colIndex, e.target.value)}
//         />
//       );
//     }
//   };

//   const handleDeleteRowsByWord = async () => {
//     if (deleteWords.length === 0) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1) {
//         setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
//         return;
//       }

//       const words = deleteWords.map(word => word.value).filter(w => w);

//       if (words.length === 0) {
//         setError('有効な削除ワードがありません。');
//         return;
//       }

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 1; i < data.length; i++) {
//         const titleCell = titleIndex !== -1 ? data[i][titleIndex] : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? data[i][jpTitleIndex] : '';

//         if (words.some(w => (titleCell && titleCell.includes(w)) || (jpTitleCell && jpTitleCell.includes(w)))) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: data[i],
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError(`指定したワードを含む行は見つかりませんでした。`);
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog(prevLog => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   const confirmDeleteRows = async () => {
//     try {
//       const requests = rowsToDelete.map(rowIndex => ({
//         deleteDimension: {
//           range: {
//             sheetId: sheetId,
//             dimension: "ROWS",
//             startIndex: rowIndex,
//             endIndex: rowIndex + 1
//           }
//         }
//       }));

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         { requests },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);

//       setDeleteWords([]); // 削除後にワードをクリアする場合
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleDeleteRow = async (rowIndex) => {
//     if (rowIndex <= 0) {
//       setError('ヘッダー行は削除できません。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const requestBody = {
//         requests: [
//           {
//             deleteDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: rowIndex,
//                 endIndex: rowIndex + 1
//               }
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         requestBody,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       setDeletionLog(prevLog => [
//         ...prevLog,
//         {
//           rowIndex: rowIndex,
//           rowData: data[rowIndex],
//         }
//       ]);

//       const newData = data.filter((_, index) => index !== rowIndex);
//       setData(newData);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting row:', err);
//       setError('行の削除に失敗しました');
//     }
//   };

//   const handleRestoreRow = async (log) => {
//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const insertRowIndex = data.length;

//       const insertRequest = {
//         requests: [
//           {
//             insertDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: insertRowIndex,
//                 endIndex: insertRowIndex + 1
//               },
//               inheritFromBefore: false
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         insertRequest,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const range = `${sheetName}!A${insertRowIndex + 1}`;
//       const values = [log.rowData];

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?valueInputOption=RAW`,
//         { values },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog(prevLog => prevLog.filter(item => item !== log));

//       setError(null);
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   // 削除ワードの変更ハンドラー
//   const handleDeleteWordsChange = (newWords) => {
//     // 新しいワードセットをそのまま設定
//     setDeleteWords(newWords || []);
//   };

//   // ワード管理用モーダルのハンドラー
//   const openManageModal = () => {
//     setIsManageModalOpen(true);
//   };

//   const closeManageModal = () => {
//     setIsManageModalOpen(false);
//   };

//   // モーダル内でのワード管理（追加・削除）
//   const handleManageAddWord = (newWord) => {
//     if (newWord && !deleteWords.some(word => word.value === newWord)) {
//       setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
//     }
//   };

//   const handleManageDeleteWord = (wordToDelete) => {
//     setDeleteWords(deleteWords.filter(word => word.value !== wordToDelete.value));
//   };

//   // 一括インポート機能のハンドラー
//   const handleImportWords = () => {
//     if (importText.trim() === '') return;

//     // カンマ、全角カンマ、改行で分割
//     const importedWords = importText.split(/[,、\n]+/).map(w => w.trim()).filter(w => w);
//     const uniqueImportedWords = Array.from(new Set(importedWords));
//     const formattedImportedWords = uniqueImportedWords.map(w => ({ value: w, label: w }));

//     setDeleteWords(prevWords => {
//       const updatedWords = [...prevWords];
//       formattedImportedWords.forEach(w => {
//         if (!updatedWords.some(word => word.value === w.value)) {
//           updatedWords.push(w);
//         }
//       });
//       return updatedWords;
//     });

//     setImportText(''); // テキストエリアをクリア
//   };

//   return (
//     <div className="editable-spreadsheet-container">
//       {/* Bulk Column Edit component */}
//       <BulkColumnEdit 
//         spreadsheetId={spreadsheetId} 
//         sheetName={sheetName}
//         fetchData={fetchData}
//         selectedColumn={selectedColumn !== null ? columnToLetter(selectedColumn + 1) : ''}
//         token={token}
//       />

//       {/* 削除ワード入力と管理ボタン */}
//       <div className="delete-rows-container">
//         <Select
//           isMulti
//           isClearable
//           onChange={handleDeleteWordsChange}
//           options={deleteWords}
//           value={deleteWords}
//           placeholder="削除したいワードを入力"
//           components={{
//             DropdownIndicator: null,
//           }}
//           styles={{
//             multiValue: (styles) => ({
//               ...styles,
//               backgroundColor: '#e0e0e0',
//             }),
//             multiValueLabel: (styles) => ({
//               ...styles,
//               color: '#333',
//             }),
//             multiValueRemove: (styles) => ({
//               ...styles,
//               color: '#666',
//               ':hover': {
//                 backgroundColor: '#ccc',
//                 color: '#000',
//               },
//             }),
//           }}
//         />
//         <button className="manage-words-button" onClick={openManageModal}>
//           ワードを管理
//         </button>
//         <button className="delete-rows-button" onClick={handleDeleteRowsByWord}>
//           指定ワードを含む行を削除
//         </button>
//         <button className="view-deletion-log-button" onClick={() => setIsDeletionLogOpen(true)}>
//           削除された商品を見る
//         </button>
//       </div>

//       {/* エラーメッセージ表示 */}
//       {error && <div className="error-message">{error}</div>}

//       {/* 確認ダイアログ */}
//       {isConfirmOpen && (
//         <div className="confirm-dialog">
//           <div className="confirm-dialog-content">
//             <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//             <ul>
//               {rowsToDelete.map((rowIndex, idx) => (
//                 <li key={idx}>
//                   行 {rowIndex}: {titleIndex !== -1 && `Title="${data[rowIndex][titleIndex]}"`} {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][jpTitleIndex]}"`}
//                 </li>
//               ))}
//             </ul>
//             <button className="confirm-button" onClick={confirmDeleteRows}>削除する</button>
//             <button className="cancel-button" onClick={cancelDeleteRows}>キャンセル</button>
//           </div>
//         </div>
//       )}

//       {/* 削除ログモーダル */}
//       {isDeletionLogOpen && (
//         <div className="deletion-log-modal">
//           <div className="deletion-log-content">
//             <h3>削除された商品</h3>
//             <button className="close-modal-button" onClick={() => setIsDeletionLogOpen(false)}>×</button>
//             {deletionLog.length > 0 ? (
//               <table className="deleted-items-table">
//                 <thead>
//                   <tr>
//                     <th>行番号</th>
//                     {titleIndex !== -1 && <th>Title</th>}
//                     {jpTitleIndex !== -1 && <th>jp_title</th>}
//                     <th>操作</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {deletionLog.map((log, index) => (
//                     <tr key={index}>
//                       <td>{log.rowIndex}</td>
//                       {titleIndex !== -1 && <td>{log.rowData[titleIndex]}</td>}
//                       {jpTitleIndex !== -1 && <td>{log.rowData[jpTitleIndex]}</td>}
//                       <td>
//                         <button className="restore-button" onClick={() => handleRestoreRow(log)}>復元</button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             ) : (
//               <p>削除された商品はありません。</p>
//             )}
//             <p>復元された商品はシートの末尾に追加されます。</p>
//           </div>
//         </div>
//       )}

//       {/* ワード管理モーダル */}
//       <Modal
//         isOpen={isManageModalOpen}
//         onRequestClose={closeManageModal}
//         contentLabel="削除ワード管理"
//         className="manage-words-modal"
//         overlayClassName="manage-words-overlay"
//       >
//         <h2>削除ワードを管理</h2>
//         <div className="manage-words-input">
//           <input
//             type="text"
//             placeholder="新しいワードを入力"
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 e.preventDefault();
//                 const newWord = e.target.value.trim();
//                 if (newWord) {
//                   handleManageAddWord(newWord);
//                   e.target.value = '';
//                 }
//               }
//             }}
//           />
//           <button onClick={() => {
//             const input = document.querySelector('.manage-words-input input');
//             const newWord = input.value.trim();
//             if (newWord) {
//               handleManageAddWord(newWord);
//               input.value = '';
//             }
//           }}>追加</button>
//         </div>
//         {/* 一括インポート機能 */}
//         <div className="manage-words-import">
//           <textarea
//             placeholder="一括でワードを入力（カンマ、改行で区切る）"
//             rows="4"
//             value={importText}
//             onChange={(e) => setImportText(e.target.value)}
//           />
//           <button onClick={handleImportWords}>一括インポート</button>
//         </div>
//         <ul className="manage-words-list">
//           {deleteWords.map((word, index) => (
//             <li key={index}>
//               {word.label}
//               <button onClick={() => handleManageDeleteWord(word)}>削除</button>
//             </li>
//           ))}
//         </ul>
//         <button onClick={closeManageModal} className="close-modal-button">閉じる</button>
//       </Modal>

//       {/* Spreadsheet display */}
//       {data.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。上部の機能を使ってデータをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <table className="editable-spreadsheet">
//             <thead>
//               <tr>
//                 <th className="row-number-header">#</th>
//                 {data[0].map((header, colIndex) => (
//                   <th 
//                     key={colIndex} 
//                     onClick={() => handleColumnClick(colIndex)}
//                     className={selectedColumn === colIndex ? 'selected-column' : ''}
//                   >
//                     {columnToLetter(colIndex + 1)}
//                   </th>
//                 ))}
//                 <th>操作</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((row, rowIndex) => (
//                 <tr key={rowIndex}>
//                   <td className="row-number">{rowIndex === 0 ? '0' : rowIndex}</td>
//                   {row.map((cell, colIndex) => (
//                     <td 
//                       key={colIndex}
//                       className={selectedColumn === colIndex ? 'selected-column' : ''}
//                       style={{ minWidth: '100px' }}
//                     >
//                       {renderCell(cell, rowIndex, colIndex)}
//                     </td>
//                   ))}
//                   {rowIndex > 0 && (
//                     <td>
//                       <button 
//                         className="delete-button" 
//                         onClick={() => handleDeleteRow(rowIndex)}
//                       >
//                         削除
//                       </button>
//                     </td>
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EditableSpreadsheet;






// // EditableSpreadsheet.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import BulkColumnEdit from './BulkColumnEdit';
// import Select from 'react-select/creatable';
// import Modal from 'react-modal';
// import './EditableSpreadsheet.css';

// // モーダルのアクセシビリティ設定
// Modal.setAppElement('#root');

// function EditableSpreadsheet({ spreadsheetId, sheetName, token, data: initialData, fetchData }) {
//   // State variables
//   const [data, setData] = useState(initialData || []);
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   const [sheetId, setSheetId] = useState(null);
//   const [deleteWords, setDeleteWords] = useState([]); // 削除ワードのステート
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
//   const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート

//   // Effect hooks
//   useEffect(() => {
//     setData(initialData || []);
//   }, [initialData]);

//   useEffect(() => {
//     const fetchSheetId = async () => {
//       try {
//         const response = await axios.get(
//           `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const sheet = response.data.sheets.find(s => s.properties.title === sheetName);
//         if (sheet) {
//           setSheetId(sheet.properties.sheetId);
//         } else {
//           setError(`シート名 "${sheetName}" が見つかりません。`);
//         }
//       } catch (err) {
//         console.error('Error fetching sheet ID:', err);
//         setError('シートIDの取得に失敗しました');
//       }
//     };

//     if (spreadsheetId && sheetName) {
//       fetchSheetId();
//     }
//   }, [spreadsheetId, sheetName, token]);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = data[0];
//       setTitleIndex(headers.findIndex(header => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex(header => header.toLowerCase() === 'jp_title'));
//     }
//   }, [data]);

//   // Functions
//   const updateCell = async (rowIndex, colIndex, newValue) => {
//     try {
//       const range = `${sheetName}!${columnToLetter(colIndex + 1)}${rowIndex + 1}`;
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
//         {
//           values: [[newValue]]
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       const newData = [...data];
//       newData[rowIndex][colIndex] = newValue;
//       setData(newData);
//     } catch (err) {
//       console.error('Error updating cell:', err);
//       setError('セルの更新に失敗しました');
//     }
//   };

//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   const columnToLetter = (column) => {
//     let temp, letter = '';
//     while (column > 0) {
//       temp = (column - 1) % 26;
//       letter = String.fromCharCode(temp + 65) + letter;
//       column = Math.floor((column - temp - 1) / 26);
//     }
//     return letter;
//   };

//   const renderCell = (cell, rowIndex, colIndex) => {
//     const header = data[0][colIndex]?.toLowerCase();
//     if (header === 'picurl' && rowIndex > 0) {
//       const imageUrls = cell?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else if (header === '画像' && rowIndex > 0) {
//       const picUrlIndex = data[0].findIndex(h => h.toLowerCase() === 'picurl');
//       const imageUrls = data[rowIndex][picUrlIndex]?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ''}
//           onChange={(e) => updateCell(rowIndex, colIndex, e.target.value)}
//         />
//       );
//     }
//   };

//   const handleDeleteRowsByWord = async () => {
//     if (deleteWords.length === 0) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1) {
//         setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
//         return;
//       }

//       const words = deleteWords.map(word => word.value).filter(w => w);

//       if (words.length === 0) {
//         setError('有効な削除ワードがありません。');
//         return;
//       }

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 1; i < data.length; i++) {
//         const titleCell = titleIndex !== -1 ? data[i][titleIndex] : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? data[i][jpTitleIndex] : '';

//         if (words.some(w => (titleCell && titleCell.includes(w)) || (jpTitleCell && jpTitleCell.includes(w)))) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: data[i],
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError(`指定したワードを含む行は見つかりませんでした。`);
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog(prevLog => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   const confirmDeleteRows = async () => {
//     try {
//       const requests = rowsToDelete.map(rowIndex => ({
//         deleteDimension: {
//           range: {
//             sheetId: sheetId,
//             dimension: "ROWS",
//             startIndex: rowIndex,
//             endIndex: rowIndex + 1
//           }
//         }
//       }));

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         { requests },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);

//       setDeleteWords([]); // 削除後にワードをクリアする場合
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleDeleteRow = async (rowIndex) => {
//     if (rowIndex <= 0) {
//       setError('ヘッダー行は削除できません。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const requestBody = {
//         requests: [
//           {
//             deleteDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: rowIndex,
//                 endIndex: rowIndex + 1
//               }
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         requestBody,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       setDeletionLog(prevLog => [
//         ...prevLog,
//         {
//           rowIndex: rowIndex,
//           rowData: data[rowIndex],
//         }
//       ]);

//       const newData = data.filter((_, index) => index !== rowIndex);
//       setData(newData);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting row:', err);
//       setError('行の削除に失敗しました');
//     }
//   };

//   const handleRestoreRow = async (log) => {
//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const insertRowIndex = data.length;

//       const insertRequest = {
//         requests: [
//           {
//             insertDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: insertRowIndex,
//                 endIndex: insertRowIndex + 1
//               },
//               inheritFromBefore: false
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         insertRequest,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const range = `${sheetName}!A${insertRowIndex + 1}`;
//       const values = [log.rowData];

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?valueInputOption=RAW`,
//         { values },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog(prevLog => prevLog.filter(item => item !== log));

//       setError(null);
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   // 削除ワードの変更ハンドラー
//   const handleDeleteWordsChange = (newWords) => {
//     // 複数ワードを一度に追加できるように、カンマや改行で区切られた入力を処理
//     const flattened = newWords.flatMap(word => {
//       if (typeof word === 'string') {
//         return word.split(/[,、\n]+/).map(w => w.trim()).filter(w => w);
//       }
//       return word;
//     });
//     // 重複を避けるためにセットに変換
//     const uniqueWords = Array.from(new Set(flattened.map(w => w.value || w)));
//     const formattedWords = uniqueWords.map(w => ({ value: w, label: w }));
//     setDeleteWords(formattedWords);
//   };

//   // ワード管理用モーダルのハンドラー
//   const openManageModal = () => {
//     setIsManageModalOpen(true);
//   };

//   const closeManageModal = () => {
//     setIsManageModalOpen(false);
//   };

//   // モーダル内でのワード管理（追加・削除）
//   const handleManageAddWord = (newWord) => {
//     if (newWord && !deleteWords.some(word => word.value === newWord)) {
//       setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
//     }
//   };

//   const handleManageDeleteWord = (wordToDelete) => {
//     setDeleteWords(deleteWords.filter(word => word.value !== wordToDelete.value));
//   };

//   return (
//     <div className="editable-spreadsheet-container">
//       {/* Bulk Column Edit component */}
//       <BulkColumnEdit 
//         spreadsheetId={spreadsheetId} 
//         sheetName={sheetName}
//         fetchData={fetchData}
//         selectedColumn={selectedColumn !== null ? columnToLetter(selectedColumn + 1) : ''}
//         token={token}
//       />

//       {/* 削除ワード入力と管理ボタン */}
//       <div className="delete-rows-container">
//         <Select
//           isMulti
//           isClearable
//           onChange={handleDeleteWordsChange}
//           options={deleteWords}
//           value={deleteWords}
//           placeholder="削除したいワードを入力（カンマ区切りでもOK）"
//           components={{
//             DropdownIndicator: null,
//           }}
//           onInputChange={(inputValue, { action }) => {
//             if (action === 'input-change') {
//               // 入力中にカンマや改行が入力された場合、自動的にワードを分割して追加
//               const words = inputValue.split(/[,、\n]+/).map(w => w.trim()).filter(w => w);
//               if (words.length > 1) {
//                 const newOptions = words.map(w => ({ value: w, label: w }));
//                 setDeleteWords(prevWords => {
//                   const updatedWords = [...prevWords];
//                   words.forEach(w => {
//                     if (!updatedWords.some(word => word.value === w)) {
//                       updatedWords.push({ value: w, label: w });
//                     }
//                   });
//                   return updatedWords;
//                 });
//                 return '';
//               }
//             }
//             return inputValue;
//           }}
//           onKeyDown={(event) => {
//             if (!event.target.value) return;
//             switch (event.key) {
//               case 'Enter':
//               case 'Tab':
//               case ',':
//               case '、':
//                 event.preventDefault();
//                 const newWords = event.target.value.split(/[,、\n]+/).map(w => w.trim()).filter(w => w);
//                 setDeleteWords(prevWords => {
//                   const updatedWords = [...prevWords];
//                   newWords.forEach(w => {
//                     if (!updatedWords.some(word => word.value === w)) {
//                       updatedWords.push({ value: w, label: w });
//                     }
//                   });
//                   return updatedWords;
//                 });
//                 event.target.value = '';
//                 break;
//               default:
//                 break;
//             }
//           }}
//           styles={{
//             multiValue: (styles) => ({
//               ...styles,
//               backgroundColor: '#e0e0e0',
//             }),
//             multiValueLabel: (styles) => ({
//               ...styles,
//               color: '#333',
//             }),
//             multiValueRemove: (styles) => ({
//               ...styles,
//               color: '#666',
//               ':hover': {
//                 backgroundColor: '#ccc',
//                 color: '#000',
//               },
//             }),
//           }}
//         />
//         <button className="manage-words-button" onClick={openManageModal}>
//           ワードを管理
//         </button>
//         <button className="delete-rows-button" onClick={handleDeleteRowsByWord}>
//           指定ワードを含む行を削除
//         </button>
//         <button className="view-deletion-log-button" onClick={() => setIsDeletionLogOpen(true)}>
//           削除された商品を見る
//         </button>
//       </div>

//       {/* エラーメッセージ表示 */}
//       {error && <div className="error-message">{error}</div>}

//       {/* 確認ダイアログ */}
//       {isConfirmOpen && (
//         <div className="confirm-dialog">
//           <div className="confirm-dialog-content">
//             <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//             <ul>
//               {rowsToDelete.map((rowIndex, idx) => (
//                 <li key={idx}>
//                   行 {rowIndex}: {titleIndex !== -1 && `Title="${data[rowIndex][titleIndex]}"`} {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][jpTitleIndex]}"`}
//                 </li>
//               ))}
//             </ul>
//             <button className="confirm-button" onClick={confirmDeleteRows}>削除する</button>
//             <button className="cancel-button" onClick={cancelDeleteRows}>キャンセル</button>
//           </div>
//         </div>
//       )}

//       {/* 削除ログモーダル */}
//       {isDeletionLogOpen && (
//         <div className="deletion-log-modal">
//           <div className="deletion-log-content">
//             <h3>削除された商品</h3>
//             <button className="close-modal-button" onClick={() => setIsDeletionLogOpen(false)}>×</button>
//             {deletionLog.length > 0 ? (
//               <table className="deleted-items-table">
//                 <thead>
//                   <tr>
//                     <th>行番号</th>
//                     {titleIndex !== -1 && <th>Title</th>}
//                     {jpTitleIndex !== -1 && <th>jp_title</th>}
//                     <th>操作</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {deletionLog.map((log, index) => (
//                     <tr key={index}>
//                       <td>{log.rowIndex}</td>
//                       {titleIndex !== -1 && <td>{log.rowData[titleIndex]}</td>}
//                       {jpTitleIndex !== -1 && <td>{log.rowData[jpTitleIndex]}</td>}
//                       <td>
//                         <button className="restore-button" onClick={() => handleRestoreRow(log)}>復元</button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             ) : (
//               <p>削除された商品はありません。</p>
//             )}
//             <p>復元された商品はシートの末尾に追加されます。</p>
//           </div>
//         </div>
//       )}

//       {/* ワード管理モーダル */}
//       <Modal
//         isOpen={isManageModalOpen}
//         onRequestClose={closeManageModal}
//         contentLabel="削除ワード管理"
//         className="manage-words-modal"
//         overlayClassName="manage-words-overlay"
//       >
//         <h2>削除ワードを管理</h2>
//         <div className="manage-words-input">
//           <input
//             type="text"
//             placeholder="新しいワードを入力"
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 e.preventDefault();
//                 const newWord = e.target.value.trim();
//                 if (newWord) {
//                   handleManageAddWord(newWord);
//                   e.target.value = '';
//                 }
//               }
//             }}
//           />
//           <button onClick={() => {
//             const input = document.querySelector('.manage-words-input input');
//             const newWord = input.value.trim();
//             if (newWord) {
//               handleManageAddWord(newWord);
//               input.value = '';
//             }
//           }}>追加</button>
//         </div>
//         <ul className="manage-words-list">
//           {deleteWords.map((word, index) => (
//             <li key={index}>
//               {word.label}
//               <button onClick={() => handleManageDeleteWord(word)}>削除</button>
//             </li>
//           ))}
//         </ul>
//         <button onClick={closeManageModal} className="close-modal-button">閉じる</button>
//       </Modal>

//       {/* Spreadsheet display */}
//       {data.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。上部の機能を使ってデータをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <table className="editable-spreadsheet">
//             <thead>
//               <tr>
//                 <th className="row-number-header">#</th>
//                 {data[0].map((header, colIndex) => (
//                   <th 
//                     key={colIndex} 
//                     onClick={() => handleColumnClick(colIndex)}
//                     className={selectedColumn === colIndex ? 'selected-column' : ''}
//                   >
//                     {columnToLetter(colIndex + 1)}
//                   </th>
//                 ))}
//                 <th>操作</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((row, rowIndex) => (
//                 <tr key={rowIndex}>
//                   <td className="row-number">{rowIndex === 0 ? '0' : rowIndex}</td>
//                   {row.map((cell, colIndex) => (
//                     <td 
//                       key={colIndex}
//                       className={selectedColumn === colIndex ? 'selected-column' : ''}
//                       style={{ minWidth: '100px' }}
//                     >
//                       {renderCell(cell, rowIndex, colIndex)}
//                     </td>
//                   ))}
//                   {rowIndex > 0 && (
//                     <td>
//                       <button 
//                         className="delete-button" 
//                         onClick={() => handleDeleteRow(rowIndex)}
//                       >
//                         削除
//                       </button>
//                     </td>
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EditableSpreadsheet;



// // EditableSpreadsheet.js
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import BulkColumnEdit from './BulkColumnEdit';
// import Select from 'react-select/creatable';
// import Modal from 'react-modal';
// import './EditableSpreadsheet.css';

// // モーダルのアクセシビリティ設定
// Modal.setAppElement('#root');

// function EditableSpreadsheet({ spreadsheetId, sheetName, token, data: initialData, fetchData }) {
//   // State variables
//   const [data, setData] = useState(initialData || []);
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   const [sheetId, setSheetId] = useState(null);
//   const [deleteWords, setDeleteWords] = useState([]); // 統合された削除ワードステート
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false);
//   const [isManageModalOpen, setIsManageModalOpen] = useState(false); // 管理モーダルのステート

//   // Effect hooks
//   useEffect(() => {
//     setData(initialData || []);
//   }, [initialData]);

//   useEffect(() => {
//     const fetchSheetId = async () => {
//       try {
//         const response = await axios.get(
//           `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const sheet = response.data.sheets.find(s => s.properties.title === sheetName);
//         if (sheet) {
//           setSheetId(sheet.properties.sheetId);
//         } else {
//           setError(`シート名 "${sheetName}" が見つかりません。`);
//         }
//       } catch (err) {
//         console.error('Error fetching sheet ID:', err);
//         setError('シートIDの取得に失敗しました');
//       }
//     };

//     if (spreadsheetId && sheetName) {
//       fetchSheetId();
//     }
//   }, [spreadsheetId, sheetName, token]);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = data[0];
//       setTitleIndex(headers.findIndex(header => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex(header => header.toLowerCase() === 'jp_title'));
//     }
//   }, [data]);

//   // Functions
//   const updateCell = async (rowIndex, colIndex, newValue) => {
//     try {
//       const range = `${sheetName}!${columnToLetter(colIndex + 1)}${rowIndex + 1}`;
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
//         {
//           values: [[newValue]]
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       const newData = [...data];
//       newData[rowIndex][colIndex] = newValue;
//       setData(newData);
//     } catch (err) {
//       console.error('Error updating cell:', err);
//       setError('セルの更新に失敗しました');
//     }
//   };

//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   const columnToLetter = (column) => {
//     let temp, letter = '';
//     while (column > 0) {
//       temp = (column - 1) % 26;
//       letter = String.fromCharCode(temp + 65) + letter;
//       column = Math.floor((column - temp - 1) / 26);
//     }
//     return letter;
//   };

//   const renderCell = (cell, rowIndex, colIndex) => {
//     const header = data[0][colIndex]?.toLowerCase();
//     if (header === 'picurl' && rowIndex > 0) {
//       const imageUrls = cell?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else if (header === '画像' && rowIndex > 0) {
//       const picUrlIndex = data[0].findIndex(h => h.toLowerCase() === 'picurl');
//       const imageUrls = data[rowIndex][picUrlIndex]?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ''}
//           onChange={(e) => updateCell(rowIndex, colIndex, e.target.value)}
//         />
//       );
//     }
//   };

//   const handleDeleteRowsByWord = async () => {
//     if (deleteWords.length === 0) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1) {
//         setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
//         return;
//       }

//       const words = deleteWords.map(word => word.value).filter(w => w);

//       if (words.length === 0) {
//         setError('有効な削除ワードがありません。');
//         return;
//       }

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 1; i < data.length; i++) {
//         const titleCell = titleIndex !== -1 ? data[i][titleIndex] : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? data[i][jpTitleIndex] : '';

//         if (words.some(w => (titleCell && titleCell.includes(w)) || (jpTitleCell && jpTitleCell.includes(w)))) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: data[i],
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError(`指定したワードを含む行は見つかりませんでした。`);
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog(prevLog => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   const confirmDeleteRows = async () => {
//     try {
//       const requests = rowsToDelete.map(rowIndex => ({
//         deleteDimension: {
//           range: {
//             sheetId: sheetId,
//             dimension: "ROWS",
//             startIndex: rowIndex,
//             endIndex: rowIndex + 1
//           }
//         }
//       }));

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         { requests },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);

//       setDeleteWords([]); // 削除後にワードをクリアする場合
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleDeleteRow = async (rowIndex) => {
//     if (rowIndex <= 0) {
//       setError('ヘッダー行は削除できません。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const requestBody = {
//         requests: [
//           {
//             deleteDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: rowIndex,
//                 endIndex: rowIndex + 1
//               }
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         requestBody,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       setDeletionLog(prevLog => [
//         ...prevLog,
//         {
//           rowIndex: rowIndex,
//           rowData: data[rowIndex],
//         }
//       ]);

//       const newData = data.filter((_, index) => index !== rowIndex);
//       setData(newData);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting row:', err);
//       setError('行の削除に失敗しました');
//     }
//   };

//   const handleRestoreRow = async (log) => {
//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const insertRowIndex = data.length;

//       const insertRequest = {
//         requests: [
//           {
//             insertDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: insertRowIndex,
//                 endIndex: insertRowIndex + 1
//               },
//               inheritFromBefore: false
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         insertRequest,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const range = `${sheetName}!A${insertRowIndex + 1}`;
//       const values = [log.rowData];

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?valueInputOption=RAW`,
//         { values },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog(prevLog => prevLog.filter(item => item !== log));

//       setError(null);
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   // 削除ワードの変更ハンドラー
//   const handleDeleteWordsChange = (newWords) => {
//     setDeleteWords(newWords);
//   };

//   // ワード管理用モーダルのハンドラー
//   const openManageModal = () => {
//     setIsManageModalOpen(true);
//   };

//   const closeManageModal = () => {
//     setIsManageModalOpen(false);
//   };

//   // モーダル内でのワード管理（追加・削除）
//   const handleManageAddWord = (newWord) => {
//     if (newWord && !deleteWords.some(word => word.value === newWord)) {
//       setDeleteWords([...deleteWords, { value: newWord, label: newWord }]);
//     }
//   };

//   const handleManageDeleteWord = (wordToDelete) => {
//     setDeleteWords(deleteWords.filter(word => word.value !== wordToDelete.value));
//   };

//   return (
//     <div className="editable-spreadsheet-container">
//       {/* Bulk Column Edit component */}
//       <BulkColumnEdit 
//         spreadsheetId={spreadsheetId} 
//         sheetName={sheetName}
//         fetchData={fetchData}
//         selectedColumn={selectedColumn !== null ? columnToLetter(selectedColumn + 1) : ''}
//         token={token}
//       />

//       {/* 削除ワード入力と管理ボタン */}
//       <div className="delete-rows-container">
//         <Select
//           isMulti
//           isClearable
//           onChange={handleDeleteWordsChange}
//           options={deleteWords}
//           value={deleteWords}
//           placeholder="削除したいワードを入力"
//           components={{
//             DropdownIndicator: null,
//           }}
//           styles={{
//             multiValue: (styles) => ({
//               ...styles,
//               backgroundColor: '#e0e0e0',
//             }),
//             multiValueLabel: (styles) => ({
//               ...styles,
//               color: '#333',
//             }),
//             multiValueRemove: (styles) => ({
//               ...styles,
//               color: '#666',
//               ':hover': {
//                 backgroundColor: '#ccc',
//                 color: '#000',
//               },
//             }),
//           }}
//         />
//         <button className="manage-words-button" onClick={openManageModal}>
//           ワードを管理
//         </button>
//         <button className="delete-rows-button" onClick={handleDeleteRowsByWord}>
//           指定ワードを含む行を削除
//         </button>
//         <button className="view-deletion-log-button" onClick={() => setIsDeletionLogOpen(true)}>
//           削除された商品を見る
//         </button>
//       </div>

//       {/* エラーメッセージ表示 */}
//       {error && <div className="error-message">{error}</div>}

//       {/* 確認ダイアログ */}
//       {isConfirmOpen && (
//         <div className="confirm-dialog">
//           <div className="confirm-dialog-content">
//             <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//             <ul>
//               {rowsToDelete.map((rowIndex, idx) => (
//                 <li key={idx}>
//                   行 {rowIndex}: {titleIndex !== -1 && `Title="${data[rowIndex][titleIndex]}"`} {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][jpTitleIndex]}"`}
//                 </li>
//               ))}
//             </ul>
//             <button className="confirm-button" onClick={confirmDeleteRows}>削除する</button>
//             <button className="cancel-button" onClick={cancelDeleteRows}>キャンセル</button>
//           </div>
//         </div>
//       )}

//       {/* 削除ログモーダル */}
//       {isDeletionLogOpen && (
//         <div className="deletion-log-modal">
//           <div className="deletion-log-content">
//             <h3>削除された商品</h3>
//             <button className="close-modal-button" onClick={() => setIsDeletionLogOpen(false)}>×</button>
//             {deletionLog.length > 0 ? (
//               <table className="deleted-items-table">
//                 <thead>
//                   <tr>
//                     <th>行番号</th>
//                     {titleIndex !== -1 && <th>Title</th>}
//                     {jpTitleIndex !== -1 && <th>jp_title</th>}
//                     <th>操作</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {deletionLog.map((log, index) => (
//                     <tr key={index}>
//                       <td>{log.rowIndex}</td>
//                       {titleIndex !== -1 && <td>{log.rowData[titleIndex]}</td>}
//                       {jpTitleIndex !== -1 && <td>{log.rowData[jpTitleIndex]}</td>}
//                       <td>
//                         <button className="restore-button" onClick={() => handleRestoreRow(log)}>復元</button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             ) : (
//               <p>削除された商品はありません。</p>
//             )}
//             <p>復元された商品はシートの末尾に追加されます。</p>
//           </div>
//         </div>
//       )}

//       {/* ワード管理モーダル */}
//       <Modal
//         isOpen={isManageModalOpen}
//         onRequestClose={closeManageModal}
//         contentLabel="削除ワード管理"
//         className="manage-words-modal"
//         overlayClassName="manage-words-overlay"
//       >
//         <h2>削除ワードを管理</h2>
//         <div className="manage-words-input">
//           <input
//             type="text"
//             placeholder="新しいワードを入力"
//             onKeyDown={(e) => {
//               if (e.key === 'Enter') {
//                 e.preventDefault();
//                 const newWord = e.target.value.trim();
//                 if (newWord) {
//                   handleManageAddWord(newWord);
//                   e.target.value = '';
//                 }
//               }
//             }}
//           />
//           <button onClick={() => {
//             const input = document.querySelector('.manage-words-input input');
//             const newWord = input.value.trim();
//             if (newWord) {
//               handleManageAddWord(newWord);
//               input.value = '';
//             }
//           }}>追加</button>
//         </div>
//         <ul className="manage-words-list">
//           {deleteWords.map((word, index) => (
//             <li key={index}>
//               {word.label}
//               <button onClick={() => handleManageDeleteWord(word)}>削除</button>
//             </li>
//           ))}
//         </ul>
//         <button onClick={closeManageModal} className="close-modal-button">閉じる</button>
//       </Modal>

//       {/* Spreadsheet display */}
//       {data.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。上部の機能を使ってデータをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <table className="editable-spreadsheet">
//             <thead>
//               <tr>
//                 <th className="row-number-header">#</th>
//                 {data[0].map((header, colIndex) => (
//                   <th 
//                     key={colIndex} 
//                     onClick={() => handleColumnClick(colIndex)}
//                     className={selectedColumn === colIndex ? 'selected-column' : ''}
//                   >
//                     {columnToLetter(colIndex + 1)}
//                   </th>
//                 ))}
//                 <th>操作</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((row, rowIndex) => (
//                 <tr key={rowIndex}>
//                   <td className="row-number">{rowIndex === 0 ? '0' : rowIndex}</td>
//                   {row.map((cell, colIndex) => (
//                     <td 
//                       key={colIndex}
//                       className={selectedColumn === colIndex ? 'selected-column' : ''}
//                       style={{ minWidth: '100px' }}
//                     >
//                       {renderCell(cell, rowIndex, colIndex)}
//                     </td>
//                   ))}
//                   {rowIndex > 0 && (
//                     <td>
//                       <button 
//                         className="delete-button" 
//                         onClick={() => handleDeleteRow(rowIndex)}
//                       >
//                         削除
//                       </button>
//                     </td>
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EditableSpreadsheet;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import BulkColumnEdit from './BulkColumnEdit';
// import './EditableSpreadsheet.css';

// function EditableSpreadsheet({ spreadsheetId, sheetName, token, data: initialData, fetchData }) {
//   // State variables
//   const [data, setData] = useState(initialData || []);
//   const [error, setError] = useState(null);
//   const [selectedColumn, setSelectedColumn] = useState(null);
//   const [sheetId, setSheetId] = useState(null);
//   const [deleteWord, setDeleteWord] = useState('');
//   const [isConfirmOpen, setIsConfirmOpen] = useState(false);
//   const [rowsToDelete, setRowsToDelete] = useState([]);
//   const [deletionLog, setDeletionLog] = useState([]);
//   const [titleIndex, setTitleIndex] = useState(-1);
//   const [jpTitleIndex, setJpTitleIndex] = useState(-1);
//   const [isDeletionLogOpen, setIsDeletionLogOpen] = useState(false); // モーダルの開閉状態

//   // Effect hooks
//   useEffect(() => {
//     setData(initialData || []);
//   }, [initialData]);

//   useEffect(() => {
//     const fetchSheetId = async () => {
//       try {
//         const response = await axios.get(
//           `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         const sheet = response.data.sheets.find(s => s.properties.title === sheetName);
//         if (sheet) {
//           setSheetId(sheet.properties.sheetId);
//         } else {
//           setError(`シート名 "${sheetName}" が見つかりません。`);
//         }
//       } catch (err) {
//         console.error('Error fetching sheet ID:', err);
//         setError('シートIDの取得に失敗しました');
//       }
//     };

//     if (spreadsheetId && sheetName) {
//       fetchSheetId();
//     }
//   }, [spreadsheetId, sheetName, token]);

//   useEffect(() => {
//     if (data && data.length > 0) {
//       const headers = data[0];
//       setTitleIndex(headers.findIndex(header => header.toLowerCase() === 'title'));
//       setJpTitleIndex(headers.findIndex(header => header.toLowerCase() === 'jp_title'));
//     }
//   }, [data]);

//   // Functions
//   const updateCell = async (rowIndex, colIndex, newValue) => {
//     try {
//       const range = `${sheetName}!${columnToLetter(colIndex + 1)}${rowIndex + 1}`;
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
//         {
//           values: [[newValue]]
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`
//           }
//         }
//       );
//       const newData = [...data];
//       newData[rowIndex][colIndex] = newValue;
//       setData(newData);
//     } catch (err) {
//       console.error('Error updating cell:', err);
//       setError('セルの更新に失敗しました');
//     }
//   };

//   const handleColumnClick = (colIndex) => {
//     setSelectedColumn(colIndex);
//   };

//   const columnToLetter = (column) => {
//     let temp, letter = '';
//     while (column > 0) {
//       temp = (column - 1) % 26;
//       letter = String.fromCharCode(temp + 65) + letter;
//       column = Math.floor((column - temp - 1) / 26);
//     }
//     return letter;
//   };

//   const renderCell = (cell, rowIndex, colIndex) => {
//     const header = data[0][colIndex]?.toLowerCase();
//     if (header === 'picurl' && rowIndex > 0) {
//       const imageUrls = cell?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else if (header === '画像' && rowIndex > 0) {
//       const picUrlIndex = data[0].findIndex(h => h.toLowerCase() === 'picurl');
//       const imageUrls = data[rowIndex][picUrlIndex]?.split('|') || [];
//       return (
//         <img 
//           src={imageUrls[0]} 
//           alt="Product" 
//           style={{ maxWidth: '100px', maxHeight: '100px' }}
//         />
//       );
//     } else {
//       return (
//         <input
//           value={cell || ''}
//           onChange={(e) => updateCell(rowIndex, colIndex, e.target.value)}
//         />
//       );
//     }
//   };

//   const handleDeleteRowsByWord = async (word) => {
//     if (!word) {
//       setError('削除したいワードを入力してください。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       if (titleIndex === -1 && jpTitleIndex === -1) {
//         setError('"Title" カラムまたは "jp_title" カラムが見つかりません。');
//         return;
//       }

//       const words = word.split(',').map(w => w.trim()).filter(w => w);

//       const rowsToDeleteTemp = [];
//       const deletionLogTemp = [];

//       for (let i = 1; i < data.length; i++) {
//         const titleCell = titleIndex !== -1 ? data[i][titleIndex] : '';
//         const jpTitleCell = jpTitleIndex !== -1 ? data[i][jpTitleIndex] : '';

//         if (words.some(w => (titleCell && titleCell.includes(w)) || (jpTitleCell && jpTitleCell.includes(w)))) {
//           rowsToDeleteTemp.push(i);
//           deletionLogTemp.push({
//             rowIndex: i,
//             rowData: data[i],
//           });
//         }
//       }

//       if (rowsToDeleteTemp.length === 0) {
//         setError(`指定したワード "${word}" を含む行は見つかりませんでした。`);
//         return;
//       }

//       rowsToDeleteTemp.sort((a, b) => b - a);

//       setRowsToDelete(rowsToDeleteTemp);
//       setDeletionLog(prevLog => [...prevLog, ...deletionLogTemp]);
//       setIsConfirmOpen(true);
//       setError(null);
//     } catch (err) {
//       console.error('Error preparing rows for deletion:', err);
//       setError('行の削除準備中にエラーが発生しました');
//     }
//   };

//   const confirmDeleteRows = async () => {
//     try {
//       const requests = rowsToDelete.map(rowIndex => ({
//         deleteDimension: {
//           range: {
//             sheetId: sheetId,
//             dimension: "ROWS",
//             startIndex: rowIndex,
//             endIndex: rowIndex + 1
//           }
//         }
//       }));

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         { requests },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       let newData = [...data];
//       for (let rowIndex of rowsToDelete) {
//         newData.splice(rowIndex, 1);
//       }
//       setData(newData);

//       setDeleteWord('');
//       setIsConfirmOpen(false);
//       setRowsToDelete([]);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting rows:', err);
//       setError('行の削除に失敗しました');
//       setIsConfirmOpen(false);
//     }
//   };

//   const cancelDeleteRows = () => {
//     setIsConfirmOpen(false);
//     setRowsToDelete([]);
//   };

//   const handleDeleteRow = async (rowIndex) => {
//     if (rowIndex <= 0) {
//       setError('ヘッダー行は削除できません。');
//       return;
//     }

//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const requestBody = {
//         requests: [
//           {
//             deleteDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: rowIndex,
//                 endIndex: rowIndex + 1
//               }
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         requestBody,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       setDeletionLog(prevLog => [
//         ...prevLog,
//         {
//           rowIndex: rowIndex,
//           rowData: data[rowIndex],
//         }
//       ]);

//       const newData = data.filter((_, index) => index !== rowIndex);
//       setData(newData);
//       setError(null);
//     } catch (err) {
//       console.error('Error deleting row:', err);
//       setError('行の削除に失敗しました');
//     }
//   };

//   const handleRestoreRow = async (log) => {
//     if (!sheetId) {
//       setError('シートIDが取得されていません。');
//       return;
//     }

//     try {
//       const insertRowIndex = data.length;

//       const insertRequest = {
//         requests: [
//           {
//             insertDimension: {
//               range: {
//                 sheetId: sheetId,
//                 dimension: "ROWS",
//                 startIndex: insertRowIndex,
//                 endIndex: insertRowIndex + 1
//               },
//               inheritFromBefore: false
//             }
//           }
//         ]
//       };

//       await axios.post(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//         insertRequest,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const range = `${sheetName}!A${insertRowIndex + 1}`;
//       const values = [log.rowData];

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${encodeURIComponent(range)}?valueInputOption=RAW`,
//         { values },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             'Content-Type': 'application/json'
//           }
//         }
//       );

//       const newData = [...data];
//       newData.push(log.rowData);
//       setData(newData);

//       setDeletionLog(prevLog => prevLog.filter(item => item !== log));

//       setError(null);
//     } catch (err) {
//       console.error('Error restoring row:', err);
//       setError('行の復元に失敗しました');
//     }
//   };

//   return (
//     <div className="editable-spreadsheet-container">
//       {/* Bulk Column Edit component */}
//       <BulkColumnEdit 
//         spreadsheetId={spreadsheetId} 
//         sheetName={sheetName}
//         fetchData={fetchData}
//         selectedColumn={selectedColumn !== null ? columnToLetter(selectedColumn + 1) : ''}
//         token={token}
//       />

//       {/* Delete rows by word input and button */}
//       <div className="delete-rows-container">
//         <input
//           type="text"
//           value={deleteWord}
//           onChange={(e) => setDeleteWord(e.target.value)}
//           placeholder="削除したいワードを入力（カンマ区切り）"
//         />
//         <button className="delete-rows-button" onClick={() => handleDeleteRowsByWord(deleteWord)}>
//           指定ワードを含む行を削除
//         </button>
//         <button className="view-deletion-log-button" onClick={() => setIsDeletionLogOpen(true)}>
//           削除された商品を見る
//         </button>
//       </div>

//       {/* Error message display */}
//       {error && <div className="error-message">{error}</div>}

//       {/* Confirmation dialog */}
//       {isConfirmOpen && (
//         <div className="confirm-dialog">
//           <div className="confirm-dialog-content">
//             <p>以下の {rowsToDelete.length} 行を削除します。よろしいですか？</p>
//             <ul>
//               {rowsToDelete.map((rowIndex, idx) => (
//                 <li key={idx}>
//                   行 {rowIndex}: {titleIndex !== -1 && `Title="${data[rowIndex][titleIndex]}"`} {jpTitleIndex !== -1 && `jp_title="${data[rowIndex][jpTitleIndex]}"`}
//                 </li>
//               ))}
//             </ul>
//             <button className="confirm-button" onClick={confirmDeleteRows}>削除する</button>
//             <button className="cancel-button" onClick={cancelDeleteRows}>キャンセル</button>
//           </div>
//         </div>
//       )}

//       {/* Deletion Log Modal */}
//       {isDeletionLogOpen && (
//         <div className="deletion-log-modal">
//           <div className="deletion-log-content">
//             <h3>削除された商品</h3>
//             <button className="close-modal-button" onClick={() => setIsDeletionLogOpen(false)}>×</button>
//             {deletionLog.length > 0 ? (
//               <table className="deleted-items-table">
//                 <thead>
//                   <tr>
//                     <th>行番号</th>
//                     {titleIndex !== -1 && <th>Title</th>}
//                     {jpTitleIndex !== -1 && <th>jp_title</th>}
//                     <th>操作</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {deletionLog.map((log, index) => (
//                     <tr key={index}>
//                       <td>{log.rowIndex}</td>
//                       {titleIndex !== -1 && <td>{log.rowData[titleIndex]}</td>}
//                       {jpTitleIndex !== -1 && <td>{log.rowData[jpTitleIndex]}</td>}
//                       <td>
//                         <button className="restore-button" onClick={() => handleRestoreRow(log)}>復元</button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             ) : (
//               <p>削除された商品はありません。</p>
//             )}
//             <p>復元された商品はシートの末尾に追加されます。</p>
//           </div>
//         </div>
//       )}

//       {/* Spreadsheet display */}
//       {data.length === 0 ? (
//         <div className="no-data-message">
//           データがありません。上部の機能を使ってデータをインポートしてください。
//         </div>
//       ) : (
//         <div className="spreadsheet-container">
//           <table className="editable-spreadsheet">
//             <thead>
//               <tr>
//                 <th className="row-number-header">#</th>
//                 {data[0].map((header, colIndex) => (
//                   <th 
//                     key={colIndex} 
//                     onClick={() => handleColumnClick(colIndex)}
//                     className={selectedColumn === colIndex ? 'selected-column' : ''}
//                   >
//                     {columnToLetter(colIndex + 1)}
//                   </th>
//                 ))}
//                 <th>操作</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((row, rowIndex) => (
//                 <tr key={rowIndex}>
//                   <td className="row-number">{rowIndex === 0 ? '0' : rowIndex}</td>
//                   {row.map((cell, colIndex) => (
//                     <td 
//                       key={colIndex}
//                       className={selectedColumn === colIndex ? 'selected-column' : ''}
//                       style={{ minWidth: '100px' }}
//                     >
//                       {renderCell(cell, rowIndex, colIndex)}
//                     </td>
//                   ))}
//                   {rowIndex > 0 && (
//                     <td>
//                       <button 
//                         className="delete-button" 
//                         onClick={() => handleDeleteRow(rowIndex)}
//                       >
//                         削除
//                       </button>
//                     </td>
//                   )}
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       )}
//     </div>
//   );
// }

// export default EditableSpreadsheet;

