import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Select,
  MenuItem,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  CardActions,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Folder as FolderIcon,
  Description as DescriptionIcon,
  Add as AddIcon,
  Edit as EditIcon,
  PlayArrow as ApplyIcon,
  FileCopy as DuplicateIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import axios from 'axios';

function ColumnNameBasedTemplateManager({ spreadsheetId, sheetName, token, fetchData }) {
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newOperation, setNewOperation] = useState({ columnName: '', action: '', value: '' });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteCategoryDialogOpen, setIsDeleteCategoryDialogOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [isNewTemplateDialogOpen, setIsNewTemplateDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuTarget, setMenuTarget] = useState(null);
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [newName, setNewName] = useState('');
  const [renameTarget, setRenameTarget] = useState(null);

  useEffect(() => {
    const savedCategories = JSON.parse(localStorage.getItem('categorizedTemplates') || '{}');
    const validatedCategories = Object.fromEntries(
      Object.entries(savedCategories).map(([key, value]) => [key, Array.isArray(value) ? value : []])
    );
    setCategories(validatedCategories);
    updateLocalStorage(validatedCategories);
  }, []);

  const updateLocalStorage = (updatedCategories) => {
    localStorage.setItem('categorizedTemplates', JSON.stringify(updatedCategories));
  };

  // const handleCategorySelect = (category) => {
  //   setSelectedCategory(category);
  //   setSelectedTemplate(null);
    
  //   if (!categories[category] || !Array.isArray(categories[category])) {
  //     const updatedCategories = { ...categories, [category]: [] };
  //     setCategories(updatedCategories);
  //     updateLocalStorage(updatedCategories);
  //   }
  // };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleNewCategory = () => {
    if (newCategoryName && !categories[newCategoryName]) {
      const updatedCategories = { ...categories, [newCategoryName]: [] };
      setCategories(updatedCategories);
      updateLocalStorage(updatedCategories);
      setNewCategoryName('');
      setSelectedCategory(newCategoryName);
      setIsNewCategoryDialogOpen(false);
    }
  };

  const handleNewTemplate = () => {
    if (newTemplateName && selectedCategory) {
      const newTemplate = { name: newTemplateName, operations: [] };
      const updatedCategories = { ...categories };
      
      if (!updatedCategories[selectedCategory] || !Array.isArray(updatedCategories[selectedCategory])) {
        updatedCategories[selectedCategory] = [];
      }
      
      updatedCategories[selectedCategory] = [...updatedCategories[selectedCategory], newTemplate];
      
      setCategories(updatedCategories);
      updateLocalStorage(updatedCategories);
      setNewTemplateName('');
      setSelectedTemplate(newTemplate);
      setIsNewTemplateDialogOpen(false);
    }
  };

  const handleDeleteTemplate = () => {
    if (selectedTemplate && selectedCategory) {
      setIsDeleteDialogOpen(true);
    }
  };

  const handleDeleteCategory = () => {
    if (selectedCategory) {
      setIsDeleteCategoryDialogOpen(true);
    }
  };

  const confirmDeleteTemplate = () => {
    const updatedTemplates = categories[selectedCategory].filter(t => t.name !== selectedTemplate.name);
    const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
    setCategories(updatedCategories);
    updateLocalStorage(updatedCategories);
    setSelectedTemplate(null);
    setIsDeleteDialogOpen(false);
  };

  const confirmDeleteCategory = () => {
    const { [selectedCategory]: deletedCategory, ...remainingCategories } = categories;
    setCategories(remainingCategories);
    updateLocalStorage(remainingCategories);
    setSelectedCategory('');
    setSelectedTemplate(null);
    setIsDeleteCategoryDialogOpen(false);
  };

  const handleAddOperation = () => {
    if (selectedTemplate && newOperation.columnName && newOperation.action) {
      const updatedTemplate = {
        ...selectedTemplate,
        operations: [...selectedTemplate.operations, newOperation]
      };
      updateTemplate(updatedTemplate);
      setNewOperation({ columnName: '', action: '', value: '' });
    }
  };

  const handleDeleteOperation = (index) => {
    if (selectedTemplate) {
      const updatedOperations = selectedTemplate.operations.filter((_, i) => i !== index);
      const updatedTemplate = { ...selectedTemplate, operations: updatedOperations };
      updateTemplate(updatedTemplate);
    }
  };

  const updateTemplate = (updatedTemplate) => {
    const updatedTemplates = categories[selectedCategory].map(t => t.name === updatedTemplate.name ? updatedTemplate : t);
    const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
    setCategories(updatedCategories);
    setSelectedTemplate(updatedTemplate);
    updateLocalStorage(updatedCategories);
  };

  const handleApplyTemplate = async () => {
    setIsConfirmDialogOpen(true);
  };

  const confirmApplyTemplate = async () => {
    setIsConfirmDialogOpen(false);
    if (!selectedTemplate) return;

    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let allValues = response.data.values || [];
      if (allValues.length === 0) {
        throw new Error("スプレッドシートにデータがありません。");
      }

      let headers = allValues[0];
      let data = allValues.slice(1);

      for (const op of selectedTemplate.operations) {
        let columnIndex = headers.findIndex(header => header.toLowerCase() === op.columnName.toLowerCase());

        if (columnIndex === -1) {
          headers.push(op.columnName);
          columnIndex = headers.length - 1;
          data.forEach(row => row.push(""));
        }

        data = data.map(row => {
          let cell = row[columnIndex] || "";
          switch (op.action) {
            case 'replace':
              cell = op.value;
              break;
            case 'prefix':
              cell = op.value + cell;
              break;
            case 'suffix':
              cell = cell + op.value;
              break;
            default:
              break;
          }
          row[columnIndex] = cell;
          return row;
        });
      }

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
        {
          values: [headers, ...data],
          range: `${sheetName}!A1`
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      alert('テンプレートが正常に適用されました！');
      fetchData();
    } catch (error) {
      console.error('Error applying template:', error);
      alert(`テンプレートの適用に失敗しました。エラー: ${error.message}`);
    }
  };

  const exportTemplates = () => {
    const blob = new Blob([JSON.stringify(categories, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'categorized_templates_export.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const importTemplates = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function(e) {
      try {
        const importedCategories = JSON.parse(e.target.result);
        setCategories(importedCategories);
        updateLocalStorage(importedCategories);
        alert('テンプレートのインポートが成功しました。');
      } catch (error) {
        console.error('テンプレートのインポートに失敗しました:', error);
        alert('テンプレートのインポートに失敗しました。ファイルを確認してください。');
      }
    };
    reader.readAsText(file);
  };

  const handleMenuOpen = (event, target) => {
    setAnchorEl(event.currentTarget);
    setMenuTarget(target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuTarget(null);
  };

  const handleDuplicate = () => {
    handleMenuClose();
    if (menuTarget.type === 'category') {
      duplicateCategory(menuTarget.name);
    } else if (menuTarget.type === 'template') {
      duplicateTemplate(menuTarget.category, menuTarget.template);
    }
  };

  const duplicateCategory = (categoryName) => {
    const newCategoryName = `${categoryName} (Copy)`;
    const updatedCategories = {
      ...categories,
      [newCategoryName]: JSON.parse(JSON.stringify(categories[categoryName])),
    };
    setCategories(updatedCategories);
    updateLocalStorage(updatedCategories);
  };

  const duplicateTemplate = (categoryName, template) => {
    const newTemplateName = `${template.name} (Copy)`;
    const updatedCategories = { ...categories };
    updatedCategories[categoryName] = [
      ...updatedCategories[categoryName],
      { ...JSON.parse(JSON.stringify(template)), name: newTemplateName },
    ];
    setCategories(updatedCategories);
    updateLocalStorage(updatedCategories);
  };

  const handleRename = () => {
    handleMenuClose();
    if (menuTarget) {
      setRenameTarget(menuTarget);
      setNewName(menuTarget.type === 'category' ? menuTarget.name : menuTarget.template.name);
      setIsRenameDialogOpen(true);
    }
  };

  const confirmRename = () => {
    if (renameTarget && newName) {
      if (renameTarget.type === 'category') {
        renameCategory(renameTarget.name, newName);
      } else if (renameTarget.type === 'template') {
        renameTemplate(renameTarget.category, renameTarget.template, newName);
      }
    }
    setIsRenameDialogOpen(false);
    setRenameTarget(null);
    setNewName('');
  };

  const renameCategory = (oldName, newName) => {
    if (oldName !== newName && !categories[newName]) {
      const updatedCategories = { ...categories };
      updatedCategories[newName] = updatedCategories[oldName];
      delete updatedCategories[oldName];
      setCategories(updatedCategories);
      updateLocalStorage(updatedCategories);
      if (selectedCategory === oldName) {
        setSelectedCategory(newName);
      }
    }
  };

  const renameTemplate = (categoryName, template, newName) => {
    if (template.name !== newName) {
      const updatedCategories = { ...categories };
      const templateIndex = updatedCategories[categoryName].findIndex(t => t.name === template.name);
      if (templateIndex !== -1) {
        updatedCategories[categoryName][templateIndex] = { ...template, name: newName };
        setCategories(updatedCategories);
        updateLocalStorage(updatedCategories);
        if (selectedTemplate && selectedTemplate.name === template.name) {
          setSelectedTemplate({ ...selectedTemplate, name: newName });
        }
      }
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            テンプレート管理
          </Typography>
          <Button color="inherit" onClick={() => setIsDrawerOpen(true)}>
            テンプレートを開く
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{ width: 350 }}
      >
        <Box sx={{ width: 350, p: 2 }}>
          <Typography variant="h6" gutterBottom>
            カテゴリーとテンプレート
          </Typography>
          <Button
            startIcon={<AddIcon />}
            onClick={() => setIsNewCategoryDialogOpen(true)}
            fullWidth
            variant="outlined"
            sx={{ mb: 2 }}
          >
            新しいカテゴリー
          </Button>
          <List>
            {Object.entries(categories).map(([categoryName, templates]) => (
              <React.Fragment key={categoryName}>
                <ListItem
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="more"
                      onClick={(event) => handleMenuOpen(event, { type: 'category', name: categoryName })}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <ListItemText primary={categoryName} />
                </ListItem>
                <List component="div" disablePadding>
                  {Array.isArray(templates) && templates.map((template) => (
                    <ListItem
                      key={template.name}
                      sx={{ pl: 4 }}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="more"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleMenuOpen(event, { type: 'template', category: categoryName, template: template });
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      }
                      onClick={() => {
                        setSelectedCategory(categoryName);
                        handleTemplateSelect(template);
                        setIsDrawerOpen(false);
                      }}
                    >
                      <ListItemIcon>
                        <DescriptionIcon />
                      </ListItemIcon>
                      <ListItemText primary={template.name} />
                    </ListItem>
                  ))}
                  <ListItem sx={{ pl: 4 }}>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setSelectedCategory(categoryName);
                        setIsNewTemplateDialogOpen(true);
                      }}
                    >
                      新しいテンプレート
                    </Button>
                  </ListItem>
                </List>
                <Divider />
              </React.Fragment>
            ))}
          </List>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button variant="contained" onClick={exportTemplates} sx={{ mr: 1 }}>
              エクスポート
            </Button>
            <input
              accept=".json"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={importTemplates}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                インポート
              </Button>
            </label>
          </Box>
        </Box>
      </Drawer>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRename}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>名前変更</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>複製</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          handleMenuClose();
          if (menuTarget.type === 'category') {
            setSelectedCategory(menuTarget.name);
            handleDeleteCategory();
          } else if (menuTarget.type === 'template') {
            setSelectedCategory(menuTarget.category);
            setSelectedTemplate(menuTarget.template);
            handleDeleteTemplate();
          }
        }}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>削除</ListItemText>
        </MenuItem>
      </Menu>

      <Box sx={{ p: 3 }}>
        {selectedTemplate ? (
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              {selectedTemplate.name} の詳細
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              overflowX: 'auto', 
              pb: 1, 
              mb: 2,
              '&::-webkit-scrollbar': { height: 6 },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' }
            }}>
              {selectedTemplate.operations.map((operation, index) => (
                <Card key={index} sx={{ 
                  width: 160,
                  minWidth: 160,
                  mr: 1,
                  mb: 1,
                  flexShrink: 0 
                }}>
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="subtitle2" component="div" noWrap>
                      {operation.columnName}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" display="block" noWrap>
                      {operation.action}
                    </Typography>
                    <Typography variant="body2" noWrap sx={{ fontSize: '0.75rem' }}>
                      {operation.value}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
                    <IconButton size="small" onClick={() => handleDeleteOperation(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                新しい操作を追加
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="カラム名"
                    value={newOperation.columnName}
                    onChange={(e) => setNewOperation({...newOperation, columnName: e.target.value})}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>アクション</InputLabel>
                    <Select
                      value={newOperation.action}
                      onChange={(e) => setNewOperation({...newOperation, action: e.target.value})}
                    >
                      <MenuItem value="replace">置換</MenuItem>
                      <MenuItem value="prefix">接頭辞追加</MenuItem>
                      <MenuItem value="suffix">接尾辞追加</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="値"
                    value={newOperation.value}
                    onChange={(e) => setNewOperation({...newOperation, value: e.target.value})}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Button variant="contained" onClick={handleAddOperation} sx={{ mt: 2 }}>
                操作を追加
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ApplyIcon />}
              onClick={handleApplyTemplate}
              sx={{ mt: 4 }}
            >
              テンプレートを適用
            </Button>
          </Paper>
        ) : (
          <Typography variant="body1">
            右側のドロワーからテンプレートを選択してください。
          </Typography>
        )}
      </Box>

      {/* 新しいカテゴリーダイアログ */}
      <Dialog open={isNewCategoryDialogOpen} onClose={() => setIsNewCategoryDialogOpen(false)}>
        <DialogTitle>新しいカテゴリー</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="カテゴリー名"
            fullWidth
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewCategoryDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleNewCategory}>作成</Button>
        </DialogActions>
      </Dialog>

      {/* 新しいテンプレートダイアログ */}
      <Dialog open={isNewTemplateDialogOpen} onClose={() => setIsNewTemplateDialogOpen(false)}>
        <DialogTitle>新しいテンプレート</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="テンプレート名"
            fullWidth
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsNewTemplateDialogOpen(false)}>キャンセル</Button>
          <Button onClick={handleNewTemplate}>作成</Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート適用確認ダイアログ */}
      <Dialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
      >
        <DialogTitle>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            このテンプレートを適用しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmApplyTemplate} autoFocus>
            適用
          </Button>
        </DialogActions>
      </Dialog>

      {/* テンプレート削除確認ダイアログ */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <DialogTitle>テンプレート削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            テンプレート "{selectedTemplate?.name}" を削除しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmDeleteTemplate} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* カテゴリー削除確認ダイアログ */}
      <Dialog
        open={isDeleteCategoryDialogOpen}
        onClose={() => setIsDeleteCategoryDialogOpen(false)}
      >
        <DialogTitle>カテゴリー削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            カテゴリー "{selectedCategory}" とそれに含まれるすべてのテンプレートを削除しますか？この操作は元に戻せません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteCategoryDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmDeleteCategory} color="error" autoFocus>
            削除
          </Button>
        </DialogActions>
      </Dialog>

      {/* 名前変更ダイアログ */}
      <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
        <DialogTitle>
          {renameTarget?.type === 'category' ? 'カテゴリー名の変更' : 'テンプレート名の変更'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="新しい名前"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsRenameDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmRename}>変更</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ColumnNameBasedTemplateManager;







//ーーーー削除機能
// import React, { useState, useEffect, useCallback } from 'react';
// import {
//   Box,
//   Grid,
//   Paper,
//   Typography,
//   Select,
//   MenuItem,
//   Button,
//   TextField,
//   IconButton,
//   FormControl,
//   InputLabel,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Card,
//   CardContent,
//   CardActions,
//   Drawer,
//   AppBar,
//   Toolbar,
//   List,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
//   Divider,
//   Menu,
//   Chip,
//   Snackbar,
//   Alert,
// } from '@mui/material';
// import {
//   Delete as DeleteIcon,
//   Folder as FolderIcon,
//   Description as DescriptionIcon,
//   Add as AddIcon,
//   Edit as EditIcon,
//   PlayArrow as ApplyIcon,
//   FileCopy as DuplicateIcon,
//   MoreVert as MoreVertIcon,
//   Save as SaveIcon,
//   CloudUpload as CloudUploadIcon,
//   CloudDownload as CloudDownloadIcon,
// } from '@mui/icons-material';
// import axios from 'axios';

// function ColumnNameBasedTemplateManager({ spreadsheetId, sheetName, token, fetchData }) {
//   const [categories, setCategories] = useState({});
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedTemplate, setSelectedTemplate] = useState(null);
//   const [newCategoryName, setNewCategoryName] = useState('');
//   const [newTemplateName, setNewTemplateName] = useState('');
//   const [newOperation, setNewOperation] = useState({ columnName: '', action: '', value: '' });
//   const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
//   const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
//   const [isDeleteCategoryDialogOpen, setIsDeleteCategoryDialogOpen] = useState(false);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
//   const [isNewTemplateDialogOpen, setIsNewTemplateDialogOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [menuTarget, setMenuTarget] = useState(null);
//   const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
//   const [newName, setNewName] = useState('');
//   const [renameTarget, setRenameTarget] = useState(null);
//   const [columnsToDelete, setColumnsToDelete] = useState([]);
//   const [availableColumns, setAvailableColumns] = useState([]);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const savedCategories = JSON.parse(localStorage.getItem('categorizedTemplates') || '{}');
//     const validatedCategories = Object.fromEntries(
//       Object.entries(savedCategories).map(([key, value]) => [key, Array.isArray(value) ? value : []])
//     );
//     setCategories(validatedCategories);
//     updateLocalStorage(validatedCategories);
//     fetchAvailableColumns();
//   }, []);

//   const fetchAvailableColumns = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!1:1`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
//       if (response.data.values && response.data.values[0]) {
//         setAvailableColumns(response.data.values[0]);
//       }
//     } catch (error) {
//       console.error('Error fetching available columns:', error);
//       setSnackbar({
//         open: true,
//         message: 'カラム情報の取得に失敗しました。',
//         severity: 'error'
//       });
//     }
//   }, [spreadsheetId, sheetName, token]);

//   const updateLocalStorage = (updatedCategories) => {
//     localStorage.setItem('categorizedTemplates', JSON.stringify(updatedCategories));
//   };

//   const handleTemplateSelect = (template) => {
//     setSelectedTemplate(template);
//   };

//   const handleNewCategory = () => {
//     if (newCategoryName && !categories[newCategoryName]) {
//       const updatedCategories = { ...categories, [newCategoryName]: [] };
//       setCategories(updatedCategories);
//       updateLocalStorage(updatedCategories);
//       setNewCategoryName('');
//       setSelectedCategory(newCategoryName);
//       setIsNewCategoryDialogOpen(false);
//       setSnackbar({
//         open: true,
//         message: `新しいカテゴリー "${newCategoryName}" が作成されました。`,
//         severity: 'success'
//       });
//     }
//   };

//   const handleNewTemplate = () => {
//     if (newTemplateName && selectedCategory) {
//       const newTemplate = { name: newTemplateName, operations: [] };
//       const updatedCategories = { ...categories };
      
//       if (!updatedCategories[selectedCategory] || !Array.isArray(updatedCategories[selectedCategory])) {
//         updatedCategories[selectedCategory] = [];
//       }
      
//       updatedCategories[selectedCategory] = [...updatedCategories[selectedCategory], newTemplate];
      
//       setCategories(updatedCategories);
//       updateLocalStorage(updatedCategories);
//       setNewTemplateName('');
//       setSelectedTemplate(newTemplate);
//       setIsNewTemplateDialogOpen(false);
//       setSnackbar({
//         open: true,
//         message: `新しいテンプレート "${newTemplateName}" が作成されました。`,
//         severity: 'success'
//       });
//     }
//   };

//   const handleDeleteTemplate = () => {
//     if (selectedTemplate && selectedCategory) {
//       setIsDeleteDialogOpen(true);
//     }
//   };

//   const handleDeleteCategory = () => {
//     if (selectedCategory) {
//       setIsDeleteCategoryDialogOpen(true);
//     }
//   };

//   const confirmDeleteTemplate = () => {
//     const updatedTemplates = categories[selectedCategory].filter(t => t.name !== selectedTemplate.name);
//     const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
//     setCategories(updatedCategories);
//     updateLocalStorage(updatedCategories);
//     setSelectedTemplate(null);
//     setIsDeleteDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: `テンプレート "${selectedTemplate.name}" が削除されました。`,
//       severity: 'success'
//     });
//   };

//   const confirmDeleteCategory = () => {
//     const { [selectedCategory]: deletedCategory, ...remainingCategories } = categories;
//     setCategories(remainingCategories);
//     updateLocalStorage(remainingCategories);
//     setSelectedCategory('');
//     setSelectedTemplate(null);
//     setIsDeleteCategoryDialogOpen(false);
//     setSnackbar({
//       open: true,
//       message: `カテゴリー "${selectedCategory}" が削除されました。`,
//       severity: 'success'
//     });
//   };

//   const handleAddOperation = () => {
//     if (selectedTemplate && newOperation.columnName && newOperation.action) {
//       const updatedTemplate = {
//         ...selectedTemplate,
//         operations: [...selectedTemplate.operations, newOperation]
//       };
//       updateTemplate(updatedTemplate);
//       setNewOperation({ columnName: '', action: '', value: '' });
//       setSnackbar({
//         open: true,
//         message: '新しい操作が追加されました。',
//         severity: 'success'
//       });
//     }
//   };

//   const handleDeleteOperation = (index) => {
//     if (selectedTemplate) {
//       const updatedOperations = selectedTemplate.operations.filter((_, i) => i !== index);
//       const updatedTemplate = { ...selectedTemplate, operations: updatedOperations };
//       updateTemplate(updatedTemplate);
//       setSnackbar({
//         open: true,
//         message: '操作が削除されました。',
//         severity: 'success'
//       });
//     }
//   };

//   const updateTemplate = (updatedTemplate) => {
//     const updatedTemplates = categories[selectedCategory].map(t => t.name === updatedTemplate.name ? updatedTemplate : t);
//     const updatedCategories = { ...categories, [selectedCategory]: updatedTemplates };
//     setCategories(updatedCategories);
//     setSelectedTemplate(updatedTemplate);
//     updateLocalStorage(updatedCategories);
//   };

//   const handleApplyTemplate = async () => {
//     setIsConfirmDialogOpen(true);
//   };

//   const confirmApplyTemplate = async () => {
//     setIsConfirmDialogOpen(false);
//     if (!selectedTemplate) return;

//     setIsLoading(true);
//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       let allValues = response.data.values || [];
//       if (allValues.length === 0) {
//         throw new Error("スプレッドシートにデータがありません。");
//       }

//       let headers = allValues[0];
//       let data = allValues.slice(1);

//       // スプレッドシートの情報を取得してシートIDを特定
//       const spreadsheetInfo = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       const sheet = spreadsheetInfo.data.sheets.find(s => s.properties.title === sheetName);
//       if (!sheet) {
//         throw new Error('指定されたシートが見つかりません。');
//       }

//       const sheetId = sheet.properties.sheetId;

//       const requests = [];
//       const columnsToDelete = [];

//       for (const op of selectedTemplate.operations) {
//         if (op.action === 'delete') {
//           const columnIndex = headers.findIndex(header => header.toLowerCase() === op.columnName.toLowerCase());
//           if (columnIndex !== -1) {
//             columnsToDelete.push(columnIndex);
//           }
//         } else {
//           let columnIndex = headers.findIndex(header => header.toLowerCase() === op.columnName.toLowerCase());

//           if (columnIndex === -1) {
//             headers.push(op.columnName);
//             columnIndex = headers.length - 1;
//             data.forEach(row => row.push(""));
//           }

//           data = data.map(row => {
//             let cell = row[columnIndex] || "";
//             switch (op.action) {
//               case 'replace':
//                 cell = op.value;
//                 break;
//               case 'prefix':
//                 cell = op.value + cell;
//                 break;
//               case 'suffix':
//                 cell = cell + op.value;
//                 break;
//               default:
//                 break;
//             }
//             row[columnIndex] = cell;
//             return row;
//           });
//         }
//       }

//       // 列削除リクエストを作成（降順にソートして後ろの列から削除）
//       columnsToDelete.sort((a, b) => b - a).forEach(columnIndex => {
//         requests.push({
//           deleteDimension: {
//             range: {
//               sheetId: sheetId,
//               dimension: "COLUMNS",
//               startIndex: columnIndex,
//               endIndex: columnIndex + 1
//             }
//           }
//         });
//       });

//       // 列削除を実行
//       if (requests.length > 0) {
//         await axios.post(
//           `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}:batchUpdate`,
//           { requests },
//           {
//             headers: { 
//               Authorization: `Bearer ${token}`,
//               'Content-Type': 'application/json'
//             }
//           }
//         );
//       }

//       // 更新されたデータを書き込む
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}!A1`,
//         {
//           values: [headers, ...data],
//           range: `${sheetName}!A1`
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setSnackbar({
//         open: true,
//         message: 'テンプレートが正常に適用されました！',
//         severity: 'success'
//       });
//       fetchData();
//       fetchAvailableColumns();
//     } catch (error) {
//       console.error('Error applying template:', error);
//       setSnackbar({
//         open: true,
//         message: `テンプレートの適用に失敗しました。エラー: ${error.message}`,
//         severity: 'error'
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const exportTemplates = () => {
//     const blob = new Blob([JSON.stringify(categories, null, 2)], { type: 'application/json' });
//     const url = URL.createObjectURL(blob);
//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'categorized_templates_export.json';
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//     URL.revokeObjectURL(url);
//     setSnackbar({
//       open: true,
//       message: 'テンプレートがエクスポートされました。',
//       severity: 'success'
//     });
//   };

//   const importTemplates = (event) => {
//     const file = event.target.files[0];
//     const reader = new FileReader();
//     reader.onload = function(e) {
//       try {
//         const importedCategories = JSON.parse(e.target.result);
//         setCategories(importedCategories);
//         updateLocalStorage(importedCategories);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートのインポートが成功しました。',
//           severity: 'success'
//         });
//       } catch (error) {
//         console.error('テンプレートのインポートに失敗しました:', error);
//         setSnackbar({
//           open: true,
//           message: 'テンプレートのインポートに失敗しました。ファイルを確認してください。',
//           severity: 'error'
//         });
//       }
//     };
//     reader.readAsText(file);
//   };

//   const handleMenuOpen = (event, target) => {
//     setAnchorEl(event.currentTarget);
//     setMenuTarget(target);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setMenuTarget(null);
//   };

//   const handleDuplicate = () => {
//     handleMenuClose();
//     if (menuTarget.type === 'category') {
//       duplicateCategory(menuTarget.name);
//     } else if (menuTarget.type === 'template') {
//       duplicateTemplate(menuTarget.category, menuTarget.template);
//     }
//   };

//   const duplicateCategory = (categoryName) => {
//     const newCategoryName = `${categoryName} (コピー)`;
//     const updatedCategories = {
//       ...categories,
//       [newCategoryName]: JSON.parse(JSON.stringify(categories[categoryName])),
//     };
//     setCategories(updatedCategories);
//     updateLocalStorage(updatedCategories);
//     setSnackbar({
//       open: true,
//       message: `カテゴリー "${categoryName}" が複製されました。`,
//       severity: 'success'
//     });
//   };

//   const duplicateTemplate = (categoryName, template) => {
//     const newTemplateName = `${template.name} (コピー)`;
//     const updatedCategories = { ...categories };
//     updatedCategories[categoryName] = [
//       ...updatedCategories[categoryName],
//       { ...JSON.parse(JSON.stringify(template)), name: newTemplateName },
//     ];
//     setCategories(updatedCategories);
//     updateLocalStorage(updatedCategories);
//     setSnackbar({
//       open: true,
//       message: `テンプレート "${template.name}" が複製されました。`,
//       severity: 'success'
//     });
//   };

//   const handleRename = () => {
//     handleMenuClose();
//     if (menuTarget) {
//       setRenameTarget(menuTarget);
//       setNewName(menuTarget.type === 'category' ? menuTarget.name : menuTarget.template.name);
//       setIsRenameDialogOpen(true);
//     }
//   };

//   const confirmRename = () => {
//     if (renameTarget && newName) {
//       if (renameTarget.type === 'category') {
//         renameCategory(renameTarget.name, newName);
//       } else if (renameTarget.type === 'template') {
//         renameTemplate(renameTarget.category, renameTarget.template, newName);
//       }
//     }
//     setIsRenameDialogOpen(false);
//     setRenameTarget(null);
//     setNewName('');
//   };

//   const renameCategory = (oldName, newName) => {
//     if (oldName !== newName && !categories[newName]) {
//       const updatedCategories = { ...categories };
//       updatedCategories[newName] = updatedCategories[oldName];
//       delete updatedCategories[oldName];
//       setCategories(updatedCategories);
//       updateLocalStorage(updatedCategories);
//       if (selectedCategory === oldName) {
//         setSelectedCategory(newName);
//       }
//       setSnackbar({
//         open: true,
//         message: `カテゴリー "${oldName}" が "${newName}" に名前変更されました。`,
//         severity: 'success'
//       });
//     }
//   };

//   const renameTemplate = (categoryName, template, newName) => {
//     if (template.name !== newName) {
//       const updatedCategories = { ...categories };
//       const templateIndex = updatedCategories[categoryName].findIndex(t => t.name === template.name);
//       if (templateIndex !== -1) {
//         updatedCategories[categoryName][templateIndex] = { ...template, name: newName };
//         setCategories(updatedCategories);
//         updateLocalStorage(updatedCategories);
//         if (selectedTemplate && selectedTemplate.name === template.name) {
//           setSelectedTemplate({ ...selectedTemplate, name: newName });
//         }
//         setSnackbar({
//           open: true,
//           message: `テンプレート "${template.name}" が "${newName}" に名前変更されました。`,
//           severity: 'success'
//         });
//       }
//     }
//   };

//   const handleColumnDeleteSelect = (event) => {
//     setColumnsToDelete(event.target.value);
//   };

//   const handleAddDeleteOperation = () => {
//     if (selectedTemplate && columnsToDelete.length > 0) {
//       const deleteOperations = columnsToDelete.map(column => ({
//         columnName: column,
//         action: 'delete',
//         value: ''
//       }));
//       const updatedTemplate = {
//         ...selectedTemplate,
//         operations: [...selectedTemplate.operations, ...deleteOperations]
//       };
//       updateTemplate(updatedTemplate);
//       setColumnsToDelete([]);
//       setSnackbar({
//         open: true,
//         message: '削除操作が追加されました。',
//         severity: 'success'
//       });
//     }
//   };

//   return (
//     <Box sx={{ flexGrow: 1 }}>
//       <AppBar position="static">
//         <Toolbar>
//           <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//             テンプレート管理
//           </Typography>
//           <Button color="inherit" onClick={() => setIsDrawerOpen(true)}>
//             テンプレートを開く
//           </Button>
//         </Toolbar>
//       </AppBar>

//       <Drawer
//         anchor="right"
//         open={isDrawerOpen}
//         onClose={() => setIsDrawerOpen(false)}
//         sx={{ width: 350 }}
//       >
//         <Box sx={{ width: 350, p: 2 }}>
//           <Typography variant="h6" gutterBottom>
//             カテゴリーとテンプレート
//           </Typography>
//           <Button
//             startIcon={<AddIcon />}
//             onClick={() => setIsNewCategoryDialogOpen(true)}
//             fullWidth
//             variant="outlined"
//             sx={{ mb: 2 }}
//           >
//             新しいカテゴリー
//           </Button>
//           <List>
//             {Object.entries(categories).map(([categoryName, templates]) => (
//               <React.Fragment key={categoryName}>
//                 <ListItem
//                   secondaryAction={
//                     <IconButton
//                       edge="end"
//                       aria-label="more"
//                       onClick={(event) => handleMenuOpen(event, { type: 'category', name: categoryName })}
//                     >
//                       <MoreVertIcon />
//                     </IconButton>
//                   }
//                 >
//                   <ListItemIcon>
//                     <FolderIcon />
//                   </ListItemIcon>
//                   <ListItemText primary={categoryName} />
//                 </ListItem>
//                 <List component="div" disablePadding>
//                   {Array.isArray(templates) && templates.map((template) => (
//                     <ListItem
//                       key={template.name}
//                       sx={{ pl: 4 }}
//                       secondaryAction={
//                         <IconButton
//                           edge="end"
//                           aria-label="more"
//                           onClick={(event) => {
//                             event.stopPropagation();
//                             handleMenuOpen(event, { type: 'template', category: categoryName, template: template });
//                           }}
//                         >
//                           <MoreVertIcon />
//                         </IconButton>
//                       }
//                       onClick={() => {
//                         setSelectedCategory(categoryName);
//                         handleTemplateSelect(template);
//                         setIsDrawerOpen(false);
//                       }}
//                     >
//                       <ListItemIcon>
//                         <DescriptionIcon />
//                       </ListItemIcon>
//                       <ListItemText primary={template.name} />
//                     </ListItem>
//                   ))}
//                   <ListItem sx={{ pl: 4 }}>
//                     <Button
//                       startIcon={<AddIcon />}
//                       onClick={() => {
//                         setSelectedCategory(categoryName);
//                         setIsNewTemplateDialogOpen(true);
//                       }}
//                     >
//                       新しいテンプレート
//                     </Button>
//                   </ListItem>
//                 </List>
//                 <Divider />
//               </React.Fragment>
//             ))}
//           </List>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
//             <Button 
//               variant="contained" 
//               onClick={exportTemplates} 
//               sx={{ mr: 1 }}
//               startIcon={<CloudDownloadIcon />}
//             >
//               エクスポート
//             </Button>
//             <input
//               accept=".json"
//               style={{ display: 'none' }}
//               id="raised-button-file"
//               type="file"
//               onChange={importTemplates}
//             />
//             <label htmlFor="raised-button-file">
//               <Button 
//                 variant="contained" 
//                 component="span"
//                 startIcon={<CloudUploadIcon />}
//               >
//                 インポート
//               </Button>
//             </label>
//           </Box>
//         </Box>
//       </Drawer>

//       <Menu
//         anchorEl={anchorEl}
//         open={Boolean(anchorEl)}
//         onClose={handleMenuClose}
//       >
//         <MenuItem onClick={handleRename}>
//           <ListItemIcon>
//             <EditIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText>名前変更</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={handleDuplicate}>
//           <ListItemIcon>
//             <DuplicateIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText>複製</ListItemText>
//         </MenuItem>
//         <MenuItem onClick={() => {
//           handleMenuClose();
//           if (menuTarget.type === 'category') {
//             setSelectedCategory(menuTarget.name);
//             handleDeleteCategory();
//           } else if (menuTarget.type === 'template') {
//             setSelectedCategory(menuTarget.category);
//             setSelectedTemplate(menuTarget.template);
//             handleDeleteTemplate();
//           }
//         }}>
//           <ListItemIcon>
//             <DeleteIcon fontSize="small" />
//           </ListItemIcon>
//           <ListItemText>削除</ListItemText>
//         </MenuItem>
//       </Menu>

//       <Box sx={{ p: 3 }}>
//         {selectedTemplate ? (
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6" gutterBottom>
//               {selectedTemplate.name} の詳細
//             </Typography>
//             <Box sx={{ 
//               display: 'flex', 
//               flexWrap: 'wrap',
//               overflowX: 'auto', 
//               pb: 1, 
//               mb: 2,
//               '&::-webkit-scrollbar': { height: 6 },
//               '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' }
//             }}>
//               {selectedTemplate.operations.map((operation, index) => (
//                 <Card key={index} sx={{ 
//                   width: 160,
//                   minWidth: 160,
//                   mr: 1,
//                   mb: 1,
//                   flexShrink: 0 
//                 }}>
//                   <CardContent sx={{ p: 1 }}>
//                     <Typography variant="subtitle2" component="div" noWrap>
//                       {operation.columnName}
//                     </Typography>
//                     <Typography variant="caption" color="text.secondary" display="block" noWrap>
//                       {operation.action}
//                     </Typography>
//                     <Typography variant="body2" noWrap sx={{ fontSize: '0.75rem' }}>
//                       {operation.value}
//                     </Typography>
//                   </CardContent>
//                   <CardActions sx={{ p: 0, justifyContent: 'flex-end' }}>
//                     <IconButton size="small" onClick={() => handleDeleteOperation(index)}>
//                       <DeleteIcon fontSize="small" />
//                     </IconButton>
//                   </CardActions>
//                 </Card>
//               ))}
//             </Box>
//             <Box sx={{ mt: 2 }}>
//               <Typography variant="subtitle1" gutterBottom>
//                 新しい操作を追加
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={3}>
//                   <FormControl fullWidth>
//                     <InputLabel>カラム名</InputLabel>
//                     <Select
//                       value={newOperation.columnName}
//                       onChange={(e) => setNewOperation({...newOperation, columnName: e.target.value})}
//                     >
//                       {availableColumns.map((column, index) => (
//                         <MenuItem key={index} value={column}>{column}</MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={3}>
//                   <FormControl fullWidth>
//                     <InputLabel>アクション</InputLabel>
//                     <Select
//                       value={newOperation.action}
//                       onChange={(e) => setNewOperation({...newOperation, action: e.target.value})}
//                     >
//                       <MenuItem value="replace">置換</MenuItem>
//                       <MenuItem value="prefix">接頭辞追加</MenuItem>
//                       <MenuItem value="suffix">接尾辞追加</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={3}>
//                   <TextField
//                     label="値"
//                     value={newOperation.value}
//                     onChange={(e) => setNewOperation({...newOperation, value: e.target.value})}
//                     fullWidth
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={3}>
//                   <Button variant="contained" onClick={handleAddOperation} fullWidth>
//                     操作を追加
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//             <Box sx={{ mt: 2 }}>
//               <Typography variant="subtitle1" gutterBottom>
//                 カラム削除操作を追加
//               </Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={9}>
//                   <FormControl fullWidth>
//                     <InputLabel>削除するカラムを選択</InputLabel>
//                     <Select
//                       multiple
//                       value={columnsToDelete}
//                       onChange={handleColumnDeleteSelect}
//                       renderValue={(selected) => (
//                         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//                           {selected.map((value) => (
//                             <Chip key={value} label={value} />
//                           ))}
//                         </Box>
//                       )}
//                     >
//                       {availableColumns.map((column, index) => (
//                         <MenuItem key={index} value={column}>{column}</MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={3}>
//                   <Button 
//                     variant="contained" 
//                     onClick={handleAddDeleteOperation} 
//                     fullWidth
//                     disabled={columnsToDelete.length === 0}
//                   >
//                     削除操作を追加
//                   </Button>
//                 </Grid>
//               </Grid>
//             </Box>
//             <Button
//               variant="contained"
//               color="primary"
//               startIcon={<ApplyIcon />}
//               onClick={handleApplyTemplate}
//               sx={{ mt: 4 }}
//               disabled={isLoading}
//             >
//               {isLoading ? 'テンプレート適用中...' : 'テンプレートを適用'}
//             </Button>
//           </Paper>
//         ) : (<Typography variant="body1">
//           右側のドロワーからテンプレートを選択してください。
//         </Typography>
//       )}
//     </Box>

//     {/* 新しいカテゴリーダイアログ */}
//     <Dialog open={isNewCategoryDialogOpen} onClose={() => setIsNewCategoryDialogOpen(false)}>
//       <DialogTitle>新しいカテゴリー</DialogTitle>
//       <DialogContent>
//         <TextField
//           autoFocus
//           margin="dense"
//           label="カテゴリー名"
//           fullWidth
//           value={newCategoryName}
//           onChange={(e) => setNewCategoryName(e.target.value)}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setIsNewCategoryDialogOpen(false)}>キャンセル</Button>
//         <Button onClick={handleNewCategory}>作成</Button>
//       </DialogActions>
//     </Dialog>

//     {/* 新しいテンプレートダイアログ */}
//     <Dialog open={isNewTemplateDialogOpen} onClose={() => setIsNewTemplateDialogOpen(false)}>
//       <DialogTitle>新しいテンプレート</DialogTitle>
//       <DialogContent>
//         <TextField
//           autoFocus
//           margin="dense"
//           label="テンプレート名"
//           fullWidth
//           value={newTemplateName}
//           onChange={(e) => setNewTemplateName(e.target.value)}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setIsNewTemplateDialogOpen(false)}>キャンセル</Button>
//         <Button onClick={handleNewTemplate}>作成</Button>
//       </DialogActions>
//     </Dialog>

//     {/* テンプレート適用確認ダイアログ */}
//     <Dialog
//       open={isConfirmDialogOpen}
//       onClose={() => setIsConfirmDialogOpen(false)}
//     >
//       <DialogTitle>確認</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           このテンプレートを適用しますか？この操作は元に戻せません。
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setIsConfirmDialogOpen(false)}>キャンセル</Button>
//         <Button onClick={confirmApplyTemplate} autoFocus>
//           適用
//         </Button>
//       </DialogActions>
//     </Dialog>

//     {/* テンプレート削除確認ダイアログ */}
//     <Dialog
//       open={isDeleteDialogOpen}
//       onClose={() => setIsDeleteDialogOpen(false)}
//     >
//       <DialogTitle>テンプレート削除の確認</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           テンプレート "{selectedTemplate?.name}" を削除しますか？この操作は元に戻せません。
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setIsDeleteDialogOpen(false)}>キャンセル</Button>
//         <Button onClick={confirmDeleteTemplate} color="error" autoFocus>
//           削除
//         </Button>
//       </DialogActions>
//     </Dialog>

//     {/* カテゴリー削除確認ダイアログ */}
//     <Dialog
//       open={isDeleteCategoryDialogOpen}
//       onClose={() => setIsDeleteCategoryDialogOpen(false)}
//     >
//       <DialogTitle>カテゴリー削除の確認</DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           カテゴリー "{selectedCategory}" とそれに含まれるすべてのテンプレートを削除しますか？この操作は元に戻せません。
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setIsDeleteCategoryDialogOpen(false)}>キャンセル</Button>
//         <Button onClick={confirmDeleteCategory} color="error" autoFocus>
//           削除
//         </Button>
//       </DialogActions>
//     </Dialog>

//     {/* 名前変更ダイアログ */}
//     <Dialog open={isRenameDialogOpen} onClose={() => setIsRenameDialogOpen(false)}>
//       <DialogTitle>
//         {renameTarget?.type === 'category' ? 'カテゴリー名の変更' : 'テンプレート名の変更'}
//       </DialogTitle>
//       <DialogContent>
//         <TextField
//           autoFocus
//           margin="dense"
//           label="新しい名前"
//           fullWidth
//           value={newName}
//           onChange={(e) => setNewName(e.target.value)}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setIsRenameDialogOpen(false)}>キャンセル</Button>
//         <Button onClick={confirmRename}>変更</Button>
//       </DialogActions>
//     </Dialog>

//     {/* スナックバー */}
//     <Snackbar
//       open={snackbar.open}
//       autoHideDuration={6000}
//       onClose={() => setSnackbar({ ...snackbar, open: false })}
//     >
//       <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
//         {snackbar.message}
//       </Alert>
//     </Snackbar>
//   </Box>
// );
// }

// export default ColumnNameBasedTemplateManager;