import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Typography,
  Box,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import SettingsIcon from '@mui/icons-material/Settings';

/**
 * ItemSpecificsApplier コンポーネント
 * 
 * @param {string} spreadsheetId - Google スプレッドシートのID
 * @param {string} sheetName - シート名
 * @param {string} token - 認証トークン
 */
const ItemSpecificsApplier = ({ spreadsheetId, sheetName, token }) => {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [categories, setCategories] = useState({});
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [partialMatch, setPartialMatch] = useState(true);
  const [previewData, setPreviewData] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);

  // 1. ローカルストレージから設定を読み込む
  const loadSettings = () => {
    const savedSettings = localStorage.getItem('itemSpecificsCategories');
    if (savedSettings) {
      try {
        const parsedSettings = JSON.parse(savedSettings);
        setCategories(parsedSettings);
      } catch (error) {
        console.error('Error parsing saved settings:', error);
        setCategories({});
      }
    } else {
      setCategories({});
    }
  };

  // 初回マウント時に設定を読み込む
  useEffect(() => {
    loadSettings();
  }, []);

  /**
   * 設定を再読み込みする関数
   */
  const handleReloadSettings = () => {
    loadSettings();
    setSnackbar({ open: true, message: '設定が更新されました。', severity: 'success' });
  };

  /**
   * スプレッドシートのデータを取得し、プレビューを表示する
   */
  const handleUpdateSpreadsheet = async () => {
    if (!selectedCategory) {
      setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
      return;
    }

    if (selectedColumns.length === 0) {
      setSnackbar({ open: true, message: '適用する列を選択してください。', severity: 'warning' });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const data = response.data.values;
      if (!data || data.length === 0) {
        throw new Error('スプレッドシートが空です。');
      }

      const headers = data[0];
      const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
      if (titleIndex === -1) {
        throw new Error('「Title」列が見つかりません。');
      }

      setOriginalData(data);

      // 全行を更新
      const newUpdatedData = data.map((row, rowIndex) => {
        if (rowIndex === 0) return row; // ヘッダーはそのまま

        const title = row[titleIndex] || '';
        const newRow = [...row];

        selectedColumns.forEach(column => {
          const columnIndex = headers.findIndex(header => header === column);
          if (columnIndex !== -1) {
            const values = categories[selectedCategory][column] || [];
            for (const value of values) {
              if (typeof value !== 'string') continue; // 値が文字列でない場合はスキップ

              const regex = new RegExp(value, caseSensitive ? 'g' : 'gi');
              if (partialMatch ? regex.test(title) : title.toLowerCase() === value.toLowerCase()) {
                // newRowが十分な長さか確認し、必要に応じてパディング
                while (newRow.length <= columnIndex) {
                  newRow.push('');
                }
                newRow[columnIndex] = value;
                break; // 一致する値が見つかったら、その列の処理を終了
              }
            }
          }
        });

        return newRow;
      });

      setUpdatedData(newUpdatedData);

      // プレビュー用データを作成（上位10行のみ）
      const previewHeaders = ['Title'];
      selectedColumns.forEach(col => {
        previewHeaders.push(`${col} (Before)`);
        previewHeaders.push(`${col} (After)`);
      });

      const previewRows = [previewHeaders];
      for (let i = 1; i < Math.min(newUpdatedData.length, 11); i++) { // 上位10行をプレビュー
        const originalRow = data[i] || [];
        const updatedRow = newUpdatedData[i] || [];
        const previewRow = [originalRow[titleIndex] || ''];

        selectedColumns.forEach(col => {
          const colIndex = headers.findIndex(header => header === col);
          if (colIndex !== -1) {
            previewRow.push(originalRow[colIndex] || '');
            previewRow.push(updatedRow[colIndex] || '');
          }
        });

        previewRows.push(previewRow);
      }

      setPreviewData(previewRows);
      setPreviewOpen(true);
    } catch (error) {
      console.error('Error updating spreadsheet:', error);
      setSnackbar({ open: true, message: `スプレッドシートの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  /**
   * プレビューを確認し、スプレッドシートを更新する
   */
  const confirmUpdate = async () => {
    if (updatedData.length === 0) {
      setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
        {
          range: sheetName,
          values: updatedData
        },
        {
          params: { valueInputOption: 'RAW' },
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      setSnackbar({ open: true, message: 'スプレッドシートが正常に更新されました。', severity: 'success' });
      setPreviewOpen(false);
      setOriginalData(updatedData); // 更新後のデータを保持
    } catch (error) {
      console.error('Error confirming update:', error);
      setSnackbar({ open: true, message: `スプレッドシートの更新確認中にエラーが発生しました: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  /**
   * 設定ダイアログを開く
   */
  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };

  /**
   * 設定ダイアログを閉じる
   */
  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false);
  };

  /**
   * 列の選択/解除を行う
   * 
   * @param {string} column - 列名
   */
  const handleColumnToggle = (column) => {
    setSelectedColumns(prev => 
      prev.includes(column) 
        ? prev.filter(c => c !== column)
        : [...prev, column]
    );
  };

  /**
   * 大文字小文字区別の切り替え
   * 
   * @param {object} event - イベントオブジェクト
   */
  const handleCaseSensitiveToggle = (event) => {
    setCaseSensitive(event.target.checked);
  };

  /**
   * 部分一致の切り替え
   * 
   * @param {object} event - イベントオブジェクト
   */
  const handlePartialMatchToggle = (event) => {
    setPartialMatch(event.target.checked);
  };

  /**
   * 設定ダイアログ内で選択されたカテゴリーの列を取得する
   */
  const getColumnsForSelectedCategory = () => {
    if (selectedCategory && categories[selectedCategory]) {
      return Object.keys(categories[selectedCategory]);
    }
    return [];
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 2 }}>
      <Typography variant="h5" gutterBottom>Item Specifics適用</Typography>
      <Typography variant="body1" paragraph>
        設定したItem Specificsをタイトルに基づいて適用し、該当する列を更新します。
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Button
            startIcon={<UpdateIcon />}
            onClick={handleUpdateSpreadsheet}
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mr: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'スプレッドシートを更新'}
          </Button>
          <Button
            startIcon={<SettingsIcon />}
            onClick={handleOpenSettingsDialog}
            variant="outlined"
            color="secondary"
            sx={{ mr: 2 }}
          >
            設定
          </Button>
          <Button
            variant="text"
            color="info"
            onClick={handleReloadSettings}
          >
            設定を更新
          </Button>
        </Box>
      </Box>

      {/* 設定ダイアログ */}
      <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Item Specifics設定</DialogTitle>
        <DialogContent>
          {/* カテゴリー選択 */}
          <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
            <InputLabel>適用するカテゴリー</InputLabel>
            <Select
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(e.target.value);
                setSelectedColumns([]); // カテゴリー変更時に選択列をリセット
              }}
              label="適用するカテゴリー"
            >
              {Object.keys(categories).map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* 列の選択 */}
          {selectedCategory && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                適用する列を選択してください。
              </Typography>
              <List>
                {getColumnsForSelectedCategory().map((column) => (
                  <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
                    <Checkbox
                      edge="start"
                      checked={selectedColumns.includes(column)}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText primary={column} />
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {/* オプション */}
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={caseSensitive}
                  onChange={handleCaseSensitiveToggle}
                  name="caseSensitive"
                />
              }
              label="大文字小文字を区別する"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={partialMatch}
                  onChange={handlePartialMatchToggle}
                  name="partialMatch"
                />
              }
              label="部分一致を許可する"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettingsDialog} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>

      {/* プレビューダイアログ */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>更新プレビュー</DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            以下の内容で更新されます。確認してください。
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader aria-label="preview table">
              <TableHead>
                <TableRow>
                  {previewData[0] && previewData[0].map((header, index) => (
                    <TableCell key={index}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.slice(1).map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>{cell}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {previewData.length > 11 && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              (表示は上位10行のみです。実際の更新はすべての行に適用されます。)
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)} color="secondary">
            キャンセル
          </Button>
          <Button onClick={confirmUpdate} color="primary" variant="contained">
            更新を確定
          </Button>
        </DialogActions>
      </Dialog>

      {/* スナックバー */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ItemSpecificsApplier;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   Box,
//   Paper,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   Checkbox,
//   FormControlLabel,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from '@mui/material';
// import UpdateIcon from '@mui/icons-material/Update';
// import SettingsIcon from '@mui/icons-material/Settings';

// /**
//  * ItemSpecificsApplier コンポーネント
//  * 
//  * @param {string} spreadsheetId - Google スプレッドシートのID
//  * @param {string} sheetName - シート名
//  * @param {string} token - 認証トークン
//  */
// const ItemSpecificsApplier = ({ spreadsheetId, sheetName, token }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [categories, setCategories] = useState({});
//   const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [originalData, setOriginalData] = useState([]);
//   const [updatedData, setUpdatedData] = useState([]);

//   // 1. ローカルストレージから設定を読み込む
//   useEffect(() => {
//     loadSettings();
//   }, []);

//   const loadSettings = () => {
//     const savedSettings = localStorage.getItem('itemSpecificsCategories');
//     if (savedSettings) {
//       try {
//         const parsedSettings = JSON.parse(savedSettings);
//         setCategories(parsedSettings);
//       } catch (error) {
//         console.error('Error parsing saved settings:', error);
//         setCategories({});
//       }
//     }
//   };

//   /**
//    * スプレッドシートのデータを取得し、プレビューを表示する
//    */
//   const handleUpdateSpreadsheet = async () => {
//     if (!selectedCategory) {
//       setSnackbar({ open: true, message: '適用するカテゴリーを選択してください。', severity: 'warning' });
//       return;
//     }

//     if (selectedColumns.length === 0) {
//       setSnackbar({ open: true, message: '適用する列を選択してください。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );

//       const data = response.data.values;
//       if (!data || data.length === 0) {
//         throw new Error('スプレッドシートが空です。');
//       }

//       const headers = data[0];
//       const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
//       if (titleIndex === -1) {
//         throw new Error('「Title」列が見つかりません。');
//       }

//       setOriginalData(data);

//       // 深いコピーを使用して全行を更新
//       const newUpdatedData = data.map((row, rowIndex) => {
//         if (rowIndex === 0) return row; // ヘッダーはそのまま

//         const title = row[titleIndex] || '';
//         const newRow = [...row];

//         selectedColumns.forEach(column => {
//           const columnIndex = headers.findIndex(header => header === column);
//           if (columnIndex !== -1) {
//             const values = categories[selectedCategory][column] || [];
//             for (const value of values) {
//               if (typeof value !== 'string') continue; // 値が文字列でない場合はスキップ

//               const regex = new RegExp(value, caseSensitive ? 'g' : 'gi');
//               if (partialMatch ? regex.test(title) : title.toLowerCase() === value.toLowerCase()) {
//                 // newRowが十分な長さか確認し、必要に応じてパディング
//                 while (newRow.length <= columnIndex) {
//                   newRow.push('');
//                 }
//                 newRow[columnIndex] = value;
//                 break; // 一致する値が見つかったら、その列の処理を終了
//               }
//             }
//           }
//         });

//         return newRow;
//       });

//       setUpdatedData(newUpdatedData);

//       // プレビュー用データを作成（上位10行のみ）
//       const previewHeaders = ['Title'];
//       selectedColumns.forEach(col => {
//         previewHeaders.push(`${col} (Before)`);
//         previewHeaders.push(`${col} (After)`);
//       });

//       const previewRows = [previewHeaders];
//       for (let i = 1; i < Math.min(newUpdatedData.length, 11); i++) { // 上位10行をプレビュー
//         const originalRow = data[i] || [];
//         const updatedRow = newUpdatedData[i] || [];
//         const previewRow = [originalRow[titleIndex] || ''];

//         selectedColumns.forEach(col => {
//           const colIndex = headers.findIndex(header => header === col);
//           if (colIndex !== -1) {
//             previewRow.push(originalRow[colIndex] || '');
//             previewRow.push(updatedRow[colIndex] || '');
//           }
//         });

//         previewRows.push(previewRow);
//       }

//       setPreviewData(previewRows);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('Error updating spreadsheet:', error);
//       setSnackbar({ open: true, message: `スプレッドシートの更新中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   /**
//    * プレビューを確認し、スプレッドシートを更新する
//    */
//   const confirmUpdate = async () => {
//     if (updatedData.length === 0) {
//       setSnackbar({ open: true, message: '更新データが存在しません。', severity: 'warning' });
//       return;
//     }

//     setLoading(true);
//     try {
//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         {
//           range: sheetName,
//           values: updatedData
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setSnackbar({ open: true, message: 'スプレッドシートが正常に更新されました。', severity: 'success' });
//       setPreviewOpen(false);
//       setOriginalData(updatedData); // 更新後のデータを保持
//     } catch (error) {
//       console.error('Error confirming update:', error);
//       setSnackbar({ open: true, message: `スプレッドシートの更新確認中にエラーが発生しました: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   /**
//    * 設定ダイアログを開く
//    */
//   const handleOpenSettingsDialog = () => {
//     setOpenSettingsDialog(true);
//   };

//   /**
//    * 設定ダイアログを閉じる
//    */
//   const handleCloseSettingsDialog = () => {
//     setOpenSettingsDialog(false);
//   };

//   /**
//    * 列の選択/解除を行う
//    * 
//    * @param {string} column - 列名
//    */
//   const handleColumnToggle = (column) => {
//     setSelectedColumns(prev => 
//       prev.includes(column) 
//         ? prev.filter(c => c !== column)
//         : [...prev, column]
//     );
//   };

//   /**
//    * 大文字小文字区別の切り替え
//    * 
//    * @param {object} event - イベントオブジェクト
//    */
//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   /**
//    * 部分一致の切り替え
//    * 
//    * @param {object} event - イベントオブジェクト
//    */
//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 2 }}>
//       <Typography variant="h5" gutterBottom>Item Specifics適用</Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルに基づいて適用し、該当する列を更新します。
//       </Typography>
//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
//         <Button
//           startIcon={<UpdateIcon />}
//           onClick={handleUpdateSpreadsheet}
//           variant="contained"
//           color="primary"
//           disabled={loading}
//         >
//           {loading ? <CircularProgress size={24} /> : 'スプレッドシートを更新'}
//         </Button>
//         <Button
//           startIcon={<SettingsIcon />}
//           onClick={handleOpenSettingsDialog}
//           variant="outlined"
//           color="secondary"
//         >
//           設定
//         </Button>
//       </Box>

//       {/* 設定ダイアログ */}
//       <Dialog open={openSettingsDialog} onClose={handleCloseSettingsDialog} maxWidth="sm" fullWidth>
//         <DialogTitle>Item Specifics設定</DialogTitle>
//         <DialogContent>
//           {/* カテゴリー選択 */}
//           <FormControl fullWidth variant="outlined" size="small" sx={{ mt: 2, mb: 2 }}>
//             <InputLabel>適用するカテゴリー</InputLabel>
//             <Select
//               value={selectedCategory}
//               onChange={(e) => {
//                 setSelectedCategory(e.target.value);
//                 setSelectedColumns([]); // カテゴリー変更時に選択列をリセット
//               }}
//               label="適用するカテゴリー"
//             >
//               {Object.keys(categories).map((category) => (
//                 <MenuItem key={category} value={category}>
//                   {category}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>

//           {/* 列の選択 */}
//           {selectedCategory && (
//             <>
//               <Typography variant="subtitle1" gutterBottom>
//                 適用する列を選択してください。
//               </Typography>
//               <List>
//                 {Object.keys(categories[selectedCategory]).map((column) => (
//                   <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
//                     <Checkbox
//                       edge="start"
//                       checked={selectedColumns.includes(column)}
//                       tabIndex={-1}
//                       disableRipple
//                     />
//                     <ListItemText primary={column} />
//                   </ListItem>
//                 ))}
//               </List>
//             </>
//           )}

//           {/* オプション */}
//           <Box sx={{ mt: 2 }}>
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={caseSensitive}
//                   onChange={handleCaseSensitiveToggle}
//                   name="caseSensitive"
//                 />
//               }
//               label="大文字小文字を区別する"
//             />
//             <FormControlLabel
//               control={
//                 <Checkbox
//                   checked={partialMatch}
//                   onChange={handlePartialMatchToggle}
//                   name="partialMatch"
//                 />
//               }
//               label="部分一致を許可する"
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseSettingsDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* プレビューダイアログ */}
//       <Dialog
//         open={previewOpen}
//         onClose={() => setPreviewOpen(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
//             <Table stickyHeader aria-label="preview table">
//               <TableHead>
//                 <TableRow>
//                   {previewData[0] && previewData[0].map((header, index) => (
//                     <TableCell key={index}>{header}</TableCell>
//                   ))}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {previewData.slice(1).map((row, rowIndex) => (
//                   <TableRow key={rowIndex}>
//                     {row.map((cell, cellIndex) => (
//                       <TableCell key={cellIndex}>{cell}</TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           {previewData.length > 11 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10行のみです。実際の更新はすべての行に適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* スナックバー */}
//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} variant="filled">
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default ItemSpecificsApplier;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Button,
//   Typography,
//   Box,
//   Paper,
//   CircularProgress,
//   Snackbar,
//   Alert,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   List,
//   ListItem,
//   ListItemText,
//   Checkbox,
//   FormControlLabel,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
// } from '@mui/material';
// import UpdateIcon from '@mui/icons-material/Update';
// import SettingsIcon from '@mui/icons-material/Settings';

// const ItemSpecificsApplier = ({ spreadsheetId, sheetName, token }) => {
//   const [loading, setLoading] = useState(false);
//   const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
//   const [settings, setSettings] = useState({});
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedColumns, setSelectedColumns] = useState([]);
//   const [caseSensitive, setCaseSensitive] = useState(false);
//   const [partialMatch, setPartialMatch] = useState(true);
//   const [previewData, setPreviewData] = useState([]);
//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [originalData, setOriginalData] = useState([]);

//   useEffect(() => {
//     loadSettings();
//   }, []);

//   const loadSettings = () => {
//     const savedSettings = localStorage.getItem('itemSpecificsColumns');
//     if (savedSettings) {
//       setSettings(JSON.parse(savedSettings));
//     }
//   };

//   const handleUpdateSpreadsheet = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         { headers: { Authorization: `Bearer ${token}` } }
//       );
      
//       const data = response.data.values;
//       const headers = data[0];

//       const titleIndex = headers.findIndex(header => header.toLowerCase() === 'title');
//       if (titleIndex === -1) {
//         throw new Error('Title column not found');
//       }

//       setOriginalData(data);

//       const updatedData = data.map((row, rowIndex) => {
//         if (rowIndex === 0) return row;

//         const title = row[titleIndex];
//         const newRow = [...row];
        
//         selectedColumns.forEach(column => {
//           const columnIndex = headers.findIndex(header => header === column);
//           if (columnIndex !== -1) {
//             const values = settings[column] || [];
//             for (const value of values) {
//               const regex = new RegExp(value, caseSensitive ? 'g' : 'gi');
//               if (partialMatch ? regex.test(title) : title.toLowerCase() === value.toLowerCase()) {
//                 newRow[columnIndex] = value;
//                 break;
//               }
//             }
//           }
//         });

//         return newRow;
//       });

//       const previewData = updatedData.map((row, rowIndex) => {
//         if (rowIndex === 0) return ['Title', ...selectedColumns.map(col => `${col} (Before)` ), ...selectedColumns.map(col => `${col} (After)`)];
        
//         const previewRow = [row[titleIndex]];
//         selectedColumns.forEach(column => {
//           const columnIndex = headers.findIndex(header => header === column);
//           previewRow.push(data[rowIndex][columnIndex]);  // Before
//           previewRow.push(row[columnIndex]);  // After
//         });
//         return previewRow;
//       });

//       setPreviewData(previewData);
//       setPreviewOpen(true);
//     } catch (error) {
//       console.error('Error updating spreadsheet:', error);
//       setSnackbar({ open: true, message: `Error updating spreadsheet: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const confirmUpdate = async () => {
//     setLoading(true);
//     try {
//       const headers = originalData[0];
//       const updatedData = originalData.map((row, rowIndex) => {
//         if (rowIndex === 0) return row;
//         const newRow = [...row];
//         selectedColumns.forEach((column, colIndex) => {
//           const columnIndex = headers.findIndex(header => header === column);
//           if (columnIndex !== -1) {
//             newRow[columnIndex] = previewData[rowIndex][colIndex * 2 + 2];  // +2 for title and offset
//           }
//         });
//         return newRow;
//       });

//       await axios.put(
//         `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}`,
//         {
//           range: sheetName,
//           values: updatedData
//         },
//         {
//           params: { valueInputOption: 'RAW' },
//           headers: { Authorization: `Bearer ${token}` }
//         }
//       );

//       setSnackbar({ open: true, message: 'Spreadsheet updated successfully', severity: 'success' });
//       setPreviewOpen(false);
//     } catch (error) {
//       console.error('Error confirming update:', error);
//       setSnackbar({ open: true, message: `Error confirming update: ${error.message}`, severity: 'error' });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleColumnToggle = (column) => {
//     setSelectedColumns(prev => 
//       prev.includes(column) 
//         ? prev.filter(c => c !== column)
//         : [...prev, column]
//     );
//   };

//   const handleCaseSensitiveToggle = (event) => {
//     setCaseSensitive(event.target.checked);
//   };

//   const handlePartialMatchToggle = (event) => {
//     setPartialMatch(event.target.checked);
//   };

//   return (
//     <Paper elevation={3} sx={{ p: 3, mt: 2, mb: 2 }}>
//       <Typography variant="h5" gutterBottom>Item Specifics適用</Typography>
//       <Typography variant="body1" paragraph>
//         設定したItem Specificsをタイトルに基づいて適用し、該当する列を更新します。
//       </Typography>
//       <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
//         <Button
//           startIcon={<UpdateIcon />}
//           onClick={handleUpdateSpreadsheet}
//           variant="contained"
//           color="primary"
//           disabled={loading}
//         >
//           {loading ? <CircularProgress size={24} /> : 'スプレッドシートを更新'}
//         </Button>
//         <Button
//           startIcon={<SettingsIcon />}
//           onClick={handleOpenDialog}
//           variant="outlined"
//           color="secondary"
//         >
//           設定
//         </Button>
//       </Box>

//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>Item Specifics設定</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             適用するItem Specificsの列を選択してください。
//           </Typography>
//           <List>
//             {Object.keys(settings).map((column) => (
//               <ListItem key={column} button onClick={() => handleColumnToggle(column)}>
//                 <Checkbox
//                   edge="start"
//                   checked={selectedColumns.includes(column)}
//                   tabIndex={-1}
//                   disableRipple
//                 />
//                 <ListItemText primary={column} />
//               </ListItem>
//             ))}
//           </List>
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={caseSensitive}
//                 onChange={handleCaseSensitiveToggle}
//                 name="caseSensitive"
//               />
//             }
//             label="大文字小文字を区別する"
//           />
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={partialMatch}
//                 onChange={handlePartialMatchToggle}
//                 name="partialMatch"
//               />
//             }
//             label="部分一致を許可する"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             閉じる
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={previewOpen}
//         onClose={() => setPreviewOpen(false)}
//         maxWidth="lg"
//         fullWidth
//       >
//         <DialogTitle>更新プレビュー</DialogTitle>
//         <DialogContent>
//           <Typography variant="body2" paragraph>
//             以下の内容で更新されます。確認してください。
//           </Typography>
//           <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
//             <Table stickyHeader aria-label="preview table">
//               <TableHead>
//                 <TableRow>
//                   {previewData[0] && previewData[0].map((header, index) => (
//                     <TableCell key={index}>{header}</TableCell>
//                   ))}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {previewData.slice(1, 11).map((row, rowIndex) => (
//                   <TableRow key={rowIndex}>
//                     {row.map((cell, cellIndex) => (
//                       <TableCell key={cellIndex}>{cell}</TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           {previewData.length > 11 && (
//             <Typography variant="body2" sx={{ mt: 2 }}>
//               (表示は上位10行のみです。実際の更新はすべての行に適用されます。)
//             </Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setPreviewOpen(false)} color="secondary">
//             キャンセル
//           </Button>
//           <Button onClick={confirmUpdate} color="primary" variant="contained">
//             更新を確定
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Snackbar
//         open={snackbar.open}
//         autoHideDuration={6000}
//         onClose={() => setSnackbar({ ...snackbar, open: false })}
//       >
//         <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
//           {snackbar.message}
//         </Alert>
//       </Snackbar>
//     </Paper>
//   );
// };

// export default ItemSpecificsApplier;