import React, { useState, useCallback, useEffect, useRef } from 'react';
import { 
  Button, 
  TextField, 
  Typography, 
  Box, 
  CircularProgress, 
  Alert, 
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  LinearProgress,
  Slider,
  FormControlLabel,
  Switch,
  Paper,
  Grid
} from '@mui/material';
import axios from 'axios';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useTheme } from '@mui/material/styles';

const INITIAL_BATCH_SIZE = 5;
const MAX_BATCH_SIZE = 20;
const MIN_BATCH_SIZE = 1;
const PRODUCTS_PER_FILE = 150;
const RATE_LIMIT_PERIOD = 24 * 60 * 60 * 1000; // 24 hours
const MAX_REQUESTS_PER_DAY = 1000;
const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 1000;

const workers = [
  'https://broken-glitter-2eca.kikuchi-shun.workers.dev/',
  'https://bold-block-5adc.kikuchi-shun.workers.dev/',
  'https://broken-fire-195b.kikuchi-shun.workers.dev/'
];

const userAgents = [
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36',
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.1.1 Safari/605.1.15',
  'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:89.0) Gecko/20100101 Firefox/89.0'
];

function getRandomWorker() {
  return workers[Math.floor(Math.random() * workers.length)];
}

function getRandomUserAgent() {
  return userAgents[Math.floor(Math.random() * userAgents.length)];
}

const imageCache = new Map();

function checkRateLimit() {
  const now = Date.now();
  const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
  const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
  if (validRequests.length >= MAX_REQUESTS_PER_DAY) {
    return false;
  }
  validRequests.push(now);
  localStorage.setItem('rateLimitRequests', JSON.stringify(validRequests));
  return true;
}

function getRemainingRequests() {
  const now = Date.now();
  const requests = JSON.parse(localStorage.getItem('rateLimitRequests') || '[]');
  const validRequests = requests.filter(time => now - time < RATE_LIMIT_PERIOD);
  return MAX_REQUESTS_PER_DAY - validRequests.length;
}

async function download(url, retryCount = 0, retryDelay = INITIAL_RETRY_DELAY) {
  if (imageCache.has(url)) {
    return imageCache.get(url);
  }

  if (!checkRateLimit()) {
    throw new Error('Daily rate limit exceeded');
  }

  const workerUrl = getRandomWorker();
  const proxyUrl = `${workerUrl}/?url=${encodeURIComponent(url)}`;
  
  try {
    const response = await axios.get(proxyUrl, {
      responseType: 'arraybuffer',
      timeout: 30000,
      headers: {
        'User-Agent': getRandomUserAgent(),
        'Referer': 'https://www.rakuten.co.jp/'
      }
    });
    const data = response.data;
    imageCache.set(url, data);
    return data;
  } catch (error) {
    if (retryCount < MAX_RETRIES) {
      await new Promise(resolve => setTimeout(resolve, retryDelay));
      return download(url, retryCount + 1, retryDelay * 2);
    }
    throw error;
  }
}

function ImageDownloader({ spreadsheetId, sheetName, token, data }) {
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(150);
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [failCount, setFailCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [batchSize, setBatchSize] = useState(INITIAL_BATCH_SIZE);
  const [autoAdjustBatchSize, setAutoAdjustBatchSize] = useState(true);
  const [downloadSpeed, setDownloadSpeed] = useState(0);
  const [estimatedTimeRemaining, setEstimatedTimeRemaining] = useState(0);
  const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
  const [remainingRequests, setRemainingRequests] = useState(getRemainingRequests());
  const theme = useTheme();

  const zipRef = useRef(new JSZip());
  const imageFolderRef = useRef(null);
  const startTimeRef = useRef(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setEndRow(Math.min(data.length - 1, 150));
    }
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingRequests(getRemainingRequests());
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const handleStartRowChange = (event) => {
    const value = parseInt(event.target.value);
    setStartRow(Math.max(1, value));
  };

  const handleEndRowChange = (event) => {
    const value = parseInt(event.target.value);
    setEndRow(Math.min(Math.max(startRow, value), data.length - 1, 150));
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleDownloadConfirmation = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleOpenSettingsDialog = () => {
    setOpenSettingsDialog(true);
  };

  const handleCloseSettingsDialog = () => {
    setOpenSettingsDialog(false);
  };

  const adjustBatchSize = (successRate) => {
    if (autoAdjustBatchSize) {
      if (successRate > 0.95 && batchSize < MAX_BATCH_SIZE) {
        setBatchSize(prev => prev + 1);
      } else if (successRate < 0.8 && batchSize > MIN_BATCH_SIZE) {
        setBatchSize(prev => Math.max(prev - 2, MIN_BATCH_SIZE));
      } else if (successRate < 0.6 && batchSize > MIN_BATCH_SIZE) {
        setBatchSize(prev => Math.max(prev - 3, MIN_BATCH_SIZE));
      }
    }
  };

  const processBatch = async (images, startIndex, globalStartRow) => {
    const batch = images.slice(startIndex, startIndex + batchSize);
    const results = await Promise.all(batch.map(async (imageUrl, index) => {
      try {
        const imageData = await download(imageUrl);
        setRemainingRequests(getRemainingRequests());
        return {
          success: true,
          data: imageData,
          fileName: `image_${globalStartRow + startIndex + index + 1}.jpg`,
          url: imageUrl
        };
      } catch (err) {
        console.error(`Failed to download image ${imageUrl}:`, err);
        return {
          success: false,
          url: imageUrl,
          error: err.message || 'Unknown error'
        };
      }
    }));

    const successfulDownloads = results.filter(r => r.success).length;
    const successRate = successfulDownloads / batch.length;

    adjustBatchSize(successRate);

    return results;
  };

  const updateDownloadStats = (downloadedCount, totalCount, elapsedTime) => {
    const speed = downloadedCount / (elapsedTime / 1000);
    setDownloadSpeed(speed);

    const remainingCount = totalCount - downloadedCount;
    const estimatedTime = remainingCount / speed;
    setEstimatedTimeRemaining(estimatedTime);
  };

  const handleDownload = useCallback(async () => {
    setIsDownloading(true);
    setError('');
    setProgress(0);
    setSuccessCount(0);
    setFailCount(0);
    startTimeRef.current = Date.now();
    imageFolderRef.current = zipRef.current.folder('images');

    try {
      const picUrlIndex = data[0].findIndex(header => header.toLowerCase() === 'picurl');
      if (picUrlIndex === -1) {
        throw new Error('PicURL column not found');
      }

      const actualEndRow = Math.min(endRow, data.length - 1, 150);
      const imagesToDownload = data.slice(startRow, actualEndRow + 1).map(row => row[picUrlIndex].split('|')[0]);

      let failedImages = [];
      for (let i = 0; i < imagesToDownload.length; i += batchSize) {
        const batchResults = await processBatch(imagesToDownload, i, startRow - 1);
        batchResults.forEach(result => {
          if (result.success) {
            imageFolderRef.current.file(result.fileName, result.data);
            setSuccessCount(prev => prev + 1);
          } else {
            setFailCount(prev => prev + 1);
            failedImages.push(result.url);
          }
        });

        const totalProgress = i + batchSize;
        setProgress(Math.round((totalProgress / imagesToDownload.length) * 100));

        const elapsedTime = Date.now() - startTimeRef.current;
        updateDownloadStats(totalProgress, imagesToDownload.length, elapsedTime);

        setRemainingRequests(getRemainingRequests());
      }

      // リトライ処理
      if (failedImages.length > 0) {
        const retryResults = await Promise.all(failedImages.map(async url => {
          try {
            const imageData = await download(url, 0, INITIAL_RETRY_DELAY * 2);
            setSuccessCount(prev => prev + 1);
            setFailCount(prev => prev - 1);
            setRemainingRequests(getRemainingRequests());
            return { success: true, data: imageData, fileName: `retry_${url.split('/').pop()}`, url };
          } catch (err) {
            console.error(`Retry failed for ${url}:`, err);
            return { success: false, url, error: err.message || 'Unknown error' };
          }
        }));

        retryResults.forEach(result => {
          if (result.success) {
            imageFolderRef.current.file(result.fileName, result.data);
          }
        });
      }

      // 全ての処理が完了したらZIPファイルを生成
      const content = await zipRef.current.generateAsync({ type: 'blob' });
      saveAs(content, `images_${startRow}_to_${actualEndRow}.zip`);

      const finalMessage = `Download completed. ${successCount} images downloaded successfully, ${failCount} failed.`;
      showSnackbar(finalMessage);
    } catch (err) {
      const errorMessage = `Download failed: ${err.message}`;
      setError(errorMessage);
      showSnackbar(errorMessage);
      console.error(errorMessage, err);
    } finally {
      setIsDownloading(false);
      setOpenConfirmDialog(false);
    }
  }, [data, startRow, endRow, batchSize]);

  return (
    <Box sx={{ mt: 5, p: 3, backgroundColor: theme.palette.background.paper, borderRadius: theme.shape.borderRadius }}>
      <Typography variant="h6" gutterBottom>Image Downloader</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            label="Start Row"
            type="number"
            value={startRow}
            onChange={handleStartRowChange}
            fullWidth
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="End Row"
            type="number"
            value={endRow}
            onChange={handleEndRowChange}
            fullWidth
            InputProps={{ inputProps: { min: startRow, max: Math.min(data ? data.length - 1 : startRow, 150) } }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip title="Download images from the specified range">
            <Button
              variant="contained"
              onClick={handleDownloadConfirmation}
              disabled={isDownloading || !data || data.length <= 1 || remainingRequests <= 0}
              fullWidth
            >
              {isDownloading ? <CircularProgress size={24} /> : '画像ダウンロード'}
            </Button>
          </Tooltip>
        </Grid>
        {/* <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            onClick={handleOpenSettingsDialog}
            fullWidth
          >
            設定
          </Button>
        </Grid> */}
      </Grid>

      <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
        Remaining daily requests: {remainingRequests} / {MAX_REQUESTS_PER_DAY}
      </Typography>

      {isDownloading && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Progress: {progress}%
          </Typography>
          <LinearProgress variant="determinate" value={progress} sx={{ mb: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Download speed: {downloadSpeed.toFixed(2)} images/second
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Estimated time remaining: {formatTimeRemaining(estimatedTimeRemaining)}
          </Typography>
        </Box>
      )}

      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

      <Paper elevation={3} sx={{ mt: 9, p: 2 }}>
        <Typography variant="body2" color="text.secondary">
          Successfully downloaded: {successCount}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Failed downloads: {failCount}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Current batch size: {batchSize}
        </Typography>
      </Paper>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"確認"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`${startRow}行目から${endRow}行目までの画像をダウンロードします。よろしいですか？`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleDownload} color="primary" autoFocus>
            ダウンロード
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSettingsDialog}
        onClose={handleCloseSettingsDialog}
        aria-labelledby="settings-dialog-title"
      >
        <DialogTitle id="settings-dialog-title">ダウンロード設定</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Switch
                checked={autoAdjustBatchSize}
                onChange={(e) => setAutoAdjustBatchSize(e.target.checked)}
              />
            }
            label="バッチサイズの自動調整"
          />
          <Box sx={{ mt: 2 }}>
            <Typography id="batch-size-slider" gutterBottom>
              バッチサイズ: {batchSize}
            </Typography>
            <Slider
              value={batchSize}
              onChange={(_, newValue) => setBatchSize(newValue)}
              aria-labelledby="batch-size-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={MIN_BATCH_SIZE}
              max={MAX_BATCH_SIZE}
              disabled={autoAdjustBatchSize}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSettingsDialog} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// ヘルパー関数: 残り時間を人間が読みやすい形式に変換
function formatTimeRemaining(seconds) {
  if (seconds < 60) {
    return `${Math.round(seconds)}秒`;
  } else if (seconds < 3600) {
    return `${Math.round(seconds / 60)}分`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.round((seconds % 3600) / 60);
    return `${hours}時間${minutes}分`;
  }
}

// エラーログ関数
function logError(message, error) {
  console.error(message, error);
  // ここにエラーロギングサービスへの送信ロジックを追加することができます
}

// レート制限のリセット関数
function resetRateLimit() {
  localStorage.removeItem('rateLimitRequests');
}

// キャッシュクリア関数
function clearImageCache() {
  imageCache.clear();
}

// ワーカーの状態をチェックする関数
async function checkWorkersStatus() {
  const results = await Promise.all(workers.map(async (worker) => {
    try {
      const response = await fetch(worker);
      return { worker, status: response.status === 200 ? 'OK' : 'Error' };
    } catch (error) {
      return { worker, status: 'Error' };
    }
  }));
  return results;
}

export default ImageDownloader;