import React, { useState, useCallback, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import { 
  Box, 
  Typography, 
  Slider, 
  TextField, 
  Button, 
  Paper, 
  Grid, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  IconButton,
  Tooltip, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SaveIcon from '@mui/icons-material/Save';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomColumnSettings = ({ onSave, initialSettings, userId }) => {
  const [targetProfitMargin, setTargetProfitMargin] = useState(25);
  const [categories, setCategories] = useState({
    additionalCosts: [],
    expenses: [],
    fees: [],
    shipping: []
  });
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(140);
  const [isLoadingExchangeRate, setIsLoadingExchangeRate] = useState(false);
  const [shippingRates, setShippingRates] = useState([
    { minPriceJPY: 0, maxPriceJPY: 7000, costJPY: 700, minPriceUSD: 0, maxPriceUSD: 50, costUSD: 5 },
    { minPriceJPY: 7000, maxPriceJPY: 14000, costJPY: 1400, minPriceUSD: 50, maxPriceUSD: 100, costUSD: 10 },
    { minPriceJPY: 14000, maxPriceJPY: Infinity, costJPY: 2100, minPriceUSD: 100, maxPriceUSD: Infinity, costUSD: 15 }
  ]);
  const [fees, setFees] = useState({
    ebayFinalValue: 10,
    payoneer: 2,
    promotedListing: 3,
    ebayTransactionFee: 0.3
  });
  const [discountRate, setDiscountRate] = useState(0);
  const [isShippingDialogOpen, setIsShippingDialogOpen] = useState(false);
  const [isFeeDialogOpen, setIsFeeDialogOpen] = useState(false);
  const [isDiscountDialogOpen, setIsDiscountDialogOpen] = useState(false);
  const [bestOfferAutoAcceptPercentage, setBestOfferAutoAcceptPercentage] = useState(95);
  const [minimumBestOfferPercentage, setMinimumBestOfferPercentage] = useState(80);
  const [isBestOfferDialogOpen, setIsBestOfferDialogOpen] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    if (initialSettings) {
      setTargetProfitMargin(initialSettings.targetProfitMargin || 25);
      setCategories(prevCategories => ({
        ...prevCategories,
        ...(initialSettings.categories || {})
      }));
      setShippingRates(prevRates => initialSettings.shippingRates || prevRates);
      setFees(prevFees => ({
        ...prevFees,
        ...(initialSettings.fees || {})
      }));
      setDiscountRate(initialSettings.discountRate || 0);
      setBestOfferAutoAcceptPercentage(initialSettings.bestOfferAutoAcceptPercentage || 95);
      setMinimumBestOfferPercentage(initialSettings.minimumBestOfferPercentage || 80);
      setExchangeRate(initialSettings.exchangeRate || 140);
    }
  }, [initialSettings]);

  useEffect(() => {
    if (userId) {
      loadUserSettings(userId);
    }
  }, [userId]);

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const loadUserSettings = async (userId) => {
    try {
      const docRef = doc(db, "userSettings", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userSettings = docSnap.data();
        setTargetProfitMargin(userSettings.targetProfitMargin || 25);
        setCategories(prevCategories => ({
          ...prevCategories,
          ...(userSettings.categories || {})
        }));
        setShippingRates(prevRates => userSettings.shippingRates || prevRates);
        setFees(prevFees => ({
          ...prevFees,
          ...(userSettings.fees || {})
        }));
        setDiscountRate(userSettings.discountRate || 0);
        setBestOfferAutoAcceptPercentage(userSettings.bestOfferAutoAcceptPercentage || 95);
        setMinimumBestOfferPercentage(userSettings.minimumBestOfferPercentage || 80);
        setExchangeRate(userSettings.exchangeRate || 140);
      }
    } catch (error) {
      console.error('Failed to load user settings:', error);
      setSnackbar({ open: true, message: 'ユーザー設定の読み込みに失敗しました', severity: 'error' });
    }
  };

  const saveUserSettings = async () => {
    if (!userId) return;

    const settings = {
      categories,
      targetProfitMargin,
      shippingRates,
      fees,
      discountRate,
      bestOfferAutoAcceptPercentage,
      minimumBestOfferPercentage,
      exchangeRate
    };

    try {
      const docRef = doc(db, "userSettings", userId);
      await setDoc(docRef, settings);
      setSnackbar({ open: true, message: 'ユーザー設定を保存しました', severity: 'success' });
    } catch (error) {
      console.error('Failed to save user settings:', error);
      setSnackbar({ open: true, message: 'ユーザー設定の保存に失敗しました', severity: 'error' });
    }
  };

  const fetchExchangeRate = async () => {
    setIsLoadingExchangeRate(true);
    try {
      const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
      const newRate = response.data.rates.JPY;
      setExchangeRate(newRate);
      updateShippingRatesWithNewExchangeRate(newRate);
      setSnackbar({ open: true, message: `為替レートを更新しました: 1 USD = ${newRate} JPY`, severity: 'info' });
    } catch (error) {
      console.error('Failed to fetch exchange rate:', error);
      setSnackbar({ open: true, message: '為替レートの取得に失敗しました。デフォルトのレートを使用します。', severity: 'error' });
    } finally {
      setIsLoadingExchangeRate(false);
    }
  };

  const updateShippingRatesWithNewExchangeRate = (newRate) => {
    setShippingRates(prevRates => prevRates.map(rate => ({
      ...rate,
      minPriceUSD: (rate.minPriceJPY / newRate).toFixed(2),
      maxPriceUSD: rate.maxPriceJPY === Infinity ? Infinity : (rate.maxPriceJPY / newRate).toFixed(2),
      costUSD: (rate.costJPY / newRate).toFixed(2)
    })));
  };

  const calculateSellingPrice = useCallback((startPrice) => {
    let totalCost = startPrice;
    let totalPercentage = 0;

    Object.values(categories).flat().forEach(item => {
      if (item.type === 'fixed') {
        totalCost += item.value;
      } else if (item.type === 'percentage') {
        totalPercentage += item.value;
      }
    });

    totalPercentage += targetProfitMargin;
    const sellingPrice = totalCost / (1 - totalPercentage / 100);
    return sellingPrice.toFixed(2);
  }, [categories, targetProfitMargin]);

  const handleProfitMarginChange = (event, newValue) => {
    setTargetProfitMargin(newValue);
  };

  const handleItemValueChange = (categoryKey, itemId, newValue, field) => {
    setCategories(prevCategories => ({
      ...prevCategories,
      [categoryKey]: prevCategories[categoryKey].map(item =>
        item.id === itemId ? { ...item, [field]: field === 'value' ? parseFloat(newValue) || 0 : newValue } : item
      )
    }));
  };

  const handleAddItem = (categoryKey) => {
    const newItem = { id: `new-item-${Date.now()}`, name: '新しい項目', value: 0, type: 'fixed' };
    setCategories(prevCategories => ({
      ...prevCategories,
      [categoryKey]: [...prevCategories[categoryKey], newItem]
    }));
    setSnackbar({ open: true, message: '新しい項目を追加しました', severity: 'success' });
  };

  const handleRemoveItem = (categoryKey, itemId) => {
    setCategories(prevCategories => ({
      ...prevCategories,
      [categoryKey]: prevCategories[categoryKey].filter(item => item.id !== itemId)
    }));
    setSnackbar({ open: true, message: '項目を削除しました', severity: 'info' });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const sourceCategory = result.source.droppableId;
    const destCategory = result.destination.droppableId;

    setCategories(prevCategories => {
      const newCategories = { ...prevCategories };
      const [reorderedItem] = newCategories[sourceCategory].splice(result.source.index, 1);
      newCategories[destCategory].splice(result.destination.index, 0, reorderedItem);
      return newCategories;
    });

    setSnackbar({ open: true, message: '項目の順序を変更しました', severity: 'info' });
  };

  const handleSaveSettings = () => {
    const settings = {
      categories,
      targetProfitMargin,
      shippingRates,
      fees,
      discountRate,
      bestOfferAutoAcceptPercentage,
      minimumBestOfferPercentage,
      exchangeRate,
      calculateSellingPrice: (startPrice) => calculateSellingPrice(startPrice)
    };
    onSave(settings);
    saveUserSettings();
    setSnackbar({ open: true, message: '設定を保存しました', severity: 'success' });
  };

  const handleResetSettings = () => {
    setIsResetDialogOpen(true);
  };

  const confirmResetSettings = () => {
    setCategories({
      additionalCosts: [],
      expenses: [],
      fees: [],
      shipping: []
    });
    setTargetProfitMargin(25);
    setShippingRates([
      { minPriceJPY: 0, maxPriceJPY: 7000, costJPY: 700, minPriceUSD: 0, maxPriceUSD: 50, costUSD: 5 },
      { minPriceJPY: 7000, maxPriceJPY: 14000, costJPY: 1400, minPriceUSD: 50, maxPriceUSD: 100, costUSD: 10 },
      { minPriceJPY: 14000, maxPriceJPY: Infinity, costJPY: 2100, minPriceUSD: 100, maxPriceUSD: Infinity, costUSD: 15 }
    ]);
    setFees({
      ebayFinalValue: 10,
      payoneer: 2,
      promotedListing: 3,
      ebayTransactionFee: 0.3
    });
    setDiscountRate(0);
    setBestOfferAutoAcceptPercentage(95);
    setMinimumBestOfferPercentage(80);
    setExchangeRate(140);
    setIsResetDialogOpen(false);
    setSnackbar({ open: true, message: '設定をデフォルトに戻しました', severity: 'info' });
  };

  const handleShippingRateChange = (index, field, value) => {
    setShippingRates(prevRates => {
      const newRates = [...prevRates];
      if (field.endsWith('JPY')) {
        newRates[index][field] = parseFloat(value);
        newRates[index][field.replace('JPY', 'USD')] = (parseFloat(value) / exchangeRate).toFixed(2);
      } else if (field.endsWith('USD')) {
        newRates[index][field] = parseFloat(value);
        newRates[index][field.replace('USD', 'JPY')] = Math.round(parseFloat(value) * exchangeRate);
      }
      return newRates;
    });
  };

  const handleAddShippingRate = () => {
    setShippingRates(prevRates => [
      ...prevRates,
      { minPriceJPY: 0, maxPriceJPY: 0, costJPY: 0, minPriceUSD: 0, maxPriceUSD: 0, costUSD: 0 }
    ]);
  };

  const handleRemoveShippingRate = (index) => {
    setShippingRates(prevRates => prevRates.filter((_, i) => i !== index));
  };

  const handleFeeChange = (feeType, value) => {
    setFees(prevFees => ({
      ...prevFees,
      [feeType]: parseFloat(value)
    }));
  };

  const handleDiscountRateChange = (event, newValue) => {
    setDiscountRate(newValue);
  };

  const handleBestOfferAutoAcceptChange = (event) => {
    setBestOfferAutoAcceptPercentage(parseFloat(event.target.value));
  };

  const handleMinimumBestOfferChange = (event) => {
    setMinimumBestOfferPercentage(parseFloat(event.target.value));
  };

  const renderCategory = (categoryKey, categoryName) => (
    <Accordion key={categoryKey}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{categoryName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Droppable droppableId={categoryKey}>
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {categories[categoryKey].map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{ display: 'flex', alignItems: 'center', mb: 1 }}
                    >
                      <TextField
                        label={item.name}
                        value={item.name}
                        onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'name')}
                        size="small"
                        sx={{ flexGrow: 1, mr: 1 }}
                      />
                      <TextField
                        label="値"
                        value={item.value}
                        onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'value')}
                        type="number"
                        size="small"
                        sx={{ width: 100, mr: 1 }}
                      />
                      <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
                        <InputLabel>タイプ</InputLabel>
                        <Select
                          value={item.type}
                          onChange={(e) => handleItemValueChange(categoryKey, item.id, e.target.value, 'type')}
                          label="タイプ"
                        >
                          <MenuItem value="fixed">固定</MenuItem>
                          <MenuItem value="percentage">割合 (%)</MenuItem>
                        </Select>
                      </FormControl>
                      <IconButton onClick={() => handleRemoveItem(categoryKey, item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
        <Button startIcon={<AddIcon />} onClick={() => handleAddItem(categoryKey)}>
          項目を追加
        </Button>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom>
        価格計算設定
        <Tooltip title="ヘルプを表示">
          <IconButton onClick={() => setIsHelpDialogOpen(true)}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>目標利益率: {targetProfitMargin}%</Typography>
        <Slider
          value={targetProfitMargin}
          onChange={handleProfitMarginChange}
          aria-labelledby="profit-margin-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={0}
          max={100}
        />
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>為替レート設定</Typography>
        <Typography>
          現在の為替レート: 1 USD = {exchangeRate.toFixed(2)} JPY
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchExchangeRate}
          disabled={isLoadingExchangeRate}
          startIcon={<RefreshIcon />}
          sx={{ mt: 2 }}
        >
          {isLoadingExchangeRate ? '更新中...' : '為替レートを更新'}
        </Button>
      </Paper>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsShippingDialogOpen(true)}
            fullWidth
          >
            送料設定
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsFeeDialogOpen(true)}
            fullWidth
          >
            手数料設定
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsDiscountDialogOpen(true)}
            fullWidth
          >
            割引設定
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsBestOfferDialogOpen(true)}
            fullWidth
          >
            ベストオファー設定
          </Button>
        </Grid>
      </Grid>

      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderCategory('additionalCosts', '上乗せ金額')}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderCategory('fees', '手数料')}
          </Grid>
        </Grid>
      </DragDropContext>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button startIcon={<SaveIcon />} variant="contained" color="primary" onClick={handleSaveSettings}>
          設定を保存
        </Button>
        <Button startIcon={<SettingsBackupRestoreIcon />} variant="outlined" onClick={handleResetSettings}>
          デフォルトに戻す
        </Button>
      </Box>

      {/* ヘルプダイアログ */}
      <Dialog open={isHelpDialogOpen} onClose={() => setIsHelpDialogOpen(false)}>
        <DialogTitle>価格計算設定のヘルプ</DialogTitle>
        <DialogContent>
          <Typography paragraph>
            このツールは、StartPrice（仕入れ価格）をベースに、目標とする利益率に基づいて適切な販売価格を計算します。
            各カテゴリーの項目をカスタマイズし、ドラッグ＆ドロップで並べ替えることができます。
          </Typography>
          <Typography paragraph>
            1. 目標利益率を設定します。
            2. 各カテゴリー（追加コスト、経費、手数料、送料）の項目と金額を入力します。
            3. 各項目は固定金額か割合（%）で設定できます。
            4. 必要に応じて項目を追加・削除・編集します。
            5. 送料設定、手数料設定、割引設定、ベストオファー設定を行います。
            6. 為替レートは自動で取得されますが、手動で更新することもできます。
          </Typography>
          <Typography>
            設定を保存して後で呼び出すことも可能です。デフォルトの設定に戻すこともできます。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsHelpDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* リセット確認ダイアログ */}
      <Dialog open={isResetDialogOpen} onClose={() => setIsResetDialogOpen(false)}>
        <DialogTitle>設定をリセット</DialogTitle>
        <DialogContent>
          <Typography>
            本当に設定をデフォルトに戻しますか？この操作は元に戻せません。
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsResetDialogOpen(false)}>キャンセル</Button>
          <Button onClick={confirmResetSettings} color="error">リセット</Button>
        </DialogActions>
      </Dialog>

      {/* 送料設定ダイアログ */}
      <Dialog open={isShippingDialogOpen} onClose={() => setIsShippingDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>送料設定</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>最小仕入れ価格 (JPY)</TableCell>
                  <TableCell>最大仕入れ価格 (JPY)</TableCell>
                  <TableCell>送料 (JPY)</TableCell>
                  <TableCell>最小仕入れ価格 (USD)</TableCell>
                  <TableCell>最大仕入れ価格 (USD)</TableCell>
                  <TableCell>送料 (USD)</TableCell>
                  <TableCell>操作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shippingRates.map((rate, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.minPriceJPY}
                        onChange={(e) => handleShippingRateChange(index, 'minPriceJPY', e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.maxPriceJPY === Infinity ? '∞' : rate.maxPriceJPY}
                        onChange={(e) => handleShippingRateChange(index, 'maxPriceJPY', e.target.value === '∞' ? Infinity : e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={rate.costJPY}
                        onChange={(e) => handleShippingRateChange(index, 'costJPY', e.target.value)}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      {(rate.minPriceJPY / exchangeRate).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {rate.maxPriceJPY === Infinity ? '∞' : (rate.maxPriceJPY / exchangeRate).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {(rate.costJPY / exchangeRate).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleRemoveShippingRate(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button startIcon={<AddIcon />} onClick={handleAddShippingRate} sx={{ mt: 2 }}>
            送料設定を追加
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsShippingDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 手数料設定ダイアログ */}
      <Dialog open={isFeeDialogOpen} onClose={() => setIsFeeDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>手数料設定</DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <ListItemText primary="eBay最終価値手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.ebayFinalValue}
                  onChange={(e) => handleFeeChange('ebayFinalValue', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="Payoneer手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.payoneer}
                  onChange={(e) => handleFeeChange('payoneer', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="広告出品手数料 (%)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.promotedListing}
                  onChange={(e) => handleFeeChange('promotedListing', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="eBay取引手数料 ($)" />
              <ListItemSecondaryAction>
                <TextField
                  type="number"
                  value={fees.ebayTransactionFee}
                  onChange={(e) => handleFeeChange('ebayTransactionFee', e.target.value)}
                  size="small"
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsFeeDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* 割引設定ダイアログ */}
      <Dialog open={isDiscountDialogOpen} onClose={() => setIsDiscountDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>割引設定</DialogTitle>
        <DialogContent>
          <Typography id="discount-slider" gutterBottom>
            割引率: {discountRate}%
          </Typography>
          <Slider
            value={discountRate}
            onChange={handleDiscountRateChange}
            aria-labelledby="discount-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={50}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDiscountDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      {/* ベストオファー設定ダイアログ */}
      <Dialog open={isBestOfferDialogOpen} onClose={() => setIsBestOfferDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>ベストオファー設定</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="ベストオファー自動承認価格 (%)"
                type="number"
                value={bestOfferAutoAcceptPercentage}
                onChange={handleBestOfferAutoAcceptChange}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="最小ベストオファー価格 (%)"
                type="number"
                value={minimumBestOfferPercentage}
                onChange={handleMinimumBestOfferChange}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsBestOfferDialogOpen(false)}>閉じる</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomColumnSettings;