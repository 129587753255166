import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { Box, Button, TextField, Typography } from '@mui/material';
import './BulkColumnEdit.css';

function BulkColumnEdit({ spreadsheetId, sheetName, fetchData, selectedColumn, token }) {
  const [columnNumber, setColumnNumber] = useState('');
  const [newValue, setNewValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    if (selectedColumn) {
      setColumnNumber(columnToNumber(selectedColumn));
    }
  }, [selectedColumn]);

  const columnToLetter = (column) => {
    let temp, letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
  };

  const columnToNumber = (letter) => {
    let column = 0;
    for (let i = 0; i < letter.length; i++) {
      column += (letter.charCodeAt(i) - 64) * Math.pow(26, letter.length - i - 1);
    }
    return column;
  };

  const handleBulkEdit = async () => {
    if (!columnNumber || !newValue) {
      setError('Please enter both column number and new value');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage('');

    try {
      const columnLetter = columnToLetter(parseInt(columnNumber));
      const range = `${sheetName}!${columnLetter}:${columnLetter}`;
      console.log('Range:', range); // デバッグ用ログ

      const getResponse = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const currentValues = getResponse.data.values || [];
      console.log('Current values:', currentValues); // デバッグ用ログ

      // 現在のデータの長さに合わせて新しい値を設定
      const newValues = currentValues.map((row, index) => {
        if (index === 0) return row; // ヘッダー行を保持
        return [newValue];
      });

      console.log('New values:', newValues); // デバッグ用ログ

      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
        {
          range: range,
          majorDimension: "COLUMNS",
          values: [newValues.map(row => row[0])]
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      fetchData();
      setNewValue('');
      setMessage(`Column ${columnLetter} (${columnNumber}) updated successfully`);
    } catch (err) {
      console.error('Error:', err);
      setError(`Failed to update column ${columnNumber}. ${err.response?.data?.error?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      complete: (results) => {
        setCsvData(results.data);
      },
      header: false,
    });
  };

  const handleCsvImport = async () => {
    if (!csvData) {
      setError('Please select a CSV file to import');
      return;
    }

    setLoading(true);
    setError(null);
    setMessage('');

    try {
      // まず、シートの内容をクリア
      await axios.post(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${sheetName}:clear`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      // 次に、新しいデータを書き込む
      const range = `${sheetName}!A1`;
      await axios.put(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?valueInputOption=RAW`,
        {
          values: csvData
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      fetchData();
      setCsvData(null);
      setMessage('CSV import completed successfully. The sheet has been replaced.');
    } catch (err) {
      setError('Failed to import CSV. Please check your file and try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCsvDownload = async () => {
    setLoading(true);
    setError(null);
    setMessage('');

    try {
      const range = `${sheetName}!A1:ZZ1000`; // 範囲を拡大

      const getResponse = await axios.get(
        `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const sheetData = getResponse.data.values;
      const rowCount = sheetData.length - 1; // ヘッダー行を除いた行数（商品数）

      // 最初の商品のタイトルを取得（ヘッダー行をスキップ）
      const titleIndex = sheetData[0].findIndex(header => header.toLowerCase() === 'title');
      let firstItemTitle = titleIndex !== -1 && sheetData.length > 1 ? sheetData[1][titleIndex] : '';
      
      // タイトルを短縮（最大20文字）
      firstItemTitle = firstItemTitle.length > 20 ? firstItemTitle.substring(0, 20) + '...' : firstItemTitle;
      // ファイル名に使用できない文字を除去
      firstItemTitle = firstItemTitle.replace(/[<>:"/\\|?*]/g, '').trim();

      const csv = Papa.unparse(sheetData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      
      // 現在の日付を取得
      const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD形式

      // ファイル名を生成
      const fileName = `${sheetName}_${currentDate}_${rowCount}items_${firstItemTitle}.csv`;

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setMessage(`CSV download completed successfully. File: ${fileName}`);
    } catch (err) {
      setError('Failed to download CSV. Please try again.');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="bulk-edit-container">
      <Box className="bulk-forms-row" sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box className="bulk-form" sx={{ flex: 1, mr: 2 }}>
          <Typography variant="h6" className="bulk-title">カラム一括編集</Typography>
          <Box className="bulk-input-group" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <TextField
              type="number"
              value={columnNumber}
              onChange={(e) => setColumnNumber(e.target.value)}
              label="列数字 (e.g., 1, 2, 3)"
              className="bulk-input-field"
              variant="outlined"
              margin="normal"
              min="1"
              sx={{ 
                mr: 4, 
                width: '200px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
                '& .MuiInputBase-input': {
                  height: '1em',
                  fontSize: '1rem',
                  borderBottom: '1px solid #000',
                }
              }}
              InputLabelProps={{ 
                shrink: true,
                style: { 
                  position: 'relative',
                  transform: 'none',
                  fontSize: '0.75rem',
                  color: '#666',
                } 
              }}
            />
            <TextField
              type="text"
              value={newValue}
              onChange={(e) => setNewValue(e.target.value)}
              label="新しい値"
              className="bulk-input-field"
              variant="outlined"
              margin="normal"
              sx={{ 
                mr: 4,
                width: '400px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'transparent',
                  },
                  '&:hover fieldset': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
                '& .MuiInputBase-input': {
                  height: '1em',
                  fontSize: '1rem',
                  borderBottom: '1px solid #000',
                }
              }}
              InputLabelProps={{ 
                shrink: true,
                style: { 
                  position: 'relative',
                  transform: 'none',
                  fontSize: '0.75rem',
                  color: '#666',
                } 
              }}
            />

            <Button
              onClick={handleBulkEdit}
              disabled={loading}
              variant="contained"
              color="success"
              className="bulk-btn"
              size="small"  // サイズを小さく設定
              sx={{ mt: 3 }}
            >
              {loading ? 'Updating...' : 'アップデート'}
            </Button>
          </Box>
        </Box>
        <Box className="bulk-form" sx={{ flex: 1, mr: 2 }}>
          <Typography variant="h6" className="bulk-title">CSVインポート</Typography>
          <Box className="bulk-input-group" sx={{ display: 'flex' }}>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              className="bulk-input-file"
            />
            <Button
              onClick={handleCsvImport}
              disabled={loading || !csvData}
              variant="contained"
              color="success"
              size="small"  // サイズを小さく設定
              className="bulk-btn"
              sx={{ mt: 2 }}
            >
              {loading ? 'Importing...' : 'Import CSV'}
            </Button>
          </Box>
        </Box>
        <Box className="bulk-form" sx={{ flex: 1 }}>
          <Typography variant="h6" className="bulk-title">CSVダウンロード</Typography>
          <Box className="bulk-input-group" sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              onClick={handleCsvDownload}
              disabled={loading}
              variant="contained"
              color="success"
              className="bulk-btn"
              size="small"  // サイズを小さく設定
              sx={{ mt: 2 }}
            >
              {loading ? 'Downloading...' : 'ダウンロード'}
            </Button>
          </Box>
        </Box>
      </Box>
      {error && <Typography className="bulk-error-message" color="error">{error}</Typography>}
      {message && <Typography className="bulk-success-message" color="success">{message}</Typography>}
    </Box>
  );
}

export default BulkColumnEdit;